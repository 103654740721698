import * as THREE from 'three'
import { CONTAINED, INTERSECTED, NOT_INTERSECTED } from 'three-mesh-bvh'
import { free, toNDC } from '../functions/threeCustomFunction'
//import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
//import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
import { getConvexHull, pointRayCrossesSegments, lineCrossesLine } from '../functions/math'
import { removeVertices, cleanGeometry, cleanEdge } from '../functions/modifyGeometry'




class CreateModel {

	constructor({model, scene, camera, domElement, controls}) {
		this.enabled = false
		this.selectionPoints = []
		this.selectionPointsDraw = []
		this.dragging = false
		this.selectionShapeNeedsUpdate = false
		this.selectionNeedsUpdate = false
		this.scene = scene
		this.model = model
		this.camera = camera
		this.domElement = domElement
		this.controls = controls
		this.colorAttr = model.geometry.getAttribute('color').clone()

	// handle building lasso shape
		this.mouseStart = new THREE.Vector2(-Infinity, -Infinity)
		this.mousePrev = new THREE.Vector2(-Infinity, -Infinity)
		this.tempVec0 = new THREE.Vector2()
		this.tempVec1 = new THREE.Vector2()
		this.tempVec2 = new THREE.Vector2()

	// handle select lasso shape
		this.toScreenSpaceMatrix = new THREE.Matrix4()
		this.boxPoints = new Array( 8 ).fill().map( () => new THREE.Vector3() )
		this.boxLines = new Array( 12 ).fill().map( () => new THREE.Line3() )
		this.lassoSegments = []
		this.perBoundsSegments = []


	//selected indices array
		this.indices = new Set()
		this.boxIndices = new Set()
		this.lassoIndicesAdd = new Set()
		this.lassoIndicesRemove = new Set()


	// selection shape
		this.selectionShape = this.createSelectionShape()

	// selection box
		this.selectionBox = this.createSelectionBox()

	//basement 
		this.model.userData.borderIndex = new Set()
		this.model.userData.borderCurve = null

	}


	enable = () => {
		if (this.enabled)
			return

		this.selectionBox = this.createSelectionBox()
		this.boxIndices = this.findIntersectionBox()
		this.colorSelection()
		this.enableLasso()
		if (this.model.userData.basement) this.model.remove(this.model.userData.basement)
		this.enabled = true
	}

	disable = () => {
		if (!this.enabled)
			return

		this.scene.remove(this.selectionBox)

		const colorAttr = this.model.geometry.attributes.color
		colorAttr.copy(this.colorAttr)
		colorAttr.needsUpdate = true
		this.disableLasso()
		if (this.model.userData.basement) this.model.add(this.model.userData.basement)
		this.enabled = false
	}



	createSelectionBox = () => {


		free(this.selectionBox)

		
		//const box = getRealBoundingBox(this.model)
		const box = new THREE.Box3().setFromObject(this.model, true)
		const center = new THREE.Vector3()
		const dimensions = new THREE.Vector3()

		box.getCenter(center)
		box.getSize(dimensions)

		const selectionBox = new THREE.Mesh (
			new THREE.BoxGeometry(dimensions.x, dimensions.y, dimensions.z),
			new THREE.MeshBasicMaterial({visible: false /* , opacity: 0.1, transparent: true, color: 'red' */  })
		)

		//selectionBox.position.set(center.x, center.y - dimensions.y/2, center.z + dimensions.z/6)
		selectionBox.position.set(0, 0, 0)
		this.scene.add(selectionBox)
		return selectionBox
	}




	createSelectionShape = () => {

		const selectionShape = new THREE.Line()
		selectionShape.material.color.set( 0xff6600 )
		selectionShape.renderOrder = 1
		selectionShape.material.depthTest = false
		selectionShape.material.depthWrite = false
		this.scene.add( selectionShape )

		return selectionShape
	}



	enableLasso = () => {
		this.domElement.addEventListener('contextmenu', this.preventContext)
		this.domElement.addEventListener( 'pointerdown', this.handleLassoDown)
	}


	disableLasso = () => {
		this.domElement.removeEventListener('contextmenu', this.preventContext)
		this.domElement.removeEventListener( 'pointerdown', this.handleLassoDown)
	}


	preventContext = e => {
		e.preventDefault()
	}


	handleLassoDown = e => {

		if (this.controls.enabled)
			return

		this.mousePrev.x = e.clientX
		this.mousePrev.y = e.clientY
		toNDC(e, this.mouseStart, this.domElement)

		this.selectionPoints.length = 0
		this.selectionPointsDraw.length = 0
		this.dragging = true

		this.domElement.addEventListener( 'pointerup', this.handleLassoUp)
		this.domElement.addEventListener( 'pointermove', this.handleLassoMove)

	}


	handleLassoUp = e => {
		
		if (this.controls.enabled)
			return

		this.selectionShape.visible = false;
		this.dragging = false;

		if ( this.selectionPoints.length > 0 )
			this.findIntersectionLasso(e.button)

		this.domElement.removeEventListener( 'pointerup', this.handleLassoUp)
		this.domElement.removeEventListener( 'pointermove', this.handleLassoMove)

	}


	handleLassoMove = e => {

		if (this.controls.enabled)
			return

		this.buttons = e.buttons
		if ( e.buttons === 0 )
			return

	//select green / unselect red
		else if (e.buttons === 2 )
			this.selectionShape.material.color.set(0xff0000)
		else 
			this.selectionShape.material.color.set(0x00ff00)


		const mouseE = new THREE.Vector2(e.clientX, e.clientY)
		const mouseN = new THREE.Vector2()
		toNDC(e, mouseN, this.domElement)

		// If the mouse hasn't moved a lot since the last point
		if ( Math.abs( mouseE.x - this.mousePrev.x ) >= 3 || Math.abs( mouseE.y - this.mousePrev.y ) >= 3 ) {

			const t = new THREE.Vector3()
			t.project(this.camera)

			const p = new THREE.Vector3(mouseN.x, mouseN.y, t.z)
			const p2 = p.clone().unproject(this.camera)

			//array for select in NDC & array for draw
			this.selectionPoints.push( p.x, p.y, 0 )
			this.selectionPointsDraw.push( p2.x, p2.y, p2.z )

			this.mousePrev.x = mouseE.x
			this.mousePrev.y = mouseE.y

			this.selectionShapeNeedsUpdate = true

			//draw if long enough
			if (this.selectionPointsDraw.length > 9)
				this.selectionShape.visible = true

		}

	}


	attach = model => {
		free(this.selectionBox)
		this.model = model
		this.colorAttr = model.geometry.attributes.color.clone()
		this.selectionBox = this.createSelectionBox()
	}



	setSelectionBoxPosition = ({x, y, z}) => {

		if (x)
			this.selectionBox.position.x = x

		if (y)
			this.selectionBox.position.y = y		

		if (z)
			this.selectionBox.position.z = z

		if (this.enabled) {

			this.boxIndices = this.findIntersectionBox()
			this.colorSelection()
		}

	}

	getSelectionBox = () => {
		const box = new THREE.Box3()
		const center = new THREE.Vector3()
		const dimensions = new THREE.Vector3()

		box.setFromObject(this.selectionBox, true)
		box.getCenter(center)
		box.getSize(dimensions)

		const position = this.selectionBox.position


		return { position, center, dimensions}
	}


	selectAll = () => {
		const indices = new Set(this.model.geometry.getIndex().array)
		this.lassoIndicesAdd.clear()
		this.lassoIndicesRemove.clear()
		this.boxIndices = indices
		this.colorSelection()

	}

	deselect = () => {

		const indices = new Set()
		this.lassoIndicesAdd.clear()
		this.lassoIndicesRemove.clear()
		this.boxIndices = indices
		this.colorSelection()
	}

	colorSelection = () => {

		const indices = new Set(this.boxIndices)
		this.lassoIndicesAdd.forEach(index => indices.add(index))
		this.lassoIndicesRemove.forEach(index => indices.delete(index))

		this.model.geometry.attributes.color.copy(this.colorAttr)
		const colorAttr = this.model.geometry.attributes.color

		indices.forEach( index => {
			colorAttr.setXYZ( index, 1, 0.4, 0 )
		})

		colorAttr.needsUpdate = true
		this.indices = indices

	}



	findIntersectionBox = () => {

		const selectionBox = new THREE.Box3()
		selectionBox.setFromObject(this.selectionBox, true)
		this.model.updateMatrixWorld()
		const modelMatrix = this.model.matrixWorld.clone()

		// Collect the intersected vertices
		const indices = new Set()
		const tempVec = new THREE.Vector3()
		const indexAttr = this.model.geometry.getIndex()
		const bvh = this.model.geometry.boundsTree
		
		bvh.shapecast(
			{

				intersectsBounds: ( box, isLeaf, score, depth, nodeIndex ) => {

					box.applyMatrix4(modelMatrix)
					const intersects = selectionBox.intersectsBox( box )
					const { min, max } = box
					if ( intersects ) {

						for ( let x = 0; x <= 1; x ++ ) {

							for ( let y = 0; y <= 1; y ++ ) {

								for ( let z = 0; z <= 1; z ++ ) {

									tempVec.set(
										x === 0 ? min.x : max.x,
										y === 0 ? min.y : max.y,
										z === 0 ? min.z : max.z
									)


									if ( ! selectionBox.containsPoint( tempVec ) ) {

										return INTERSECTED
									}
								}
							}
						}

						return CONTAINED

					}

					return intersects ? INTERSECTED : NOT_INTERSECTED

				},


				intersectsTriangle: ( tri, index, contained ) => {

					const i3 = 3 * index
					const a = i3 + 0
					const b = i3 + 1
					const c = i3 + 2
					const va = indexAttr.getX( a )
					const vb = indexAttr.getX( b )
					const vc = indexAttr.getX( c )
					if ( contained ) {

						indices.add( va )
						indices.add( vb )
						indices.add( vc )

					} else {

						if ( selectionBox.containsPoint( tri.a ) )
							indices.add( va )

						if ( selectionBox.containsPoint( tri.b ) ) 
							indices.add( vb )

						if ( selectionBox.containsPoint( tri.c ) ) 
							indices.add( vc )
					}

					return false

				}
			}
		)

		return indices
	}



	findIntersectionLasso = button => {

		this.toScreenSpaceMatrix
			.copy( this.model.matrixWorld )
			.premultiply( this.camera.matrixWorldInverse )
			.premultiply( this.camera.projectionMatrix )

		// create scratch points and lines to use for selection
		while ( this.lassoSegments.length < this.selectionPoints.length ) {

			this.lassoSegments.push( new THREE.Line3() )

		}

		this.lassoSegments.length = this.selectionPoints.length

		for ( let s = 0, l = this.selectionPoints.length; s < l; s += 3 ) {

			const line = this.lassoSegments[ s ]
			const sNext = ( s + 3 ) % l
			line.start.x = this.selectionPoints[ s ]
			line.start.y = this.selectionPoints[ s + 1 ]

			line.end.x = this.selectionPoints[ sNext ]
			line.end.y = this.selectionPoints[ sNext + 1 ]

		}


		const indices = new Set()
		const indexAttr = this.model.geometry.index

		this.model.geometry.boundsTree.shapecast(
			{
				intersectsBounds: ( box, isLeaf, score, depth ) => {

					// Get the bounding box points
					const { min, max } = box
					let index = 0

					let minY = Infinity
					let maxY = - Infinity
					let minX = Infinity
					for ( let x = 0; x <= 1; x ++ ) {

						for ( let y = 0; y <= 1; y ++ ) {

							for ( let z = 0; z <= 1; z ++ ) {

								const v = this.boxPoints[ index ]
								v.x = x === 0 ? min.x : max.x
								v.y = y === 0 ? min.y : max.y
								v.z = z === 0 ? min.z : max.z
								v.w = 1
								v.applyMatrix4( this.toScreenSpaceMatrix )
								index ++

								if ( v.y < minY ) minY = v.y
								if ( v.y > maxY ) maxY = v.y
								if ( v.x < minX ) minX = v.x
							}
						}
					}

					// Find all the relevant segments here and cache them in the above array for
					// subsequent child checks to use.
					const parentSegments = this.perBoundsSegments[ depth - 1 ] || this.lassoSegments
					const segmentsToCheck = this.perBoundsSegments[ depth ] || []
					segmentsToCheck.length = 0
					this.perBoundsSegments[ depth ] = segmentsToCheck
					for ( let i = 0, l = parentSegments.length; i < l; i ++ ) {

						const line = parentSegments[ i ]
						const sx = line.start.x
						const sy = line.start.y
						const ex = line.end.x
						const ey = line.end.y
						if ( sx < minX && ex < minX ) continue

						const startAbove = sy > maxY
						const endAbove = ey > maxY
						if ( startAbove && endAbove ) continue

						const startBelow = sy < minY
						const endBelow = ey < minY
						if ( startBelow && endBelow ) continue

						segmentsToCheck.push( line )

					}

					if ( segmentsToCheck.length === 0 ) {

						return NOT_INTERSECTED

					}

					// Get the screen space hull lines
					let hull = getConvexHull( this.boxPoints )
					if (!hull) return 

					const lines = hull.map( ( p, i ) => {

						const nextP = hull[ ( i + 1 ) % hull.length ]
						const line = this.boxLines[ i ]
						line.start.copy( p )
						line.end.copy( nextP )
						return line

					} )

					// If a lasso point is inside the hull then it's intersected and cannot be contained
					if ( pointRayCrossesSegments( segmentsToCheck[ 0 ].start, lines ) % 2 === 1 ) {

						return INTERSECTED

					}

					// check if the screen space hull is in the lasso
					let crossings = 0
					for ( let i = 0, l = hull.length; i < l; i ++ ) {

						const v = hull[ i ]
						const pCrossings = pointRayCrossesSegments( v, segmentsToCheck )

						if ( i === 0 ) {

							crossings = pCrossings;

						}

						// if two points on the hull have different amounts of crossings then
						// it can only be intersected
						if ( crossings !== pCrossings ) {

							return INTERSECTED

						}

					}

					// check if there are any intersections
					for ( let i = 0, l = lines.length; i < l; i ++ ) {

						const boxLine = lines[ i ]
						for ( let s = 0, ls = segmentsToCheck.length; s < ls; s ++ ) {

							if ( lineCrossesLine( boxLine, segmentsToCheck[ s ] ) ) {

								return INTERSECTED

							}
						}
					}

					return crossings % 2 === 0 ? NOT_INTERSECTED : CONTAINED

				},

				intersectsTriangle: ( tri, index, contained, depth ) => {


					const i3 = 3 * index
					const a = i3 + 0
					const b = i3 + 1
					const c = i3 + 2
					const va = indexAttr.getX( a )
					const vb = indexAttr.getX( b )
					const vc = indexAttr.getX( c )

					if ( contained ) {

						indices.add( va )
						indices.add( vb )
						indices.add( vc )

					}

					return false

				}

			})

			if (button === 0) {
				indices.forEach(index => this.lassoIndicesAdd.add(index))
				indices.forEach(index => this.lassoIndicesRemove.delete(index))
			}
			else if (button === 2) {
				indices.forEach(index => this.lassoIndicesAdd.delete(index))
				indices.forEach(index => this.lassoIndicesRemove.add(index))
			}


			this.toScreenSpaceMatrix = new THREE.Matrix4()
			this.boxPoints = new Array( 8 ).fill().map( () => new THREE.Vector3() )
			this.boxLines = new Array( 12 ).fill().map( () => new THREE.Line3() )
			this.lassoSegments = []
			this.perBoundsSegments = []

			this.colorSelection()
	}



	update = () => {

		if ( this.selectionShapeNeedsUpdate ) {

			const ogLength = this.selectionPoints.length
			this.selectionPoints.push(
				this.selectionPoints[ 0 ],
				this.selectionPoints[ 1 ],
				this.selectionPoints[ 2 ]
			)

			const ogLength2 = this.selectionPointsDraw.length
			this.selectionPointsDraw.push(
				this.selectionPointsDraw[ 0 ],
				this.selectionPointsDraw[ 1 ],
				this.selectionPointsDraw[ 2 ]
			)

			this.selectionShape.geometry.setAttribute(
				'position',
				new THREE.Float32BufferAttribute( this.selectionPointsDraw, 3, false )
			)

			this.selectionPoints.length = ogLength
			this.selectionPointsDraw.length = ogLength2

			this.selectionShapeNeedsUpdate = false

		}
	}




	clean = () => {

		return new Promise((resolve, reject) => {

			if (this.indices.size === 0) {
				reject({error: {message: 'please select part of the model'}})
				return
			}

			this.model.updateMatrixWorld()
			const transform = this.model.matrixWorld.clone()
			const transformInvert = this.model.matrixWorld.clone().invert()

			this.model.geometry.applyMatrix4(transform)
			this.model.geometry.attributes.color.copy(this.colorAttr)


			const mesh = removeVertices(this.model, this.indices)

			this.colorAttr = mesh.geometry.getAttribute('color').clone()

		//inverse transform old gemoetry if reset geometry
			this.model.geometry.applyMatrix4(transformInvert)
			mesh.geometry.applyMatrix4(transformInvert)

			this.model.userData.borderIndex = new Set()
			this.model.userData.borderCurve = null

			
			resolve({mesh})

		})

	}


	fix = (direction=1) => {

		return new Promise((resolve, reject) => {

			cleanGeometry(this.model)
			const {borderIndex, borderCurve} = cleanEdge(this.model, direction)

			this.model.userData.borderIndex = borderIndex
			this.model.userData.borderCurve = borderCurve
			this.colorAttr = this.model.geometry.getAttribute('color').clone()

			resolve({mesh: this.model})
		})

	}


	createBasement = async (height=5, thickness=3, jaw='maxillar') => {

		if (!this.model.userData.borderCurve)
			this.fix()

		const COLOR_INSIDE = [1, 0.4, 0]
		const COLOR_OUTSIDE = [169/255, 145/255, 94/255]


		const extrude = (border1, border2) => {

	//perform indexed extrude
		
			const border1Index = []
			const border2Index = []

			const bufferPosition = []
			const bufferColor = []

			border1.forEach((v, i) => {
				border1Index.push(bufferPosition.length/3)
				bufferPosition.push(v.position.x, v.position.y, v.position.z)
				bufferColor.push(v.color.x, v.color.y, v.color.z)
			})
			border1Index.push(border1Index[0])


			border2.forEach((v, i) => {
				border2Index.push(bufferPosition.length/3)
				bufferPosition.push(v.position.x, v.position.y, v.position.z)
				bufferColor.push(v.color.x, v.color.y, v.color.z)
			})
			border2Index.push(border2Index[0])



			const index = []

			for (let i = 0; i < border1.length ; i++) {

				index.push(border1Index[i], border2Index[i], border2Index[i+1])
				index.push(border2Index[i+1], border1Index[i+1], border1Index[i])
			}



			const geometry = new THREE.BufferGeometry()


			geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(bufferPosition), 3 ) )
			geometry.setAttribute( 'color', new THREE.BufferAttribute( new Float32Array(bufferColor), 3 ) )
			geometry.setIndex(index)
			geometry.computeVertexNormals()

			border1Index.pop()
			border2Index.pop()

			return {geometry, border1Index, border2Index}

		}




		return new Promise(resolve => {

			free(this.model.userData.basement)


			this.model.updateMatrixWorld()
			const transform = this.model.matrixWorld.clone()
			const transformInvert = this.model.matrixWorld.clone().invert()

			this.model.geometry.applyMatrix4(transform)
			this.model.userData.borderCurve.geometry.applyMatrix4(transform)
			this.model.applyMatrix4(transformInvert)



		//sort to avoid normal inverted
			const borderIndex = Array.from(this.model.userData.borderIndex).sort((a, b) => a - b)

		//calc height basement
			this.model.geometry.computeBoundingBox()
			const bBox = this.model.geometry.boundingBox
			const height2 = jaw==='mandibular' ? bBox.min.y - height : bBox.max.y + height

			const basementMaterial = this.model.material

		//external part

			let position = this.model.userData.borderCurve.geometry.getAttribute('position')
			//let normal = this.borderCurve.geometry.getAttribute('normal')

			const edgeBorder = borderIndex.map(index => {
				return {
					position: new THREE.Vector3().fromBufferAttribute(position, index),
					color: new THREE.Vector3().fromArray(COLOR_OUTSIDE)
				}
				 
			})

			const edgeHeight = borderIndex.map(index => {
				const p = new THREE.Vector3().fromBufferAttribute(position, index) 
				return {
					position: new THREE.Vector3(p.x, height2, p.z),
					color: new THREE.Vector3().fromArray(COLOR_OUTSIDE)
				}
			})

			const borderExtrude = extrude(edgeBorder, edgeHeight)
			borderExtrude.geometry.computeBoundsTree()
//const m = new THREE.MeshStandardMaterial({color: 'red'}) */
			const border = new THREE.Mesh( borderExtrude.geometry, basementMaterial )

//const helper = new VertexNormalsHelper( border, 3, 0x00ff00, 1 )
//const helper2 = new VertexNormalsHelper( this.borderCurve, 3, 0xff0000, 2 )
//border.add(helper)




		//border part
			/* position = borderExtrude.geometry.getAttribute('position')
			normal = borderExtrude.geometry.getAttribute('normal')

			const edgeHeight2 = borderExtrude.border2Index.map(index => {
				return {
					position: new THREE.Vector3().fromBufferAttribute(position, index),
					color: new THREE.Vector3().fromArray(COLOR_OUTSIDE)
				}
				 
			})

			const edgeInner = borderExtrude.border2Index.map(index => {

				const p = new THREE.Vector3().fromBufferAttribute(position, index) 
				const n = new THREE.Vector3().fromBufferAttribute(normal, index) 

				n.normalize()
				n.negate()
				n.multiplyScalar(thickness)
				const pp = p.clone().add(n)

				



				const raycasterN = new THREE.Raycaster(pp, new THREE.Vector3(1, 0, 0))
				const raycasterS = new THREE.Raycaster(pp, new THREE.Vector3(-1, 0, 0))
				const raycasterE = new THREE.Raycaster(pp, new THREE.Vector3(0, 0, 1))
				const raycasterW = new THREE.Raycaster(pp, new THREE.Vector3(0, 0, -1))
				const interN = raycasterN.intersectObject(border)
				const interS = raycasterS.intersectObject(border)
				const interE = raycasterE.intersectObject(border)
				const interW = raycasterW.intersectObject(border)


				if(interN.length > 0 && interS.length > 0 && interE.length > 0 && interW.length > 0 )
					p.add(n)


				return {
					position: new THREE.Vector3(p.x, p.y, p.z),
					color: new THREE.Vector3().fromArray(COLOR_OUTSIDE)
				}

			})

			const innerExtrude = extrude(edgeHeight2, edgeInner)
			const inner = new THREE.Mesh( innerExtrude.geometry, basementMaterial ) */
			/* const ii = inner.clone()
			ii.position.y -= height */


		//floor part
			position = borderExtrude.geometry.getAttribute('position')

			const fillPoints = borderExtrude.border2Index.map(index => {
				const p = new THREE.Vector3().fromBufferAttribute(position, index)
				return new THREE.Vector2(p.x, -p.z)
			})


			const shape = new THREE.Shape(fillPoints)
			const floorGeometry = new THREE.ShapeGeometry( shape )

			if (jaw === 'mandibular') {
				const index = floorGeometry.getIndex()
				index.array.reverse()
				index.needsUpdate = true
			}


			floorGeometry.computeVertexNormals()
			floorGeometry.deleteAttribute('uv')
			floorGeometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(floorGeometry.getAttribute('position').count*3),3))
			const colorFill = floorGeometry.getAttribute('color')


			for (let i = 0; i < colorFill.count; i++) {
				const [x, y, z] = COLOR_INSIDE
				colorFill.setXYZ(i, x, y, z)

			}


			const floor = new THREE.Mesh( floorGeometry, basementMaterial ) ;
			floor.rotation.x = -Math.PI/2
			floor.position.y = height2 //jaw==='mandibular' ? bBox.min.y : bBox.max.y



		//internal part
			/* const edgeInner2 = innerExtrude.border2Index.map(index => {
				return {
					position: new THREE.Vector3().fromBufferAttribute(position, index), 
					color: new THREE.Vector3().fromArray(COLOR_INSIDE)
				}

			})


			const edgeInner3 = innerExtrude.border2Index.map(index => {

				const p = new THREE.Vector3().fromBufferAttribute(position, index) 
				return {
					position: new THREE.Vector3(p.x, bBox.max.y, p.z),
					color: new THREE.Vector3().fromArray(COLOR_INSIDE)
				}

			})

			const innerExtrude2 = extrude(edgeInner2, edgeInner3)
			const inner2 = new THREE.Mesh( innerExtrude2.geometry, basementMaterial) */


			const basement = new THREE.Group()
			basement.add(border  , /*inner, inner2, */ floor /*, ii */ )


			this.model.geometry.applyMatrix4(transformInvert)
			this.model.userData.borderCurve.geometry.applyMatrix4(transformInvert)
			this.model.applyMatrix4(transform)

			basement.applyMatrix4(transformInvert)


			resolve({basement})

		})


	

	}




}











export default CreateModel