import React from "react"
import socket from '../functions/socket'
import {formatDate} from '../functions/formatFunctions'
import UserConsumer from '../context/UserContextConsumer'
import Cardtridge from './Cardtridge'
import PulseLoader from "react-spinners/PulseLoader"
import { jsPDF } from "jspdf"
import logo from '../images/cadsmile_logo.png'




const Invoices = props=>{

	return <>

		<div style={{top : 0, bottom : 0, position : 'absolute', display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>

			<AddressInvoice/>

			<InvoicesGenerator/>

		</div>

	</>
}


class AddressInvoice extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading : false,
			updated : false,
			address : {
				name : '',
				address : '',
				postalCode : '',
				city : '',
				state : '',
				country : ''
			}
		}
	}

	componentDidMount = ()=> {

		this.setState({address : {...this.props.userContext.address, name : this.props.userContext.address.name? this.props.userContext.address.name : this.props.userContext.username}})
		
	}

	update = ()=>{
		this.setState({loading : true, address : {...this.state.address, name : this.state.address.name? this.state.address.name : this.props.userContext.username}},()=>{
			socket.emit('updateBillAddress', this.state.address, response=>{
				this.setState({loading : false, updated : response.success? true : false})
			})
		})
	}

	render = ()=>{

		//console.log(this.props.userContext.address.name, this.state.address)

		const inputStyle = {
			margin : 5
		}

		const fields = [
			{name : 'Name or social reason', id : 'name'},
			{name : 'Address', id : 'address'},
			{name : 'ZIP / postal code', id : 'postalCode'},
			{name : 'City / town', id : 'city'},
			{name : 'State / province / region', id : 'state'},
			{name : 'Country', id : 'country'},
		]


		return <>

			<Cardtridge style = {{flex : 1}}>

				<div style={{margin: 10, fontWeight: 'bold'}}>Billing address</div>

				<div>

					{fields.map((field, index)=><input 
						key={index} 
						style={inputStyle} 
						placeholder={field.name} 
						value={this.state.address[field.id]}
						onChange = {e=>this.setState({updated : false, address : {...this.state.address, [field.id] : e.target.value}})}
					/>)}

				</div>

				<button disabled={JSON.stringify(this.state.address)===JSON.stringify(this.props.userContext.address)} style={{position : 'relative'}} onClick={this.update}>

					{this.state.loading? <PulseLoader  loading={true} size={10}/> : 'Update'}
					{this.state.updated && <span style={{position : 'absolute', right : -20, top : -3, fontSize : '1.3em', color : 'green'}}>✓</span>}

				</button>
				
			</Cardtridge>

		</>
	}

}






class InvoicesGenerator extends React.Component {

	constructor(props){
		super(props)
		//this.scrollRef = React.createRef()
		this.state={
			listInvoices : [],
			loading : false,
			numberInvoice : 0,
			fetching : true,
			loader : true,
			startDate : '',
			endDate : ''
		}
	}

	componentDidMount = ()=> {

		this.fetchList()

	}

	fetchList = (limit = 10)=>{

		if (this.state.fetching) {

			this.setState({fetching : false, loading : true})

			let limits = [this.state.listInvoices[this.state.listInvoices.length-1]? this.state.listInvoices[this.state.listInvoices.length-1].id : undefined, limit]
			let startDate = this.state.startDate? new Date(this.state.startDate).getTime()/1000 : undefined
			let endDate = this.state.endDate ? (new Date(this.state.endDate).getTime()/1000) + (24*3600): undefined

			socket.emit('invocesList', limits, startDate, endDate, data=>{
				this.setState({listInvoices : [...this.state.listInvoices, ...data.data], loading : false, fetching : data.data.length<limit? false : true, loader : data.data.length<limit? false : true})
			})

		}
	}

	changeDateFilter = e=>{
		this.setState({listInvoices : [], fetching : true}, this.fetchList)
	}

	handleScroll = e=>{

		if ((e.target.offsetHeight + e.target.scrollTop)>= e.target.scrollHeight) this.setState({loading : true},this.fetchList)
	}

	generatePdf = invoice=>{
		invoicePDF(invoice, this.props.userContext.address)
	}

	render = ()=> {

		const invoiceStyle = {
			padding : 5, margin : 10,
			display: 'flex', alignItems: 'center', justifyContent : 'space-between',
			borderBottom : 'solid'
		}

		const styleIcon = {
			cursor : 'pointer'
		}

		return <>


			<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent : 'space-around',}}>

				<span style={{fontWeight : 'bold'}}>Filter</span>
				<input type='date' value={this.state.startDate} onChange={e=>this.setState({startDate : e.target.value})}/>
				<input type='date' value={this.state.endDate} onChange={e=>this.setState({endDate : e.target.value})}/>
				<button onClick={this.changeDateFilter}>Apply</button>

			</Cardtridge>
		

			<div onScroll={this.handleScroll} style={{flex : 2, overflowY : 'auto'}}>

				{this.state.listInvoices.map((invoice, index)=><div style={invoiceStyle} key={index}>

					<div style={{flex : 2}}>{formatDate(new Date(invoice.status_transitions.paid_at*1000)).englishFormat}</div>
					<div style={{flex : 3}}>Invoice {invoice.number}</div>
					<div style={{flex : 3}}>{invoice.lines.data[0].description}</div>
					<div style={{flex : 1}}>{invoice.lines.data[0].amount/100} {invoice.lines.data[0].currency}</div>
					<div style={{flex : 1}}><i className="material-icons" style={styleIcon} title='download invoice' onClick={()=>this.generatePdf(invoice)}>picture_as_pdf</i></div>

				</div>)}

				{this.state.loading && this.state.loader  && <PulseLoader  loading={true} size={10}/>}

			</div>

		</>

	}


}

const invoicePDF = (invoice, address)=>{

	const date = formatDate(new Date(invoice.status_transitions.paid_at*1000))
	const TVA = 0.2
		
	const doc = new jsPDF()

	doc.setFontSize(12)

	doc.setFont("helvetica", "bold")
	doc.text(`To : ${address.name}`, 20, 70)

	doc.setFont("helvetica", "Normal")
	doc.text(`${address.address}`, 20, 75)
	doc.text(`${address.postalCode} ${address.city}`, 20, 80)
	doc.text(`${address.country} ${address.state}`, 20, 85)

	doc.text(`${date.text}`, 140, 70)

	doc.setFontSize(22);
	doc.setFont("helvetica", "bold")

	doc.text('INVOICE', 140,40)
	doc.setFontSize(16)
	doc.text(`#${invoice.number}`, 140, 50)

	doc.addImage(logo, "PNG", 10, 5, 32, 40)
	doc.setTextColor(240,152,35)
	doc.text('CADSMILE 3D',10,50)

	doc.setFontSize(12)

	doc.setLineWidth(0.5);
	doc.line(20, 100, 190, 100);
	doc.line(20, 220, 190, 220);


	doc.setFontSize(12)
	doc.setTextColor(0,0,0)
	doc.setFont("helvetica", "normal")


	invoice.lines.data.map((line, index)=>{
		doc.text(30, 115+(index*30), line.description)
		doc.text(160, 115+(index*30), `${line.amount/100} ${line.currency}`)
		return true
	})

	let HTTotal=((invoice.total/(1+TVA))/100).toFixed(2)

	doc.text(130, 230, `Total without tax : `)
	doc.text(180, 230, `${HTTotal} ${invoice.currency}`)
	doc.text(130, 240, `Tax (${TVA*100}%) :`)
	doc.text(180, 240, `${(invoice.total/100 - HTTotal).toFixed(2)} ${invoice.currency}`)

	doc.setFontSize(16)
	doc.setFont("helvetica", "bold")

	doc.text(130, 260, `Total`)
	doc.text(180, 260, `${invoice.total/100} ${invoice.currency}`)

	doc.setFont("helvetica", "italic")
	doc.setFontSize(10)
	doc.text(105, 290, 'laBoiteBleue - SIRET 84397348800029', {align : 'center'})



	doc.save(`invoice_cadsmile_${invoice.number}_${date.englishFormat}.pdf`)

}




AddressInvoice = UserConsumer(AddressInvoice)
InvoicesGenerator = UserConsumer(InvoicesGenerator)
export default UserConsumer(Invoices)