import React from 'react'
import socket from '../functions/socket'
import Popup from './Popup'


const Activation = props => {

	const [popup, setPopup] = React.useState(false)
	const [popupMessage, setPopupMessage] = React.useState('')
	const [popupError, setPopupError] = React.useState(false)
	

	React.useEffect(()=>{
		try {

			const activationData = atob(window.location.search.substring(1))
			const activationObj = JSON.parse(activationData)

			socket.emit(props.socketEvent, activationObj, result=> {

				if (result.error) {
					setPopup(true)
					setPopupMessage(result.error.message)
					setPopupError(true)
				} 
				else {
					setPopup(true)
					setPopupMessage(result.message)
					localStorage.setItem('mail', result.mail)
				}
			})
		}
		catch(e) {
			setPopup(true)
			setPopupMessage('your key is invalid or outdated.')
			setPopupError(true)
		}

	}, [props.socketEvent])


	const hidePopup = () => {
		setPopup(false)
		setPopupMessage('')
		setPopupError(false)
		window.setTimeout(() => window.close(), 500) 
	}

	return <>

		{popup&&<Popup hide={hidePopup} message={popupMessage} error={popupError}/>}

	</>
}

export default Activation