import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import AddImageFile from './AddImageFile'
import AddModelFile from './AddModelFile'
import Cardtridge from './Cardtridge'
import SetLandmarks from './SetLandmarks'
import Matching3D from './Matching3D'
import DWD from './DWD'
import list from '../icons/list.svg'
import summary from '../icons/summary.svg'
import Chat from './chat'
import Options from './Options'
import hashNav from '../functions/hashNav'
import socket from '../functions/socket'


class CaseSummary extends React.Component {

	goToList = () => {
		//console.log(this.props.caseContext.id)
		socket.emit('leaveChatRoom',this.props.caseContext.id)
		window.location.hash = ''
	}

	goToSummary = () => {
		hashNav({page: 'summary'})
	}

	render = () => {

		const isSummary = this.props.caseContext.page==='summary'

		return <>

		<div style={{position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', zIndex: 300}}>
			<span style={{fontWeight: 'bolder', fontStyle: 'italic', fontSize: 12}}>{this.props.caseContext.name}</span>
			<span style={{fontStyle: 'italic', fontSize: 10}}>&nbsp; by {this.props.caseContext.creator.name}</span>
		</div>

		<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'grid', gridTemplateColumns: '3fr 5fr 5fr 2fr', gridTemplateRows: '2fr 1fr 2fr', gridGap: 5 }}>
			
			{isSummary&&<Cardtridge style={{gridColumn: 1, gridRow: '1 / span 3' }}><Chat/></Cardtridge>}
			
			<div style={{gridColumn: 2, gridRow: 1, position: 'relative'}}>
				<AddImageFile fileName='photoFace' additionalFileName='photoBuccal'/>
			</div> 

			<div style={{gridColumn: 3, gridRow: 1, position: 'relative'}}>
				<AddModelFile fileName='modelMaxillar'/>
			</div>

			<div style={{gridColumn: 2, gridRow: 2, position: 'relative'}}>
				<SetLandmarks fileName='photoFace'/>
			</div>

			<div style={{gridColumn: 3, gridRow: 2, position: 'relative'}}>
				<Matching3D fileName={{photo: 'photoFace', model: 'modelMaxillar', buccal: 'photoBuccal'}}/>
			</div>

			<div style={{gridColumn: '2 / span 2', gridRow: 3, position: 'relative' }}>
				<DWD fileName={{photo: 'photoFace', buccal: 'photoBuccal', model: 'modelMaxillar', mandibular: 'modelMandibular'}}/>
			</div>


			{isSummary&&<Cardtridge style={{gridColumn: 4, gridRow: '1 / span 3', position: 'relative'}}><Options/></Cardtridge>} 
		</div>

		<img onClick={isSummary?this.goToList:this.goToSummary} alt='list' title={isSummary?'go back to list':'go back to case summary'} src={isSummary?list:summary} style={{position: 'absolute', left: 5, top: 0, zIndex: 50, height: 30, cursor: 'pointer', background: 'rgba(255, 255, 255, 0.5)', boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.3)', padding: 3}}/>

		</>
	}
}


export default CaseConsumer(CaseSummary)