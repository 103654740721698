import React from 'react'


class Transition extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			mounted: props.mounted,
			unmounted: !props.mounted
		}
	}

	componentDidUpdate = (prevProps, prevState) => {

		if (prevProps.mounted !== this.props.mounted) {
			this.setState({mounted: this.props.mounted})
			this.unmount()
		}

	}

	unmount = () => {

		setTimeout(()=>{
				this.setState({unmounted: !this.state.mounted})
			}, this.state.mounted?this.props.time *1000:0)

		setTimeout(()=>{
			if (this.state.mounted)
				this.props.appeared()
			else
				this.props.vanished()
		}, (this.props.time + this.props.delay) *1000)

	}


	render = () => {

		let transition = {}

		if (this.props.transition === 'leftSlide')
			transition = { transform: this.state.mounted?'translateX(0px)':this.state.unmounted?'translateX(-50%)':'translateX(50%)'}		

		if (this.props.transition === 'downSlide')
			transition = { transform: this.state.mounted?'translatey(0px)':this.state.unmounted?'translateY(-50%)':'translateY(50%)'}

		if (this.props.transition === 'accordion')
			transition = { maxHeight: this.state.mounted?'100%':0}


		return <div style={{maxHeight: this.state.mounted?'1000px':'0px', transition: `all ${this.props.time}s ease ${this.props.delay}s`}}>
			<div style={{opacity: this.state.mounted?1:0, transition: `all ${this.props.time}s ease ${this.props.delay}s`, ...transition, ...this.props.style }}>

				{!this.state.unmounted&& this.props.children }

			</div>
		</div>
	}
}



/* const Transition = props => {

	const [mounted, setMounted] = React.useState(props.mounted)
	const [unmounted, setUnmounted] = React.useState(false)

	const unmount = () => {

		if (mounted) 
			setTimeout(()=> setUnmounted(true), props.time *1000)
		
		else
			setUnmounted(false)
	
	}



	React.useEffect(()=> {
		setMounted(props.mounted)
		unmount()
	}, [props.mounted])	



	return <div style={{maxHeight: mounted?'1000px':'0px', transition: `max-height ${props.time}s` }}>
			<div style={{opacity: mounted?1:0, transform: mounted?'translateX(0px)':'translateX(50%)', transition: `transform ${props.time}s, opacity ${props.time}s`}}>

				{!unmounted&& props.children }

			</div>
		</div>
} */

Transition.defaultProps = {
	mounted: true,
	time: 1,
	delay: 0,
	appeared: ()=>null,
	vanished: ()=>null,
	transition: ''
}


export default Transition