import React from 'react'
import * as THREE from 'three'

import Range from './Range'
import Cardtridge from './Cardtridge'
import Menu from './Menu'
import Transition from './Transition'


import { OrthographicCamera } from '../functions/threeCustomClass'
import { loadTexture, unmountThree, free } from '../functions/threeCustomFunction'
import getExif from '../functions/getExif'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { BrightnessContrastShader } from 'three/examples/jsm/shaders/BrightnessContrastShader'
import { HueSaturationShader } from 'three/examples/jsm/shaders/HueSaturationShader'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import brightness from '../icons/brightness.svg'
import contrast from '../icons/contrast.svg'
import saturation from '../icons/saturation.svg'
import rotate from '../icons/rotate90.svg'
import refresh from '../icons/refresh.svg'
import play from '../icons/play.svg'
import trashcan from '../icons/trashcan.svg'
import documentWidth from '../icons/documentWidth.svg'
import documentHeight from '../icons/documentHeight.svg'
import changeFile from '../icons/changeFile.svg'
import fitLips from '../icons/fitSmile.svg'

import CaseConsumer from '../context/CaseContextConsumer'


class AdjustImage extends React.Component {

	constructor(props) {
	 	super(props)
	 	this.containerRef = React.createRef()

	 	this.state = {
	 		anglePlane: 0,
	 		brightness: 0,
	 		contrast: 0,
	 		saturation: 0,
	 		opacity: 0.25,
	 		dimension: [0, 0],
	 		ratio: 1,
	 		exif: {},
	 		buccal: false
	 	}

	 	this.inactive = false
	 	this.inputBuccalRef = React.createRef()
	}


	componentDidMount = () => {

	//scene
		this.scene = new THREE.Scene()

	//camera
		const width = window.innerWidth
		const height = window.innerHeight
		this.camera = new OrthographicCamera( width/- 2, width/2, height/2, height /-2, 1, 1000 )
		this.camera.position.z = 100


	//renderer
		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
		this.renderer.setSize( window.innerWidth, window.innerHeight )

	//post processing
		this.composer = new EffectComposer( this.renderer )
		const renderPass = new RenderPass( this.scene, this.camera )
		this.composer.addPass( renderPass )
		this.shaderpassBright = new ShaderPass( BrightnessContrastShader )
		this.shaderpassBright.material.transparent = true
		this.composer.addPass( this.shaderpassBright )		
		this.shaderPassSat = new ShaderPass( HueSaturationShader )
		this.shaderPassSat.material.transparent = true
		this.composer.addPass( this.shaderPassSat )

		const effectFXAA = new ShaderPass( FXAAShader )
		effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
		this.composer.addPass( effectFXAA )

	//add canvas to DOM
		this.containerRef.current.appendChild( this.renderer.domElement )

	//load image
		this.updateFile(this.props.im)

	//draw in canvas
		this.animate()


	//some events
		window.addEventListener('resize', this.handleResize)


	}


	componentDidUpdate = prevProps => {
		
		if (prevProps.im !== this.props.im) {
			this.updateFile(this.props.im)
		}

	}


	updateFile = async im => {

		this.props.caseContext.setState({loading: true})


		const texturePlane = await loadTexture(im)
		const exif = await getExif(texturePlane.image)
		this.setState({exif})

		const material = new THREE.MeshBasicMaterial( { map: texturePlane, color: 0xffffff } )
		const planeGeo = new THREE.PlaneGeometry( texturePlane.image.width, texturePlane.image.height, 1, 1 )

		free(this.plane)
		this.plane = new THREE.Mesh( planeGeo, material )
		this.scene.add( this.plane )

		this.setState({dimension: this.getDimension()})

	//fit image to screen
		this.camera.fit(this.plane, this.renderer)

	//add buccal photo if exists
		const buccal = this.props.additionalIm
		const buccalTransform = this.props.additionalTransform

		if (buccal) {
			this.plane.clear()
			this.setState({buccal: true})
			const bucc = await loadTexture(buccal)
			const material = new THREE.MeshBasicMaterial( { map: bucc, color: 0xffffff, transparent: true, opacity: this.state.opacity } )
			const planeGeo = new THREE.PlaneGeometry( bucc.image.width, bucc.image.height, 1, 1 )
			this.buccalPlane = new THREE.Mesh( planeGeo, material )
			this.plane.add( this.buccalPlane )

			if (buccalTransform) {
				this.buccalPlane.position.copy(new THREE.Vector3().fromArray(buccalTransform.position))
				this.buccalPlane.quaternion.copy(new THREE.Quaternion().fromArray(buccalTransform.quaternion))
				this.buccalPlane.scale.copy(new THREE.Vector3().fromArray(buccalTransform.scale))
			}
		}

		this.props.caseContext.setState({loading: false})

	}

	componentWillUnmount = () => {
	//avoid leak memory	
		window.removeEventListener('resize', this.handleResize)
		cancelAnimationFrame( this.loop )
		unmountThree(this.scene, this.renderer)

	}


	getDimension = () => {
		const clone = this.plane.clear()
		const bBox = new THREE.Box3().setFromObject(clone, true)
		const meshSize = new THREE.Vector3()
		bBox.getSize(meshSize)

		if (this.buccalPlane)
			this.plane.add(this.buccalPlane)

		return [Math.round(meshSize.x), Math.round(meshSize.y)]
	}

	handleChangeWidth = e => {
		let value = e.target.value
		const [x, ] = this.state.dimension
		const ratio = value / x
		this.setState({ratio: ratio})
	}

	handleBlurWidth = e => {
		let value = e.target.value
		const [x, ] = this.state.dimension
		if (value >= x) value = x
		if (value <= x/10) value = x/10
		const ratio = value / x
		this.setState({ratio: ratio})
	}

	handleChangeHeight = e => {
		let value = e.target.value
		const [, y] = this.state.dimension
		const ratio = value / y
		this.setState({ratio: ratio})
	}

	handleBlurHeight = e => {
		let value = e.target.value
		const [, y] = this.state.dimension
		if (value >= y) value = y
		if (value <= y/10) value = y/10
		const ratio = value / y
		this.setState({ratio: ratio})
	}


	handleResize = () => {
		//change composer size to keep quality
		this.renderer.setSize( window.innerWidth, window.innerHeight )
		this.composer.setSize( window.innerWidth, window.innerHeight )
		this.camera.fit(this.plane, this.renderer)
	}


	rotate = (angle, seconds=0.2)=> {
	//rotate with animation

		let i = 0
		const FPS = 60

		const step = setInterval(()=>{
			this.plane.rotateZ(THREE.MathUtils.degToRad(-angle)/(FPS*seconds))
			this.camera.fit(this.plane, this.renderer)
			i++

			if (i >= FPS*seconds)
				clearInterval(step)

			this.setState({
					anglePlane: Math.round(THREE.MathUtils.radToDeg(this.plane.rotation.z)),
					dimension: this.getDimension()
				})
		}, 1000/FPS)	
	}

	animate = () => {

		this.loop = requestAnimationFrame(this.animate)
		this.refresh()
	}


	refresh = () => {

		this.composer.render()

	}


	changeBrightness = e => {
		let value = e.target.value
		if (value >= 1) value = 1
		if (value <= -1) value = -1
		this.setState({brightness: value})
		this.shaderpassBright.uniforms.brightness.value = value
	}


	changeContrast = e => {
		let value = e.target.value
		if (value >= 1) value = 1
		if (value <= -1) value = -1
		this.setState({contrast: value})
		this.shaderpassBright.uniforms.contrast.value = value
	}


	changeSaturation = e => {
		let value = e.target.value
		if (value >= 1) value = 1
		if (value <= -1) value = -1
		this.setState({saturation: value})
		this.shaderPassSat.uniforms.saturation.value = value
	}

	changeOpacity = e => {
		const opacity = e.target.value
		this.setState({opacity})
		this.buccalPlane.material.opacity = opacity
	}

	resetFilters = () => {
		this.setState({brightness: 0, contrast: 0, saturation: 0})
		this.shaderpassBright.uniforms.brightness.value = 0
		this.shaderpassBright.uniforms.contrast.value = 0
		this.shaderPassSat.uniforms.saturation.value = 0
	}

	
	dropManager = e => {
		e.preventDefault()

		const file = e.dataTransfer.items[0].getAsFile()
		if (!file) return

		this.props.readAdditionalFile(file)
	}


	deleteBuccal = () => {
		this.setState({buccal: false})
		free(this.buccalPlane)
		this.props.cancelAdditional()

	}



	saveImage = () => {



		this.props.caseContext.setState({loading: true})

		const renderToBlob = renderer =>
   			new Promise( resolve =>  {

	   			const blob = renderer.domElement.toDataURL('image/jpeg')
				resolve(blob)	
   			})

   		setTimeout( () => {

			const [x, y] = this.getDimension()
			this.plane.clear()

			//create a brand new canvas for export in full definition
			const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, preserveDrawingBuffer: true})
			renderer.setSize( x*this.state.ratio, y*this.state.ratio )

			//clone camera for fit on new canvas
			const camera = this.camera.clone()

			//apply filters on new canvas
			const composer = new EffectComposer( renderer )
			const renderPass = new RenderPass( this.scene, camera )
			composer.addPass( renderPass )
			composer.addPass( this.shaderpassBright )		
			composer.addPass( this.shaderPassSat )
			//fit to canvas
			camera.fit(this.plane, renderer)
			composer.render()
			const renderImage = renderToBlob(renderer)


			//thumbnail renderer
			const rendererThumb = new THREE.WebGLRenderer({antialias: true, alpha: true, preserveDrawingBuffer: true})
			const size = renderer.getSize(new THREE.Vector2())
			const ratio = size.y / size.x
			rendererThumb.setSize( 512, 512*ratio)
			const composerThumb = new EffectComposer( rendererThumb )
			const renderThumbPass = new RenderPass( this.scene, camera )
			composerThumb.addPass( renderThumbPass )
			composerThumb.addPass( this.shaderpassBright )		
			composerThumb.addPass( this.shaderPassSat )
			composerThumb.render()
			const renderThumbnail = renderToBlob(rendererThumb)

			Promise.all([ renderImage, renderThumbnail ]).then( ([image, thumbnail]) => {
				renderer.dispose()
				this.inactive = false
				this.props.save({exif: this.state.exif, image, thumbnail})

			})

   		}, 100)


	}





	render = ()=> {

		const [x, y] = this.state.dimension

	return <div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>

		<Cardtridge style={{zIndex: 11, position: 'absolute', left: 0, right: 0, top: 40, fontWeight: 'bolder', textAlign: 'right', padding: 0, margin: 0, paddingRight: 10}}>{this.props.additionalImage?'face photo':'buccal photo'}</Cardtridge>
		<div ref={this.containerRef} style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}} onDrop={this.dropManager} onDragOver={e=>e.preventDefault()}></div>


			<Menu>

				<Cardtridge>
					<Range icon={brightness} label='brightness' min={-1} max={1} step={0.05} value={this.state.brightness} onChange={this.changeBrightness}/>
					<Range icon={contrast} label='contrast' min={-1} max={1} step={0.05} value={this.state.contrast} onChange={this.changeContrast}/>
					<Range icon={saturation} label='saturation' min={-1} max={1} step={0.05} value={this.state.saturation} onChange={this.changeSaturation}/>
					<img src={refresh} onClick={this.resetFilters} style={{height: 20, transform: 'scaleX(-1)', cursor: 'pointer'}} alt='reset' title='reset' />
				</Cardtridge>

				<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
					<img src={rotate} onClick={()=>this.rotate(-90)} style={{flex: 1, transform: 'scaleX(-1)', height: 20, cursor: 'pointer'}} alt='rotate90' title='rotate 90° counterclockwise'/>
					<div style={{flex: 1}}>{-this.state.anglePlane}°</div>
					<img src={rotate} onClick={()=>this.rotate(90)} style={{flex: 1, height: 20, cursor: 'pointer'}} alt='rotate90' title='rotate 90° clockwise'/>
				</Cardtridge>

				{this.props.additionalImage&&<>

					<Cardtridge>
						<div 
							onClick={this.props.changeAdditionalFile} style={{border: 'dashed 1px black', margin: 5, cursor: 'pointer', fontWeight: 'bold'}}
							onDrop={this.dropManager} 
							onDragOver={e=>e.preventDefault()}
								>add buccal photo
						</div>

						<Transition mounted={this.state.buccal} transition='leftSlide'>
							<div style={{display: 'flex', alignItems: 'cente', justifyContent: 'space-around'}}>
								<div><button onClick={this.deleteBuccal} title='delete'><img src={trashcan} style={{height: 25, width: 25}} alt='delete'/></button></div>
								<div><button onClick={this.props.goToMatch2D} title='matching'><img src={fitLips} style={{height: 25, width: 25}} alt='matching'/></button></div>
							</div>
							<div><input style={{width: '90%'}} type='range' min={0} max={1} step={0.01} value={this.state.opacity} onChange={this.changeOpacity} /></div>
						</Transition>

					</Cardtridge>
				</>}

				<Cardtridge>
					<Range icon={documentWidth} label='image width' min={x/10} max={x} step={1} value={Math.round(x *this.state.ratio)} onChange={this.handleChangeWidth} onBlur={this.handleBlurWidth}/>
					<Range icon={documentHeight} label='image height' min={y/10} max={y} step={1} value={Math.round(y *this.state.ratio)} onChange={this.handleChangeHeight} onBlur={this.handleBlurHeight}/>

				</Cardtridge>

				<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
					<img src={play} style={{height: 20, cursor: 'pointer', transform: 'rotateZ(180deg)'}} alt='previous step' title='previous step' onClick={this.props.cancel}/>
					<img src={changeFile} style={{height: 20, cursor: 'pointer'}} alt='change file' title='change file' onClick={this.props.changeFile}/>
					<img src={play} style={{height: 20, cursor: 'pointer'}} alt='next step' title='next step' onClick={this.saveImage}/>
				</Cardtridge>

			</Menu>
		
	</div>
	}

}


AdjustImage.defaultProps = {
	additionalImage: false,
	additionalIm: null,
	changeAdditionalFile: () => null,
	readAdditionalFile: () => null,
	cancelAdditional: () => null
}




export default CaseConsumer(AdjustImage)
