import React from 'react'
import Cardtridge from './Cardtridge'
import { UsernameInput, ChoosePasswordInput } from './Inputs'
import Transition from './Transition'
import { ButtonTxt } from './Button'
import { passwordHash } from '../functions/crypto'
import socket from '../functions/socket'
import gcuEV from '../GCU/CGVEV.pdf'
import gcuVF from '../GCU/CGVVF.pdf'

import frFlag from '../icons/frFlag.png'
import engFlag from '../icons/engFlag.png'



import UserConsumer from '../context/UserContextConsumer'


class Register extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

			usernameValid: false,
			PWValid: false,
			CGUCheck: false, 
			usernameValue: '',
			PWValue: '',
			usernameDefault: ''
		}
	}

	usernameValue = value => {
		this.setState({usernameValue: value})
	}

	PWValue = value => {
		this.setState({PWValue: value})
	}

	usernameValid = valid => {
		this.setState({usernameValid: valid})
	}

	PWValid = valid => {
		this.setState({PWValid: valid})
	}

	CGUCheck = e => {
		this.setState({CGUCheck: e.target.checked})
	}

	handleSubmit = e => {
		e.preventDefault()

		this.props.userContext.setState({loading: true})

		const obj = {
			mail: this.props.userContext.mail,
			username: this.state.usernameValue.trim(),
			hashPW: passwordHash(this.state.PWValue)
		}

		socket.emit('register', obj, result => {

			if (!this.state.PWValue) this.props.userContext.setState({popup: true, popupMessage: 'password field is empty.', popupError: true, loading: false})
			else if (result.error) this.props.userContext.setState({popup: true, popupMessage: result.error.message, popupError: true, loading: false})
			else {
				this.props.userContext.setState({popup: true, popupMessage: result.message, loading: false, isExists: true})
			}
		})

		

	}

	render = () => {

		const valid = this.state.usernameValid && this.state.PWValid && this.state.CGUCheck

		return <Cardtridge>
			<form onSubmit={this.handleSubmit}>

				<UsernameInput sessionStorage='username' value={this.usernameValue} valid={this.usernameValid}/>
				<ChoosePasswordInput value={this.PWValue} valid={this.PWValid}/>


				<div style={{fontSize: 12, fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<input type='checkbox' checked={this.state.CGUCheck} onChange={this.CGUCheck}/>i have downloaded, read and accepted the General Condition of Use
				</div>

				<div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
					<div style={{padding: 10}} title='download english'><a href={gcuEV} download='GCU_Cadsmile3D.pdf'><img  alt='en' src={engFlag} width = {40}/></a></div>

					<div style={{padding: 10}} title='download french'><a href={gcuVF} download='CGU_Cadsmile3D.pdf'><img  alt='fr' src={frFlag} width = {40}/></a></div>
				</div>

				<Transition mounted={valid} transition='leftSlide'>
					<ButtonTxt label="subscribe me !"/>
				</Transition>

			</form>
		</Cardtridge>

	}
	
} 


export default UserConsumer(Register)