const MAX_UNDO = 12
const TIME_INACTIVE = 300


class History {

	constructor ({update, loadState, saveState, localStorage=null}) {
		this.update = update
		this.loadState = loadState
		this.saveState = saveState
		this.localStorage = localStorage

		this.current = 0
		this.history = []

		if (this.localStorage && window.localStorage.getItem(this.localStorage))
			this.loadState(window.localStorage.getItem(this.localStorage))
	}


	saveHistory = (...arg) => {

		const history = this.history

		if (history[this.current - 1]) {

			if ( new Date() - history[this.current - 1].time < TIME_INACTIVE)
				return
		}

		history.splice(this.current)
		if (history.length >= MAX_UNDO) {
			history.shift()
			this.current --
		}

		this.history = history
		const currentState =  this.saveState(...arg)
		this.history.push(currentState)
		this.current ++
		this.checkDisable()

		if (this.localStorage) 
			window.localStorage.setItem(this.localStorage, currentState)

	}

	deleteHistory = () => {
		this.current = 0
		this.history = []
		this.saveHistory()
	}


	undo = () => {
		if (this.current === 1)
			return  

		const prevState = this.history[this.current - 2]
		this.current --
		this.loadState(prevState)
		this.checkDisable()

		if (this.localStorage) 
			window.localStorage.setItem(this.localStorage, prevState)

	}


	redo = () => {

		if (this.current === this.history.length)
			return  

		const nextState = this.history[this.current]
		this.current ++
		this.loadState(nextState)
		this.checkDisable()

		if (this.localStorage) 
			window.localStorage.setItem(this.localStorage, nextState)

	}

	checkDisable = () => {
		const disabledUndo = this.current === 1
		const disabledRedo = this.current === this.history.length

		this.update({disabledUndo, disabledRedo})


	}

}

export default History