import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'
import { writeSearch } from '../functions/crypto'
import socket from '../functions/socket'
import pencil from '../icons/pencil.svg'
import trashcan from '../icons/trashcan.svg'
import copy from '../icons/copy.svg'
import share2 from '../icons/share2.svg'
import DesignStore from './designStore'





const Options = props => {

	const creator = props.caseContext.creator.id === props.userContext.id

	return <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflowY: 'auto'}}>

		<CaseName caseContext={props.caseContext} userContext={props.userContext}/>
		<DuplicateCase caseContext={props.caseContext}/>
		{(props.userContext.admin||creator)&&<DeleteCase caseContext={props.caseContext}/>}
		<ShareCase caseContext={props.caseContext} userContext={props.userContext}/>

		<div style={{width : '95%', paddingTop : 15}}><DesignStore/></div>


	</div>
}



class CaseName extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			updatable: false,
			name: props.caseContext.name
		}

		this.inputRef = React.createRef()
	}

	componentDidUpdate = prevProps => {
		if (prevProps.caseContext.name !== this.props.caseContext.name)
			this.setState({name: this.props.caseContext.name})
	}

	makeUpdatable = () => {
		this.setState({updatable: true}, () => {
			this.inputRef.current.focus()
		})
	}

	cancelUpdate = () => {
		this.setState({updatable: false, name: this.props.caseContext.name})
	}

	handleChange = e => {
		this.setState({name: e.target.value})
	}

	validUpdate = e => {
		e.preventDefault()

		if (this.state.name.trim() === '')
			return 


		socket.emit('updateCaseName', this.props.caseContext.id, this.state.name, name => {
			this.props.caseContext.setState({name})
			this.setState({updatable: false})
		})
	}

	validEnter = e => {
		if (e.keyCode === 13)
			this.validUpdate(e)
	}

	render = () => {

		const creator = this.props.caseContext.creator.id === this.props.userContext.id

		return 	<form style={{position: 'relative', width: '100%'}} onSubmit={this.validUpdate}>

			<textarea 
				ref={this.inputRef} style={{resize: 'none', fontWeight: 'bold', textTransform: this.state.updatable?'none':'uppercase', border: 'none', background: 'none', width: '75%'}} 
				disabled={!this.state.updatable} onBlur={this.cancelUpdate}
				value={this.state.name} onChange={this.handleChange}
				onKeyDown={this.validEnter}
				/>	

			{(this.props.userContext.admin || creator)&&!this.state.updatable&&<img src={pencil} onClick={this.makeUpdatable} style={{height: 20, position: 'absolute', right: 0, top: 0, cursor: 'pointer'}} alt='modify' title='update case name'/>}

		</form>

	}
}


const DuplicateCase = props => {

	const duplicateCase = () => {
		window.location.hash = ''
		socket.emit('duplicateCase', props.caseContext.id, newCaseId => {

			window.location.hash = '#case?'+writeSearch({id: newCaseId, page: 'summary'})
		})
	}

	return <div style={{marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
		<img src={copy} style={{height: 25}} alt='copy'/><button onClick={duplicateCase}>duplicate case</button>
	</div> 
}


const DeleteCase = props => {

	const ask = (message, button) => {
		props.caseContext.setState({
			popup: true,
			popupMessage: message,
			popupButton: button
		})
	}

	const askDelete = () => {
		ask(<div>are you sure you want to delete the case "{props.caseContext.name}"?</div>, <button onClick={deleteCase}>yes !</button>)
	}

	const deleteCase = () => {
		socket.emit('deleteCase', props.caseContext.id, () => {
			window.location.hash = ''
			props.caseContext.setState({ popup: false, popupMessage: '', popupButton: null})			
		})
	}

	return <div style={{marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
		<img src={trashcan} style={{height: 25}} alt='delete'/><button onClick={askDelete}>delete case</button>
	</div>
}


const ShareCase = props=> {

	const [mail, setMail] = React.useState('')
	const [valid, setValid] = React.useState(false)

	const error = message => {
		props.userContext.setState({
			popup: true,
			popupError: true,
			popupMessage: message,
			popupButton: null
		})
	}

	const ask = (message, button) => {
		props.userContext.setState({
			popup: true,
			popupMessage: message,
			popupButton: button
		})
	}

	const inviteUser = () => {
		socket.emit('inviteUser', mail, props.userContext.username, response => {
			if (response.error)
				error(response.error.message)
			else
				props.userContext.setState({popup: true, popupError: false, popupMessage: response.message, popupButton: null})
		})
	}


	const share = shared => {
		socket.emit('addShareUser', props.caseContext.id, shared, response => {
			if (response.error)
				error(response.error.message)
			else {
				const userAccess = [...props.caseContext.userAccess, response.userAccess]
				props.userContext.setState({popup: true, popupError: false, popupMessage: response.message, popupButton: null})
				props.caseContext.setState({userAccess})
				erase()
			}
		})
	}

	const askUnshare = user => {
		const message = user.id === props.userContext.id ? 
			'you will lose access to this case' :
			`${user.name} will lose access to this case`

		ask(<div>{message}</div>, <button onClick={() => unshare(user.id)}>do it !</button>)
	}


	const unshare = userId => {

		socket.emit('removeShareUser', props.caseContext.id, userId, response => {

			if (response && response.error)
				error(response.error.message)
			else {
				const userAccess = props.caseContext.userAccess
				const index = userAccess.findIndex(access => access && response && access.id === response.id)
				userAccess.splice(index, 1)
				props.caseContext.setState({userAccess})
				props.userContext.setState({ popup: false, popupMessage: '', popupButton: null})

				if (userId === props.userContext.id)
					window.location.hash = ''		
			}
		})
	}
	


	const handleChange = e => {
		const value = e.target.value.trim()
		updateMail(value)
	}

	const updateMail = value => {
		const regex = /^.+@.+\..{2,4}$/
		setMail(value)
		setValid(regex.test(value))
	}

	const erase = () => {
		updateMail('')
	}

	const sendMail = e=>{
		e.preventDefault()

		socket.emit('checkMailUser', mail, shared => {

			if (!shared)
				error(<>
					<div style={{marginBottom: 10}}>we can't found "{mail}" in our database</div>
					<div><button onClick={inviteUser}>send an invitation to use cadsmile3D</button></div>
				</>)

			else if (props.userContext.admin)
				ask(<div>do you want to share this case with {shared.username}?</div>, <button onClick={()=>share(shared)}>yes !</button>)

			else if (shared._id === props.userContext.id)
				error(<div>you can't share a case with yourself</div>)

			else
				ask(<div>do you want to share this case with {shared.username}?</div>, <button onClick={()=>share(shared)}>yes !</button>)


		})
	}

	const creator = props.caseContext.creator.id === props.userContext.id

	const accessList = props.caseContext.userAccess.map(user => 
		<div key={user.id} style={{position: 'relative'}}>
			{user.name}
			<div onClick={()=>askUnshare(user)} style={{position: 'absolute', right: 0, top: 0, bottom: 0, cursor: 'pointer'}}>&#x2716;</div>
		</div>
	)

	const shareElement = <div>
		<div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}><img src={share2} style={{height: 25}} alt='share'/><div>share case</div></div>
		<form onSubmit={sendMail}>

			<div style={{position: 'relative', width: '90%'}}>
				<input placeholder='email adress' style={{position: 'relative', width: '100%'}} value={mail} onChange={handleChange}/>
				{mail&&<div style={{position: 'absolute', right: 0, top: 0, bottom: 0, cursor: 'pointer'}} onClick={erase}>&#x2716;</div>}
			</div>
			<button disabled={!valid}>share!</button>

			<div>
				{accessList}
			</div>

		</form>
	</div>

	const unshareElement = <div style={{marginBottom: 20}}>
		<button onClick={()=> {
			askUnshare(props.userContext)

		}}>unshare</button>
	</div>

	return <div>
		{(props.userContext.admin || creator)? shareElement : unshareElement}
	</div>
}



export default UserConsumer(CaseConsumer(Options))