import Cardtridge from './Cardtridge'
import React from 'react'



class Popup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this.hideKeyboard)
		setTimeout(this.mount, 50)
	}

	componentWillUnmount = () =>{
		document.removeEventListener('keydown', this.hideKeyboard)
	}

	hide = () => {
		this.props.hide()
	}

	hideKeyboard = e => {
		e.preventDefault()
		if ( e.code === 'Escape' || e.code === 'Space' )
			this.hide()
	}

	mount = () => {
		this.setState({show: true})
	}

	render = () => {
		return <>
			<div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'grey', opacity: this.state.show?0.8:0, zIndex: 250, transition: 'all 0.5s'}} onClick={this.hide}></div>

			<Cardtridge 
				style={{
					zIndex: 250,
					position: 'absolute',
					width: '40%',
					minWidth: 200,
					minHeight: 200,
					left: '50%',
					top: '40%',
					transform: this.state.show?'translate(-50%, -50%)':'translate(-50%, -150%)',
					opacity: this.state.show?1:0,
					backgroundColor: 'white',
					display: 'flex',
					alignItems: 'center', 
					justifyContent: 'center',
					flexDirection: 'column',
					transition: 'all 0.5s'
				}}>

				<div style={{position: 'absolute', top: 5, left: 0, paddingLeft: 10, width: '80%', background: this.props.error?'red':this.props.button?'orange':'green', color: 'white', fontWeight: 'bold', textAlign: 'left'}}>{this.props.error?'ERROR !':this.props.button?'?':'SUCCESS !'}</div>
				<button style={{position: 'absolute', right: 5, top: 5, cursor: 'pointer'}} onClick={this.hide}>&#x2716;</button>


				<div style={{margin: 10}}>{this.props.message}</div>

				<div style={{margin: 10}}>{this.props.button}</div>

			</Cardtridge>
		</>
	}

}



Popup.defaultProps = {
	hide: () => null
}


export default Popup