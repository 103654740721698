import socket from '../functions/socket'
import init from '../init'
import iconWaxupDesign from '../icons/iconWaxupDesign.png'
import iconWaxupDesign2 from '../icons/iconWaxupDesign2.png'


const products = [{
	name : 'waxup design - 1 jaw',
	code : 'waxup',
	icon : iconWaxupDesign,
	price : 60,
	currency : '€',
	description : 'request for a 1 jaw waxup designed by cadsmile3d team',
	time : '24h',
	prerequisite : 'you must upload face smile photography and maxillary digital dental impression',
	
	action : async (caseId, caseName, creator, msg, callback)=>{
		
		let message = `Hey cadsmile3d ! Thx to design a waxup for me (1 jaw) ! ${msg!==''?'My instructions : '+msg : ''}`
		let mailMessage = `About case ${caseId} (${caseName}) : ${message}`
		
		socket.emit('saveChatMsg', message, caseId)
		socket.emit('contactMessage', mailMessage)
		socket.emit('addShareUser',caseId, await checkMailUser(init.designerMail), callback)

	}
},

{
	name : 'waxup design - 2 jaws',
	code : 'waxup2',
	icon : iconWaxupDesign2,
	price : 100,
	currency : '€',
	description : 'request for a 2 jaws waxup designed by cadsmile3d team',
	time : '24h',
	prerequisite : 'you must upload face smile photography and maxillary digital dental impression',
	
	action : async (caseId, caseName, creator, msg, callback)=>{
		
		let message = `Hey cadsmile3d ! Thx to design a waxup for me (2 jaws) ! ${msg!==''?'My instructions : '+msg : ''}`
		let mailMessage = `About case ${caseId} (${caseName}) : ${message}`
		
		socket.emit('saveChatMsg', message, caseId)
		socket.emit('contactMessage', mailMessage)
		socket.emit('addShareUser',caseId, await checkMailUser(init.designerMail), callback)

	}
}]



const checkMailUser = mail=>{

	return new Promise ((resolve, reject)=>{

		socket.emit('checkMailUser', mail, res=>{

			if (res) resolve(res)
			else reject()

		})

	})
}



export default products
