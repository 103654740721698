/** @jsxImportSource @emotion/react */

import React, {useState, useEffect} from "react"
import socket from '../functions/socket'
import UserConsumer from '../context/UserContextConsumer'
import CaseConsumer from '../context/CaseContextConsumer'
import products from '../object/productList.js'
import buyIcon from '../icons/store.svg'
import { css } from '@emotion/react'




// const product = products[0]


const DesignStore = props=>{

	return <>


		<div style={{margin : 5}}>

			<div style={{backgroundColor : 'black'}}>

				<img src={buyIcon} alt='buy' width={30}/>

			

			</div>

		<div style={{display: 'flex', flexDirection : 'column', marginTop : 10}}>
		
		{products.map((item, index)=><Product product={item} key={index}/>)}

		</div>

		</div>

	</>


}





let Product = props =>{

	const [open, setOpen] = useState(false)

	const close = e=>setOpen(false)

	const openStore = e=>{
		let creator = props.caseContext.creator.id===props.userContext.id

		if (creator) setOpen(true)
		else props.userContext.setState({popup: true, popupMessage: 'You must be the case creator for access to the store !', popupError: true})
	}

	// const cssIcon = css`
	// 	width : 50px ;
	// 	margin : 5px ;  margin-bottom : 0px ;
	// 	border : solid ; border-radius : 10px ;
	// 	cursor : pointer ;
	// 	transition : box-shadow 0.2s ;
	// 	&:hover {box-shadow : 0px 0px 0px 2px #000000 ;}
	// `

	const styleIcon = {
		width : 50,
		margin : 5, marginBottom : 0,
		border : 'solid', borderRadius : 10,
	}

	// const styleDiv = {
	// 	//border : 'solid', 
	// 	width : '100%',
	// 	display: 'flex', alignItems: 'center', justifyContent: 'space-around',
	// 	backgroundColor : 'lightgrey'

	// }

	const cssDiv = css`
		width : 100% ;
		display: flex ; align-items : center ; justify-content : space-around ;
		cursor : pointer ;
		transition : background-color 0.2s ;
		&:hover {background-color : lightgrey ;}
	`
	
	return <>

		<div css={cssDiv}> 

			<div style={{flex : 1}}>

				<img 
					style={styleIcon} 
					src={props.product.icon} 
					alt={props.product.name} 
					title={props.product.description}
					onClick={openStore}
				/>

				<div style={{fontSize : 10, position : 'relative', top : -5}}>{props.product.name}</div>

			</div>

			<div style={{flex : 1}}>{props.product.price} {props.product.currency}</div>
	

		</div>

		{open && <DesignForm product={props.product} close={close}/>}

	</>
}




let DesignForm = props=>{

	const [instruction, setInstruction] = useState('')

	const divStyle = {
		//position : 'fixed', 
		//bottom : 20, right : 200, 
		zIndex: 91, 
		background : 'white',
		textAlign : 'left',
		padding : 10,
		display: 'flex', alignItems: 'center', justifyContent: 'space-around'
	}

	const pay = pm=>{

		props.userContext.setState({loading : true})

		socket.emit('buyStore', props.product.code, pm, res=>{

			props.userContext.setState({loading : false})
			//console.log(res)
			if (res.error) paymentFail()
			if (res.success) paymentSuccess()

		})

	}

	const paymentSuccess = ()=>{
		console.log('pay good')
		props.product.action(props.caseContext.id, props.caseContext.name, props.caseContext.creator, instruction,()=>{
			props.userContext.setState({popup: true, popupMessage: 'Congratulation ! Your command is a success !', popupCallback : props.close})
			console.log('close')
		})

	}

	const paymentFail = ()=>{
		props.userContext.setState({popup: true, popupMessage: 'You have problem with your payment method. Please change it and try again !', popupError: true})
	}

	// const close = props.close

	return <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

		<div onClick={props.close} style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'grey', opacity: 0.8, zIndex: 90}}/>

		<div style={divStyle}>

			<div style={{flex : 1, display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection : 'column', padding : 10}}>

				<div style={{textAlign : 'center'}}>

					<h2 style={{position : 'relative', top : -30}}><img alt='icon' style={{position : 'relative', top : 25}} src={props.product.icon} width = {100}/> 
					<br/> {props.product.name}</h2>
		
				</div>

				<DesignFormPay product={props.product} pay={pay}/>

			</div>

			<div style={{flex : 1, display: 'flex', alignItems: 'end', justifyContent: 'end',  flexDirection : 'column', padding : 10}}>

				<div>
					<p><b>Description : </b>{props.product.description}</p>
					<p><b>Delivery delay : </b>{props.product.time}</p>
					<p><b>Prerequisite : </b>{props.product.prerequisite}</p>
					<p><b>Price : </b>{props.product.price} {props.product.currency}</p>

					<textarea 
						placeholder='write here your instructions' 
						rows={6} 
						style={{resize : 'none', width : '100%'}} 
						value={instruction}
						onChange = {e=>setInstruction(e.target.value)}
					/>

				</div>
			
			</div>

		</div>

	</div>
}





let DesignFormPay = props => {

	const [data, setData] = useState(false)
	const [selected, setSelected] = useState('')


	useEffect(() => {

		props.userContext.setState({loading : true})
			
		socket.emit('fetchSubscribeData', props.userContext.subscription.id, data=>{
			setData(data)
			setSelected(data.subscription.customer.invoice_settings.default_payment_method)
			props.userContext.setState({loading : false})
		})

	}, [])

	

	const handleSelect = e=>setSelected(e.target.value)

	const to2 = number=>number<10? '0'+number : number

	const pmList = data? data.pmList.data : []

	const WithPm = <div style={{textAlign : 'center'}}>

			<div>

			<div style={{fontSize : '0.8em', color : '#f09823'}}><i>Be carefull, if you click on buy button, you will pay {props.product.price} {props.product.currency}</i></div>

			<select value={selected? selected : ''} onChange={handleSelect}>
					{pmList.map((pm, index)=><option key = {index} value={pm.id}>💳 XXXX XXXX XXXX {pm.card.last4} {to2(pm.card.exp_month)}/{pm.card.exp_year} {pm.card.exp_year<new Date().getFullYear() || (pm.card.exp_year===new Date().getFullYear() && pm.card.exp_month<new Date().getMonth()+1)?'(EXPIRED)' : ''}</option>)}	
			</select>

			</div>

			<div>

			<button style={{margin : 10, fontSize : '1.5em'}} onClick={e=>props.pay(selected)}>Buy</button>

			</div>

		
		</div>

	const WithoutPm = <div style={{fontSize : '0.8em', color : '#f09823'}}><i>You must record a payment method in your user profil for buying items in store</i></div>

	return <>

		{pmList.length>0 ? WithPm : WithoutPm}

	</>


}









DesignFormPay = UserConsumer(DesignFormPay)
DesignFormPay = CaseConsumer(DesignFormPay)
DesignForm = UserConsumer(DesignForm)
DesignForm = CaseConsumer(DesignForm)
Product = UserConsumer(Product)
Product = CaseConsumer(Product)




export default DesignStore