//import Cardtridge from './Cardtridge'
import React, {useState} from "react"
import socket from '../functions/socket'
import {formatDate} from '../functions/formatFunctions'
import UserConsumer from '../context/UserContextConsumer'
import Cardtridge from './Cardtridge'
import {PayNew, PayCardApplication} from './pay'
//import InvoicesGenerator from './invoices'

import { loadStripe } from "@stripe/stripe-js"
import init from '../init'

const stripePromise = loadStripe(init.stripeKey, {locale: 'en'})
//const stripe = Stripe(init.stripeKey, {locale: 'en'})






let SubscribeStatus = props=>{

	const monthly = {name : 'monthly', amount : 40, currency : 'eur', period : 'month'}
	const yearly = {name : 'yearly', amount : 400, currency : 'eur', period : 'year'}

	const [data, setData] = useState(false)
	const [selected, setSelected] = useState('')
	const [payCard, setPayCard] = useState(false)
	const [selectSubscription, setSubscription] = useState(monthly)

	React.useEffect(() => fetchData(), [])

	const fetchData = ()=>{
		if (props.userContext.subscription.id) {
			props.userContext.setState({loading : true})
			socket.emit('fetchSubscribeData', props.userContext.subscription.id, data=>{
				setData(data)
				setSelected(data.subscription.customer.invoice_settings.default_payment_method)
				props.userContext.setState({loading : false})
			})
		} 
	}

	// const closePayCard = ()=>{
	// 	window.location.hash=''
	// }

	const to2 = number=>number<10? '0'+number : number

	const handleSelect = e=>setSelected(e.target.value)

	const subscribe = e=>{

		if (selected==='' || selected===null) setPayCard(true)

		else {
			props.userContext.setState({loading : true})
			socket.emit('subscribeUserWithSavedCard', selected, selectSubscription.name, async clientSecret=>{
				
				const stripe = await stripePromise
				
				let confirmPayment = await stripe.confirmCardPayment(clientSecret)
					
				if (confirmPayment.error) props.userContext.setState({loading : false, popup: true, popupMessage: 'You are a probleme with your payment', popupError: true})
					
				else props.userContext.setState({subscribe: true, loading : false, popup: true, popupMessage: 'Congratulation ! Your are subscribe !', popupError: false, popupCallback: exit})
					//window.location.hash=''
				})

		}

	}

	const exit = ()=>window.location.hash=''

	const cancelCard = active=>{
		socket.emit('cancelSubscription',active,fetchData)
	}

	const pmList = data? data.pmList.data : []

	//console.log(props.userContext)

	const goToDemo = () => {
		window.location.hash = 'demo'
	}
	
	return <>

		{payCard && <PayCardApplication type={selectSubscription.name} cancel={()=>setPayCard(false)} success={()=>window.location.hash=''}/>}

		<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

			<div>

				{data && props.userContext.vip ? <Cardtridge>			
						
					<div style={{border : 'solid green', color : 'green', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>VIP</div>
					<div>Lucky !! Your have a VIP subscription ! You don't need pay cadsmile3d for use it !!!</div>

				</Cardtridge> :

				data && props.userContext.subscription.status === 'trial' && new Date(props.userContext.subscription.expiration*1000)>new Date() ? <Cardtridge>			
						
					<div style={{border : 'solid green', color : 'green', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>TRIAL</div>
					<div>Lucky !! Your have a free trial subscription. You can use cadsmile3d without pay.</div>
						<div style={{fontWeight : 'bold'}}>Your trial period finish the {formatDate(new Date(props.userContext.subscription.expiration*1000)).text}</div>

				</Cardtridge> : 

				data && props.userContext.subscription.status === 'trial' && new Date(props.userContext.subscription.expiration*1000)<new Date() ? <Cardtridge>			
						
					<div style={{border : 'solid red', color : 'red', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>INACTIVE</div>
					<div>Your trial period is done. If you want use cadsmile3d you must suscribe it. The subscription price is {selectSubscription.amount} {selectSubscription.currency} / {selectSubscription.period} without obligation.</div>
						<div style={{fontWeight : 'bold'}}>Your trial period is finish from the {formatDate(new Date(props.userContext.subscription.expiration*1000)).text}</div>

				</Cardtridge> : 


				data && data.subscription.status === 'active' && !data.subscription.cancel_at_period_end && new Date(data.subscription.current_period_end*1000)>new Date() ? <Cardtridge>			
						
					<div style={{border : 'solid green', color : 'green', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>ACTIVE</div>
					<div>Your subscription is active. You are actually paying {data.subscription.plan.amount/100} {data.subscription.plan.currency} / {data.subscription.plan.interval}. You can cancel the subscription at any time without obligation.</div>
					<div style={{fontWeight : 'bold'}}>Your next payment date is {formatDate(new Date(data.subscription.current_period_end*1000)).text}</div>

				</Cardtridge> :

					
				data && data.subscription.status === 'active' && data.subscription.cancel_at_period_end && new Date(data.subscription.current_period_end*1000)>new Date() ? <Cardtridge>			
						
					<div style={{border : 'solid orange', color : 'orange', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>CANCELED</div>
					<div>Your subscription is being canceled. You can use cadsmile3D until the birthday date of your subscription. After which your subscription will be canceled.</div>
					<div style={{fontWeight : 'bold'}}>Your subscription will be canceled at {formatDate(new Date(data.subscription.current_period_end*1000)).text}</div>

				</Cardtridge> :
			
				data && (data.subscription.status === 'incomplete' || data.subscription.status === 'incomplete_expired') ?<Cardtridge>			
						
					<div style={{border : 'solid red', color : 'red', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>INACTIVE</div>
					<div>Welcome! You are not yet a cadsmile 3D subscriber. The subscription price is {selectSubscription.amount} {selectSubscription.currency} / {selectSubscription.period} without obligation.</div>
					<div style={{fontWeight : 'bold'}}>Have fun !</div>

				</Cardtridge> :

				data && (data.subscription.status === 'past_due' || data.subscription.status === 'unpaid') ?<Cardtridge>			
						
					<div style={{border : 'solid red', color : 'red', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>INACTIVE</div>
					<div>You have problem with payment</div>
					<div style={{fontWeight : 'bold'}}>Please retry to subscribe</div>

				</Cardtridge> :

				<Cardtridge>			
						
					<div style={{border : 'solid red', color : 'red', padding : 2, borderRadius : 5, marginLeft : 6, fontSize : '1.5em'}}>INACTIVE</div>
					<div>your subscription has been canceled. No more payment will be taken. You can re-subscribe at any time. The subscription price is {selectSubscription.amount} {selectSubscription.currency} / {selectSubscription.period}.</div>
					<div style={{fontWeight : 'bold'}}>Nice to see you again...</div>

				</Cardtridge>}

			</div>

		
			{data && data.subscription.status !== 'active' && !props.userContext.vip && <div>

				{pmList.length>0 && <select value={selected} onChange={handleSelect}>

					<option value=''>-- Use new card --</option>
					{pmList.map((pm, index)=><option key = {index} value={pm.id}>💳 XXXX XXXX XXXX {pm.card.last4} {to2(pm.card.exp_month)}/{pm.card.exp_year} {pm.card.exp_year<new Date().getFullYear() || (pm.card.exp_year===new Date().getFullYear() && pm.card.exp_month<new Date().getMonth()+1)?'(EXPIRED)' : ''}</option>)}
				
				</select>}


				<button style={{margin : 10}} onClick={subscribe}>Subscribe</button>

				<div>


					<input type="radio" onClick={e=>setSubscription(JSON.parse(e.target.value))} name="subscription" value={JSON.stringify(monthly)} defaultChecked={selectSubscription.name === monthly.name}/>Monthly subscription (40€/month)

					&nbsp;

					<input type="radio" onClick={e=>setSubscription(JSON.parse(e.target.value))} name="subscription" value={JSON.stringify(yearly)} defaultChecked={selectSubscription.name === yearly.name}/>Yearly subscription (400€/years)


				</div>

			</div>}

			

			
			{data && data.subscription.status === 'active' && !props.userContext.vip && props.userContext.subscription.status !== 'trial' && <div>

				<button style={{margin : 10}} onClick={()=>cancelCard(data.subscription.cancel_at_period_end)}>{!data.subscription.cancel_at_period_end? 'Cancel subscription' : 'Retablish subscription'}</button>

			</div>}


			<button style={{fontWeight: 'bold', fontSize: 18, background: '#ff6600', color: 'white', padding: 5, cursor: 'pointer'}} onClick={goToDemo}>try Cadsmile3D for free with a demo case</button>

		</div>

	</>
}









let CardStatus = props=>{

	const [data, setData] = useState(false)
	const [selected, setSelected] = useState('')
	const [newCard, setNewCard] = useState(false)

	React.useEffect(() => fetchData(), [])

	const fetchData = ()=>{
		props.userContext.setState({loading : true})
		socket.emit('fetchSubscribeData', props.userContext.subscription.id, data=>{
			setData(data)
			props.userContext.setState({loading : false})
		})
	}

	const to2 = number=>number<10? '0'+number : number

	const handleSelect = e=>setSelected(e.target.value)

	const pmList = data? data.pmList.data : []
	const usedPmId = data? data.subscription.customer.invoice_settings.default_payment_method : []
	const usedPm = pmList.filter(pm=>pm.id===usedPmId)[0]

	const deleteCard = ()=>props.userContext.setState({popup : true, popupError : true, popupMessage : popupMessage})

	const confirmDeleteCard = ()=>{
		socket.emit('deleteCard', selected, fetchData)
		props.userContext.setState({popup: false, popupMessage: '', popupError: false})
		setSelected('')
		//if (pmList.length===1) props.desactiveSubscription()
	}

	const useCard = ()=>{
		socket.emit('selectCard', selected, ()=>{
			fetchData()
			setSelected('')
		})

	}

	const success = ()=>{
		fetchData()
		setNewCard(false)
		setSelected('')
	}

	const close = ()=>setNewCard(false)


	const popupMessage = <>

		<div>{`${pmList.length===1?'You have only one payment method. If you delete this card your subscription will be canceled.' : ''} Are-you sur to delete this paycard?`}</div>
		<br/>
		<button onClick={confirmDeleteCard}>Yes</button>

	</>



	return <>

		{newCard && <PayNew success={success} fail={()=>false} cancel={close}/>}

		<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

			<Cardtridge>

				<div style={{margin: 10, fontWeight: 'bold'}}>Used pay card</div>

				<div>
					{usedPm ? `💳 XXXX XXXX XXXX ${usedPm.card.last4} ${to2(usedPm.card.exp_month)}/${usedPm.card.exp_year}` :  `No saved pay card`} 
					{usedPm && (usedPm.card.exp_year<new Date().getFullYear() || (usedPm.card.exp_year===new Date().getFullYear() && usedPm.card.exp_month<new Date().getMonth()+1) ) && <span style={{color : 'red'}}>   (EXPIRED)</span>}
				</div>

			</Cardtridge>

			<Cardtridge>
				
				<div>
			 
					<select value={selected} onChange={handleSelect}>
						<option value=''>-- Choose saved card --</option>
						{pmList.map((pm, index)=><option key = {index} value={pm.id}>💳 XXXX XXXX XXXX {pm.card.last4} {to2(pm.card.exp_month)}/{pm.card.exp_year} {pm.card.exp_year<new Date().getFullYear() || (pm.card.exp_year===new Date().getFullYear() && pm.card.exp_month<new Date().getMonth()+1)?'(EXPIRED)' : ''}</option>)}
					</select>

				</div>
				
				<div>
					<button style={{margin : 10}} onClick={e=>setNewCard(true)}>Add new card</button>
					<button style={{cursor : selected===''? 'not-allowed' : 'default', margin : 10}} onClick={deleteCard} disabled={selected===''}>Delete card</button>
					<button style={{cursor : (selected==='' || selected===usedPm.id)? 'not-allowed' : 'default', margin : 10}} onClick={useCard} disabled={(selected==='' || selected===usedPm.id)}>Use this card</button>
				</div>

			</Cardtridge>

		</div>

	</>
}



CardStatus = UserConsumer(CardStatus)
SubscribeStatus = UserConsumer(SubscribeStatus)



export {CardStatus, SubscribeStatus}