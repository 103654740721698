const Button = props => 

	<button style={{cursor: props.disabled?'not-allowed':'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: props.disabled?0.6:1}} title={props.label} {...props}>
		<img style={{height: 15}} src={props.icon} alt={props.label}/>
	</button>


const ButtonTxt = props => 
	<button style={{cursor: props.disabled?'not-allowed':'pointer', opacity: props.disabled?0.6:1}} {...props}>
		{props.label}
	</button>



export { Button, ButtonTxt }