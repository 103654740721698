import React from 'react'
import invisibleIcon from '../icons/invisible.svg'
import visibleIcon from '../icons/visible.svg'

const TeethManager = props => {

	const sort = teeth => {
		if (!teeth)
			return 

		const sect1 = teeth.filter(tooth => tooth.number[0] === '1').reverse()
		const sect2 = teeth.filter(tooth => tooth.number[0] === '2')
		const sect3 = teeth.filter(tooth => tooth.number[0] === '3')
		const sect4 = teeth.filter(tooth => tooth.number[0] === '4').reverse()

		return ([...sect1, ...sect2, ...sect4, ...sect3])
	}

	const toogleVisible = tooth => {
		const visible = tooth.mesh.visible
		props.setVisibility(!visible, tooth)
	}

	const showUpper = () => {
		props.setVisibility(true, ...props.maxillar.teeth)
	}

	const hideUpper = () => {
		props.setVisibility(false, ...props.maxillar.teeth)
	}

	const showLower = () => {
		props.setVisibility(true, ...props.mandibular.teeth)
	}

	const hideLower = () => {
		props.setVisibility(false, ...props.mandibular.teeth)
	}

	const upperTeeth = props.maxillar? sort(props.maxillar.teeth).map(tooth => <Tooth key={tooth.number} toogleVisible={toogleVisible} tooth={tooth} />) : null
	const lowerTeeth = props.mandibular? sort(props.mandibular.teeth).map(tooth => <Tooth key={tooth.number} toogleVisible={toogleVisible} tooth={tooth}/>) : null

	return <div>

		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div><img style={{width: 15, cursor: 'pointer', marginRight: 5}} src={visibleIcon} alt='visibility' title='show all' onClick={showUpper}/></div>
			{upperTeeth}
			<div><img style={{width: 15, cursor: 'pointer', marginLeft: 5}} src={invisibleIcon} alt='invisibility' title='hide all' onClick={hideUpper}/></div>
		</div>

		{lowerTeeth&&<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div><img style={{width: 15, cursor: 'pointer', marginRight: 5}} src={visibleIcon} alt='visibility' title='show all' onClick={showLower}/></div>
			{lowerTeeth}
			<div><img style={{width: 15, cursor: 'pointer', marginLeft: 5}} src={invisibleIcon} alt='invisibility' title='hide all' onClick={hideLower}/></div>
		</div>}
	</div>
}



const Tooth = props => {

	const visible = props.tooth.mesh.visible

	const toogleVisible = () => {
		props.toogleVisible(props.tooth)
	}


	return <div>
		<div 
			style={{fontSize: 8, border: '1px solid black', margin: 1, fontWeight: 'bold', padding: 1, background: visible?'white':'lightgrey', color: visible?'black':'grey', transition: 'all 0.2s', cursor: 'pointer' }} 
			onClick={toogleVisible}
			title={`${visible?'hide':'show'} tooth ${props.tooth.number}`}
		>{props.tooth.number}</div>
	</div>
}

export default TeethManager