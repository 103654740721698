import React from 'react'
import Cardtridge from './Cardtridge'
import { ChoosePasswordInput } from './Inputs'
import Transition from './Transition'
import { ButtonTxt } from './Button'
import { passwordHash } from '../functions/crypto'
import socket from '../functions/socket'
import Popup from './Popup'



const ForgotPW = props => {


	const [value, setValue] = React.useState('')
	const [valid, setValid] = React.useState(false)
	const [popup, setPopup] = React.useState(false)
	const [popupMessage, setPopupMessage] = React.useState('')
	const [popupError, setPopupError] = React.useState(false)

	const PWValue = value => setValue(value)
	const PWValid = valid => setValid(valid)

	const hidePopup = () => {
		setPopup(false)
		setPopupMessage('')
		setPopupError(false)
		window.close()	
	}

	const handleSubmit = e => {
		e.preventDefault()

		try {
			const resetPWData = atob(window.location.search.substring(1))
			const resetPWObj = JSON.parse(resetPWData)

			socket.emit('updatePW', {...resetPWObj, hashPW: passwordHash(value)}, result=> {

				if (result.error) {
					setPopup(true)
					setPopupMessage(result.error.message)
					setPopupError(true)
				} 
				else {
					setPopup(true)
					setPopupMessage(result.message)
				}
			})
		}
		catch(e) {
			setPopup(true)
			setPopupMessage('your key is invalid or outdated.')
			setPopupError(true)
		}

	}


	return <>

		<Cardtridge style={{width: '40%', minWidth: 300, margin: 'auto', marginTop: 40, zIndex: 1}}>
			<form onSubmit={handleSubmit}>

				<div style={{textTransform: 'uppercase', fontSize: 22, fontWeight: 'bolder'}}>get a brand new password</div>
				<ChoosePasswordInput value={PWValue} valid={PWValid}/>

				<Transition mounted={valid} transition='leftSlide'>
					<ButtonTxt label="update my password"/>
				</Transition>

			</form>
		</Cardtridge>

		{popup&&<Popup hide={hidePopup} message={popupMessage} error={popupError}/>}

	</>
}


export default ForgotPW