import React from 'react'
import * as THREE from 'three'
import { unmountThree, toNDC, drawText } from '../functions/threeCustomFunction'

class GizmoView extends React.Component {

	constructor(props) {
		super(props)

		this.containerRef = React.createRef()
		this.mouse = new THREE.Vector2(5, 5)
		//this.perspective = props.camera.clone()

	}

	componentDidMount = () => {
		const WIDTH = this.containerRef.current.getBoundingClientRect().width
		const HEIGHT = this.containerRef.current.getBoundingClientRect().height

		this.scene = new THREE.Scene()
		this.camera = new THREE.PerspectiveCamera( 75, WIDTH/HEIGHT, 0.1, 1000 )
		this.camera.setFocalLength(80)
		this.raycaster = new THREE.Raycaster()
		this.raycaster.firstHitOnly = true		
		this.raycasterText = new THREE.Raycaster()
		this.raycasterText.firstHitOnly = true


		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
		this.renderer.setSize( WIDTH, HEIGHT )
		this.containerRef.current.appendChild( this.renderer.domElement )

		const geometry = new THREE.BoxGeometry()
		const material = new THREE.MeshPhysicalMaterial( { color: 0xffffff } )
		this.cube = new THREE.Mesh( geometry, material )
		const light = new THREE.DirectionalLight(  0xffffff , 0.5 )
		const ambient = new THREE.AmbientLight(  0xffffff , 0.5 )
		light.position.x = -1
		light.position.z = 1
		light.lookAt(this.cube)

		//add text on cube's faces

		const front = drawText('FRONT', 0.2)
		const back = drawText('BACK', 0.2)
		const left = drawText('LEFT', 0.2)
		const right = drawText('RIGHT', 0.2)
		const up = drawText('UP', 0.2)
		const down = drawText('DOWN', 0.2)
		front.position.z = 0.501
		back.position.z = -0.501
		left.position.x = 0.501
		right.position.x = -0.501
		up.position.y = 0.501
		down.position.y = -0.501
		back.rotation.y = Math.PI
		left.rotation.y = Math.PI/2
		right.rotation.y = -Math.PI/2
		up.rotation.x = -Math.PI/2
		down.rotation.x = Math.PI/2


		this.texts = [front, back, left, right, up, down]
		this.cube.add(...this.texts)


		this.scene.add( this.cube, light, ambient )

		this.camera.position.z = 4


		this.containerRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouse, this.renderer.domElement))
		this.containerRef.current.onpointerdown = this.clickMesh


	}

	componentWillUnmount = () => {

		unmountThree(this.scene, this.renderer)

	}

	clickMesh = () => {

		this.raycaster.setFromCamera( this.mouse, this.camera )
		const intersect = this.raycaster.intersectObject( this.cube )		
		this.raycasterText.setFromCamera( this.mouse, this.camera )
		const intersectText = this.raycasterText.intersectObjects( this.texts )

	//check if mouse on model
		if (intersect.length > 0) {
			switch (intersect[0].faceIndex) {
				case 8: case 9: this.props.orient('front'); break
				case 10: case 11: this.props.orient('back'); break
				case 0: case 1: this.props.orient('left'); break
				case 2: case 3: this.props.orient('right'); break
				case 4: case 5: this.props.orient('up'); break
				case 6: case 7: this.props.orient('down'); break
				default: break

			}			
		}	

		if (intersectText.length > 0) {
			switch (intersectText[0].object) {
				case this.texts[0]: this.props.orient('front'); break
				case this.texts[1]: this.props.orient('back'); break
				case this.texts[2]: this.props.orient('left'); break
				case this.texts[3]: this.props.orient('right'); break
				case this.texts[4]: this.props.orient('up'); break
				case this.texts[5]: this.props.orient('down'); break
				default: break			
			}
		}

	}

	animate = () => {

		this.renderer.render( this.scene, this.camera )
		
		if (this.camera) {
			const quaternion = new THREE.Quaternion().setFromRotationMatrix(this.props.camera.matrix.invert())
			this.cube.setRotationFromQuaternion(quaternion)

		}
	}




	render = () => {

		return <div ref={this.containerRef} style={{height: 120, width: 120, position: 'absolute', right: 5, bottom: 5, ...this.props.style}}>
		</div>
	}
}

export default GizmoView