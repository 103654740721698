import React from 'react'

import Header from './Header'
import Log from './Log'
import Cases from './Cases'
import Case from './Case'
import Profil from './ProfilPage'
import Demo from './Demo'

import UserConsumer from '../context/UserContextConsumer'
import CaseContextProvider from '../context/CaseContextProvider'
import { readSearch } from '../functions/crypto'





class Desktop extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			hash: '',
			search: {}
		}
	}

	componentDidMount = () => {


	//navigation hash
		const updatehashState = ()=> {

			const [hash, search] = window.location.hash.split('?')

			this.setState({hash, search: readSearch(search) })

		}

		window.addEventListener('hashchange', updatehashState)
		updatehashState()


	}

	render = () => {

		//console.log(this.props.userContext.logged)

	    return <>

	    	<CaseContextProvider>

	    	<Header/>


		   	{ !this.props.userContext.logged  &&
		   		<div style={{width: '40%', minWidth: 300, margin: 'auto', zIndex: 1}}>

		   			<Log/>

		   		</div>
		   	}

		   	
			   	{ this.props.userContext.logged && this.state.hash==='' &&
			   		<Cases/>
			   	}

			   	{ this.props.userContext.logged && this.state.hash==='#demo' &&
			   		<Demo/>
			   	}

			   	{ this.props.userContext.logged && this.state.hash==='#case' &&
			   		<Case id={this.state.search.id} page={this.state.search.page}/>	
			   	}			   	

			   	{ this.props.userContext.logged && this.state.hash==='#profil' &&
			   		<Profil/>	
			   	}
			</CaseContextProvider>




	    </>}

}

export default UserConsumer(Desktop)