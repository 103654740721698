/** @jsxImportSource @emotion/react */

import React from 'react'
import * as THREE from 'three'
import socket from '../functions/socket'

import hashNav from '../functions/hashNav'
import Wall from './Wall'
import { loadTexture, drawMarker, calcMarkerRadius, drawLine, free, unmountThree } from '../functions/threeCustomFunction'
import History from '../functions/History'
import { DragControls } from '../functions/DragControls.js'
import { Controls } from '../functions/threeCustomClass'

import landmarksDetection from '../functions/landmarksDetection'
import Cardtridge from './Cardtridge'
import { Button } from './Button'
import Menu from './Menu'
import TutoNavigation from './TutoNavigation'
//import exportThumbnail from '../functions/thumbnailExporter'
import Check from './Check'

import neural from '../images/neural.png'
import fit from '../icons/fit.svg'
import fitSmile from '../icons/fitSmile.svg'
import AI from '../icons/AI.svg'
import undo from '../icons/undo.svg'
import redo from '../icons/redo.svg'
import play from '../icons/play.svg'
//import cancel from '../icons/cancel.svg'
import TutoAccess from './TutoAccess'



import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'

import Cadscore from './Cadscore'



//import Stats from 'three/examples/jsm/libs/stats.module.js'


//CameraControls.install( { THREE: THREE } )


const SetLandmarks = props => {

	const saveLandmarks = landmarks => {

		//socket.emit('savethumbnail', props.caseContext.id, {name: 'thumbnailLandmarks', blob: thumbnail}, () => null/* resolve(state.blob) */)
		
		const cadscore = props.caseContext[props.fileName].cadscore

		socket.emit('saveData', props.caseContext.id, {object: {landmarks, cadscore}, fileName: props.fileName, priv: true}, () => {
		//save in global state
			props.caseContext.setState({[props.fileName]: {...props.caseContext[props.fileName], landmarks}}, 
				()=>hashNav({page: 'summary'})
			)
		})
	}

	const cancel = () => {
		window.history.back()
	}

	const gotoLandmarks = () => {
		if (props.caseContext[props.fileName].file)
			hashNav({page: 'landmarks'})
	}

	const show = props.caseContext.page === 'landmarks'
	const showButton = props.caseContext.page === 'summary'
	const disabled = !props.caseContext[props.fileName].file
	const checked = props.caseContext[props.fileName].landmarks
	const creator = props.caseContext.creator.id === props.userContext.id  || props.userContext.admin || props.userContext.designer

	return <>
		{showButton&&
			<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
				<div css={{opacity: creator?1:0.6, transition: 'all 0.5s', background: `center / cover no-repeat url(${neural})`, position: 'absolute', top: 5, bottom: 5, left: 5, right: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: !creator||disabled?'not-allowed':'pointer', '&:hover': !creator||disabled?{}:{top: 0, bottom: 0, left: 0, right: 0}}}
						title='set landmarks'
						onClick={creator?gotoLandmarks:null}>
					
					<div style={{transition: 'all 0.5s', fontSize: 30, fontWeight: 'bolder', textShadow: '0px 0px 2px white', opacity: disabled?0.4:1}}>set landmarks</div>
				</div>
					<Check checked={checked}/>
					{disabled&&creator&&<div style={{
						position: 'absolute', 
						bottom: 0, 
						left: 0, 
						right: 0,
						fontWeight: 'bold', 
						color: 'white', 
						fontSize: 10, 
						pointerEvents: 'none', 
						background: 'rgba(0, 0, 0, 0.4)'
					}}>you need to put photo of face in order to use it</div>}

					{!creator&&<TutoAccess/>}

			</div>}


		{show&&<LandmarksGenerator caseContext={props.caseContext} cancel={cancel} save={saveLandmarks}/>}
		{disabled&&show&&<Wall callback={cancel}/>}

	</>

}



class LandmarksGenerator extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
		}
		this.containerRef = React.createRef()
		this.cropOpacity = 0
		this.history = new History({
			update: state=>this.setState(state),
			loadState: this.loadState,
			saveState: this.saveState
		})

		this.guidelinesNeedUpdate = false
		this.markersScaleNeedsUpdate = false

		this.clock = new THREE.Clock()

	}


	componentDidMount = () => {

		window.addEventListener('resize', this.handleResize)
		window.addEventListener('keydown', this.handleKeyDown)

		this.props.caseContext.setState({loading: true, loadingMessage: 'face points detection'})

		loadTexture(this.props.caseContext.photoFace.file).then(texturePlane => {

			this.texturePlane = texturePlane

		//get landmarks			
			if (this.props.caseContext.photoFace.landmarks) {
			//from database
				this.landmarks = this.props.caseContext.photoFace.landmarks

				if (this.containerRef.current)
					this.sceneConstruct()

				this.props.caseContext.setState({loading: false, loadingMessage: ''})

			}
			else {
		//from AI
				this.getLandmarks().then(landmarks => {
					this.landmarks = landmarks

					if (this.containerRef.current)
						this.sceneConstruct()

					this.props.caseContext.setState({loading: false, loadingMessage: ''})

				})
			}
		})

	}


	sceneConstruct = () => {

	//scene
		this.scene = new THREE.Scene()

	//camera
		const width = window.innerWidth
		const height = window.innerHeight
		this.camera = new THREE.OrthographicCamera( width/- 2, width/2, height/2, height /-2, 1, 1000 )
		this.camera.position.z = 100


	//renderer
		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
		this.renderer.setSize( window.innerWidth, window.innerHeight )


	//image
//		this.texturePlane = await loadTexture(this.props.caseContext.photoFace.file)
		const material = new THREE.MeshBasicMaterial( { map: this.texturePlane, color: 0xffffff} )
		const planeGeo = new THREE.PlaneGeometry( this.texturePlane.image.width, this.texturePlane.image.height, 1, 1 )
		this.plane = new THREE.Mesh( planeGeo, material )
		this.scene.add( this.plane )


	//navigation
		this.controls = new Controls( this.camera, this.renderer.domElement )
		this.controls.mouseButtons.wheel = Controls.ACTION.ZOOM
		this.controls.mouseButtons.middle = Controls.ACTION.ZOOM

	//prevent rotation
		this.controls.minPolarAngle = Math.PI/2
		this.controls.maxPolarAngle = Math.PI/2
		this.controls.minAzimuthAngle = 0
		this.controls.maxAzimuthAngle = 0

	//get landmarks
		/* if (this.props.caseContext.photoFace.landmarks)
			//from database
			this.landmarks = this.props.caseContext.photoFace.landmarks
		else 
			//from AI
			this.landmarks = await this.getLandmarks() */

	//if not a photo of face
		if (!this.landmarks) {
			this.props.caseContext.setState({loading: false, loadingMessage: '', popup: true, popupError: true, popupMessage: 'i can\'t detect face on this picture.' })
			this.props.cancel()
			return
		}


	//draw markers	
		this.drawLandmarks()


	//draw guidelines
		this.guidelinesNeedUpdate = true
		this.markersScaleNeedsUpdate = true



	//dragControls events
		this.dragControls = new DragControls( [
			this.landmarksObject.leftIris, 
			this.landmarksObject.rightIris,
			this.landmarksObject.middle,
			this.landmarksObject.leftNose,
			this.landmarksObject.rightNose,
			this.landmarksObject.crop.topLeft,
			this.landmarksObject.crop.topRight,
			this.landmarksObject.crop.bottomRight,
			this.landmarksObject.crop.bottomLeft,
			...this.landmarksObject.lips

		], this.camera, this.renderer.domElement )

		this.dragControls.addEventListener('drag', this.handleDragControl)
		this.dragControls.addEventListener('dragstart', this.handleDragStart)


	//save first history
		this.history.saveHistory()



	//fit image
		this.controls.fitToBox(this.plane, false)

	//add canvas to DOM
		this.containerRef.current.appendChild( this.renderer.domElement )

		this.animate()

	}
	

	componentWillUnmount = () => {
	//avoid leak memory	
		cancelAnimationFrame( this.loop )
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('keydown', this.handleKeyDown)
		unmountThree(this.scene, this.renderer)
		this.props.caseContext.setState({loading: false, loadingMessage: ''})

	}


	handleResize = () => {
	//make it responsive
		const WIDTH = this.containerRef.current.getBoundingClientRect().width
		const HEIGHT = this.containerRef.current.getBoundingClientRect().height		
		this.renderer.setSize( WIDTH, HEIGHT )		
		const aspect = WIDTH / HEIGHT

		const frustumSize = Math.abs(this.camera.top - this.camera.bottom)

		this.camera.left = - frustumSize * aspect / 2
		this.camera.right = frustumSize * aspect / 2
		this.camera.top = frustumSize / 2
		this.camera.bottom = - frustumSize / 2

		this.camera.updateProjectionMatrix()

	}


	handleKeyDown = e => {

		if (this.props.caseContext.loading)
			return


		if (e.code==='KeyW' && e.ctrlKey) {
			e.preventDefault()
			this.history.undo()		
		}

		if (e.code==='KeyY' && e.ctrlKey) {
			e.preventDefault()
			this.history.redo()
		}
		
	}


	fit = () => {
		const padding = calcMarkerRadius(this.plane) * 5
		this.controls.fitToBox(this.crop, true, { paddingTop: padding*3, paddingLeft: padding, paddingBottom: padding, paddingRight: padding })
	}

	fitLips = (transition=true/* , callback=()=>null */) => {
		/* const sleep = () => {
			this.controls.removeEventListener('sleep', sleep)
			callback()
		}

		this.controls.addEventListener('sleep', sleep) */
		const padding = calcMarkerRadius(this.plane) * 5
		this.controls.fitToBox(this.lips, transition, { paddingTop: padding*4, paddingLeft: padding*4, paddingBottom: padding*4, paddingRight: padding*4 })
	}


	getLandmarks = async () => {

	//get AI Landmarks
		const landmarks = await landmarksDetection(this.props.caseContext.id)
		//const landmarks = await landmarksDetection(this.texturePlane.image)

		this.setState({landmarksFace : landmarks})




		
	//only accept face photos
		if (!landmarks) {
			return null
		}


	//save landmarks coordinates from middle 
		const center = coords => {
			const image = this.texturePlane.image
			const [x, y, ] = coords
			return [x-image.width/2, -y + image.height/2, 1]
		}


	//lips array
		const lips = [ ]
		const lipsLandmarks = [...landmarks.annotations.lipsUpperInner, ...landmarks.annotations.lipsLowerInner.reverse()]
		lipsLandmarks.forEach((element, i)=> {
				if (i%2===0 || i===5)
					lips.push(center(element)) 
			})

		return {
			leftIris: center(landmarks.annotations.leftEyeIris[0]),
			rightIris: center(landmarks.annotations.rightEyeIris[0]),
			middle: center(landmarks.annotations.noseBottom[0]),
			silhouette: landmarks.annotations.silhouette.map(element=>center(element)),
			leftNose: center(landmarks.annotations.noseLeftCorner[0]),
			rightNose: center(landmarks.annotations.noseRightCorner[0]),
			lips: lips,
			//ear : center(landmarks.vertices[230])
		} 
	}

	createMarker = (markerCoords, color = 'red') => {
		const radius = calcMarkerRadius(this.plane)
		const [x, y, z] = markerCoords
		const marker = drawMarker(x, y, z, 1, color)
		marker.scale.set(radius, radius, radius)
		this.plane.add(marker)

		return marker
	}


	drawLandmarks = () => {
		const landmarks = this.landmarks
		const createMarker = this.createMarker

		this.lips = new THREE.Group()
		this.plane.add(this.lips)

		//const radius = (new THREE.Vector3().fromArray(landmarks.leftIris).distanceTo(new THREE.Vector3().fromArray(landmarks.rightIris)))/40


		this.landmarksObject = {

			//ear : createMarker(landmarks.leftIris, 'red'),
			leftIris: createMarker(landmarks.leftIris, 'white'),
			rightIris: createMarker(landmarks.rightIris, 'white'),
			middle: createMarker(landmarks.middle, 'white'),
			leftNose: createMarker(landmarks.leftNose, 'white'),
			rightNose: createMarker(landmarks.rightNose, 'white'),
			lips: landmarks.lips.map(marker=> {
				const marker3D = createMarker(marker, 'yellow')
				this.lips.add(marker3D)
				return marker3D
			}),
			crop: {}
		}

		



	// add corner to landmarksObject
		const cropGroup = new THREE.Group()
		const getCorner = (x, y) => {
			const marker = drawMarker(x, y, 1, 1.5, 'blue')
			cropGroup.add(marker)
			return marker
		}

		
		if (landmarks.hasOwnProperty('crop')) {
	//if landmarks from database
			this.landmarksObject.crop.topLeft = getCorner(landmarks.crop.topLeft[0], landmarks.crop.topLeft[1])
			this.landmarksObject.crop.topRight = getCorner(landmarks.crop.topRight[0], landmarks.crop.topRight[1])
			this.landmarksObject.crop.bottomRight = getCorner(landmarks.crop.bottomRight[0], landmarks.crop.bottomRight[1])
			this.landmarksObject.crop.bottomLeft = getCorner(landmarks.crop.bottomLeft[0], landmarks.crop.bottomLeft[1])
		}

		else if (landmarks.hasOwnProperty('silhouette')) {
	//if landmarks from AI
			const faceBox = this.cornerFromSilhouette(landmarks)


			this.landmarksObject.crop.topLeft = getCorner(faceBox.min.x, faceBox.max.y)
			this.landmarksObject.crop.topRight = getCorner(faceBox.max.x, faceBox.max.y)
			this.landmarksObject.crop.bottomRight = getCorner(faceBox.max.x, faceBox.min.y)
			this.landmarksObject.crop.bottomLeft = getCorner(faceBox.min.x, faceBox.min.y)

		}

		this.landmarksObject.crop.group = cropGroup
		this.scene.add(cropGroup) 

	}

	cornerFromSilhouette =  landmarks => {

		const createMarker = this.createMarker

		//face contour landmarks
			const silhouette = new THREE.Group()
			landmarks.silhouette.forEach(coords => {
				const sil = createMarker(coords, 'blue')
				silhouette.add(sil)
			})


		// crop face
			const percent = 15	
			const faceBox = new THREE.Box3().setFromObject(silhouette)
			const xBox = faceBox.getSize(new THREE.Vector3()).x
			const yBox = faceBox.getSize(new THREE.Vector3()).y
			faceBox.expandByVector(new THREE.Vector3(xBox*percent/100, yBox*percent/100, 1))


			return faceBox
	}


	horizontalize = () => {
		const x = this.landmarksObject.leftIris.position.x - this.landmarksObject.rightIris.position.x
		const y = this.landmarksObject.leftIris.position.y - this.landmarksObject.rightIris.position.y
		const angle = Math.atan2(y, x)
		this.plane.rotation.z = -angle
	}


	handleDragStart = e => {

		if (e.object === this.landmarksObject.crop.topLeft ||
			e.object === this.landmarksObject.crop.topRight ||
			e.object === this.landmarksObject.crop.bottomRight ||
			e.object === this.landmarksObject.crop.bottomLeft) {

			this.cropOpacity = 0.1

		}

					this.dragControls.addEventListener('dragend', this.handleDragEnd)

	}

	handleDragEnd = e => {
		this.cropOpacity = 0
		this.dragControls.removeEventListener('dragend', this.handleDragEnd)
		this.guidelinesNeedUpdate = true
		this.markersScaleNeedsUpdate = true

		this.history.saveHistory()
	}

	handleDragControl = e => {

	//get all markers dimension
		const allMarkersGroup = new THREE.Group()
			allMarkersGroup.add(
				this.landmarksObject.leftIris, 
				this.landmarksObject.rightIris,
				this.landmarksObject.middle,
				this.landmarksObject.leftNose,
				this.landmarksObject.rightNose,
				)
			//this.landmarksObject.lips.map(marker=>allMarkersGroup.add(marker))
			allMarkersGroup.add(this.lips)

			this.plane.add(allMarkersGroup)
			const markerBox = new THREE.Box3().setFromObject(allMarkersGroup)

	//rule for move crop control
		switch (e.object) {

			case this.landmarksObject.crop.topLeft : 

				if ( this.landmarksObject.crop.topLeft.position.x >= markerBox.min.x )
					this.landmarksObject.crop.topLeft.position.x = markerBox.min.x 

				if ( this.landmarksObject.crop.topLeft.position.y <= markerBox.max.y ) 
					this.landmarksObject.crop.topLeft.position.y =  markerBox.max.y

				this.landmarksObject.crop.bottomLeft.position.x = this.landmarksObject.crop.topLeft.position.x
				this.landmarksObject.crop.topRight.position.y = this.landmarksObject.crop.topLeft.position.y

				break


			case this.landmarksObject.crop.topRight : 

				if ( this.landmarksObject.crop.topRight.position.x <= markerBox.max.x )
					this.landmarksObject.crop.topRight.position.x = markerBox.max.x

				if ( this.landmarksObject.crop.topRight.position.y <= markerBox.max.y ) 
					this.landmarksObject.crop.topRight.position.y = markerBox.max.y

				this.landmarksObject.crop.bottomRight.position.x = this.landmarksObject.crop.topRight.position.x
				this.landmarksObject.crop.topLeft.position.y = this.landmarksObject.crop.topRight.position.y

				break


			case this.landmarksObject.crop.bottomRight : 

				if ( this.landmarksObject.crop.bottomRight.position.x <= markerBox.max.x )
					this.landmarksObject.crop.bottomRight.position.x = markerBox.max.x

				if ( this.landmarksObject.crop.bottomRight.position.y >= markerBox.min.y ) 
					this.landmarksObject.crop.bottomRight.position.y = markerBox.min.y

				this.landmarksObject.crop.topRight.position.x = this.landmarksObject.crop.bottomRight.position.x
				this.landmarksObject.crop.bottomLeft.position.y = this.landmarksObject.crop.bottomRight.position.y

				break


			case this.landmarksObject.crop.bottomLeft : 

				if ( this.landmarksObject.crop.bottomLeft.position.x >= markerBox.min.x )
					this.landmarksObject.crop.bottomLeft.position.x = markerBox.min.x

				if ( this.landmarksObject.crop.bottomLeft.position.y >= markerBox.min.y ) 
					this.landmarksObject.crop.bottomLeft.position.y = markerBox.min.y

				this.landmarksObject.crop.topLeft.position.x = this.landmarksObject.crop.bottomLeft.position.x
				this.landmarksObject.crop.bottomRight.position.y = this.landmarksObject.crop.bottomLeft.position.y

				break

			default :
				break

		}

		this.guidelinesNeedUpdate = true
		this.markersScaleNeedsUpdate = true

	}


	drawGuideLines = () => {

	//update rotation	
		this.horizontalize()

		const lineMaterial = new THREE.LineBasicMaterial({
			color: 'white',
			linewidth: 2,
		})		

		const cropLineMaterial = new THREE.LineBasicMaterial({
			color: 'blue',
			linewidth: 2,
		})

		const lipsMaterial = new THREE.LineBasicMaterial({
			color: 'yellow',
			linewidth: 2,
		})
		
		const image = this.plane.material.map.image
		const scaleDash = image.height/100
		const dashedMaterial = new THREE.LineDashedMaterial({
			color: 'white',
			linewidth: 2,
			dashSize: scaleDash,
			gapSize: scaleDash,
			transparent: true,
			opacity: 0.7
		})

		const cropMaterial = new THREE.MeshBasicMaterial({
			color: 'blue',
			side: THREE.DoubleSide,
			transparent: true,
			opacity: this.cropOpacity
		})

	//clean viewport
		free(this.horizontal, this.vertical, this.verticalLeft, this.verticalRight)


	//horizontal line
		this.scene.attach(this.landmarksObject.leftIris)
		this.horizontal = drawLine(
			[ 
				new THREE.Vector3( this.landmarksObject.crop.topLeft.position.x, this.landmarksObject.leftIris.position.y, 5 ) , 
				new THREE.Vector3( this.landmarksObject.crop.topRight.position.x, this.landmarksObject.leftIris.position.y, 5 ) 
			],
			lineMaterial
		)
		this.plane.attach(this.landmarksObject.leftIris)


	//vertical line
		this.scene.attach(this.landmarksObject.middle)
		this.vertical = drawLine(
			[ 
				new THREE.Vector3( this.landmarksObject.middle.position.x, this.landmarksObject.crop.bottomLeft.position.y, 5 ), 
				new THREE.Vector3( this.landmarksObject.middle.position.x, this.landmarksObject.crop.topLeft.position.y, 5 )	
			],
			lineMaterial
		)
		this.plane.attach(this.landmarksObject.middle)


	//left nose
		this.scene.attach(this.landmarksObject.leftNose)
		this.verticalLeft = drawLine(
			[ 
				new THREE.Vector3( this.landmarksObject.leftNose.position.x, this.landmarksObject.crop.bottomLeft.position.y, 1 ), 
				new THREE.Vector3( this.landmarksObject.leftNose.position.x, this.landmarksObject.crop.topLeft.position.y, 1 )	
			],
			dashedMaterial
		)
		this.plane.attach(this.landmarksObject.leftNose)


	//right nose
		this.scene.attach(this.landmarksObject.rightNose)
		this.verticalRight = drawLine(
			[ 
				new THREE.Vector3( this.landmarksObject.rightNose.position.x, this.landmarksObject.crop.bottomLeft.position.y, 1 ), 
				new THREE.Vector3( this.landmarksObject.rightNose.position.x, this.landmarksObject.crop.topLeft.position.y, 1 )
			],
			dashedMaterial
		)
		this.plane.attach(this.landmarksObject.rightNose)


		this.scene.add(this.horizontal, this.vertical, this.verticalLeft, this.verticalRight)


	//lips
		free(this.lipsLine)
		const lipsVector3 = this.landmarksObject.lips.map(mesh => mesh.position)

		const lipsCurve = new THREE.CatmullRomCurve3( lipsVector3 )
		lipsCurve.closed = true
		const lipsPoints = lipsCurve.getPoints( 100 )
		const lipsGeometry = new THREE.BufferGeometry().setFromPoints( lipsPoints )
		this.lipsLine = new THREE.Line( lipsGeometry, lipsMaterial )
		this.plane.add(this.lipsLine)


	//crop
		free(this.crop, this.cropLine)
		const cropPoints = [ 
		this.landmarksObject.crop.topLeft.position,
		this.landmarksObject.crop.topRight.position,
		this.landmarksObject.crop.bottomRight.position,
		this.landmarksObject.crop.bottomLeft.position
		]

		const cropGeometry = new THREE.BufferGeometry().setFromPoints( cropPoints )
		cropGeometry.setIndex([0, 1, 3, 1, 2, 3])

		const cropGeometryLine = new THREE.BufferGeometry().setFromPoints( cropPoints )
		this.crop = new THREE.Mesh( cropGeometry, cropMaterial )
		this.cropLine = new THREE.LineLoop( cropGeometryLine, cropLineMaterial )
		this.scene.add(this.crop, this.cropLine)



		this.guidelinesNeedUpdate = false

	}

	updateMarkersScale = () => {


		const markers = [
			this.landmarksObject.leftIris, 
			this.landmarksObject.rightIris,
			this.landmarksObject.middle,
			this.landmarksObject.leftNose,
			this.landmarksObject.rightNose,
			this.landmarksObject.crop.topLeft,
			this.landmarksObject.crop.topRight,
			this.landmarksObject.crop.bottomRight,
			this.landmarksObject.crop.bottomLeft,
			...this.landmarksObject.lips
		]


		const crop = [
			this.landmarksObject.crop.topLeft.position,
			this.landmarksObject.crop.topRight.position,
			this.landmarksObject.crop.bottomRight.position,
			this.landmarksObject.crop.bottomLeft.position
		]

		const sphere = new THREE.Sphere().setFromPoints(crop)
		const SCALE_FACTOR = sphere.radius

		markers.forEach(marker => {
			const s = SCALE_FACTOR/90
			marker.scale.set(s, s, s)
		})

		this.markersScaleNeedsUpdate = false


	}

	/* updateMesh = () => {

		const temp = {
			middle: this.landmarksObject.middle.position.clone()
		}
		const diff = new THREE.Vector3().subVectors(temp.middle, this.temp.middle)
		this.landmarksObject.mesh.forEach(o => o.position.add(diff))
		this.landmarksObject.lips.forEach(o => o.position.add(diff))
		this.landmarksObject.leftNose.position.add(diff)
		this.landmarksObject.rightNose.position.add(diff)
		this.landmarksObject.rightIris.position.add(diff)
		this.landmarksObject.leftIris.position.add(diff)


		this.temp = temp

	} */



	animate = () => {
		this.loop = requestAnimationFrame(this.animate)
		const delta = this.clock.getDelta()
		this.controls.update( delta )

		this.refresh()
	}


	refresh = () => {

		if (this.guidelinesNeedUpdate)
			this.drawGuideLines()

		if (this.markersScaleNeedsUpdate)
			this.updateMarkersScale()

		this.renderer.render(this.scene, this.camera)
		//console.log(this.renderer.info.memory)

	}


	resetLandmarks = async () => {
		this.props.caseContext.setState({loading: true, loadingMessage: 'face points detection'})
		this.landmarks = await this.getLandmarks()
		this.props.caseContext.setState({loading: false, loadingMessage: ''})


		const updatePosition = key => {
			const [x, y] = this.landmarks[key]
			this.landmarksObject[key].position.set(x, y, 1)
		}


		updatePosition('leftIris')
		updatePosition('rightIris')
		updatePosition('middle')
		updatePosition('leftNose')
		updatePosition('rightNose')
		//updatePosition('ear')
		this.landmarksObject.lips.forEach((marker, i)=>{
			const [x, y, ] = this.landmarks.lips[i]
			marker.position.set(x, y, 1)
		})


		const faceBox = this.cornerFromSilhouette(this.landmarks)
		this.landmarksObject.crop.topLeft.position.set(faceBox.min.x, faceBox.max.y, 1)
		this.landmarksObject.crop.topRight.position.set(faceBox.max.x, faceBox.max.y, 1)
		this.landmarksObject.crop.bottomRight.position.set(faceBox.max.x, faceBox.min.y, 1)
		this.landmarksObject.crop.bottomLeft.position.set(faceBox.min.x, faceBox.min.y, 1)


		this.guidelinesNeedUpdate = true
		this.markersScaleNeedsUpdate = true

		this.controls.fitToBox(this.plane, true)


		this.history.saveHistory()

	}



	saveState = () => {
		const landmarks = {}
		const positionToArray = mesh => [mesh.position.x, mesh.position.y, mesh.position.z ]

		landmarks.rightIris = positionToArray(this.landmarksObject.rightIris)
		landmarks.leftIris = positionToArray(this.landmarksObject.leftIris)
		landmarks.middle = positionToArray(this.landmarksObject.middle)
		landmarks.leftNose = positionToArray(this.landmarksObject.leftNose)
		landmarks.rightNose = positionToArray(this.landmarksObject.rightNose)
		landmarks.lips = this.landmarksObject.lips.map(mesh=>positionToArray(mesh))

		landmarks.crop = {}
		Object.entries(this.landmarksObject.crop).forEach(([key, value])=> {
			if (key !== 'group')
				landmarks.crop[key] = positionToArray(value)
		})

		return landmarks
	}


	loadState = landmarks => {

		const updatePosition = key => {
			const [x, y] = landmarks[key]
			this.landmarksObject[key].position.set(x, y, 1)
		}


		updatePosition('leftIris')
		updatePosition('rightIris')
		updatePosition('middle')
		updatePosition('leftNose')
		updatePosition('rightNose')
		this.landmarksObject.lips.forEach((marker, i)=>{
			const [x, y, ] = landmarks.lips[i]
			marker.position.set(x, y, 1)
		})

		this.landmarksObject.crop.topLeft.position.set(landmarks.crop.topLeft[0], landmarks.crop.topLeft[1], 1)
		this.landmarksObject.crop.topRight.position.set(landmarks.crop.topRight[0], landmarks.crop.topRight[1], 1)
		this.landmarksObject.crop.bottomRight.position.set(landmarks.crop.bottomRight[0], landmarks.crop.bottomRight[1], 1)
		this.landmarksObject.crop.bottomLeft.position.set(landmarks.crop.bottomLeft[0], landmarks.crop.bottomLeft[1], 1)

		this.guidelinesNeedUpdate = true
		this.markersScaleNeedsUpdate = true

	}
	

	saveLandmarks = () => {
		const landmarks = this.saveState()
		this.props.save(landmarks)

		/* const drawThumb = () => {
			exportThumbnail({
				scene: this.scene,
				camera: this.camera,
				renderer: this.renderer
			}).then(thumbnail => this.props.save({landmarks, thumbnail}))
		}

		this.fitLips(false, drawThumb) */
	
	}


	render = () => <>

		<div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>		

			<div ref={this.containerRef} style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}}>
				<TutoNavigation rotate={false}/>
			</div>


			<Menu>

				<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
					<Button icon={fit} label='fit to face' onClick={this.fit}/>
					<Button icon={fitSmile} label='fit to lips' onClick={this.fitLips}/>
					<Button icon={AI} label='detect facial landmarks with AI' onClick={this.resetLandmarks}/>
				</Cardtridge>

				<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
					<Button icon={undo} label='undo (ctrl+Z)' onClick={this.history.undo} disabled={this.state.disabledUndo}/>
					<Button icon={redo} label='redo (ctrl+Y)' onClick={this.history.redo} disabled={this.state.disabledRedo}/>
				</Cardtridge>

				<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
					<img src={play} style={{height: 20, cursor: 'pointer', transform: 'rotateZ(180deg)'}} alt='previous step' title='previous step' onClick={this.props.cancel}/>
					<img src={play} style={{height: 20, cursor: 'pointer'}} alt='next step' title='next step' onClick={this.saveLandmarks}/>
				</Cardtridge>

			</Menu>

			{(this.props.caseContext.photoFace.cadscore || this.state.landmarksFace) && <Cadscore landmarks={this.state.landmarksFace}/>}


		</div>


	</>
	

}


export default UserConsumer(CaseConsumer(SetLandmarks))