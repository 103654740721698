import React from 'react'
import UserContext from './UserContext'
import Popup from '../components/Popup'
import Loading from '../components/Loading'
//import Transition from '../components/Transition'
import socket from '../functions/socket'
import {PayApplication} from '../components/pay'



class UserContextProvider extends React.Component {

	constructor(props) {
		super(props)
		this.state = {

			setState: this.setStateProvider,
			popup: false,
			popupMessage: '',
			popupError: false,
			popupbutton: null,
			popupCallback: ()=>null,

			loading: false,

			logged: false,
			isExists: undefined,
			subscribe : true,
			admin: false,
			
			id: '',
			mail: '',
			username: '',

			logOut: this.logOut
		}
	}

	componentDidMount = () => {
	// use saved user data
		if (localStorage.getItem('sessId'))
			sessionStorage.setItem('sessId', localStorage.getItem('sessId'))
				
		socket.on('fetchDataLog', send=> {
				send(sessionStorage.getItem('sessId'))
		})

		socket.on('plug', this.login)
		socket.on('unplug', this.logOut)
		socket.on('subscribe', this.subscribe)


	}


	setStateProvider = (state, callback) => {
		this.setState(state, callback)
	}

	hidePopup = () => {
		this.state.popupCallback()
		this.setState({popup: false, popupMessage: '', popupError: false, popupCallback: ()=>null, popupButton: null})
	}

	login = state => {
		//console.log('login', state)
		this.setState(state)
	}

	logOut = () => {
		//console.log('logout')
		this.setState({logged: false, username: '', isExists: undefined})
		sessionStorage.removeItem('sessId')
		localStorage.removeItem('sessId')
		window.location.hash = ''	
	}

	subscribe = value=>{
		console.log('subscribe', value)
		this.setState({subscribe : value})
		if (value!=='active') window.location.hash='profil'
	}



	render = () => 
		<UserContext.Provider value={this.state}>
			<>

				{ this.props.children }

				{this.state.popup&&<Popup hide={this.hidePopup} message={this.state.popupMessage} error={this.state.popupError} button={this.state.popupButton}/>}
				{this.state.loading&&<Loading/>}

				{false && this.state.logged && !this.state.subscribe && <PayApplication/>}


			</>

		</UserContext.Provider>
}


export default UserContextProvider