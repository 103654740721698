import * as THREE from 'three'


const toIndexed = geometry => {

	const index = geometry.getIndex()

	if (index) {
	//if already indexed, juste compute normals
		geometry.computeVertexNormals()
		return geometry
	}


	const position = geometry.getAttribute('position')
	const color = geometry.hasAttribute('color') ? geometry.getAttribute('color') : null
	const uv = geometry.hasAttribute('uv') ? geometry.getAttribute('uv') : null

	const newPosition = []
	const newColor = []
	const newUV = []

	const newIndex = []
	const indexStore = {}

	for (let i = 0; i < position.count; i++) {

		const posX =  position.array[ 3*i ]
		const posY =  position.array[ 3*i + 1 ]
		const posZ =  position.array[ 3*i + 2 ]

		const colX =  color ? color.array[ 3*i ] : null
		const colY =  color ? color.array[ 3*i + 1 ] : null
		const colZ =  color ? color.array[ 3*i + 2 ] : null

		const uvX =  uv ? uv.array[ 2*i ] : null
		const uvY =  uv ? uv.array[ 2*i + 1 ] : null



		//store to find quickly merged
		const key = JSON.stringify({x: posX, y: posY, z: posZ})
		const twinIndex = indexStore[key]


		if (twinIndex) 
			newIndex.push(twinIndex)

		else {
			newIndex.push(newPosition.length/3)
			indexStore[key] = newPosition.length/3
			newPosition.push( posX, posY, posZ )

			if (color)
				newColor.push( colX, colY, colZ )

			if (uv)
				newUV.push( uvX, uvY )

		}

	}

	const indexedGeometry = new THREE.BufferGeometry()


	indexedGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(newPosition), 3 ) )

	if (color)
		indexedGeometry.setAttribute( 'color', new THREE.BufferAttribute( new Float32Array(newColor), 3 ) )

	if (uv)
		indexedGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(newUV), 2 ) )


	indexedGeometry.setIndex(newIndex)	
	indexedGeometry.computeVertexNormals()


	return indexedGeometry
}



export default toIndexed

