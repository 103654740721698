import React from 'react'
import * as THREE from 'three'

import Cardtridge from './Cardtridge'
import invisibleIcon from '../icons/invisible.svg'
import visibleIcon from '../icons/visible.svg'

class InfoWindow extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			show: 'maxillar'
		}
	}


	componentDidMount = () => {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown = e => {

		if (e.keyCode === 27)
			this.props.cancel()
	}

	handleChange = e => {
		this.setState({show: e.target.value})
	}

	showAll = () => {
		const teeth = this.state.show==='maxillar' ? this.props.maxillar.teeth : this.props.mandibular ? this.props.mandibular.teeth : []
		this.props.setVisibility(true, ...teeth)
	}

	hideAll = () => {
		const teeth = this.state.show==='maxillar' ? this.props.maxillar.teeth : this.props.mandibular ? this.props.mandibular.teeth : []
		this.props.setVisibility(false, ...teeth)
	}



	render = () => {

		const teethObject = this.state.show==='maxillar' ? this.props.maxillar.teeth : this.props.mandibular ? this.props.mandibular.teeth : []

		const teeth = teethObject.map((tooth, i) => <ToothInfos  key={tooth.number} tooth={tooth} setVisibility={this.props.setVisibility} style={{background: i%2===0?'#eeeeee':'none'}}/>)

		return <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right:0, background: 'rgba(255, 255, 255, 0.8)', zIndex: 10}} onClick={this.props.cancel}>
			
			<Cardtridge style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', position: 'absolute', top: '20%', bottom: '20%', left: '20%', right: '20%', background: 'white', overflow: 'auto'}} onClick={e=>e.stopPropagation()}>


				{this.props.mandibular&&<div style={{display: 'flex', justifyContent: 'flex-start'}}>
					<select value={this.state.show} onChange={this.handleChange}>
						<option value='maxillar'>maxillar</option>
						<option value='mandibular'>mandibular</option>
					</select>
				</div>}

				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', fontWeight: 'bolder'}}>
					<div style={{flex: 1}}>tooth</div>
					<div style={{flex: 1}}>width</div>
					<div style={{flex: 1}}>height</div>
					<div style={{flex: 1}}>depth</div>
					<div style={{flex: 1}}>proportion (width/height)</div>
					<div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flex: 1}}>
						<div>visibility</div> 
						<div><img style={{width: 20, cursor: 'pointer'}} src={visibleIcon} alt='visibility' title='show all' onClick={this.showAll}/></div>
						<div><img style={{width: 20, cursor: 'pointer'}} src={invisibleIcon} alt='invisibility' title='hide all' onClick={this.hideAll}/></div>
					</div>
				</div>
				
				{teeth}

					
			</Cardtridge>

		</div>
	}

}


const ToothInfos = props => {

	const tooth = props.tooth
	const visible = tooth.mesh.visible


	const setVisibility = e => {
		const checked = e.target.checked
		props.setVisibility(checked, tooth)
	}


	const bBox = new THREE.Box3().setFromObject(tooth.mesh, true)
	const dimensions = new THREE.Vector3()
	bBox.getSize(dimensions)
	const x = dimensions.x.toFixed(2)
	const y = dimensions.y.toFixed(2)
	const z = dimensions.z.toFixed(2)


	return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', ...props.style}}>
		<div style={{flex: 1}}>{tooth.number}</div>
		<div style={{flex: 1, visibility: visible?'visible':'hidden'}}>{x} mm</div>
		<div style={{flex: 1, visibility: visible?'visible':'hidden'}}>{y} mm</div>
		<div style={{flex: 1, visibility: visible?'visible':'hidden'}}>{z} mm</div>
		<div style={{flex: 1, visibility: visible?'visible':'hidden'}}>{Math.round(100*x/y)} %</div>
		<div title={visible?'hide tooth':'show tooth'} style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><input type='checkbox' checked={tooth.mesh.visible} onChange={setVisibility}/><img style={{width: 20}} src={visible?visibleIcon:invisibleIcon} alt='visibility'/></div>
	</div>
}




export default InfoWindow