import React, {useState, useEffect} from "react"
import socket from '../functions/socket'
import Cardtridge from './Cardtridge'
import mail from '../icons/mail.png'
import UserConsumer from '../context/UserContextConsumer'
import facebook from '../icons/facebook.png'
import messager from '../icons/messager.png'
import youtube from '../icons/youtube.png'



const Contact = props => {
	return <div style={{width: '80%'}}>
		<ContactForm/>
		<ContactMethods/>
	</div>
}


let ContactForm = props=>{

	const [message, setMessage] = useState('')
	const [valid, setValid] = useState(false)

	useEffect(() => {
    	updateMessage(window.localStorage.getItem('contactMessage'))
  	},[])

	const handleChange = e=>{
		updateMessage(e.target.value)
	}

	const updateMessage = value => {
		setMessage(value)
		window.localStorage.setItem('contactMessage', value)

		if (!value || value.trim() === '')
			setValid(false)
		else
			setValid(true)
	}

	const sendMessage = e=> {
		socket.emit('contactMessage', message.trim(), response=>{

			if (response.error) props.userContext.setState({popup: true, popupMessage: 'We have problem to send your message. Try again.', popupError: true})

			if (response.success) {
				props.userContext.setState({popup: true, popupMessage: 'You successfull send your message'})
				setMessage('')
			}	


		})
	}

	return <div>

	<div>

		<textarea value = {message} onChange={handleChange} style={{border : 'solid black', resize : 'none', width : '80%', height : '25vh', margin : 10}}/>

	</div>

	<div>

		<button style={{cursor : valid? 'pointer' : 'not-allowed'}} disabled={!valid} onClick={e=>updateMessage('')} >clear message</button>
		&nbsp;
		<button style={{cursor : valid? 'pointer' : 'not-allowed'}} disabled={!valid} onClick={sendMessage} >send message</button>

		</div>

	</div>

}


const ContactMethods = props =>{

	const [copyCoords, setCopyCoords] = useState({x:0, y:0})
	const [copyOpacity, setCopyOpacity] = useState(0)

	const copyMail = e=>{
		navigator.clipboard.writeText('support@cadsmile3d.com').then(()=>{
			setCopyCoords({x:e.clientX, y:e.clientY})
			setCopyOpacity(1)
			setTimeout(()=>setCopyOpacity(0), 1500)
		})

	}

	const goFacebook = e=>{
		window.open("https://www.facebook.com/106215444049548", "_blank")
	}

	const goMessager = e=>{
		window.open("https://m.me/106215444049548", "_blank")
	}

	const goYoutube = e=>{
		window.open("https://www.youtube.com/channel/UCzF3zf-qs5RVRO0aDW7XT5w", "_blank")

	}


	return <Cardtridge style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin : 25}}>

	<div>
		<div><img style={{cursor : 'pointer'}} src={mail} alt='mail' width ={40} title='copy email address : support@cadsmile3d.com in your clipboard' onClick = {copyMail}/></div>
		<div style={{fontSize : '0.8em'}}>support@cadsmile3d.com</div>
		<div style={{position : 'fixed', padding : 3, backgroundColor : 'grey', color : 'white', borderRadius : 4, fontSize : '0.7em', top : copyCoords.y, left : copyCoords.x, opacity : copyOpacity, transition : 'opacity 0.3s'}}>Copied in clipboard</div>
	</div>

	<div>
		<img style={{cursor : 'pointer'}} src={facebook} alt='facebook' width={40} title='go to facebook page' onClick={goFacebook}/>
	</div>

	<div>
		<img style={{cursor : 'pointer'}} src={messager} alt='messenger' width={40} title='go to facebook messenger page' onClick={goMessager}/>
	</div>

	<div>
		<img style={{cursor : 'pointer'}} src={youtube} alt='youtube' width={40} title='go to youtube page' onClick={goYoutube}/>
	</div>

	

	</Cardtridge>


}



ContactForm = UserConsumer(ContactForm)
export { ContactMethods }
export default Contact