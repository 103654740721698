import * as THREE from 'three'
//import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { average } from './math'
import simplify from './simplify'
//import { drawBall } from './threeCustomFunction'
import toIndexed from './toIndexed'



const getEdgePoints = (mesh, showEdges=false) => {

	const edges = new THREE.EdgesGeometry( mesh.geometry, Infinity )
	if (edges.getAttribute('position').count === 0)
		return null

	const edgeBuffer = edges.getAttribute('position').array

	if (showEdges) {
		const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x00ff00 } ) )
		mesh.add( line )
	}



	const vertexTuple = []

	for (let i = 0; i < edgeBuffer.length; i+=6) {

		const start = new THREE.Vector3(edgeBuffer[i], edgeBuffer[i+1], edgeBuffer[i+2] )
		const end = new THREE.Vector3(edgeBuffer[i+3], edgeBuffer[i+4], edgeBuffer[i+5] )

		const tuple = [start, end]
		vertexTuple.push(tuple)

	}

	//sort bigger part first
	const vertices = sortDominos(vertexTuple)
	vertices.sort((a, b) => b.length - a.length)

	return vertices

}


const getEdgeIndex = (mesh, edge) => {

	const meshBuffer = mesh.geometry.getAttribute('position').array
	const buffer = verticesToBuffer(edge)
	const index = new Set()

	for (let i = 0; i < buffer.length; i+=3) {

		for (let j = 0; j < meshBuffer.length; j+=3) {

			if (buffer[i] === meshBuffer[j] && buffer[i+1] === meshBuffer[j+1] && buffer[i+2] === meshBuffer[j+2] ) {
				index.add(j/3)
				break
			}
		}
	}		

	return index

}


const getPartIndex = (mesh, edgeIndex) => {
	const meshBuffer = mesh.geometry.clone().getIndex().array

	const indexPart = new Set(edgeIndex)


	for (let i = 0; i < meshBuffer.length; i+=3) {

		if (indexPart.has(meshBuffer[i])) {
			indexPart.add(meshBuffer[i+1])
			indexPart.add(meshBuffer[i+2])
		}

		if (indexPart.has(meshBuffer[i+1])) {
			indexPart.add(meshBuffer[i])
			indexPart.add(meshBuffer[i+2])
		}

		if (indexPart.has(meshBuffer[i+2])) {
			indexPart.add(meshBuffer[i])
			indexPart.add(meshBuffer[i+1])
		}

	}



	return indexPart


}


const cleanGeometry = mesh => {


	const edges = getEdgePoints(mesh)


	if (!edges) 
		return

	//largest part = model geometry
	const indexMesh = smoothEdge(mesh, edges[0], 0).index
	const partMesh = getPartIndex(mesh, indexMesh)


	const toFill = []
	const toRemove = []


	edges.forEach((edge, i) => {
	//skip largest edge
		if (i===0)
			return

		const indexToFix = getEdgeIndex(mesh, edge)
		const partToFix = getPartIndex(mesh, indexToFix)


		if (isHole(partMesh, partToFix)) 
			toFill.push(indexToFix)

		else 
			toRemove.push(...partToFix)

	})


	fillHoles(mesh, ...toFill)

//invert selection
	const setToRemove = new Set(toRemove)
	const toKeep = new Set()
	const index = mesh.geometry.getIndex().array

	index.forEach(i => {
		if (!setToRemove.has(i))
			toKeep.add(i)
	})

	mesh.geometry = removeVertices(mesh, toKeep).geometry


} 



const cleanEdge = (mesh, direction=1) =>{

	const position = mesh.geometry.getAttribute('position')


	const edge = getEdgePoints(mesh)[0]

	if (!edge)
		return

	const smooth = smoothEdge(mesh, edge, 1, 1, [0, direction*0.5, 0], false)


	const borderCurve = smooth.curveObject
	const modelBorderIndex = smooth.index



	const borderIndex = new Set()
	const borderPoint = []

	modelBorderIndex.forEach(index => {

		const pp = new THREE.Vector3().fromBufferAttribute(position, index)
		const raycaster = new THREE.Raycaster(pp, new THREE.Vector3())
		raycaster.params.Line.threshold = 5
		const inter = raycaster.intersectObject(borderCurve)

		const closestIndex = Math.min(...inter.map(({point})=>point.distanceTo(pp)))
		const closestInter = inter.filter(({point})=> point.distanceTo(pp) === closestIndex)[0]
		const p = closestInter.point

		position.setXYZ(index, p.x, p.y, p.z)
		borderPoint.push(p)

		borderIndex.add(closestInter.index)
	})


//clean index table
	let geometry = mesh.geometry
	geometry = geometry.toNonIndexed()
	geometry = toIndexed(geometry)
	geometry.computeBoundsTree()
	mesh.geometry = geometry


	return {borderCurve, borderIndex}

}



const isHole = (meshIndex, partIndex) => {



	for (const index of partIndex) {
		if (meshIndex.has(index))
			return true 
	}

	return false

}


const smoothEdge = (mesh, edgePoints, smooth, scale=1, offset=[0, 0, 0], draw=false, flatten=false) => {
	
	const bBox = new THREE.Box3().setFromObject(mesh, true)
	const height = bBox.max.y + offset[1]

	const edgeIndex = getEdgeIndex(mesh, edgePoints)
	//const position = mesh.geometry.getAttribute('position')

	const edge = edgePoints.map(point => new THREE.Vector3( point.x + offset[0], flatten?height:(point.y + offset[1]), point.z + offset[2] ))

	const simple = simplify(edge, smooth)

	const curve = new THREE.CatmullRomCurve3(simple, false)
	const points = curve.getSpacedPoints( edgePoints.length)


	const geometry = new THREE.BufferGeometry().setFromPoints( points )
	geometry.computeVertexNormals()
	const material = new THREE.LineBasicMaterial( { visible: draw, color : 0xff0000, side: THREE.DoubleSide } )
	const curveObject = new THREE.Line( toIndexed(geometry) , material )
	mesh.add( curveObject )
	



	return { index: edgeIndex, points, curve, curveObject }


}





const fillHoles = (mesh, ...edgesIndex) => {

	const averageEdge =	(edgeIndex, attributeName) => {

		const attribute = mesh.geometry.getAttribute(attributeName)

		const vectors = Array.from(edgeIndex).map(index => {
			const v = new THREE.Vector3()
			v.fromBufferAttribute(attribute, index)
			return v
		})

		const averageVectors = average(vectors)
		const {x, y, z} = averageVectors


		edgeIndex.forEach(index => {
			attribute.setXYZ(index, x, y, z)
		})

	}


	edgesIndex.forEach(edgeIndex => {
		averageEdge(edgeIndex, 'position')
		averageEdge(edgeIndex, 'color')
		averageEdge(edgeIndex, 'normal')
	})





}








/* const removeGeometry = (mesh, partIndex) => {

	const indexArray = mesh.geometry.getIndex().array
	const newIndex = []
	indexArray.forEach(index => {
		if (!partIndex.has(index))
			newIndex.push(index)
	})

	mesh.geometry.setIndex(newIndex)

} */




const sortDominos = array => {


	const sort = array => {

		const rest = [...array]
		const sorted = []

		for (let i = 0; i < array.length; i++) {

			if (i === 0) {
				sorted.push(array[i])	
				rest.shift()
			}

			else {

				for (let j = 0; j < rest.length; j++) {

					if (!sorted[i-1])
						break

					if (sorted[i-1][1].equals(rest[j][0])) {

						sorted.push(rest[j])
						rest.splice(j, 1)
						break
						
					}
				}
			}
		}

		return { sorted, rest }
	}


	const edges = []
	let rest = [...array]

	while (rest.length > 0) {

		const edge = sort(rest)
		edges.push(edge.sorted.map(tuple => tuple[0]))
		rest = edge.rest
	}

	return edges

}


const removeVertices = (model, indices) => {

	console.log()

	const position = model.geometry.getAttribute('position')
	const color = model.geometry.getAttribute('color')
	const normal = model.geometry.getAttribute('normal')
	const index =  model.geometry.getIndex().array


	const indicesSelect = []

	for (let i = 0; i<index.length; i+=3) {

		if ( indices.has(index[i]) && indices.has(index[i+1]) && indices.has(index[i+2]) )
			indicesSelect.push(index[i], index[i+1], index[i+2])
	}


	const vertexArray = []
	const colorArray = []
	const normalArray = []
	const indexArray = []



	const reverseIndex = {}

	Array.from(indices).forEach((index, i) => {

		reverseIndex[index] = i

		vertexArray.push(position.getX(index), position.getY(index), position.getZ(index))
		colorArray.push(color.getX(index), color.getY(index), color.getZ(index))
		normalArray.push(normal.getX(index), normal.getY(index), normal.getZ(index))

	})


	indicesSelect.forEach(index => {

		indexArray.push(reverseIndex[index])

	})


	let geometry = new THREE.BufferGeometry()
	const vertexBuffer = new Float32Array(vertexArray)
	const colorBuffer = new Float32Array(colorArray)
	const normalBuffer = new Float32Array(normalArray)
		
	geometry.setAttribute( 'position', new THREE.BufferAttribute( vertexBuffer, 3 ) )
	geometry.setAttribute( 'color', new THREE.BufferAttribute( colorBuffer, 3 ) )
	geometry.setAttribute( 'normal', new THREE.BufferAttribute( normalBuffer, 3 ) )
	geometry.setIndex(indexArray)


	//clean index table
	/* geometry = geometry.toNonIndexed()
	geometry = toIndexed(geometry)
	geometry.computeBoundsTree() */

	return new THREE.Mesh(geometry, model.material)

}



const verticesToBuffer = vertices => {

	const buffer = []
	for (let i = 0; i < vertices.length; i++) {
		buffer.push(vertices[i].x, vertices[i].y, vertices[i].z )
	}

	return buffer
}


export { getEdgePoints, getEdgeIndex, cleanGeometry, cleanEdge, fillHoles, removeVertices/* , removeGeometry */ }