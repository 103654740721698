import * as THREE from 'three'
import bgCanvas from './bgCanvas'
import { OrthographicCamera, PerspectiveCamera } from './threeCustomClass'
//import { free } from '../functions/threeCustomFunction'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { TexturePass } from 'three/examples/jsm/postprocessing/TexturePass'
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass'
import { MaskPass, ClearMaskPass } from 'three/examples/jsm/postprocessing/MaskPass'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { OutlinePass } from './OutlinePass'



class PhotoScene {

	constructor({scene, photo, buccal=null, showLips=false, teethObject = []}) {
		this.scene = scene
		this.photo = photo
		this.buccal = buccal
		this.showLips = showLips
		this.teethObject = teethObject

	}

	draw = async () => {

		//get image canvas
		this.bgObject = await bgCanvas(this.photo, this.buccal)
		this.bg = new THREE.CanvasTexture(this.bgObject.canvas)
		this.camera = new PerspectiveCamera( 75, this.bg.image.width / this.bg.image.height, 1, 10000 )

		this.camera.setFocalLength(this.photo.camera.focalLength)
		this.camera.position.copy(new THREE.Vector3().fromArray(this.photo.camera.position))
		this.camera.quaternion.copy(new THREE.Quaternion().fromArray(this.photo.camera.quaternion))


		//render
		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: false})
		this.renderer.setSize( this.bg.image.width, this.bg.image.height )

		


		//fetch landmarks
		this.landmarks = this.bgObject.landmarks

		//create mask scene
		const maskScene = new THREE.Scene()
		const maskCamera = new OrthographicCamera( this.bg.image.width/- 2, this.bg.image.width/2, this.bg.image.height/2, this.bg.image.height /-2, 1, 10000 )
		maskCamera.position.z = 100

		this.maskRenderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
		this.maskRenderer.setSize( this.bg.image.width, this.bg.image.height )
		


		//draw lips
		const maskMaterial = new THREE.MeshBasicMaterial({
			color: 'white',
			side: THREE.DoubleSide,
		})


		const lipsVector3 = this.landmarks.lips.map(mesh=>mesh.position)
		const lipsCurve = new THREE.CatmullRomCurve3( lipsVector3 )
		lipsCurve.closed = true
		const lipsPoints = lipsCurve.getPoints( 100 )

		const shape = new THREE.Shape(lipsPoints)
		const geometry = new THREE.ShapeGeometry( shape)
		const mask = new THREE.Mesh( geometry, maskMaterial )
		maskScene.add(mask)


		//compositing 
		const clearPass = new ClearPass()
		const clearMaskPass = new ClearMaskPass()
		this.maskPass = new MaskPass( maskScene, maskCamera )
		this.renderPass = new RenderPass( this.scene, this.camera )
		
		this.renderPass.clear = false
		const outputPass = new ShaderPass( CopyShader )
		const texturePass = new TexturePass( this.bg )
	  	
	  	const renderTarget = new THREE.WebGLRenderTarget(this.bg.image.width, this.bg.image.height, { 
	  		minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			format: THREE.RGBFormat,
			stencilBuffer: true 
		})

	  	const SCALE_FACTOR = new THREE.Sphere().setFromPoints(lipsVector3).radius
	  	const setOutline = () => {
	  		const outlinePass = new OutlinePass( new THREE.Vector2( this.bg.image.width, this.bg.image.height ), this.scene, this.camera )
			outlinePass.edgeStrength = SCALE_FACTOR /50
			outlinePass.edgeThickness = SCALE_FACTOR / 400
			outlinePass.visibleEdgeColor.set( 0xffffff )
			outlinePass.hiddenEdgeColor.set( 0x222222)

			return outlinePass
	  	}

	  	this.outlinePass = setOutline()
		this.enableLips(this.showLips)


		this.composer = new EffectComposer( this.renderer , renderTarget )
		this.composer.addPass( clearPass )		
		this.composer.addPass( texturePass )		
		this.composer.addPass( this.maskPass )
		this.composer.addPass( this.renderPass )
		this.composer.addPass( clearMaskPass )
			
		this.composer.addPass( this.outlinePass )

		this.composer.addPass( outputPass )





	}

	enableLips = bool => {
		this.showLips = bool
		this.maskPass.enabled = bool

		if (this.renderPass.enabled)
			this.outlinePass.enabled = !bool
	}

	show = bool => {
		this.renderPass.enabled = bool

		if (!this.showLips)
			this.outlinePass.enabled = bool
	}


	render = () => {
		
		this.composer.render()
	}

	dispose =() => {

		this.bgObject.dispose()
		this.renderer.dispose()
		this.maskRenderer.dispose()
	}

}



export default PhotoScene

