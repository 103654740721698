import React from 'react'

import CaseContext from '../context/CaseContext'
import Loading from '../components/Loading'
import Popup from '../components/Popup'


class CaseContextProvider extends React.Component {

	constructor(props) {
		super(props)
		this.state = {

			setState: this.setStateProvider,
			loading: false,
			loadingMessage: '',
			progress: false,
			popup: false,
			popupMessage: '',
			popupError: false,
			popupbutton: null,
			popupCallback: ()=>null,
			enableShortcuts: true,

		//filters cases list state
			filter: '',
			limit: 15,
			sort: 'date',
			direction: -1,

		//case data
			id: '',
			name: '',
			creator: {},
			userAccess: [],
			page: '',


			photoFace: {
				file: '',
				exif: '',
				landmarks: '',
				camera: '',
				cadscore: ''
			},

			photoBuccal: {
				file: '',
				transform: ''
			},

			modelMaxillar: {
				file: '',
				basement: '',
				thumbnail: ''
			},

			modelMandibular: {
				file: '',
				basement: ''
			},
			
			transform : [ 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1 ],

			savedList: []
		}
	}



	setStateProvider = (state, callback) => {
		this.setState(state, callback)
	}

	hidePopup = () => {
		this.state.popupCallback()
		this.setState({popup: false, popupMessage: '', popupError: false, popupCallback: ()=>null, popupButton: null})
	}


	render = () => 
		<CaseContext.Provider value={this.state}>
			<>
				{ this.props.children }

				{this.state.popup&&<Popup hide={this.hidePopup} message={this.state.popupMessage} error={this.state.popupError} button={this.state.popupButton}/>}
				{this.state.loading&&<Loading progress={this.state.progress} message={this.state.loadingMessage}/>}

			</>

		</CaseContext.Provider>
}


export default CaseContextProvider 