/** @jsxImportSource @emotion/react */

/* import logo from '../images/laBoiteBleue_logo_text.svg' */
import { keyframes } from '@emotion/react'


const colors = {
	tardisBlue : '#003b6f'
}


const Logo = props => {

	let {width, height} = props.style
	const border = 4

	width -= border *2	
	height -= border *2

	const faceStyle = {
		position: 'absolute', 
		width: width, 
		height: height, 
		border: `solid white ${border}px`, 
		backgroundColor: colors.tardisBlue
	}

	const rotate = keyframes({
		from: {transform: 'rotateY(0deg)'},
		to: {transform: 'rotateY(360deg)'}
	})

	/* const logoImg = <img 
		alt='logo' 
		style={{
			position: 'absolute', 
			right: -0.5, 
			top: '50%', 
			transform: 'translateY(-50%)', 
			height: '95%'}} 
		src={logo}/> */

	const pleaseWait = 	<div style={{color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div>please</div>
							<div>wait</div>
							<div>...</div>
						</div>

	const message = <div style={{color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'column' , alignItems: 'center'}}>
							<div>{props.message}</div>
							<div>...</div>
						</div>


	return(
		<div style={{perspective: 600, perspectiveOrigin: `${width/2}px ${height/2}px`}}>

			<div css={{

				width: width, 
				transformStyle: 'preserve-3D',
				transform: 'rotateY(45deg)',
				transition: 'transform 1s',
				'&:hover': {
					transform: 'rotateY(55deg)'
					},
				animation: props.animate&&`${rotate} 5s infinite linear`
				}}>

				<div style={{...faceStyle, transform: `translateZ(${-width/2 -border}px) rotateY(180deg)`}}>
					{props.message?message:pleaseWait}
				</div>
				
				<div style={{...faceStyle, transform: `translateZ(${width/2 +border}px)`}}>
					{props.message?message:pleaseWait}

				</div>
				
				<div style={{...faceStyle, transform: `rotateY(-270deg) translateX(${width/2 +border}px)`, transformOrigin: 'center right'}}>
					{pleaseWait}
				</div>
				
				<div style={{...faceStyle, transform: `rotateY(270deg) translateX(${-width/2 -border}px)`, transformOrigin: 'center left'}}>
					{pleaseWait}
				</div>

			</div>


		</div>
	)
}

Logo.defaultProps = {
	style: { width: 120, height: 100},
	animate: false
}



export default Logo