/** @jsxImportSource @emotion/react */

import React from 'react'
import Cardtridge from './Cardtridge'
import { PasswordInput } from './Inputs'
import Transition from './Transition'
import { ButtonTxt } from './Button'
import { passwordHash, encrypt } from '../functions/crypto'
import socket from '../functions/socket'
import puKey from '../key/puKey.pem'


import UserConsumer from '../context/UserContextConsumer'


class Loggin extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			PWValue: '',
			PWValid: false,
			keepAlive: true
		}

		this.SendActivationMail = 
			<>
				<div style={{marginBottom: 20}}>
					your account is not active. Check your spam if you don't receive activation mail.
				</div>
				<ButtonTxt onClick={this.SendMailActivation} label="send me another activation mail"/>
			</>

		this.refPW = React.createRef()


	}

	componentDidMount = () => {
		this.refPW.current.focus()
	}

	PWValue = value => {
		this.setState({PWValue: value})
	}

	PWValid = valid => {
		this.setState({PWValid: valid})
	}

	keepAlive = e => {
		this.setState({keepAlive: e.target.checked})
	}

	SendMailActivation = () => {
		this.sendMail('resendActivation')
	}

	sendMailForgotPW = () => {
		this.sendMail('sendForgotPW')
	}

	sendMail = socketEvent => {

		this.props.userContext.setState({loading: true})

		const logData = {
			mail: this.props.userContext.mail,
			hash: passwordHash(this.state.PWValue)
		}

		socket.emit(socketEvent, logData, result => {
			if (result.error) this.props.userContext.setState({popup: true, popupMessage: result.error.message, popupError: true, loading: false})
			else this.props.userContext.setState({popup: true, popupMessage: result.message, popupError: false, loading: false})
		})

	}

	handleSubmit = async e => {
		e.preventDefault()

		if (!this.state.PWValid)
			return

		this.setState({loading: true})

		const logData = {
			mail: this.props.userContext.mail,
			hash: passwordHash(this.state.PWValue)
		}

		//encrypt log data
		let publicKey = await fetch(puKey).then(data=>data.text())
		const encrypted = encrypt(JSON.stringify(logData), publicKey)

		//save session
		if (this.state.keepAlive)
			localStorage.setItem('sessId', encrypted)

		sessionStorage.setItem('sessId', encrypted)

		socket.emit('login', logData, result => {
			if (result.error) this.props.userContext.setState({logged: false, popup: true, popupMessage: result.error.message, popupError: true, loading: false})
			else if (!result.active) this.props.userContext.setState({logged: false, popup: true, popupMessage: this.SendActivationMail, popupError: true, loading: false})
			else this.props.userContext.setState(result)
		})
	}

	
	render = () => {




		return <Cardtridge>
			<form onSubmit={this.handleSubmit}>

				<PasswordInput ref={this.refPW} value={this.PWValue} valid={this.PWValid}/> 

				<Transition style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}} mounted={this.state.PWValid} transition='leftSlide'>
					<div><input type='checkbox' checked={this.state.keepAlive} onChange={this.keepAlive}/> keep connection alive</div>
					<ButtonTxt label="connect me !"/>


				</Transition>

				<div onClick={this.sendMailForgotPW} css={{marginTop: 15, color: 'grey', fontSize: 12, textAlign: 'center', cursor: 'pointer', fontStyle: 'italic',fontWeight: 'bold', '&:hover': {color: 'black'}, transition: 'all 0.5s'}}>I forgot my password</div>

			</form>
		</Cardtridge>
	}
}




export default UserConsumer(Loggin)