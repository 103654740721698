/** @jsxImportSource @emotion/react */

import React from 'react'
import socket from '../functions/socket'
import { formatDate, showRegex } from '../functions/formatFunctions'
import Cardtridge from './Cardtridge'
//import Transition from './Transition'
import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'
import { writeSearch } from '../functions/crypto'
import pencil from '../icons/pencilWhite.svg'
import cancel from '../icons/cancel.svg'
import trashcan from '../icons/trashcan.svg'
import copy from '../icons/copy.svg'
import play from '../icons/play.svg'
import message from '../icons/message.svg'
import thumbDefault from '../icons/thumbDefault.svg'
import unshare from '../icons/unshare.svg'
import refresh from '../icons/refresh.svg'
import doctor from '../icons/doctor.png'
import patient from '../icons/user.png'



class Cases extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			list: [], listUsers : [],
			selected: null,
			preview: false,
			focus: false,
			adminMode : false
		}

	}

	componentDidMount = () => {

		this.props.userContext.setState({loading : true})
		this.updateList()
		//if (this.props.userContext.subscription.status!=='active') window.location.hash='profil'
		window.addEventListener('keydown', this.navigate)
		socket.on('refreshcaseslist', this.updateList)
		
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.navigate)
		socket.off('refreshcaseslist', this.updateList)
	}

	navigate = e => {
		if (this.state.focus) 
			return

		const {list, selected} = this.state
		const index = list.indexOf(selected)

		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault()
				if (index === -1 || index === list.length-1)
					this.select(list[0])
				else
					this.select(list[index+1])
				break

			case 'ArrowUp':			
				e.preventDefault()
				if (index === -1 || index === 0)
					this.select(list[list.length-1])
				else
					this.select(list[index-1])
				break

			case 'Enter':
				e.preventDefault()
				if (index !== -1)
					this.goToCase()
				break

			default:
				break

		}

	}

	goToCase = () => {
		const id  = this.state.selected._id
		window.location.hash = '#case?'+writeSearch({id, page: 'summary'})
	}

	updateList = ()=> {
		this.state.adminMode? this.updateUserList() : this.updateCasesList()	
	}

	updateCasesList = () => {

	//	console.log(this.props.userContext)

		socket.emit('updatecaseslist', 
			{filter: this.props.caseContext.filter, sort: this.props.caseContext.sort, direction: this.props.caseContext.direction, limit: this.props.caseContext.limit},
			list => {
				this.setState({list})
				this.props.userContext.setState({loading : false})
			})
	}

	updateUserList = () =>{

		socket.emit('updateUserList', 
			{filter: this.props.caseContext.filter, sort: this.props.caseContext.sort, direction: this.props.caseContext.direction, limit: this.props.caseContext.limit, activeFilter : this.props.caseContext.activeFilter, activeSubscribeFilter : this.props.caseContext.activeSubscribeFilter},
			listUsers=>{
				this.setState({listUsers})
			})

	}

	updateFilters = filters => {
		this.props.caseContext.setState(filters, this.updateList)
	}

	select = caseObject => {
		this.setState({selected: caseObject})
	}

	unselect = () => {
		this.setState({selected: null})
	}

	addCase = () => {
		const caseName = this.props.caseContext.filter
		socket.emit('addCase', {caseName}, result => {
			const id = result._id
			this.updateList()
			window.location.hash = '#case?'+writeSearch({id, page: 'summary'})
			this.props.caseContext.setState({filter: ''})

		})
	}

	switchAdminMode = mode=>this.setState({adminMode : !mode}, this.updateList)

	render = () => {

		return <div onFocus={()=>this.setState({focus: true})} onBlur={()=>this.setState({focus: false})} onClick={this.unselect} style={{position: 'absolute', top: 50, bottom: 0, left: 0, right: 0, overflow: 'auto'}}>

			<Menu 
				updateCasesList={this.updateList} 
				updateFilters={this.updateFilters} 
				filters={{filter: this.props.caseContext.filter, sort: this.props.caseContext.sort, direction: this.props.caseContext.direction, limit: this.props.caseContext.limit}}
				switchAdminMode = {this.switchAdminMode}
				adminMode = {this.state.adminMode}
			/>
					

			{!this.state.adminMode && this.props.caseContext.filter&&this.state.list.length===0&&<div  style={{position: 'relative', zIndex: 100}}>
				<div>no result for <span style={{fontWeight: 'bolder'}}>{this.props.caseContext.filter}</span>.</div>
				<div>do you want add a case with this name ?</div>
				<button onClick={this.addCase}>yes</button>
			</div>}			

			{!this.state.adminMode && <ListCases list={this.state.list} filter={this.props.caseContext.filter} selected={this.state.selected} select={this.select}/>}

			{this.state.adminMode && <ListUsers update={this.updateList} list={this.state.listUsers} filter={this.props.caseContext.filter} selected={this.state.selected} select={this.select}/>}


			{this.state.selected&&<Preview selected={this.state.selected} unselect={this.unselect} updateCasesList={this.updateList} caseContext={this.props.caseContext} userContext={this.props.userContext}/>}


		</div>
	}
}


let Menu = props => {

	return <Cardtridge style={{position: 'fixed', top: 50, bottom: 10, left: 0, right: '80%', overflow: 'auto'}}>

		<AddCase updateCasesList={props.updateCasesList}/>

		<Filters updateFilters={props.updateFilters} updateCasesList={props.updateCasesList} filters={props.filters} adminMode={props.adminMode}/>

		{props.userContext.admin && <div style={{position : 'absolute', bottom : 10, width : '95%'}}>

			<img alt='patient' src={patient} width={30}/> <Switch onChange={props.switchAdminMode}/> <img alt='doctor' src={doctor} width={30}/>

		</div>}

	</Cardtridge>
}


const Switch = props => {

	const [switched, setSwitch] = React.useState(false)

	const handleChange = e=>{
		setSwitch(!switched)
		props.onChange(switched)
	}

	const styleSpan = {
		position : 'relative',
		display: 'inline-block', 
		border : 'solid blue', borderRadius : 10, 
		width : 40, height : 20,
		cursor : 'pointer'
	}

	const cursorStyle = {
		position : 'absolute', 
		top : 0, 
		left : switched? 20 : 0,
		height : 20, width : 20, 
		backgroundColor : 'blue',
		borderRadius : 10,
		transition : 'left 0.3s'
	}

	return <>

		<span style={styleSpan} onClick={handleChange}>
			<div style={cursorStyle}/>
		</span>

	</>

}


class Input extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			value: props.initValue
		}
	}

	updateValue = value => {
		this.setState({value})
		this.props.setValue(value)
	}

	handleChange = e => {
		const value = e.target.value
		this.updateValue(value)	
	}

	erase = () => {
		this.updateValue('')
	}

	render = () =>
		<div style={{position: 'relative', textAlign: 'right'}}>
			<input type={this.props.type} style={{width: '95%'}} placeholder={this.props.placeholder} value={this.state.value} onChange={this.handleChange} />
			{this.state.value&&this.props.type!=='number'&&<div onClick={this.erase} style={{position: 'absolute', right: 2, top: '50%', transform: 'translateY(-50%)', cursor: 'pointer'}}>&#x2716;</div>}
		</div>

	static defaultProps = {
		setValue: value => value,
		initValue: ''
	}
}





const UNSPAM = 500
class Filters extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			search: props.filters.filter,
			limit: props.filters.limit,
			sort: props.filters.sort,
			direction: props.filters.direction,
			activeFilter : false,
			activeSubscribeFilter : false
		}
	}

	setSearchValue = value => {
		clearTimeout(this.time)
		this.setState({search: value}, () => {
			this.time = setTimeout(this.updateFilters, UNSPAM)
		})
	}

	setLimitValue = value => {
		clearTimeout(this.time)
		this.setState({limit: value}, () => {
			this.time = setTimeout(this.updateFilters, UNSPAM)
		})	
	}

	setSortValue = sort => {
		if (this.state.sort !== sort) {
			let direction
			if (sort === 'date')
				direction = -1
			else
				direction = 1

			this.setState({sort, direction}, this.updateFilters)
		}
		else
			this.setState({direction: -this.state.direction}, this.updateFilters)
	}

	setActiveFilter = e=>{
		clearTimeout(this.time)
		this.setState({activeFilter : e.target.checked}, () => {
			this.time = setTimeout(this.updateFilters, UNSPAM)
		})	
	}

	setActiveSubscribeFilter = e=>{
		clearTimeout(this.time)
		this.setState({activeSubscribeFilter : e.target.checked}, () => {
			this.time = setTimeout(this.updateFilters, UNSPAM)
		})	
	}

	updateFilters = () => {
		this.props.updateFilters({filter: this.state.search, limit: Number(this.state.limit), sort: this.state.sort, direction: this.state.direction, activeFilter : this.state.activeFilter, activeSubscribeFilter : this.state.activeSubscribeFilter})
	}

	updateCasesList = () => {
		this.props.updateCasesList()
	}




	render = () => {
		return <>
			<Cardtridge style={{fontWeight: 'bold', textAlign: 'left', padding: 5, marginTop: 15, background: 'none'}}>filters
				<Input placeholder='search by name...' initValue={this.state.search} setValue={this.setSearchValue}/>		
				

			</Cardtridge>
			
			<Cardtridge style={{fontWeight: 'bold', textAlign: 'left', padding: 5, marginTop: 15, background: 'none'}}>sort
				
				<div style={{fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<div style={{flex: 1, textAlign: 'right'}}>
						{this.state.sort==='date'&&this.state.direction===1&&<span>&#x21e7;</span>}
						{this.state.sort==='date'&&this.state.direction===-1&&<span>&#x21e9;</span>}
					</div>
					<button style={{flex: 1, margin: 2}} onClick={()=>this.setSortValue('date')}>by date</button>
				</div>
				
				<div style={{fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<div style={{flex: 1, textAlign: 'right'}}>
						{this.state.sort==='name'&&this.state.direction===1&&<span>&#x21e7;</span>}
						{this.state.sort==='name'&&this.state.direction===-1&&<span>&#x21e9;</span>}
					</div>
					<button style={{flex: 1, margin: 2}} onClick={()=>this.setSortValue('name')}>by name</button>
				</div>
				
			</Cardtridge>			

			<Cardtridge style={{fontWeight: 'bold', textAlign: 'left', padding: 5, marginTop: 15, background: 'none'}}>limit
				<Input type='number' min={1} initValue={this.state.limit} setValue={this.setLimitValue}/>
			</Cardtridge>

			{this.props.adminMode && <Cardtridge style={{fontWeight: 'bold', textAlign: 'left', padding: 5, marginTop: 15, background: 'none'}}>Admin options
				<div style={{fontWeight: 'normal', display: 'flex', alignItems: 'end', justifyContent: 'center', flexDirection : 'column'}}>
					<div style={{flex: 1, textAlign: 'right'}}>🔴 <input type='checkbox' onChange={this.setActiveFilter} checked={this.state.activeFilter}/></div>
					<div style={{flex: 1, textAlign: 'right', color : 'red'}}>INACTIVE SUBCRIPTION <input type='checkbox' onChange={this.setActiveSubscribeFilter} checked={this.state.activeSubscribeFilter}/></div>
				</div>
			</Cardtridge>}

			<img style={{marginTop: 15, height: 15, cursor: 'pointer'}} title='refresh' src={refresh} alt='refresh' onClick={this.updateCasesList}/>	
		</>

	}

} 


const ListCases = props => {

	const list = props.list

	return <div style={{position: 'absolute', top: 10, bottom: props.selected?'50%':10, left: '20%', right: 0, overflowY: 'scroll'}}>
		
		{list.map(caseObject =>

			<CaseLine key={caseObject._id} caseData={caseObject} filter={props.filter} select={props.select} selected={props.selected}/>

		)}

	</div>

}


const ListUsers = props => {

	const list = props.list

	return <div style={{position: 'absolute', top: 10, bottom: props.selected?'50%':10, left: '20%', right: 0, overflowY: 'scroll'}}>
		
		{list.map(userObject =>

			<UserLine key={userObject._id} userData={userObject} filter={props.filter} select={props.select} selected={props.selected} update={props.update}/>

		)}

	</div>

}






class Preview extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			updatable: false,
			name: props.selected.name
		}

		this.refName = React.createRef()
	}

	componentDidUpdate = prevProps => {
		if (prevProps.selected.name !== this.props.selected.name)
			this.setState({name: this.props.selected.name})
	}

	error = message => {
		this.props.caseContext.setState({
			popup: true,
			popupError: true,
			popupMessage: message,
			popupButton: null
		})
	}

	
	ask = (message, button) => {
		this.props.caseContext.setState({
			popup: true,
			popupMessage: message,
			popupButton: button
		})
	}


	makeUpdatable = () => {
		this.setState({updatable: true}, () => this.refName.current.focus())
	}

	cancelUpdate = () => {
		this.setState({updatable: false, name: this.props.selected.name})	
	}


	handleChange = e => {
		this.setState({name: e.target.value})
	}

	validUpdate = e => {
		e.preventDefault()

		if (this.state.name.trim() === '')
			return 

		socket.emit('updateCaseName', this.props.selected.id, this.state.name, name => {

			if (this.props.caseContext.id === this.props.selected.id) 
				this.props.caseContext.setState({name})
			
			this.props.updateCasesList()
			this.setState({updatable: false})
		})
	}

	goToCase = () => {
		const id = this.props.selected._id
		window.location.hash = '#case?'+writeSearch({id, page: 'summary'})
	}

	askDelete = () => {
		this.ask(<div>are you sure you want to delete the case "{this.props.selected.name}"?</div>, <button onClick={this.deleteCase}>yes !</button>)
	}

	deleteCase = () => {
		socket.emit('deleteCase', this.props.selected.id, () => {
			this.props.caseContext.setState({ popup: false, popupMessage: '', popupButton: null})
			this.props.updateCasesList()
			this.props.unselect()		
		})
	}

	duplicateCase = () => {
		socket.emit('duplicateCase', this.props.selected.id, newCaseId => {
			this.props.updateCasesList()
		})
	}

	askUnshare = () => {

		this.ask(<div>you will lose access to this case</div>, <button onClick={this.unshare}>do it !</button>)
	}


	unshare = () => {

		const userId = this.props.userContext.id
		socket.emit('removeShareUser', this.props.selected.id, userId, response => {

			if (response.error)
				this.error(response.error.message)
			else {
				/* const userAccess = props.caseContext.userAccess
				const index = userAccess.findIndex(access => access.id === response.id)
				userAccess.splice(index, 1)
				props.caseContext.setState({userAccess}) */
				this.props.caseContext.setState({ popup: false, popupMessage: '', popupButton: null})
				this.props.updateCasesList()
				this.props.unselect()		
			}
		})
	}


	render = () => {

		const { unreadMessages, name, thumbnail, created } = this.props.selected
		const thumb = thumbnail?URL.createObjectURL(new Blob([thumbnail])):thumbDefault


		return <Cardtridge onClick={e=>e.stopPropagation()} style={{position: 'absolute', top: '50%', bottom: 0, left: '20%', right: 0, overflow: 'auto', display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>

				<img style={{height: '95%', flex: 1, objectFit: thumbnail?'cover':'contain', border: 'solid white 5px'}} src={thumb} alt='thumbnail'/>

				<div style={{position: 'relative', flex: 2}}>
					<form style={{position: 'relative'/* , left: 0, right: 0 */}} onSubmit={this.validUpdate}>

						<input 	ref={this.refName} 
								disabled={!this.state.updatable} onBlur={this.cancelUpdate}
								style={{fontWeight: 'bold', fontSize: 22, color: 'white', background: 'black', textTransform: this.state.updatable?'none':'uppercase', width: '100%', border: 'none', textAlign: 'center'}} 
								value={this.state.name}  onChange={this.handleChange}/>	

						{(this.props.userContext.admin || created)&&!this.state.updatable&&<img onClick={this.makeUpdatable} src={pencil} style={{height: 25, position: 'absolute', right: 0, top: 0, cursor: 'pointer'}} alt='modify' title='update case name'/>}
						<div style={{fontStyle: 'italic', fontSize: 18}}>created by {this.props.selected.creator.name}</div>
					</form>

					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 60}}>
						<img style={{height: 40, cursor: 'pointer'}} src={cancel} alt='cancel' title='close' onClick={this.props.unselect}/>
						
						{(this.props.userContext.admin || created)? <img style={{height: 40, cursor: 'pointer'}} src={trashcan} alt='delete' title={`delete "${name}"`} onClick={this.askDelete}/> :
						<img style={{height: 40, cursor: 'pointer'}} src={unshare} alt='unshare' title={`unshare "${name}"`} onClick={this.askUnshare}/>}
						
						<img style={{height: 40, cursor: 'pointer'}} src={copy} alt='copy' title={`duplicate "${name}"`} onClick={this.duplicateCase}/>
						<img style={{height: 40, cursor: 'pointer'}} src={play} alt='play' title={`go to "${name}"`} onClick={this.goToCase}/>
						
					</div>

				</div>

				{unreadMessages>0&&<div style={{position: 'absolute', right: 5, bottom: 0}} title={`you have ${unreadMessages} unread ${unreadMessages>1?'messages':'message'}`}>
					<img style={{height: 40}} src={message} alt='message'/>
					<div style={{position: 'absolute', right: -3, top: -3, background: 'red', color: 'white', fontWeight: 'bold', fontSize: 12, padding: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 10, height: 10, borderRadius: 10}}>{this.props.selected.unreadMessages}</div>
				</div>}

		</Cardtridge>
	}
}


/* const Preview = props => {

	const [updatable, setUpdatable] = React.useState(false)
	const refName = React.useRef(null)

	const makeUpdatable = () => {
		setUpdatable(true)
		refName.current.focus()
	}

	const goToCase = () => {
		const id  = props.selected._id
		window.location.hash = '#case?'+writeSearch({id, page: 'summary'})
	}

	return 	props.selected&&<Cardtridge onClick={e=>e.stopPropagation()} style={{position: 'absolute', top: '50%', bottom: 0, left: '20%', right: 0, overflow: 'auto', display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
		<>

			<img style={{height: '95%', flex: 1, objectFit: 'cover', border: 'solid white 5px'}} src={URL.createObjectURL(new Blob([props.selected.thumbnail]))} alt='thumbnail'/>

			<div style={{position: 'relative', flex: 2}}>
				<div style={{position: 'absolute', left: 0, right: 0}}>
					<input ref={refName} disabled={!updatable} style={{fontWeight: 'bold', fontSize: 22, color: 'white', background: 'black', textTransform: 'uppercase', width: '100%', border: 'none', textAlign: 'center'}} value={props.selected.name}/>				
					<img onClick={makeUpdatable} src={pencil} style={{height: '100%', position: 'absolute', right: 0, top: 0, cursor: 'pointer'}} alt='modify' title='update case name'/>
				</div>

				<div style={{fontStyle: 'italic', fontSize: 18}}>created by {props.selected.creator.name}</div>
				<button onClick={props.unselect}>fermer</button>
				<button onClick={goToCase}>goto</button>
			</div>

		</>
	</Cardtridge>
} */




const CaseLine = props => {

	const select = e => {
		e.stopPropagation()
		props.select(props.caseData)
	}

	const goToCase = () => {
		const id  = props.selected._id
		window.location.hash = '#case?'+writeSearch({id, page: 'summary'})
	}

	const {created, unreadMessages, name, date, creator} = props.caseData


	return <div 
		css={{fontWeight: 'bold', color: created?'black':'grey', background: props.selected&&props.selected._id===props.caseData._id?'rgba(255, 255, 255, 0.8)':'none',display: 'flex', alignItems: 'center', justifyContent: 'space-around', cursor: 'pointer', '&:hover': {background: 'rgba(255, 255, 255, 0.8)'}}} 
		onClick={select}
		onDoubleClick={goToCase}
	>
		<div style={{flex: 1, textAlign: 'left', marginLeft: 25, fontSize: 18}}>{showRegex(name, props.filter, {textDecoration: 'underline', fontWeight: 'bolder'})}</div>
		<div style={{flex: 1, textAlign: 'left'}}>{formatDate(date).text}</div>
		<div style={{flex: 1, textAlign: 'left'}}>by {creator.name}</div>
		<div style={{flex: 1, textAlign: 'left', color: unreadMessages>0?'red':created?'black':'grey', fontWeight: 'normal'}}>
				{unreadMessages} unread {unreadMessages>1?'messages':'message'}
		</div>
	</div>
}




const UserLine = props => {

	const {_id, username, date, mail, active, subscription, vip, admin} = props.userData

	const [subscriptionOptions, setSubscriptionOptions] = React.useState(false)
	const [nameMailOptions, setNameMailOptions] = React.useState(false)


	const alarm = <span style={{color : 'red'}}>{subscription.expiration*1000 > new Date().getTime()? '' : '!!'}</span>

	const copyMail = e=>navigator.clipboard.writeText(mail).then(()=>console.log(mail+' copied'))

	const subscriptionType = ()=>{

		let date = formatDate(subscription.expiration*1000)
		// active, canceled, incomplete, incomplete_expired, past_due

		if (vip) return <span style={{color : 'green'}}>subscription VIP</span>
		else if (subscription.status==='active' && subscription.cancelAtEnd) return <span style={{color : 'orange'}}>subscription CANCELED (end : {alarm}{date.englishFormat} {date.time}{alarm})</span>
		else if (subscription.status==='active') return <span style={{color : 'green'}}>subscription ACTIVE (next payment : {alarm}{date.englishFormat} {date.time}{alarm})</span>
		else if (subscription.status==='trial') return <span style={{color : 'green'}}>subscription TRIAL (end : {alarm}{date.englishFormat} {date.time}{alarm})</span>
		else return <span style={{color : 'red'}}>subscription INACTIVE</span>
		 

		//{formatDate(subscription.expiration*1000).englishFormat}

	}

	const close = e=>{
		setSubscriptionOptions(false)
		setNameMailOptions(false)
		props.update()
	}
	

	return <div
		css={{fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-around', cursor: 'pointer', '&:hover': {background: 'rgba(255, 255, 255, 0.8)'}}} 
	>
		
		<div style={{flex: 1, textAlign: 'left', marginLeft: 25, fontSize: 18}} title={_id} onClick={e=>setNameMailOptions(true)}>
			<span title={`account is ${active? 'activate' : 'not activate'}, creation ${formatDate(date).englishFormat}`}>{active? '🟢' : '🔴'}&nbsp;</span>
			{showRegex(username, props.filter, {textDecoration: 'underline', fontWeight: 'bolder'})}
		</div>

		<div style={{flex: 1, textAlign: 'left', marginLeft: 25, fontSize: 18}} title={_id}>
			<span style={{flex: 1, textAlign: 'left', fontWeight : 'normal', fontStyle: 'italic'}} onClick={copyMail}>{showRegex(mail, props.filter, {textDecoration: 'underline', fontWeight: 'bolder'})}</span>
		</div>

		<div style={{flex: 0.3, textAlign: 'left', marginLeft: 25, fontSize: 14}}>
			{admin && <span style={{border : 'solid', borderRadius : 5, color : 'orange'}}>ADMIN</span>}
		</div>
		
		
		<div style={{flex: 2, textAlign: 'left'}} onClick={e=>setSubscriptionOptions(true)} title={'status : '+subscription.status}>
			{subscriptionType()}
		</div>


		{/*<div style={{flex: 0.5, textAlign: 'left'}}>creation {formatDate(date).englishFormat}</div>*/}

		{subscriptionOptions && <SubscribeOption data={props.userData} onClose={close}/>}
		{nameMailOptions && <UpdateNameMail data={props.userData} onClose={close}/>}
		
	</div>
}





const SubscribeOption = props=>{

	const {_id, username,subscription, vip} = props.data

	const [dateEnd, setDateEnd] = React.useState(formatDate(subscription.expiration*1000).englishFormat)
	const [trial, setTrial] = React.useState(subscription.status==='trial')
	const [vipStatus, setVipStatus] = React.useState(vip)

	const styleBack = {
		position: 'fixed', 
		top: 0, bottom: 0, left: 0, right: 0, 
		background: 'grey', opacity: 0.8, zIndex: 250,
		//display: 'flex', alignItems: 'center', justifyContent: 'center',
	}

	const styleDiv = {
		 display: 'flex', alignItems: 'center', justifyContent: 'center',
		 position: 'fixed', 
		 top: 0, bottom: 0, left: 0, right: 0, 
		 //zIndex : 251
	}

	const subscriptionType = ()=>{
		// active, canceled, incomplete, incomplete_expired, past_due

		if (vip) return <span style={{color : 'green'}}>subscription VIP</span>
		else if (subscription.status==='active' && subscription.cancelAtEnd) return <span style={{color : 'orange'}}>subscription CANCELED (end : {formatDate(subscription.expiration*1000).englishFormat})</span>
		else if (subscription.status==='active') return <span style={{color : 'green'}}>subscription ACTIVE (next payment : {formatDate(subscription.expiration*1000).englishFormat})</span>
		else if (subscription.status==='trial') return <span style={{color : 'green'}}>subscription TRIAL (end : {formatDate(subscription.expiration*1000).englishFormat})</span>
		else return <span style={{color : 'red'}}>subscription INACTIVE</span>

	}

	const changeDate = e=>setDateEnd(e.target.value)

	const save = e=>{
		socket.emit('saveAdminOptions', {id : _id, subscription, trial, vipStatus, dateEnd : new Date(dateEnd).getTime()/1000}, response=>{
			props.onClose()
		})
	}

	

	return <>


		<div style={styleDiv}>

			<div style={styleBack} onClick={props.onClose}/>

			<Cardtridge style={{textAlign : 'center', zIndex:251}}>
			
				<div>{username}'s subscription</div>
				
				<div>{subscriptionType()}</div>
				
				<div>
					<input type='checkBox' checked={vipStatus} onChange={e=>setVipStatus(e.target.checked)}/>VIP 
					<input type='checkBox' checked={trial} onChange={e=>setTrial(e.target.checked)}/>Trial
				</div>
				
				<div>
					<input type='date' value={dateEnd} onChange={changeDate}/>
				</div>

				<div>
					<button onClick={save}>Save</button>
				</div>

			</Cardtridge>

		</div>

	</>


}



const UpdateNameMail = props=>{

	const {_id, username, mail, active} = props.data

	const [name, setName] = React.useState(username)
	const [email, setEmail] = React.useState(mail)
	const [activate, setActivate] = React.useState(active)


	const styleBack = {
		position: 'fixed', 
		top: 0, bottom: 0, left: 0, right: 0, 
		background: 'grey', opacity: 0.8, zIndex: 250,
		//display: 'flex', alignItems: 'center', justifyContent: 'center',
	}

	const styleDiv = {
		 display: 'flex', alignItems: 'center', justifyContent: 'center',
		 position: 'fixed', 
		 top: 0, bottom: 0, left: 0, right: 0, 
		 //zIndex : 251
	}

	

	const save = e=>{
		socket.emit('adminChangeNameMail', {id : _id, username : name, mail : email, active : activate}, response=>{
			props.onClose()
		})
	}

	

	return <>


		<div style={styleDiv}>

			<div style={styleBack} onClick={props.onClose}/>

			<Cardtridge style={{textAlign : 'center', zIndex:251}}>
			
				<div style={{cursor : 'pointer'}} onClick={e=>setActivate(!activate)}>{activate? '🟢' : '🔴'} {username}'s profil</div>
				
				<div>name <input value={name} onChange={e=>setName(e.target.value)}/></div>
				<div>mail <input value={email} onChange={e=>setEmail(e.target.value)}/></div>
				

				<div>
					<button onClick={save}>Save</button>
				</div>

			</Cardtridge>

		</div>

	</>


}




const AddCase = props => {

	const [caseName, setCaseName] = React.useState('')
	const disabled = caseName.trim() === ''


	const setValue = value => {
		setCaseName(value)
	}

	const addCase = e => {
		e.preventDefault()
		socket.emit('addCase', {caseName}, result => {
			const id = result._id
			props.updateCasesList()
			window.location.hash = '#case?'+writeSearch({id, page: 'summary'})

		})
	}


	return <form onSubmit={addCase}> 
		<Cardtridge style={{fontWeight: 'bold', textAlign: 'left', padding: 5, marginTop: 15, background: 'none'}}>add new case
			<Input setValue={setValue} placeholder='name your case'/>
			<div style={{textAlign: 'right'}}><button disabled={disabled}>add</button></div>
		</Cardtridge>
	</form>
}


Menu = UserConsumer(Menu)

export default UserConsumer(CaseConsumer(Cases))  