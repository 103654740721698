import React from 'react'

const Pills = props => {

	const [active, setActive] = React.useState(false)

	React.useEffect(()=> {
		setActive(props.value)
	}, [props.value])

	const handleClick = () => {
		setActive(!active)
		props.onChange(!active)
	}

	return <div onClick={handleClick} title={props.title} style={{position: 'relative', width: props.size, height: props.size/2, background: active?'#0075ff':'none', border: 'solid grey 1px', borderRadius: props.size/2, cursor: 'pointer', transition: 'all 0.4s'}}>
		<div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: active?props.size/2:0, width: props.size/2, height: props.size/2, background: 'white', border: 'solid 1px grey', borderRadius: props.size/2, transition: 'all 0.1s'}}></div>
	</div>

}

Pills.defaultProps = {
	size: 30,
	onChange: () => null
}


export default Pills