import JSEncrypt from 'jsencrypt'
import cryptoJS from 'crypto-js'	


	const generateHexString = (length) => {
//generate random key
  		let ret = ""
  		while (ret.length < length) 
  		{
    		ret += Math.random().toString(16).substring(2)
  		}

  		return ret.substring(0,length)
	}

	//password hash
	const passwordHash = pw => cryptoJS.SHA512(pw).toString(cryptoJS.enc.Base64)

	
	const encrypt = (message, publicKey)=>{
		try{
			//generate random key
			const WEPKey = generateHexString(26)
			//symetric encrypt
			const encryptText = cryptoJS.AES.encrypt(message, WEPKey).toString()
			const crypt = new JSEncrypt()
			//set asymetric public key
			crypt.setPublicKey(publicKey)

			//encrypt key
			const WEPKeyCrypt = crypt.encrypt(WEPKey)
			//concatenate
			const encryptedMessage = WEPKeyCrypt+'::::::'+encryptText
			//base64 convert
			return window.btoa(encryptedMessage)

		}catch(e){}

	}

	const writeSearch = object => {
		/* const objArray = Object.entries(object)
		const reduced = objArray.reduce((accu, [key, value], i, array) => {
			const last = i === array.length -1
			return `${accu}${key}=${value}${last?'':'&'}`
		}, '')

		return window.btoa(reduced) */

		return window.btoa(JSON.stringify(object))

	}


	const readSearch = text => {
		try {
			/* let search = window.atob(text).split('&')
			search = search.map(a => a.split('='))

			return Object.fromEntries(search) */
			
			const search = window.atob(text)
			return JSON.parse(search)

		}catch(e){
			return ''
		}

	}
		




	export { encrypt, passwordHash, generateHexString, writeSearch, readSearch }