import UserConsumer from '../context/UserContextConsumer'
import CheckMail from './CheckMail'
import Transition from './Transition'
import Register from './Register'
import Login from './Login'
import Description from './Description'




const Log = props => {

	const userContext = props.userContext

	return <>

		<CheckMail/>

		{userContext.isExists===undefined&&<Description/>}

		<Transition mounted={userContext.isExists===false}>
			<Register/>
		</Transition>

		<Transition mounted={userContext.isExists===true}>
			<Login/>
		</Transition>


	</>
}


export default UserConsumer(Log)