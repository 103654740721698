import React from 'react'
import Cardtridge from './Cardtridge'
import { MailInput } from './Inputs'
import socket from '../functions/socket'
import Transition from './Transition'
import { ButtonTxt } from './Button'

import UserConsumer from '../context/UserContextConsumer'





class CheckMail extends React.Component {

	constructor(props) {

		super(props)
		this.state = {
			mailValue: '',
			mailValid: false,
		}
	}

	updateMailValue = mailValue => {
		this.setState({mailValue})
	}	

	updateMailValid = mailValid => {
		this.setState({mailValid})
	}

	handleSubmit = e => {
		e.preventDefault()

		if (!this.state.mailValid)
			return  

		this.props.userContext.setState({loading: true})

		const obj = { mail: this.state.mailValue}
		socket.emit('checkMail', obj, result => {

			if (result.error) this.props.userContext.setState({popup: true, popupMessage: result.error.message, popupError: true, loading: false})
			else this.props.userContext.setState({isExists: result, mail: this.state.mailValue, loading: false})

		})
	}

	reset = () => {
		this.props.userContext.setState({isExists: undefined, mail: ''})
	}

	render = () => 

		<Cardtridge style={{position: 'relative'}}>
			<Transition mounted={this.props.userContext.isExists===undefined} transition='leftSlide'>
				<form onSubmit={this.handleSubmit}>

					<MailInput localStorage='mail' value={this.updateMailValue} valid={this.updateMailValid}/>

					<Transition mounted={this.state.mailValid} transition='leftSlide'>
						<ButtonTxt label="let's go !"/>
					</Transition>

				</form>
			</Transition>


			{this.props.userContext.mail&&this.props.userContext.isExists!==undefined&&
				<>

					<div style={{fontSize: 22, fontWeight: 'bolder', color: 'white', marginBottom: 10, textTransform: 'uppercase', background: 'grey'}}>{this.props.userContext.isExists?'connection':'registration'}</div>
					<div style={{fontWeight: 'bolder'}}>
						{this.props.userContext.mail} 
						<button style={{position: 'absolute', right: 5, cursor: 'pointer'}} onClick={this.reset} title='go back'>&#x2716;</button>
					</div>
				</>}

		</Cardtridge>

}
	




export default UserConsumer(CheckMail) 