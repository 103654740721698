import * as THREE from 'three'


const createRenderer = ({scene, renderer, camera, width=512}) => {
	const rendererThumbnail = new THREE.WebGLRenderer({antialias: true})
	scene.background = new THREE.Color( 'white' )
	const size = renderer.getSize(new THREE.Vector2())
	const ratio = size.y / size.x
	rendererThumbnail.setSize( width, width*ratio)
	rendererThumbnail.render( scene, camera )
	scene.background = null

	return rendererThumbnail
}

const exportThumbnailToBlob = ({scene, renderer, camera, width=512}) =>
	new Promise(resolve => {

		const rendererThumbnail = createRenderer({scene, renderer, camera, width})

		rendererThumbnail.domElement.toBlob(blob=> {
			rendererThumbnail.dispose()
			resolve(blob)
		}, 'image/jpeg')
	})


const exportThumbnailToBase64 = ({scene, renderer, camera, width=512}) =>
	new Promise(resolve => {

		const rendererThumbnail = createRenderer({scene, renderer, camera, width})

		const data = rendererThumbnail.domElement.toDataURL('image/jpeg')
		rendererThumbnail.dispose()

		resolve(data)

	})






export { exportThumbnailToBlob, exportThumbnailToBase64 }