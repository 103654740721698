import React from 'react'
import * as THREE from 'three'

import photoScene from '../functions/PhotoScene'
import SculptTool from '../functions/Sculpt'
import Smile from '../functions/teethGenerator'
import { loadTexture, resetTransform, createModel, init2DScene, init3DScene, toNDC, drawBall, drawVoid, drawRect, drawLine, calcMarkerRadius, free, exportPLY, exportSTL } from '../functions/threeCustomFunction'
import { DragControls } from '../functions/DragControls.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import GizmoView from './GizmoView'
import { exportThumbnailToBase64 } from '../functions/thumbnailExporter'
import History from '../functions/History'
import SaveWindow from './SaveWindow'
import ExportWindow from './ExportWindow'
import InfoWindow from './InfoWindow'
import JSZip from 'jszip'
import mirrorGeometry from '../functions/mirrorGeometry'
import { readSearch } from '../functions/crypto'
import TeethManager from './TeethManager'

import translate from '../icons/translate.svg'
import rotate from '../icons/rotate.svg'
import scale from '../icons/scale.svg'
import lock from '../icons/lock.svg'
import sculpt from '../icons/sculpt.svg'
import fit from '../icons/fit.svg'
import fitSmile from '../icons/fitSmile.svg'
import undo from '../icons/undo.svg'
import redo from '../icons/redo.svg'
import symmetry from '../icons/symmetry.svg'
import refresh from '../icons/refresh.svg'
import smile from '../icons/smile.svg'
import retractedSmile from '../icons/retractedSmile.svg'
import floppy from '../icons/floppy.svg'
import share from '../icons/share.svg'
/* import teethTransparent from '../icons/teethTransparent.svg'
import teethWhite from '../icons/teethWhite.svg' */
import trashcan from '../icons/trashcan.svg'
import thugGlasses from '../images/thugGlasses.png'
import thugGlassesIcon from '../images/thugGlasses.svg'
import guideLinesIcon from '../icons/guideLines.svg'
import invisibleIcon from '../icons/invisible.svg'
import visibleIcon from '../icons/visible.svg'
import showLower from '../icons/showLower.svg'
import hideLower from '../icons/hideLower.svg'
import teethWhite from '../icons/teethWhite.svg'
import teethTransparent from '../icons/teethTransparent.svg'


import Pills from './Pills'
import Cardtridge from './Cardtridge'
import Menu from './Menu'


import TutoPlace from './TutoPlace'
import TutoDesign from './TutoDesign'

import lib1 from '../smileLibrary/lib1_max.obj'
import lib2 from '../smileLibrary/lib2_max.obj'
import lib3 from '../smileLibrary/lib3_max.obj'
import lib4 from '../smileLibrary/lib4_max.obj'
import lib5 from '../smileLibrary/lib5_max.obj'
import libMand from '../smileLibrary/lib_mand.obj'


import helvetica from 'three/examples/fonts/helvetiker_bold.typeface.json'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

//import Stats from 'three/examples/jsm/libs/stats.module.js'


class SmileDesigner extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			loaded: false,
			selected: null,
//			allSelected: false,
			mirror: false,
			homotetic: false,
			transformMode: 'translate',
			lips: false, 
			guideLines: true,
			glasses: false,
			visible: true,
			placed: false,
			save: false,
			export: false,
			info: false,
			opacity: 1,
			showTeeth: true,
			showModel: true,
			showUpperContact: false,
			showLowerContact: false,
			upperJawVisible: true,
			lowerJawVisible: true,
			upperWaxOpacity: 1,
			lowerWaxOpacity: 1,
			buccalOpacity: 0,
			VDO: 0

		}

		this.clock = new THREE.Clock()
		this.photoRef = React.createRef()
		this.viewportRef = React.createRef()
		this.gizmoRef = React.createRef()

		this.mousePhoto = new THREE.Vector2(5, 5)
		this.mouseViewport = new THREE.Vector2(5, 5)
		this.timeClick = 0

		this.controlPoints = []
		this.lastPosition = [new THREE.Vector3(), new THREE.Vector3()]
		this.transforming = false
		this.smileNeedsUpdate = true
		this.contactNeedsUpdate = true
		this.globalQuaternion = {
			maxillar: new THREE.Quaternion(),
			mandibular: new THREE.Quaternion()
		}
		this.hasBuccal = this.props.buccal.file?true:false


		this.history = new History({
			update: state=>this.setState(state),
			loadState: this.loadState,
			saveState: this.saveState
		})


	}

	componentDidMount = () => {

		this.props.caseContext.setState({loading: true})


		createModel({
			url: this.props.model.file
		}).then(mesh => {

			this.canvas3D = init3DScene({
				ref: this.viewportRef,
				mesh
			})
			this.canvas3D.camera.setFocalLength(90)


			this.texture = new photoScene({
				scene: this.canvas3D.scene,
				photo: this.props.photo,
				buccal: this.props.buccal
			})
			
			this.texture.draw().then(() => {
				this.texture.render()

				this.canvas2D = init2DScene({
					ref: this.photoRef,
					photo: this.texture
				})
				this.fitToLips(false)
				
				if (this.viewportRef.current)
					this.sceneConstruct()
			})
		})
		
	}

	sceneConstruct = async () => {


		this.photoRef.current.addEventListener('pointerdown', this.markPhoto)
		
		this.viewportRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouseViewport, this.canvas3D.renderer.domElement))
		this.photoRef.current.addEventListener('pointermove', e=> toNDC(e, this.mousePhoto, this.canvas2D.renderer.domElement))
			

/* this.stats = new Stats()
this.viewportRef.current.appendChild( this.stats.dom ) */

//get basement if exists

		const basement = this.props.model.basement ? await createModel({url: this.props.model.basement}) : null
		if (basement ) {
			basement.material = this.canvas3D.mesh.material
			this.canvas3D.mesh.add(basement)
			this.canvas3D.mesh.userData.basement = basement

		}

//get lowerJaw if exists
		this.lowerJaw = this.props.mandibular.file ? await createModel({url: this.props.mandibular.file}) : null
		if (this.lowerJaw) {
			this.canvas3D.scene.add(this.lowerJaw)
			this.lowerJaw.material.transparent = true
		}

//get lowerBasement if exists
		const lowerBasement = this.props.mandibular.basement ? await createModel({url: this.props.mandibular.basement}) : null
		if (lowerBasement) {
			lowerBasement.material = this.lowerJaw.material
			this.lowerJaw.add(lowerBasement)
			this.lowerJaw.userData.basement = lowerBasement
		}


		this.mandibular = new Smile(true)
		this.smile = new Smile()

//get tooth from lib
		await this.mandibular.getFromLib(libMand)
		await this.smile.getFromLib(lib1)


		this.texture.outlinePass.selectedObjects = this.smile.teethMesh
		this.visibleTeeth = [...this.smile.teethMesh, ...this.mandibular.teethMesh]

///////////////////////////


	//gizmo
		this.transformControl = new TransformControls( this.canvas3D.camera, this.canvas3D.renderer.domElement )
		this.transformControl.setSpace('local')


		this.transformControl.addEventListener('mouseDown', () => {
			this.canvas3D.controls.enabled = false
			this.transforming = true
		})

		this.transformControl.addEventListener('mouseUp', () => {
			this.canvas3D.controls.enabled = true
			setTimeout(()=>this.transforming = false, 300)


			if (this.state.selected.number === '01') {
				this.globalQuaternion.maxillar = this.anchor.quaternion.clone()
				this.updateContact(true)
			}
			else if (this.state.selected.number === '02') {
				this.globalQuaternion.mandibular = this.anchor.quaternion.clone()
				this.updateContact(true)
			}
			else if (this.state.selected.number === '00') {
				this.globalQuaternion.mandibular.multiply(this.globalQuaternion.maxillar.clone().invert()).multiply(this.anchor.quaternion.clone())
				this.globalQuaternion.maxillar = this.anchor.quaternion.clone()
				this.updateContact(true)
			}


			this.history.saveHistory()
		})

		this.transformControl.addEventListener('change', this.transform)


		this.sculptTool = new SculptTool({
			camera: this.canvas3D.camera, 
			domElement: this.viewportRef.current,
			controls: this.canvas3D.controls,
			onChange: this.handleSculpt,
			callback: () => this.history.saveHistory()
		})

		this.canvas3D.scene.add(this.sculptTool.cursor)

	//set mirror axis
		this.yAxis = drawRect(0, 0, 0, 80, 60, 'white')
		this.xAxis = drawRect(0, 0, 0, 80, 60, 'red')
		this.yAxis.geometry.rotateY(Math.PI/2)
		this.xAxis.material.visible = false
		//this.yAxis.visible = false
		this.anchor = new THREE.Object3D()
		this.canvas3D.scene.add(this.xAxis/* , this.yAxis */)
		this.xAxis.add(this.anchor)



	//draw raycast outside plane
		this.outside = drawRect(0, 0, 15, 10000, 10000, 'red')
		this.outside.material.visible = false
		this.canvas3D.scene.add(this.outside)
	//default placement method
		this.hitMethod = [this.outside, this.canvas3D.mesh]


	//draw thug glasses
		const glasses = await loadTexture(thugGlasses)
		this.thug = new THREE.Sprite( new THREE.SpriteMaterial({ map: glasses }) )
		const ratio = glasses.image.width / glasses.image.height
		const dist = this.canvas2D.landmarks.leftIris.position.distanceTo(this.canvas2D.landmarks.rightIris.position)
		const middle = this.canvas2D.landmarks.leftIris.position.clone().lerp(this.canvas2D.landmarks.rightIris.position, 0.5)
		this.thug.position.copy(middle)
		this.thug.scale.set(dist*2.3, dist*2.3/ratio, 1)


	//draw facial guide lines
		const planeBox = new THREE.Box3().setFromObject(this.canvas2D.bgPlane, true)

		//horizontal line
		const lineMaterial = new THREE.LineBasicMaterial({
			color: 'white',
			linewidth: 2
		})

		const checkMaterial = new THREE.LineBasicMaterial({
			color: 'blue',
			linewidth: 2
		})

		const scaleDash = (planeBox.max.y*2)/100
		const dashedMaterial = new THREE.LineDashedMaterial({
			color: 'white',
			linewidth: 2,
			dashSize: scaleDash,
			gapSize: scaleDash,
			transparent: true,
			opacity: 0.7
		})	

		const horizontal = drawLine(
			[ 
				new THREE.Vector3( planeBox.min.x, this.canvas2D.landmarks.leftIris.position.y, 5 ) , 
				new THREE.Vector3( planeBox.max.x, this.canvas2D.landmarks.leftIris.position.y, 5 ) 
			],
			lineMaterial
		)

		//vertical line
		const vertical = drawLine(
			[ 
				new THREE.Vector3( this.canvas2D.landmarks.middle.position.x, planeBox.min.y, 5 ), 
				new THREE.Vector3( this.canvas2D.landmarks.middle.position.x, planeBox.max.y, 5 )	
			],
			lineMaterial
		)


		//left nose
		const verticalLeft = drawLine(
			[ 
				new THREE.Vector3( this.canvas2D.landmarks.leftNose.position.x, planeBox.min.y, 5 ), 
				new THREE.Vector3( this.canvas2D.landmarks.leftNose.position.x, planeBox.max.y, 5 )	
			],
			dashedMaterial
		)

		//right nose
		const verticalRight = drawLine(
			[ 
				new THREE.Vector3( this.canvas2D.landmarks.rightNose.position.x, planeBox.min.y, 5 ), 
				new THREE.Vector3( this.canvas2D.landmarks.rightNose.position.x, planeBox.max.y, 5 )
			],
			dashedMaterial
		)

		this.guideLines = new THREE.Group()
		this.guideLines.add(horizontal, vertical, verticalLeft, verticalRight)
		this.canvas2D.bgPlane.add(this.guideLines)


	//draw check line
		this.checkLine = drawLine(
			[ 
				new THREE.Vector3( -10000, 0, 5 ), 
				new THREE.Vector3( 10000, 0, 5 )	
			],
			checkMaterial
		)

		this.canvas2D.scene.add(this.checkLine)


		const  size2D = calcMarkerRadius(this.canvas2D.lips, 80)

		const drawMark = () => {

			const ball2D = drawBall(0, 0, -100, size2D, 0xff6600)
			ball2D.material.opacity = 1
			this.canvas2D.bgPlane.add(ball2D)

			const ball3D = drawVoid()
			this.canvas3D.scene.add(ball3D)

			return { onPhoto: ball2D.position, onModel: ball3D.position, ball2D, ball3D }
		}


	//3 control points
		this.first = drawMark()
		this.second = drawMark()
		this.third = drawMark()




		if (this.viewportRef.current) {
		//some events when mounted to prevent memory leaks
			window.addEventListener('keydown', this.handleKeyDown)
			window.addEventListener('resize', this.handleResize)
			window.addEventListener('beforeunload', this.beforeUnload)
			this.animate()

			this.setState({loaded: true})
			this.props.caseContext.setState({loading: false, loadingMessage: ''})

			this.history.saveHistory()

			//load last design
			const [, search] = window.location.hash.split('?')
			const savedId = readSearch(search).saved
			if (savedId)
				this.props.load(savedId)


		}

		
	}

	fitToFace = (transition=true) => {
		this.canvas2D.controls.fitToBox(this.canvas2D.bgPlane, transition)
	}

	fitToLips = (transition=true) => {
		this.canvas2D.controls.fitToBox(this.canvas2D.lips, transition)
	}

	beforeUnload = e => {

		let lastSaveTime
		if (this.props.mountedCase) {
			lastSaveTime = new Date(this.props.mountedCase.date).getTime()
			const lastSave = new Date().getTime() - lastSaveTime

			if (lastSave < 5*60*1000) {
				return
			}
		}

		const warn = 'don\'t forget to save your work before close'
		e.returnValue = warn
		return warn


	}

	componentWillUnmount = () => {
	//avoid leak memory	
		cancelAnimationFrame(this.loop)
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('keydown', this.handleKeyDown)
		window.removeEventListener('beforeunload', this.beforeUnload)
		this.props.caseContext.setState({loading: false})
		if (this.canvas3D) this.canvas3D.dispose()
		if (this.canvas2D) this.canvas2D.dispose()
		if (this.transformControl) {
			this.transformControl.detach()
			this.transformControl.dispose()
		}
	}

	handleResize = () => {

	//make it responsive
		const contexts = [
			{renderer: this.canvas3D.renderer, ref: this.viewportRef, camera: this.canvas3D.camera}, 
			{renderer: this.canvas2D.renderer, ref: this.photoRef, camera: this.canvas2D.camera}
		]

		contexts.forEach(context => context.renderer.domElement.remove())

		contexts.forEach(context => {
			const WIDTH = context.ref.current.getBoundingClientRect().width
			const HEIGHT = context.ref.current.getBoundingClientRect().height
			context.renderer.setSize(WIDTH, HEIGHT)
			context.camera.responsive(WIDTH/HEIGHT)
		})

		contexts.forEach(context => context.ref.current.appendChild(context.renderer.domElement))

	}

	handleKeyDown = e => {
		if (this.state.save || this.state.info || this.state.export || !this.props.caseContext.enableShortcuts)
			return

		if (e.shiftKey) {
			e.preventDefault()
			this.mirror = false
			window.addEventListener('keyup', this.handleKeyUp, {once: true})
		}

		if (e.keyCode===90 && e.ctrlKey) {
			this.undo()		
		}

		else if (e.keyCode===89 && e.ctrlKey) {
			this.redo()
		}

		else if (e.keyCode===84) {
			this.selectTransformMode('translate')
		}

		else if (e.keyCode===82) {
			this.selectTransformMode('rotate')
		}

		else if (e.keyCode===83) {
			this.selectTransformMode('scale')
		}

		else if (e.keyCode===68) {
			this.selectTransformMode('sculpt')
		}

		else if (e.keyCode===77) {
			this.symmetriseAll()
		}

	}

	handleKeyUp = e => {
		this.mirror = true
	}


////place all teeth/////////////////////////////////////////////////////////////////////
	markPhoto = e => {
	//prevent right click
		if (e.buttons !== 1)
			return

		const planeWidth = this.canvas2D.bgPlane.material.map.image.width
		const planeHeight = this.canvas2D.bgPlane.material.map.image.height
		const firstPoint = this.mousePhoto.clone()

		const  size2D = calcMarkerRadius(this.canvas2D.lips, 50)

		const updateMark = (mark, ndcCoords=this.mousePhoto) => {

			this.canvas2D.raycaster.setFromCamera(ndcCoords, this.canvas2D.camera)
			const intersect = this.canvas2D.raycaster.intersectObject(this.canvas2D.bgPlane)

			if (intersect.length === 0)
				return 
			const point = intersect[0].point
			const pos = new THREE.Vector3(point.x, point.y, 10)


			const planeCoords = new THREE.Vector2(pos.x/(planeWidth/2), pos.y/(planeHeight/2))
			this.canvas3D.raycaster.setFromCamera(planeCoords, this.texture.camera)
			const intersect2 = this.canvas3D.raycaster.intersectObjects(this.hitMethod)

			if (intersect2.length === 0) 
				return

			const pos2 = intersect2[0].point

			mark.ball2D.position.copy(pos)
			mark.ball3D.position.copy(pos2)


		}


		const updateSecond = () => {
			updateMark(this.second, new THREE.Vector2(this.mousePhoto.x, firstPoint.y))
			this.updateMiddle(true)
		}

		const validSecond = () => {

			this.smile.ungroup()

			if (this.state.selected && this.state.selected.number === '01' )
				this.selectAll('maxillar')
			else if (this.state.selected && this.state.selected.number === '02' )
				this.selectAll('mandibular')
			else if (this.state.selected && this.state.selected.number === '00' )
				this.selectAll('all')
			else
				this.group(this.state.selected)

			this.photoRef.current.removeEventListener('pointermove', updateSecond )
			this.photoRef.current.removeEventListener('pointerup', validSecond)


			if (Math.abs(this.first.onPhoto.x - this.second.onPhoto.x) < size2D && Math.abs(this.first.onPhoto.y - this.second.onPhoto.y) < size2D)
				this.reset()
			else {
				this.updateMiddle(true)
				this.setState({placed: true})
				this.updateContact(true)
			//cheat a bit : remove first history to avoid a bug
				this.history.deleteHistory()

			}

		}

	//no more 2 points control
		if (this.controlPoints.length > 2) 
			return

		this.ungroup()
		this.smile.groupAll(this.mandibular.teethMesh)
		this.canvas3D.scene.add(this.smile.grouped)

		updateMark(this.first)
		this.controlPoints[0] = this.first.ball2D

		this.second.onPhoto.copy(this.first.onPhoto)
		this.controlPoints[1] = this.second.ball2D

		this.third.onPhoto.copy(this.first.onPhoto)
		this.controlPoints[2] = this.third.ball2D


	//make control draggable
		this.createDragControl(this.controlPoints)

	//some events
		this.photoRef.current.addEventListener('pointermove', updateSecond )
		this.photoRef.current.addEventListener('pointerup', validSecond)

	}


	createDragControl = (array) => {
		this.dragControls = new DragControls( array , this.canvas2D.camera, this.canvas2D.renderer.domElement )
		
		this.dragControls.addEventListener('drag', this.handleDragControl)
		this.dragControls.addEventListener('dragstart', e => {
			this.ungroup()

			this.smile.groupAll(this.mandibular.teethMesh)
			this.canvas3D.scene.add(this.smile.grouped)

			this.canvas2D.controls.enabled = false
			this.handleDragControl(e)
		})
		this.dragControls.addEventListener('dragend', e => {

			
			this.smile.ungroup()

			this.canvas2D.controls.enabled = true
			this.updateContact(true)
			this.history.saveHistory()

			if (!this.state.selected)
				return

			else if (this.state.selected.number === '01') 
				this.selectAll('maxillar')
			else if (this.state.selected.number === '02') 
				this.selectAll('mandibular')
			else if (this.state.selected.number === '00') 
				this.selectAll('all')
			else
				this.group(this.state.selected)
		})
	}


	updateMiddle = (firstTime=false) => {

		const  size2D = calcMarkerRadius(this.canvas2D.lips, 50)
		const dist = this.first.onPhoto.x - this.second.onPhoto.x

	//prevent bug
		if (Math.abs(dist) < size2D*2) return

	//inverse depend order of controls
		const mirror = dist < 0

		//const planeWidth = this.canvas2D.bgPlane.material.map.image.width

		const middle2D = new THREE.Vector3().lerpVectors(this.first.onPhoto, this.second.onPhoto, 0.5)
		const middle3D = new THREE.Vector3().lerpVectors(this.first.onModel, this.second.onModel, 0.5)
		this.third.ball3D.position.copy(middle3D)

		if (firstTime)
			this.third.ball2D.position.copy(middle2D)
		/* else
			this.third.ball2D.position.x = middle2D.x */

	//set axis for rotation
		const axisX = new THREE.Vector3().subVectors(this.first.onModel, this.second.onModel).normalize()
		const axisY = new THREE.Vector3(0, 1, 0)
		const axisZ = axisX.clone().cross(new THREE.Vector3(0, 1, 0))

//set waxup position, rotation, scale

	//set hierarchy
		this.canvas3D.scene.add(this.smile.anchor.middle)
		this.smile.grouped.add(this.smile.anchor[13], this.smile.anchor[23])
		this.smile.anchor.middle.attach(this.smile.grouped)

	//set scale
		this.smile.grouped.updateMatrixWorld()

		const distance1 = this.first.onModel.distanceTo(this.second.onModel)
		const distance2 = this.smile.anchor[13].getWorldPosition(new THREE.Vector3()).distanceTo(this.smile.anchor[23].getWorldPosition(new THREE.Vector3()))
		const distance = distance1/distance2
		this.smile.anchor.middle.scale.set(distance, distance, distance)
	
	//set position
		this.xAxis.position.copy(middle3D)
		this.yAxis.position.copy(middle3D)
		this.smile.anchor.middle.position.copy(middle3D)

	//set angles
		let ax = Math.atan2(this.third.onPhoto.y - this.second.onPhoto.y, 500)
		if (mirror) ax *= -1
		const ay = Math.atan2(this.second.onModel.x - this.third.onModel.x,  this.second.onModel.z - this.third.onModel.z)
		const ay2 = Math.atan2(this.third.onPhoto.x - middle2D.x, 200)


	//apply rotations for X and Y axis
		const qx = new THREE.Quaternion().setFromAxisAngle(axisX, -ax)
		const qy = new THREE.Quaternion().setFromAxisAngle(axisY, ay)
		const qy2 = new THREE.Quaternion().setFromAxisAngle(axisY, ay2)
		const q = new THREE.Quaternion().multiplyQuaternions(qx, qy).multiply(qy2)

		this.xAxis.quaternion.copy(q)
		this.yAxis.quaternion.copy(q)
		this.smile.anchor.middle.quaternion.copy(q)

		if (mirror) {
			this.smile.anchor.middle.rotateY(-Math.PI/2)
			this.xAxis.rotateY(-Math.PI/2)
			this.yAxis.rotateY(-Math.PI/2)
		}
		else {
			this.smile.anchor.middle.rotateY(Math.PI/2)
			this.xAxis.rotateY(Math.PI/2)
			this.yAxis.rotateY(Math.PI/2)
		}


	//set Z rotation to horizontalise smile line
		this.xAxis.updateMatrixWorld()

		const a = new THREE.Vector3()
		this.smile.anchor[13].getWorldPosition(a)
		this.xAxis.worldToLocal(a)

		const b = new THREE.Vector3()
		this.second.ball3D.getWorldPosition(b)
		this.xAxis.worldToLocal(b)

		const az = mirror? ( -2 * Math.atan2(b.y - a.y, b.x - a.x) ) : ( -2 * Math.atan2(b.x - a.x, b.y - a.y) )


	//apply Z axis rotation
		this.xAxis.rotateOnWorldAxis(axisZ, az)
		this.yAxis.rotateOnWorldAxis(axisZ, az)
		this.smile.anchor.middle.rotateOnWorldAxis(axisZ, az )


	//reverse hierarchy
		this.canvas3D.scene.attach(this.smile.grouped)
		this.smile.grouped.remove(this.smile.anchor[13], this.smile.anchor[23])
		this.canvas3D.scene.remove(this.smile.anchor.middle)

	//reinit pivot scale
		this.smile.anchor.middle.scale.set(1, 1, 1)

	//refresh photo
		this.smile.grouped.visible = true
		this.smileNeedsUpdate = true

	}


///////several tooth/////////////////////////////////////////////////////////////////


	reset = () => {

		if (this.state.lips)
			this.toogleLips()

	//reset control points
		this.dragControls.dispose()
		this.controlPoints.forEach(point => point.position.set(0, 0, -100))
		this.controlPoints = []
		this.setState({selected: null})

		this.transformControl.detach()
		this.ungroup()
	//remove from scene
		this.canvas3D.scene.remove(...this.smile.teethMesh, ...this.mandibular.teethMesh, this.smile.grouped, this.mandibular.grouped)
		this.setState({placed: false})

	//delete history
		this.history.deleteHistory()

		const teeth = [...this.smile.teeth, ...this.mandibular.teeth]

		teeth.forEach(tooth => {

		//reset teeth transform
			const m = this.smile.grouped.matrix.clone()
			resetTransform(tooth.mesh)
			//tooth.mesh.visible = true
			tooth.mesh.applyMatrix4(m)

		//reset teeth geometry
			tooth.mesh.geometry.copy(tooth.originalGeometry)
			tooth.mesh.geometry.computeVertexNormals()
      		tooth.mesh.geometry.normalizeNormals()
      		tooth.mesh.geometry.computeBoundingBox()
			tooth.mesh.geometry.computeBoundsTree()

		}) 

		this.globalQuaternion.maxillar = new THREE.Quaternion()			
		this.globalQuaternion.mandibular = new THREE.Quaternion()			
		this.anchor.quaternion.copy(new THREE.Quaternion())

		this.visibleTeeth = [...this.smile.teethMesh, ...this.mandibular.teethMesh]
		this.smileNeedsUpdate = true

	}

	handleDragControl = e => {

	//refresh 
		const planeWidth = this.canvas2D.bgPlane.material.map.image.width
		const planeHeight = this.canvas2D.bgPlane.material.map.image.height


		if (e && !e.e.shiftKey) {

			const middle2D = new THREE.Vector3().lerpVectors(this.first.onPhoto, this.second.onPhoto, 0.5)

		//if shift pressed => disable symetry
			if (e.object === this.controlPoints[0]) this.controlPoints[1].position.y = e.object.position.y
			else if (e.object === this.controlPoints[1]) this.controlPoints[0].position.y = e.object.position.y 

			this.third.ball2D.position.x = middle2D.x

		}


	//prevent markers out of model
		const intersects = this.controlPoints.map(point =>  {

			const planeCoords = new THREE.Vector2(point.position.x/(planeWidth/2), point.position.y/(planeHeight/2))
			this.canvas3D.raycaster.setFromCamera(planeCoords, this.texture.camera)
			const intersect = this.canvas3D.raycaster.intersectObjects(this.hitMethod)

			return intersect
		})

		const [intersect1, intersect2, ] = intersects

		this.controlPoints.forEach((point, i) => {
			if (!intersect1.length>0 || !intersect2.length>0) {
				point.position.copy(this.lastPosition[i])
			}
			else {
				this.first.onModel.copy(intersect1[0].point)
				this.second.onModel.copy(intersect2[0].point) 
			}
		
			this.lastPosition[i] = point.position.clone()
		})

	//update middle position
		this.updateMiddle()

	}



	select = (e, intersect) => {

		const button = e.button
		let selected

		let mesh = null
		if (intersect && intersect.object.parent && intersect.object.visible)
			mesh = intersect.object



	//right click select all teeth
		if (mesh && button === 2) {

			this.timeClick ++

			this.timeOut = setTimeout( ()=> {
				
			//check double click
				if (this.timeClick > 1) {
					this.ungroup()
					this.selectAll('all')
				}
				else {
					const jaw = this.smile.teethMesh.includes(mesh) ? 'maxillar' : this.mandibular.teethMesh.includes(mesh) ? 'mandibular' : null
					this.ungroup()
					this.selectAll(jaw)
				}

				
				this.timeClick = 0
				clearTimeout(this.timeOut)
				return

			}, 200)	
		}

	//left click select one tooth

		else if (mesh && button === 0) {
			resetTransform(this.anchor)
			selected = [ ...this.smile.teeth, ...this.mandibular.teeth ].filter(tooth => tooth.mesh === mesh)[0]
		}

		this.ungroup()
		this.setState({selected})


		if (selected) {
			this.group(selected)
		}



	}


	selectAll = jaw => {

		const all = new THREE.Group()
		const visibleTeeth = new THREE.Group()
		
		const teeth = jaw==='maxillar' ? this.smile.teeth : jaw==='mandibular' ? this.mandibular.teeth : jaw==='all' ? [ ...this.smile.teeth, ...this.mandibular.teeth ] : []
		this.smile.select(...teeth)
		this.mandibular.select(...teeth)

		teeth.forEach(sel => {

			all.add(sel.mesh)

			if (sel.mesh.visible)
				visibleTeeth.add(sel.mesh.clone())
		})


		const selected = {number: jaw==='maxillar' ? '01' : jaw==='mandibular' ? '02' : jaw==='all' ? '00' : '', mesh: all, center: this.anchor}

		const bBox = new THREE.Box3().setFromObject(visibleTeeth, true)
		const middle = new THREE.Vector3()
		bBox.getCenter(middle)

		if (jaw==='all')
			jaw='maxillar'

		this.anchor.position.copy(middle)	
		this.anchor.quaternion.copy(this.globalQuaternion[jaw])
		this.xAxis.worldToLocal(this.anchor.position)

		this.anchor.attach(all)

		this.setState({selected}, ()=>this.selectTransformMode())

	}

	group = selected => {

		if (!selected) {
			return
		}
		else if ( selected.number === '01' ) {
			this.selectAll('maxillar')
		}		
		else if ( selected.number === '02' ) {
			this.selectAll('mandibular')
		}		
		else if ( selected.number === '00' ) {
			this.selectAll('all')
		}

		else {
			const symmetry = this.getSymmetry(selected)

			this.smile.select(selected)
			this.mandibular.select(selected)

			this.xAxis.attach(selected.center)
			this.xAxis.attach(symmetry.center)
			selected.center.attach(selected.mesh)
			symmetry.center.attach(symmetry.mesh)

		}


		this.selectTransformMode()
	}

	ungroup = () => {

		const unselectJaw = (object) => {
			this.state.selected.mesh.updateMatrixWorld()
			const m = this.state.selected.mesh.matrixWorld.clone()
			object.teeth.forEach(tooth => {
				tooth.mesh.applyMatrix4(m)
				this.canvas3D.scene.add(tooth.mesh)
				tooth.mesh.attach(tooth.center)
			})
		}

		const unselectAll = () => {
			[ ...this.smile.teeth, ...this.mandibular.teeth ].forEach(tooth => {
				this.canvas3D.scene.attach(tooth.mesh)
				tooth.mesh.attach(tooth.center)
			})
		}


		this.transformControl.detach()
		this.sculptTool.detach()
		this.smile.unselect()
		this.mandibular.unselect()

		if (!this.state.selected) {
			return
		}

		else if (this.state.selected.number==='01') {
			unselectJaw(this.smile)
		}
		else if (this.state.selected.number==='02') {
			unselectJaw(this.mandibular)
		}
		else if (this.state.selected.number==='00') {
			unselectJaw(this.smile)
			unselectJaw(this.mandibular)
		}
		else {
			unselectAll()
		}

	}


	selectTransformMode = (mode=this.state.transformMode) => {

		const selected = this.state.selected
		const cancel = () => {
			this.transformControl.detach()
			this.sculptTool.detach()
		}

		if (!selected) {
			this.setState({transformMode: mode})
			cancel()
			return
		}

		switch (mode) {
			case 'translate':
				this.sculptTool.detach()
				this.transformControl.attach(selected.center) 
				this.transformControl.setMode('translate')
				this.setState({transformMode: mode})
				break

			case 'rotate':
				this.sculptTool.detach()
				this.transformControl.attach(selected.center) 
				this.transformControl.setMode('rotate')
				this.setState({transformMode: mode})
				break

			case 'scale':
				this.sculptTool.detach()
				this.transformControl.attach(selected.center) 
				this.transformControl.setMode('scale')
				this.setState({transformMode: mode})
				break

			case 'sculpt':
				this.transformControl.detach()
				this.sculptTool.attach(selected.mesh)
				this.setState({transformMode: mode})
				break

			default:
				break

		}

	}



	getSymmetry = tooth => {

		let symmetry

		if (Number(tooth.number) === 17) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 27)[0]
		else if (Number(tooth.number) === 16) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 26)[0]
		else if (Number(tooth.number) === 15) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 25)[0]
		else if (Number(tooth.number) === 14) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 24)[0]
		else if (Number(tooth.number) === 13) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 23)[0]
		else if (Number(tooth.number) === 12) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 22)[0]
		else if (Number(tooth.number) === 11) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 21)[0]
		else if (Number(tooth.number) === 21) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 11)[0]
		else if (Number(tooth.number) === 22) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 12)[0]
		else if (Number(tooth.number) === 23) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 13)[0]
		else if (Number(tooth.number) === 24) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 14)[0]
		else if (Number(tooth.number) === 25) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 15)[0]
		else if (Number(tooth.number) === 26) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 16)[0]
		else if (Number(tooth.number) === 27) symmetry = this.smile.teeth.filter(tooth => Number(tooth.number) === 17)[0]

		else if (Number(tooth.number) === 37) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 47)[0]
		else if (Number(tooth.number) === 36) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 46)[0]
		else if (Number(tooth.number) === 35) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 45)[0]
		else if (Number(tooth.number) === 34) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 44)[0]
		else if (Number(tooth.number) === 33) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 43)[0]
		else if (Number(tooth.number) === 32) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 42)[0]
		else if (Number(tooth.number) === 31) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 41)[0]
		else if (Number(tooth.number) === 41) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 31)[0]
		else if (Number(tooth.number) === 42) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 32)[0]
		else if (Number(tooth.number) === 43) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 33)[0]
		else if (Number(tooth.number) === 44) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 34)[0]
		else if (Number(tooth.number) === 45) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 35)[0]
		else if (Number(tooth.number) === 46) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 36)[0]
		else if (Number(tooth.number) === 47) symmetry = this.mandibular.teeth.filter(tooth => Number(tooth.number) === 37)[0]
		
		else symmetry = null

		return symmetry
	}


	handleChangeMirror = e =>{

		const checked = e.target.checked
		this.setState({mirror: checked})
		if (checked)
			this.canvas3D.scene.add(this.yAxis)
		else
			this.canvas3D.scene.remove(this.yAxis)
	}

	applySymmetry = (tooth, allTransforms=false) => {

		if (!tooth || this.state.selected.number==='01' || this.state.selected.number==='02' || this.state.selected.number==='00') 
			return

		const axis = this.xAxis
		tooth.center.updateMatrixWorld()

		const symmetry = this.getSymmetry(tooth)

		const ghost = drawVoid()
		ghost.applyMatrix4(tooth.center.matrixWorld)

		axis.attach(ghost)


		const p = ghost.position
		const s = ghost.scale
		const r = ghost.rotation


		if (allTransforms) {
			symmetry.center.position.set(-p.x, p.y, p.z)
			symmetry.center.setRotationFromEuler(new THREE.Euler(r.x , -r.y, -r.z))
			symmetry.center.scale.set(s.x, s.y, s.z)

			const geo = tooth.mesh.geometry.clone()
			mirrorGeometry(geo)
			symmetry.mesh.geometry.copy(geo)
			symmetry.mesh.geometry.computeBoundsTree()

		}

		else if (this.state.transformMode === 'translate') 
			symmetry.center.position.set(-p.x, p.y, p.z)

		else if (this.state.transformMode === 'rotate')
			symmetry.center.setRotationFromEuler(new THREE.Euler(r.x , -r.y, -r.z))

		else if (this.state.transformMode === 'scale')
			symmetry.center.scale.set(s.x, s.y, s.z)

	}


	handleFocusMirror = bool => {

		if (bool) {

			const ghostMaterial = new THREE.MeshBasicMaterial({color: 0xff6600, transparent: true, opacity: 0.3, depthTest: false})

			const ghost = this.state.selected.mesh.clone()
			ghost.clear()
			ghost.material = ghostMaterial
			ghost.applyMatrix4(this.state.selected.center.matrix)
			ghost.scale.x *= -1
			ghost.position.x *= -1
			ghost.rotation.y *= -1
			ghost.rotation.z *= -1
			this.mirrorGhost = ghost

			this.xAxis.add(this.mirrorGhost)
		}

		else {
			free(this.mirrorGhost)
		}


	}


	symmetriseAll = () => {

		this.applySymmetry(this.state.selected, true)

		this.handleFocusMirror(false)
		//this.updateContact(false, true)
		this.smileNeedsUpdate = true

		this.history.saveHistory()

	}


	transform = e => {

		const selected = this.state.selected
		
		this.scaleConstrain(e)

		if (!selected)
			return

		if (this.state.mirror)
			this.applySymmetry(selected)
		
		this.smileNeedsUpdate = true
		this.contactNeedsUpdate = true

	}

	resetTransform = () => {

		const selected = this.state.selected
		const m = this.smile.grouped.matrix.clone()

		const reset = tooth => {

			this.canvas3D.scene.attach(tooth.mesh)
			tooth.mesh.attach(tooth.center)

			resetTransform(tooth.mesh)
			tooth.mesh.applyMatrix4(m)

			this.canvas3D.scene.attach(tooth.center)
			tooth.center.attach(tooth.mesh)
		}

		if (!this.state.selected) {
			return
		}

		else if (this.state.selected.number === '01') {

			this.ungroup()	
			this.smile.teeth.forEach(tooth => {
				resetTransform(tooth.mesh)
				tooth.mesh.applyMatrix4(m)
			})
			this.globalQuaternion.maxillar = new THREE.Quaternion()			
			this.selectAll('maxillar')
		} 

		else if (this.state.selected.number === '02') {

			this.ungroup()	
			this.mandibular.teeth.forEach(tooth => {
				resetTransform(tooth.mesh)
				tooth.mesh.applyMatrix4(m)
			})
			this.globalQuaternion.mandibular = new THREE.Quaternion()			
			this.selectAll('mandibular')
		}

		else if (this.state.selected.number === '00') {
			this.ungroup()
			this.smile.teeth.forEach(tooth => {
				resetTransform(tooth.mesh)
				tooth.mesh.applyMatrix4(m)
			})
			this.mandibular.teeth.forEach(tooth => {
				resetTransform(tooth.mesh)
				tooth.mesh.applyMatrix4(m)
			})
			this.globalQuaternion.maxillar = new THREE.Quaternion()			
			this.globalQuaternion.mandibular = new THREE.Quaternion()			
			this.selectAll('all')
		}

		else {
			this.ungroup()

			reset(selected)
		 	if (this.state.mirror) 
		 		reset(this.getSymmetry(selected))
		
		 	this.group(this.state.selected)

		}
	}


	resetGeometry = () => {
		const selected = this.state.selected

		const reset = tooth => {
			tooth.mesh.geometry.copy(tooth.originalGeometry)
			tooth.mesh.geometry.computeVertexNormals()
			tooth.mesh.geometry.computeBoundsTree()
		}

		if (!this.state.selected) {
			return
		}
		else if (this.state.selected.number === '01') {
			this.smile.teeth.forEach(tooth => reset(tooth))
		}
		else if (this.state.selected.number === '02') {
			this.mandibular.teeth.forEach(tooth => reset(tooth))
		}
		else if (this.state.selected.number === '00') {
			this.smile.teeth.forEach(tooth => reset(tooth))
			this.mandibular.teeth.forEach(tooth => reset(tooth))
		}
		else {

			reset(selected)
			if (this.state.mirror)
				reset(this.getSymmetry(selected))

		}
	}


	resetTooth = () => {
		this.resetTransform()
		this.resetGeometry()

		/* if (this.state.allSelected) {
			this.updateContact(true)
		} */

		this.contactNeedsUpdate = true
		this.smileNeedsUpdate = true
		this.history.saveHistory()
	}


	hideTooth = () => {

		if (!this.state.selected) {
			return 
		}

		else if (this.state.selected.number === '01') {
			this.smile.teethMesh.forEach(mesh => mesh.visible = false)
		}
		else if (this.state.selected.number === '02') {
			this.mandibular.teethMesh.forEach(mesh => mesh.visible = false)
		}		
		else if (this.state.selected.number === '00') {
			this.smile.teethMesh.forEach(mesh => mesh.visible = false)
			this.mandibular.teethMesh.forEach(mesh => mesh.visible = false)
		}
		else {
			const mesh = this.state.selected.mesh
			mesh.visible = false
		}

		this.visibleTeeth = [...this.smile.teethMesh, ...this.mandibular.teethMesh].filter(mesh => mesh.visible === true)

		this.ungroup()
		this.setState({selected: null})
		this.smileNeedsUpdate = true
		this.history.saveHistory()
	}



	scaleConstrain = e => {
		if ( !e.target.object)
			return

		const s = e.target.object.scale
		s.x = Math.abs(s.x)
		s.y = Math.abs(s.y)
		s.z = Math.abs(s.z)


		if (e.target.mode === 'scale' && (this.state.homotetic || this.state.selected.number==='01' || this.state.selected.number==='02')) {
			
			let scale
			const axis = e.target.axis

			//force homothetic scale
			if (/XYZ/i.test(axis)) scale = null
			else if (/X/i.test(axis)) scale = e.target.object.scale.x
			else if (/Y/i.test(axis)) scale = e.target.object.scale.y
			else if (/Z/i.test(axis)) scale = e.target.object.scale.z
			else scale = null

			if (scale)
				e.target.object.scale.set(scale, scale, scale)
		}

	}


/////camera navigation/////////////////////////////////////////////////////////

	orient = side => {

		this.orienting = true
		setTimeout(()=>this.orienting = false, 100)

		const DEG90 = Math.PI /2
		const DEG180 = Math.PI

		switch ( side ) {

			case 'front': this.canvas3D.controls.rotateTo( 0, DEG90, true ); break
			case 'back': this.canvas3D.controls.rotateTo( DEG180, DEG90, true ); break
			case 'up': this.canvas3D.controls.rotateTo( 0, 0, true ); break
			case 'down': this.canvas3D.controls.rotateTo( 0, DEG180, true ); break
			case 'left': this.canvas3D.controls.rotateTo( DEG90, DEG90, true ); break
			case 'right': this.canvas3D.controls.rotateTo( - DEG90, DEG90, true ); break
			default: break

		}

		this.canvas3D.controls.fitToBox( this.canvas3D.mesh, true, { paddingTop: 10, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 } )
		
	}

	fitToTooth = () => {
		const selected = this.state.selected

		if (selected)
			this.canvas3D.controls.fitToBox( selected.mesh, true, { paddingTop: 10, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 } )
		else
			this.canvas3D.controls.fitToBox( this.canvas3D.mesh, true, { paddingTop: 10, paddingLeft: 10, paddingBottom: 10, paddingRight: 10 } )

	}

////sculpt tool//////////////////////////////////////////////////

	handleSculpt = () => {

		const selected = this.state.selected

		if (this.state.mirror) {

			const symmetry = this.getSymmetry(selected)
			const geo = selected.mesh.geometry.clone()
			mirrorGeometry(geo)
			symmetry.mesh.geometry.copy(geo)
			symmetry.mesh.geometry.computeBoundsTree()	
			
		}

		this.contactNeedsUpdate = true
		this.smileNeedsUpdate = true

	}

/////////////////////////////////////////////////////////////////



	updateTexturePhoto = () => {

	//update photo texture with right material

		this.smile.setMaterial(this.smile.currentMaterial)
		const selected = []

		this.smile.teeth.forEach(tooth => {
			selected.push(tooth.material.viewport.emissive.clone())
			tooth.material.toon.visible = this.state.showTeeth
			tooth.material.viewport.visible = this.state.showTeeth
			tooth.material.viewport.opacity = 1
			tooth.material.viewport.emissive = new THREE.Color(0x222222)
			tooth.material.viewport.vertexColors = false
		})

		this.mandibular.teeth.forEach(tooth => {
			tooth.material.viewport.visible = false
		})

		this.canvas3D.mesh.material.visible = this.state.showModel
		//this.smile.showContact(this.state.showContact)
		if (this.lowerJaw) this.lowerJaw.material.visible = false
		this.yAxis.material.visible = false
		this.canvas3D.scene.remove( this.transformControl )


		this.texture.render()
		this.canvas2D.bg.needsUpdate = true
		this.smileNeedsUpdate = false


		this.smile.setMaterial('viewport')	

		this.smile.teeth.forEach((tooth, i) => {
			tooth.material.toon.visible = true
			//tooth.material.viewport.visible = true
			tooth.material.viewport.opacity = this.state.upperWaxOpacity
			tooth.material.viewport.visible = this.state.upperWaxOpacity > 0
			tooth.material.viewport.emissive = selected[i]
			tooth.material.viewport.vertexColors = this.state.showUpperContact
		})	

		this.mandibular.teeth.forEach(tooth => {
			//tooth.material.viewport.visible = true
			tooth.material.viewport.opacity = this.state.lowerWaxOpacity
			tooth.material.viewport.visible = this.state.lowerWaxOpacity > 0
			tooth.material.viewport.vertexColors = this.state.showLowerContact
		})

		this.canvas3D.mesh.material.visible = this.state.upperJawVisible
/* console.log(this.state.showContact)
		this.smile.showContact(this.state.showContact) */
		if (this.lowerJaw) this.lowerJaw.material.visible = this.state.lowerJawVisible
		this.yAxis.material.visible = true
		this.canvas3D.scene.add( this.transformControl )

	}




	/* showContact = (tooth, target) => {
		if (!target) 
			return


		const bvh = target.geometry.boundsTree

		const position = tooth.geometry.getAttribute('position')
		const color = []

		for (let i=0; i<position.count; i++) {
			
			const p = new THREE.Vector3(position.array[ 3*i ], position.array[ 3*i +1 ], position.array[ 3*i +2 ])
			tooth.updateMatrixWorld()
			target.updateMatrixWorld()
			const inverse = target.matrixWorld.clone().invert()
			p.applyMatrix4(tooth.matrixWorld)
			
			const raycaster = new THREE.Raycaster(p, new THREE.Vector3(0, this.lower?-1:1, 0) , 0, 10)
			raycaster.firstHitOnly = true
			const inter = raycaster.intersectObject(target)

			if (inter.length>0)
				color.push(1, 0, 0)

			else {
				p.applyMatrix4(inverse)
				const info = new THREE.Vector3()
				const dist = bvh.closestPointToPoint( target, p, info, 0, 1.5 )
				
				if (dist<1.5) 
					color.push(dist/1.5, dist/1.5, 1)		
			
				else
					color.push(1, 1, 1)	
			}

		}

		tooth.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))
	} */


	/* hideContact = tooth => {

		const colorAttribute = tooth.geometry.getAttribute('color')
		const color = []

		for (let i=0; i<colorAttribute.count; i++) {
			color.push(1, 1, 1)
		}

		tooth.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))

	} */



	updateContact = (all=false, forceMirror=false) => {

		const RANGE = 1.5

		const showContact = (tooth, targets=[]) => {
			if (targets.length === 0) 
				return

			const  lower = Number(tooth.number) > 30
			const position = tooth.mesh.geometry.getAttribute('position')
			const color = []
			tooth.mesh.updateMatrixWorld()


			const calcDist = i => {

				const distances = []

				for (let j=0; j<targets.length; j++) {

					const p = new THREE.Vector3(position.array[ 3*i ], position.array[ 3*i +1 ], position.array[ 3*i +2 ])
					const target = targets[j]
					p.applyMatrix4(tooth.mesh.matrixWorld)


					const raycaster = new THREE.Raycaster(p, new THREE.Vector3(0, lower?-1:1, 0) , 0, 10)
					raycaster.firstHitOnly = true
					const inter = raycaster.intersectObject(target)

					if (inter.length>0)
						return -1

					const bvh = target.geometry.boundsTree
					const inverse = target.matrixWorld.clone().invert()

					p.applyMatrix4(inverse)
					const info = new THREE.Vector3()
					const dist = bvh.closestPointToPoint( p, info, 0, RANGE )

					if (info.distance < RANGE)
						distances.push(info.distance)	
				}

				return Math.min(...distances)
			}



			for (let i=0; i<position.count; i++) {
				
				const dist = calcDist(i)
				if (dist === -1)
					color.push(1, 0, 0)
				else if (dist<RANGE) 
					color.push(dist/RANGE, dist/RANGE, 1)		
				else
					color.push(1, 1, 1)	

			}

			tooth.mesh.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))
		}



		const selected = this.state.selected

		if (!this.lowerJaw) {
			return
		}

		else if (all) {

			if (this.state.showUpperContact) {
				const upperTeeth = this.smile.teeth.filter(tooth=>tooth.mesh.visible===true)
				upperTeeth.forEach(tooth => showContact(tooth, [this.lowerJaw, ...this.mandibular.teethMesh.filter(tooth=>tooth.visible===true)]))
			}
			
			if (this.state.showLowerContact) {
				const lowerTeeth = this.mandibular.teeth.filter(tooth=>tooth.mesh.visible===true)
				lowerTeeth.forEach(tooth => showContact(tooth, [this.canvas3D.mesh, ...this.smile.teethMesh.filter(tooth=>tooth.visible===true)]))
			}
				
			
		}

		else if (!selected) {
			return
		}

		else if (selected.number !== '00' && selected.number !== '01' && selected.number !== '02') {

			const  lower = Number(selected.number) > 30
			const opposite = lower ? [this.canvas3D.mesh, ...this.smile.teethMesh.filter(tooth=>tooth.visible===true)] : [this.lowerJaw, ...this.mandibular.teethMesh.filter(tooth=>tooth.visible===true)]
			if ( (lower && this.state.showLowerContact) || (!lower && this.state.showUpperContact) ) {
				showContact(selected, opposite)

				if (this.state.mirror || forceMirror)
					showContact(this.getSymmetry(selected), opposite)
			}

		
		}
		
		this.contactNeedsUpdate = false
	}


	animate = () => {

		this.loop = requestAnimationFrame(this.animate)
			
		const delta = this.clock.getDelta()
		this.canvas3D.controls.update( delta )
		this.canvas2D.controls.update( delta )

		this.refresh()
	}

	refresh = () => {

		this.canvas3D.raycaster.setFromCamera( this.mouseViewport, this.canvas3D.camera )
		const intersectShow = this.canvas3D.raycaster.intersectObjects(this.visibleTeeth)

			
		if (this.state.placed && intersectShow.length>0)
			this.canvas3D.renderer.domElement.style.cursor = 'pointer'
		else
			this.canvas3D.renderer.domElement.style.cursor = 'default'



		this.viewportRef.current.onpointerup = e => {

			if (this.state.placed && !this.sculptTool.sculpting && !this.canvas3D.controls.controlled && !this.transforming && !this.orienting) {
				const intersect = this.canvas3D.raycaster.intersectObjects(this.visibleTeeth.filter(mesh => mesh.material.opacity > 0.15))
				this.select(e, intersect[0])	
			}
		}


		this.updateCheckLine()


		if (this.gizmoRef.current)
			this.gizmoRef.current.animate()

		//this.sculptTool.cursor.material.visible = false

		if (this.smileNeedsUpdate)
			this.updateTexturePhoto()

		if (this.contactNeedsUpdate)
			this.updateContact()



		this.canvas2D.renderer.render(this.canvas2D.scene, this.canvas2D.camera)

		if (this.state.placed && !this.canvas3D.controls.controlled && !this.transforming && !this.orienting)
			this.sculptTool.update()

		this.canvas3D.renderer.render(this.canvas3D.scene, this.canvas3D.camera)


//this.stats.update()
//console.log(this.canvas3D.renderer.info.memory)

	}


	toogleLips = () => {
		const lips = this.texture.showLips
		this.texture.enableLips(!lips)
		this.smile.currentMaterial = !lips?'viewport':'toon'

		this.setState({lips: !this.state.lips})
		this.smileNeedsUpdate = true

	}

	changeOpacity = (state, value) => {
		this.setState({[state]: value})
		this.smileNeedsUpdate = true

	}


	toogleGuideLines = () => {
		const guideLines = this.state.guideLines
		this.setState({guideLines: !guideLines})
		if (guideLines) 
			this.canvas2D.bgPlane.remove(this.guideLines)
		else
			this.canvas2D.bgPlane.add(this.guideLines)
	}

	toogleGlasses = () => {

		const glasses = this.state.glasses
		this.setState({glasses: !glasses})
		if (glasses) 
			this.canvas2D.bgPlane.remove(this.thug)
		else
			this.canvas2D.bgPlane.add(this.thug)
	}

	toogleVisible = () => {
		const visible = this.state.visible
		this.setState({visible: !visible})
		this.texture.show(!visible)
		this.smileNeedsUpdate = true
	}

	toogleToothVisibility = () => {
		const showTeeth = this.state.showTeeth
		this.setState({showTeeth: !showTeeth})
		this.smileNeedsUpdate = true
	}	

	toogleModelVisibility = () => {
		const showModel = this.state.showModel
		this.setState({showModel: !showModel})
		this.smileNeedsUpdate = true
	}

	toogleLowerJawVisibility = bool => {

		this.setState({lowerJawVisible: bool}, () => this.smileNeedsUpdate = true)

		/* if (bool) 
			this.canvas3D.scene.add(this.lowerJaw)
		else
			this.canvas3D.scene.remove(this.lowerJaw) */

		//this.setState({lowerJawVisible: bool}, () => this.changeDVO(this.state.VDO))
	}

	toogleUpperJawVisibility = bool => {

		this.setState({upperJawVisible: bool}, () => this.smileNeedsUpdate = true)
	}


	toogleLowerContact = bool => {
		this.setState({showLowerContact: bool})

		if (bool)
			this.setState({showUpperContact: false}, () => this.updateContact(true))		

		this.smileNeedsUpdate = true

	}

	toogleUpperContact = bool => {
		this.setState({showUpperContact: bool})

		if (bool)
			this.setState({showLowerContact: false}, () => this.updateContact(true))

		this.smileNeedsUpdate = true
	}


	setVisibility = (bool, ...teeth) => {
		teeth.forEach( tooth => tooth.mesh.visible = bool )
		this.visibleTeeth = [ ...this.smile.teethMesh.filter(mesh => mesh.visible === true), ...this.mandibular.teethMesh.filter(mesh => mesh.visible === true) ]
		this.history.saveHistory()

			if (!this.state.placed)
				this.history.deleteHistory()

		this.smileNeedsUpdate = true
	}


	updateLibrary = async lib => {

		this.ungroup()

		this.props.caseContext.setState({loading: true, loadingMessage: 'load library'})
		await this.smile.updateLib(lib)
		this.props.caseContext.setState({loading: false, loadingMessage: ''})

	//update smile position from control points
		this.smile.groupAll()
		this.handleDragControl()
		this.smile.ungroup()

		this.group(this.state.selected)
		this.updateContact(true)
		this.history.saveHistory()

	}

	saveState = () => {

		//save control points position
		const controlPoints = this.controlPoints.map(point => point.position.toArray())

		const grouped = {
			position: this.smile.grouped.position.toArray(),
			quaternion: this.smile.grouped.quaternion.toArray(),
			scale: this.smile.grouped.scale.toArray()
		}

		const teeth = [...this.smile.teeth, ...this.mandibular.teeth].map(tooth => {

			const toothAttributes =  {
				number: tooth.number,
				position: tooth.mesh.getWorldPosition(new THREE.Vector3()).toArray(),
				quaternion: tooth.mesh.getWorldQuaternion(new THREE.Quaternion()).toArray(),
				scale: tooth.mesh.getWorldScale(new THREE.Vector3()).toArray(),
				visible: tooth.mesh.visible,
				geometry: tooth.mesh.geometry.clone(),
				original: tooth.originalGeometry.clone()
			}

			return toothAttributes
		})

		const globalQuaternion = {
			maxillar: this.globalQuaternion.maxillar.toArray(),
			mandibular: this.globalQuaternion.mandibular.toArray()
		}
		const VDO = this.state.VDO

		return { controlPoints, grouped, teeth, globalQuaternion, VDO }

	}

	loadState = ({ controlPoints, grouped, teeth, globalQuaternion, VDO }) => {


		if (controlPoints.length < 3)
			return

	//create control point if not already done
		if (this.controlPoints.length < 3) {
				this.controlPoints = [this.first.ball2D, this.second.ball2D, this.third.ball2D]
				this.createDragControl(this.controlPoints)
				this.canvas3D.scene.add(...this.smile.teethMesh, this.smile.grouped, ...this.mandibular.teethMesh, this.mandibular.grouped )
				this.setState({placed: true})
		}

	//load control points
		controlPoints.forEach((point, i) => {
			const position = new THREE.Vector3().fromArray(point)
			this.controlPoints[i].position.copy(position) 
		})

	//prevent bug
		if (!globalQuaternion.hasOwnProperty('maxillar') || !globalQuaternion.hasOwnProperty('mandibular')) 
			globalQuaternion = {maxillar: globalQuaternion, mandibular: globalQuaternion}

		this.globalQuaternion = {
			maxillar:  new THREE.Quaternion().fromArray(globalQuaternion.maxillar),
			mandibular: new THREE.Quaternion().fromArray(globalQuaternion.mandibular)
		}
		this.ungroup()
		
	//update mirror plan in right place
		this.smile.groupAll()
		this.handleDragControl()
		this.smile.ungroup()


		this.smile.grouped.position.copy(new THREE.Vector3().fromArray(grouped.position))
		this.smile.grouped.scale.copy(new THREE.Vector3().fromArray(grouped.scale))
		this.smile.grouped.quaternion.copy(new THREE.Quaternion().fromArray(grouped.quaternion))		

	

	//transform & hide tooth not saved
		this.smile.teethMesh.forEach(mesh => mesh.visible = false)
		this.mandibular.teethMesh.forEach(mesh => {
			mesh.visible = false
			mesh.applyMatrix4(this.smile.grouped.matrix)
		})

	//set teeth transformation
		teeth.forEach(tooth => {
			const applyTransformation = mesh => {
				mesh.position.copy(new THREE.Vector3().fromArray(tooth.position))
				mesh.scale.copy(new THREE.Vector3().fromArray(tooth.scale))
				mesh.quaternion.copy(new THREE.Quaternion().fromArray(tooth.quaternion))
				mesh.visible = tooth.visible
			}



			const mesh = this.smile.tooth[tooth.number] ?? this.mandibular.tooth[tooth.number] 
			const original = this.smile.original[tooth.number] ?? this.mandibular.original[tooth.number]


			if (mesh.parent === this.canvas3D.scene) {
				applyTransformation(mesh)
			}
			else {
				const anchor = mesh.parent
				const parent = anchor.parent
				this.canvas3D.scene.attach(mesh)
				mesh.attach(anchor)
				applyTransformation(mesh)
				parent.attach(anchor)
				anchor.attach(mesh) 
			}

		//set teeth geometry
			mesh.geometry.copy(tooth.geometry)
			original.copy(tooth.original)
			mesh.geometry.computeVertexNormals()
      		mesh.geometry.computeBoundingBox()
			mesh.geometry.computeBoundsTree()


		})

		this.visibleTeeth = [ ...this.smile.teethMesh.filter(mesh => mesh.visible === true), ...this.mandibular.teethMesh.filter(mesh => mesh.visible === true) ]
		this.group(this.state.selected)
		this.changeDVO(VDO)
		this.smileNeedsUpdate = true
		//this.updateContact(true)

	}

	undo = () => {
		this.history.undo()
		this.smileNeedsUpdate = true
	}

	redo = () => {
		this.history.redo()
		this.smileNeedsUpdate = true
	}

	save = async ({name, description, callback=()=>null}) => {

		if (!this.state.placed) {
			this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'maybe could you do a first move before save...'})
			return
		}



		const {controlPoints, grouped, teeth, globalQuaternion, VDO} = this.saveState()

		const promiseTeeth = teeth.map( async tooth => {

			return {
				number: tooth.number,
				position: tooth.position,
				quaternion: tooth.quaternion,
				scale: tooth.scale,
				visible: tooth.visible,
				blob: await exportPLY(new THREE.Mesh(tooth.geometry), true, false),
				original: await exportPLY(new THREE.Mesh(tooth.original), true, false)
			}
		})

		const dataTeeth = await Promise.all(promiseTeeth)

		//fit for thumbnail
		const fit = () =>
			new Promise(resolve=> {

				const controlsSleep = () => {
					this.canvas3D.controls.removeEventListener('sleep', controlsSleep)
					resolve()
				}
					
					this.canvas3D.controls.addEventListener('sleep', controlsSleep)
					this.canvas3D.controls.fitToBox( this.canvas3D.mesh, false )
		})


		await fit()
		const thumbnail = await exportThumbnailToBase64({
				scene: this.canvas3D.scene,
				camera: this.canvas3D.camera,
				renderer: this.canvas3D.renderer,
				width: 512
			})


		const data = {
			controlPoints,
			globalQuaternion,
			VDO,
			grouped,
			dataTeeth,
			thumbnail,
			name,
			description
		}

		this.props.save(data)
		callback()
	}


	load = teethData => {
		this.ungroup()

	//add color buffer
		teethData.teeth.forEach(tooth => {
			const position = tooth.geometry.getAttribute('position')
			const color = []
			for (let i=0; i<position.count; i++) {
				color.push(1, 1, 1)
			}
		const colorAttr = new THREE.BufferAttribute(new Float32Array(color), 3)

			tooth.geometry.setAttribute('color', colorAttr)
			tooth.original.setAttribute('color', colorAttr)
		})

		this.loadState(teethData)
		this.setState({save: false})
		this.history.deleteHistory()
	}






	export = async state => {


		const saveAs = (filename, blob) => {
			const link = document.createElement('a')
			link.style.display = 'none'
			document.body.appendChild( link )
			link.href = URL.createObjectURL( blob )
			link.download = filename
			link.click()
			link.remove()
		}


		const writeOnBasement = (basement, text='test', top=true) => {
			
			return new Promise(resolve => {

				basement.geometry.computeBoundsTree()
				const bBox = new THREE.Box3().setFromObject(basement, true)
				const height = top ? bBox.max.y - 2 : bBox.min.y + 3

			//construct curve
				const points = []
				for (let i = bBox.min.z + 15; i < bBox.max.z - 2; i++) {

					const origin = new THREE.Vector3(bBox.min.x, height, i)
					const direction = new THREE.Vector3(1, 0, 0)
					const raycaster = new THREE.Raycaster(origin, direction)
					raycaster.firstHitOnly = true

					const intersect = raycaster.intersectObject(basement.parent)
					if (intersect.length === 0)
						resolve(new THREE.Object3D())

					const p = origin.add(direction.multiplyScalar(intersect[0].distance))
					points.push(p)
				}

				const curve = new THREE.CatmullRomCurve3( points, false)


				let textLength = 0
				const letters = new THREE.Group()

				for (const letter of Array.from(text)) {

					const step = textLength/curve.getLength()
					if (step >1) {
						break
					}

					const loader = new FontLoader()
					const font = loader.parse(helvetica)
					const g = new TextGeometry(letter, { font, size: 2, height: 1.5,  })
					
					const mesh = new THREE.Mesh( g, basement.material )

					const bBox = new THREE.Box3().setFromObject(mesh, true)
					let width = bBox.getSize(new THREE.Vector3()).x 
					if (width === 0)
						width = 0.3

					const p = curve.getPointAt(step)
					const t = curve.getTangentAt(step).normalize()

					mesh.position.copy(p)
					mesh.position.x += 1
					mesh.position.y -= 1

					const a = t.angleTo(new THREE.Vector3(0, 0, 1))
					mesh.rotation.y = a - Math.PI/2

					textLength += width + 0.3

					letters.add(mesh)
				}

				resolve(letters)
			})
		}


		const savePhoto = (anonymous=true) => {

			return new Promise(resolve => {

				if (anonymous)
					this.canvas2D.bgPlane.add(this.thug)
				else
					this.canvas2D.bgPlane.remove(this.thug)

				this.texture.render()
				const renderer = new THREE.WebGLRenderer()
				const {width, height} = this.texture.bg.image
				renderer.setSize(width, height)
				const camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, 1, 1000)
				camera.position.z = 20
				renderer.render(this.canvas2D.scene, camera)

				renderer.domElement.toBlob(blob => {

					if (this.state.glasses)
						this.canvas2D.bgPlane.add(this.thug)
					else
						this.canvas2D.bgPlane.remove(this.thug)

					renderer.dispose()
					resolve(blob)
				}, 'image/jpeg', 0.95)

			})

		}



		const saveModel = async (mesh, text='') => {

			if (!mesh) 
				return

			const model = mesh.clone()
			const bas = mesh.userData.basement

			const top = mesh === this.canvas3D.mesh 

			if (bas && text) {
				const letters = await writeOnBasement(bas, text, top)
				model.add(letters)
			}

			model.applyMatrix4(transformMatrixInvert)
			model.updateMatrixWorld()

			const modelSTL = await exportSTL(model)
			const modelPLY = await exportPLY(model, false)

			return {mesh: model, ply: modelPLY, stl: modelSTL}
		}


		const saveDWD = async (model, smile) => {

			if (!model)
				return

			const mesh = model.mesh
			const DWD = new THREE.Group()
			const clones = []

			smile.teethMesh.forEach(tooth => {

				if (tooth.visible) {
					tooth.updateMatrixWorld()
					const clone = new THREE.Mesh(tooth.geometry.clone())

					clone.geometry.deleteAttribute('color')
					clone.clear()
					clone.applyMatrix4(tooth.matrixWorld)
					clone.applyMatrix4(transformMatrixInvert)
					clone.updateMatrixWorld()
					clones.push( clone ) 
				}
			})

			DWD.add(mesh.clone(), ...clones)

			const modelSTL = await exportSTL(DWD)
			const modelPLY = await exportPLY(DWD, false)

			return {mesh: DWD, ply: modelPLY, stl: modelSTL}
		
		}

		const saveTeeth = async teethMesh => {

			return new Promise(resolve => {

				const teeth = teethMesh.map(async toothMesh => {

					if (toothMesh.visible) {

						const clone = toothMesh.clone()
						clone.clear()
						clone.applyMatrix4(transformMatrixInvert)
						clone.updateMatrixWorld()

						return({
							mesh: clone,
							stl: await exportSTL(clone),
							ply: await exportPLY(clone, false, false)
						})
					}
				})

				Promise.all(teeth).then((result)=>{
					resolve(result) 
				})
			})
			

		}

///////////////////////////////////////////////////////////
		this.ungroup()
		this.props.caseContext.setState({loading: true})
		const zipName = this.props.caseContext.name.replaceAll(' ', '_')
		const zip = new JSZip()
		const rootZip = zip.folder(zipName+'_cadsmile3D')


		const transformMatrix = new THREE.Matrix4().fromArray(this.props.caseContext.transform)
		const transformMatrixInvert = transformMatrix.clone().invert()

		const text = state.text?state.textBasement:''


	//export models
		const upperModel = await saveModel(this.canvas3D.mesh, text)
		const lowerModel = await saveModel(this.lowerJaw, text)

		if (state.modelSTL) {
			rootZip.file('models/'+zipName+'_model_maxillar.stl', upperModel.stl)
			if (lowerModel) 
				rootZip.file('models/'+zipName+'_model_mandibular.stl', lowerModel.stl)
		}

		if (state.modelPLY) {
			rootZip.file('models/'+zipName+'_model_maxillar.ply', upperModel.ply)
			if (lowerModel) 
				rootZip.file('models/'+zipName+'_model_mandibular.ply', lowerModel.ply)
		}

	//export DWD
		if (state.waxSTL || state.waxPLY) {

			const upperDWD = await saveDWD(upperModel, this.smile)
			const lowerDWD = await saveDWD(lowerModel, this.mandibular)

			if (state.waxSTL) {
				rootZip.file('models/'+zipName+'_DWD_maxillar.stl', upperDWD.stl)
				if (lowerDWD)
					rootZip.file('models/'+zipName+'_DWD_mandibular.stl', lowerDWD.stl)
			}

			if (state.waxPLY) {
				rootZip.file('models/'+zipName+'_DWD_maxillar.ply', upperDWD.ply)
				if (lowerDWD)
					rootZip.file('models/'+zipName+'_DWD_mandibular.ply', lowerDWD.ply)
			}
		}


	//export each tooth
		if (state.teethSTL || state.teethPLY) {

			const teeth = await saveTeeth([...this.smile.teethMesh, ...this.mandibular.teethMesh])
			teeth.forEach(tooth => {

				if (tooth && state.teethSTL) 
					rootZip.file('models/'+zipName+'_tooth_'+tooth.mesh.name+'.stl', tooth.stl)

				if (tooth && state.teethPLY)
					rootZip.file('models/'+zipName+'_tooth_'+tooth.mesh.name+'.ply', tooth.ply)
			})
			
		}
		
	
	//hide control points
		this.controlPoints.forEach(point=>point.visible = false)
		if (this.lowerJaw) this.lowerJaw.visible = false
		this.mandibular.teethMesh.forEach(toothMesh => toothMesh.material.visible = false)
	
	//export original photo
		if (state.original) {		
			this.texture.show(false)
			rootZip.file('photos/'+zipName+'_photo.jpg', await savePhoto(state.anonymous))
			this.texture.show(true)
		}


		const lips = this.state.lips
		if (lips)
			this.toogleLips()

		this.smile.setMaterial('toon')	
	//export retracted photo
		if (state.retracted)
			rootZip.file('photos/'+zipName+'_photo_retracted.jpg', await savePhoto(state.anonymous))

		this.texture.enableLips(true)
		this.smile.setMaterial('viewport')
	//export smile photo
		if (state.smile)
			rootZip.file('photos/'+zipName+'_photo_smile.jpg', await savePhoto(state.anonymous))

		this.texture.enableLips(false)






		zip.generateAsync({type:"blob"}).then(content => {
			saveAs(zipName, content)
			this.props.caseContext.setState({loading: false})

			if (lips)
				this.toogleLips()
			this.controlPoints.forEach(point=>point.visible = true)
			if (this.lowerJaw) this.lowerJaw.visible = true
			this.mandibular.teethMesh.forEach(toothMesh => toothMesh.material.visible = true)

		})


	}


	changeDVO = height => {

		if (!this.lowerJaw)
			return

		if (height<0) 
			height = 0

		this.setState({VDO: height})
		resetTransform(this.lowerJaw)

		if (height > 0) {

			const bBox = new THREE.Box3().setFromObject(this.lowerJaw, true)
			const center = new THREE.Vector3()
			bBox.getCenter(center)

			const ref = drawVoid()
			const pivot = drawVoid(center.x, bBox.max.y, bBox.min.z)
			ref.add(pivot)
			ref.quaternion.copy(this.texture.camera.quaternion)
			const a = Math.atan(height / (bBox.max.z - bBox.min.z))
			this.canvas3D.scene.add(ref)
			pivot.attach(this.lowerJaw)
			pivot.rotation.x = a
			this.canvas3D.scene.attach(this.lowerJaw)
			free(ref)

			if (!this.state.lowerJawVisible)
				this.canvas3D.scene.remove(this.lowerJaw)

		}
		this.updateContact(true)

	}

	setBuccalOpacity = opacity => {
		this.setState({buccalOpacity: opacity})
		this.texture.bgObject.setOpacity(opacity)
		this.texture.bg.needsUpdate = true
		this.smileNeedsUpdate = true
	}

	updateMethod = method => {

		// if (!this.state.placed) 
		// 	return

		switch(method) {
			case 'model front':
				this.hitMethod = [this.outside]				
				this.outside.position.set(0, 0, 20)
				this.outside.quaternion.copy(new THREE.Quaternion())
				break

			case 'photo front':
				this.hitMethod = [this.outside]
				this.outside.position.set(0, 0, 20)
				this.outside.quaternion.copy(this.texture.camera.quaternion)
				break

			case 'model hit':
				this.hitMethod = [this.outside, this.canvas3D.mesh]
				this.outside.position.set(0, 0, 0)
				this.outside.quaternion.copy(new THREE.Quaternion())
				break

			default: 
				return
		}

		/* this.smile.groupAll(this.mandibular.teethMesh)
		this.handleDragControl()
		this.smile.ungroup()
		this.history.saveHistory() */
	}


	updateCheckLine = e => {
		if( this.mousePhoto.buttons === 1 && Math.abs(this.mousePhoto.x) < 0.9 && Math.abs(this.mousePhoto.y) < 0.9 ) {
			this.checkLine.visible = true
			const {x, y} = this.mousePhoto
			const lineP = this.checkLine.position.clone().project(this.canvas2D.camera)
			const newPosition = new THREE.Vector3(x, y, lineP.z).unproject(this.canvas2D.camera)
			this.checkLine.position.y = newPosition.y
		}
		else {
			this.checkLine.visible = false
		}
	}




	render = () => {
		const selected = this.state.selected ? <div> {this.state.selected.number}</div> : <div></div>
		const allSelected = this.state.selected?(this.state.selected.number==='00' || this.state.selected.number==='01' || this.state.selected.number==='02'):false
		const maxillar = this.smile
		const mandibular = this.lowerJaw ? this.mandibular : null

		return <div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>

				<ToolsBarRight
					fit={this.fitToTooth} 
					undo={this.undo} disabledUndo={this.state.disabledUndo}
					redo={this.redo} disabledRedo={this.state.disabledRedo}
					selected={selected} allSelected={allSelected} showInfo={()=>this.setState({info: true})}
					transformMode={this.state.transformMode} selectTransformMode={this.selectTransformMode}
					homotetic={this.state.homotetic} onChangeHomotetic={e=>this.setState({homotetic: e.target.checked})}
					symmetriseAll={this.symmetriseAll} mirror={this.state.mirror} focusMirror={this.handleFocusMirror} onChangeMirror={this.handleChangeMirror} mirrorDisabled={(!this.state.selected || allSelected)}
					resetTooth={this.resetTooth} resetDisabled={!this.state.selected}
					hide={this.hideTooth} hideDisabled={!this.state.selected}
					showSave={()=>this.setState({save: true})} 
					showExport={()=>this.setState({export: true})} disabledExport={!this.state.placed}
					reset={this.reset}
				/>


				<ToolsBarLeft
					fitToFace={this.fitToFace} fitToLips={this.fitToLips}
					toogleLips={this.toogleLips} lips={this.state.lips}
					/* toogleVisible={this.toogleVisible} visible={this.state.visible} */
					toogleToothVisibility={this.toogleToothVisibility} toothVisible={this.state.showTeeth}
					toogleModelVisibility={this.toogleModelVisibility} modelVisible={this.state.showModel}
					toogleGuideLines={this.toogleGuideLines}
					toogleGlasses={this.toogleGlasses}
					hasBuccal={this.hasBuccal}
					buccalOpacity={this.state.buccalOpacity}
					setBuccalOpacity={this.setBuccalOpacity}
				/>

				<div style={{position: 'absolute', top: 40, right: '60%', zIndex: 10}}>
					<ChooseMethod updateMethod={this.updateMethod}/>
				</div>

				{this.state.placed&&<ToolsBarBottom
					/* opacity={this.state.opacity} changeOpacity={this.changeOpacity} */ updateLibrary={this.updateLibrary}
				/>}}


			<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center'}}>
			
				<div style={{position: 'relative', flex: 2, width: '100%', height: '100%', background: 'rgba(100,100,100,0.2)'}} ref={this.photoRef}>
					{!this.state.placed&&<TutoPlace/>}
				</div>

				<div style={{position: 'relative', flex: 3, width: '100%', height: '100%'}} ref={this.viewportRef}>
					{this.state.placed&&<TutoDesign/>}
					{this.state.loaded&&<GizmoView style={{right: 65, bottom: 25}} ref={this.gizmoRef} camera={this.canvas3D.camera} controls={this.canvas3D.controls} orient={this.orient}/>}
				</div>

				<Menu style={{position: 'absolute', left: '40%', top: 50, zIndex: 10, marginLeft: 5}}>

					<Cardtridge style={{overflowY: 'scroll'}}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<img title='hide' style={{width: 35, cursor: 'pointer'}}src={teethTransparent} alt='hide' onClick={e =>this.changeOpacity('upperWaxOpacity', 0)}/>
							<input type='range' min={0} max={1} step={0.01} value={this.state.upperWaxOpacity} title='upper jaw waxup opacity' onChange={e=>this.changeOpacity('upperWaxOpacity', e.target.value)}/>
							<img title='show' style={{width: 35, cursor: 'pointer'}}src={teethWhite} alt='show' onClick={()=>this.changeOpacity('upperWaxOpacity', 1)}/>
						</div>

						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<img style={{transform: 'rotateZ(180deg)', width: 25}}src={hideLower} alt='hide'/>
							<Pills value={this.state.upperJawVisible} onChange={this.toogleUpperJawVisibility} title={this.state.upperJawVisible?'hide upper jaw':'show upper jaw'}/>
							<img style={{transform: 'rotateZ(180deg)', width: 25}}src={showLower} alt='show'/>
						</div>

						{this.lowerJaw&&<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<div style={{width: 19, height: 19, borderRadius: 19, background: 'rgb(255, 255, 255)', border: 'solid rgb(0, 0, 0) 1px'}}></div>
							<Pills value={this.state.showUpperContact} onChange={this.toogleUpperContact} title={this.state.showUpperContact?'hide contact points':'show contact points'}/>
							<div style={{width: 15, height: 15, borderRadius: 15, background: 'rgb(255, 0, 0)', border: 'solid rgb(0, 0, 255) 5px'}}></div>
						</div>}
					</Cardtridge>


					<Cardtridge>
						<TeethManager maxillar={maxillar} mandibular={mandibular} setVisibility={this.setVisibility}/>
					</Cardtridge>


					{this.lowerJaw&&<Cardtridge>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<img title='hide' style={{transform: 'rotateZ(180deg)', width: 35, cursor: 'pointer'}}src={teethTransparent} alt='hide' onClick={e =>this.changeOpacity('lowerWaxOpacity', 0)}/>
							<input type='range' min={0} max={1} step={0.01} value={this.state.lowerWaxOpacity} title='lower jaw waxup opacity' onChange={e=>this.changeOpacity('lowerWaxOpacity', e.target.value)}/>
							<img title='show' style={{transform: 'rotateZ(180deg)', width: 35, cursor: 'pointer'}}src={teethWhite} alt='show' onClick={()=>this.changeOpacity('lowerWaxOpacity', 1)}/>
						</div>

						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<img style={{width: 25}}src={hideLower} alt='hide'/>
							<Pills value={this.state.lowerJawVisible} onChange={this.toogleLowerJawVisibility} title={this.state.lowerJawVisible?'hide lower jaw':'show lower jaw'}/>
							<img style={{width: 25}}src={showLower} alt='show'/>
						</div>

						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<div style={{width: 19, height: 19, borderRadius: 19, background: 'rgb(255, 255, 255)', border: 'solid rgb(0, 0, 0) 1px'}}></div>
							<Pills value={this.state.showLowerContact} onChange={this.toogleLowerContact} title={this.state.showLowerContact?'hide contact points':'show contact points'}/>
							<div style={{width: 15, height: 15, borderRadius: 15, background: 'rgb(255, 0, 0)', border: 'solid rgb(0, 0, 255) 5px'}}></div>
						</div>
					</Cardtridge>}


					{this.lowerJaw&&<Cardtridge>
						<b>VDO +</b> <input style={{width: 40}} type='number' min={0} step={0.5} value={this.state.VDO} onChange={e=>this.changeDVO(e.target.value)}/> <b>mm</b>
					</Cardtridge>}
				</Menu>

			</div>


			{this.state.save&&<SaveWindow caseContext={this.props.caseContext} load={this.props.load} save={this.save} del={this.props.del} cancel={()=>this.setState({save: false})}/>}
			{this.state.export&&<ExportWindow caseContext={this.props.caseContext} export={this.props.exportDesign} cancel={()=>this.setState({export: false})}/>}
			{this.state.info&&<InfoWindow maxillar={maxillar} mandibular={mandibular} setVisibility={this.setVisibility} cancel={()=>this.setState({info: false})}/>}

		</div>
	}
}


const Button = props => 
		<div style={{display: 'flex', justifyContent: 'center', alignItems: 'end',  ...props.style}}>
			<button style={{boxShadow: props.selected?'0px 0px 1px 2px #ff6600':'none', margin: 2, opacity: props.disabled?0.6:1}} disabled={props.disabled} onClick={props.onClick} title={props.title} onMouseEnter={()=>props.focus(true)} onMouseLeave={()=>props.focus(false)}><img src={props.icon} style={{width: 25, height: 25}} alt={props.title}/></button>
			{props.checkbox&&<div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<img style={{height: 10}} src={props.checkboxIcon} alt={props.checkboxTitle} />
				<input style={{position: 'relative', zIndex: 20}} type='checkbox' checked={props.checked} onChange={props.onCheckboxChange} title={props.checkboxTitle}/>
			</div>}
		</div>

	Button.defaultProps = {
		disabled: false,
		selected: false,
		onClick: ()=>null,
		title: 'title',
		icon: null,
		checkbox: false,
		checkboxTitle: 'checkbox title',
		checkboxIcon: null,
		checked: false,
		onCheckboxChange: ()=>null,
		focus: ()=>null
	}





const ToolsBarRight = props => {

	const selectTransformMode = mode => {
		props.selectTransformMode(mode)
	}

	return <div style={{overflowX: 'hidden', overflowY: 'auto', flexDirection: 'column', display: 'flex', justifyContent: 'start', alignItems: 'start', zIndex: 10, position: 'absolute', top: 40, right: 0, bottom: 30, width: 50, padding: 15}}>

		<Button onClick={props.fit} title='fit to screen' icon={fit}/>
		<Button onClick={props.undo} title='undo (ctrl+Z)' icon={undo} disabled={props.disabledUndo}/>
		<Button onClick={props.redo} title='redo (ctrl+Y)' icon={redo} disabled={props.disabledRedo}/>

		
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: 20, background: 'black', color: 'white', fontWeight: 'bold', marginTop: 20, marginBottom: 3, padding: 1}}>
			<div>{props.selected}</div>
			<div title='some usefull informations' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', height: 20, width: 20, borderRadius: 20, background: 'white', fontStyle: 'italic', fontWeight: 'bolder', cursor: 'pointer'}} onClick={props.showInfo}>i</div>
		</div>

		<Button onClick={()=>selectTransformMode('translate')} title='translate (shortcut T)' icon={translate} selected={props.transformMode==='translate'}/>
		<Button onClick={()=>selectTransformMode('rotate')} title='rotate (shortcut R)' icon={rotate} selected={props.transformMode==='rotate'}/>
		<Button onClick={()=>selectTransformMode('scale')} title='scale (shortcut S)' icon={scale} selected={props.transformMode==='scale'} checkbox={true} checked={props.homotetic} checkboxIcon={lock} checkboxTitle='lock proportions' onCheckboxChange={props.onChangeHomotetic}/>
		<Button onClick={()=>selectTransformMode('sculpt')} title='sculpt (shortcut D)' icon={sculpt} selected={props.transformMode==='sculpt'} disabled={props.allSelected}/>
		<Button onClick={props.symmetriseAll} title='mirror (shortcut M)' icon={symmetry} disabled={props.mirrorDisabled} focus={props.focusMirror} checkbox={true} checked={props.mirror} checkboxIcon={lock} checkboxTitle='lock mirror' onCheckboxChange={props.onChangeMirror}/>
		<Button onClick={props.resetTooth} title='reset' icon={refresh} disabled={props.resetDisabled}/>
		<Button onClick={props.hide} title='hide' icon={invisibleIcon} disabled={props.hideDisabled}/>

		<Button style={{marginTop: 25}} onClick={props.showSave} title='save' icon={floppy}/>
		<Button onClick={props.showExport} title='export' icon={share} disabled={props.disabledExport}/>
		<Button onClick={props.reset} title='reset' icon={trashcan}/>

		
	</div>
}


const ToolsBarLeft = props => {

	return <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', zIndex: 10, position: 'absolute', left: 0, bottom: 0, height: 40, padding: 5}}>

		<Button onClick={props.fitToFace} title='fit to face' icon={fit}/>
		<Button onClick={props.fitToLips} title='fit to smile' icon={fitSmile}/>
		<Button onClick={props.toogleLips} title={props.lips?'hide lips':'show lips'} icon={props.lips?retractedSmile:smile}/>
		
		<div style={{position: 'relative'}} onClick={props.toogleToothVisibility}>
			<Button title={props.toothVisible?'hide teeth':'show teeth'} icon={props.toothVisible?visibleIcon:invisibleIcon}/>	
			<div style={{display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 1, left: 0, right: 0, fontWeight: 'bold', fontSize: 10}}>teeth</div>
		</div>


		<div style={{position: 'relative'}} onClick={props.toogleModelVisibility}>
			<Button title={props.modelVisible?'hide model':'show model'} icon={props.modelVisible?visibleIcon:invisibleIcon}/>
			<div style={{display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 1, left: 0, right: 0, fontWeight: 'bold', fontSize: 10}}>model</div>
		</div>

		<Button onClick={props.toogleGuideLines} title={'toogle guide lines'} icon={guideLinesIcon}/>

		<Button onClick={props.toogleGlasses} title={'toogle glasses'} icon={thugGlassesIcon}/>

		{props.hasBuccal&&<input type='range' min={0} max={1} step={0.01} value={props.buccalOpacity} onChange={e=>props.setBuccalOpacity(e.target.value)}/>}
	</div>
}

const ToolsBarBottom = props => {

	return <div style={{position: 'absolute', right: 0, bottom: 0, zIndex: 10}} title='change opacity'>

		<ChooseLibrary updateLibrary={props.updateLibrary}/>

		{/* <img src={teethTransparent} onClick={()=>props.changeOpacity({target: {value: 0}})} style={{height: 15, cursor: 'pointer'}} alt='hide'/>
		<input type='range' min={0} max={1} step={0.01} style={{position: 'relative', zIndex: 20, width: 60}} value={props.opacity} onChange={props.changeOpacity}/>
		<img src={teethWhite} onClick={()=>props.changeOpacity({target: {value: 100}})} style={{height: 15, cursor: 'pointer'}} alt='show'/> */}
	</div>
}


const ChooseLibrary = props => {

	const [lib, setLib] = React.useState('')

	const handleChange = e => {
		const library = e.target.value
		props.updateLibrary(library)
		setLib('')
	}

	return <select value={lib} onChange={handleChange} style={{fontStyle: 'italic', marginRight: 20, marginBottom: 2}}>
			<option value='' style={{fontStyle: 'normal', display: 'none'}}>predesigned teeth shape</option>
			<option value={lib1} style={{fontStyle: 'normal'}}>library 1</option>
			<option value={lib2} style={{fontStyle: 'normal'}}>library 2</option>
			<option value={lib3} style={{fontStyle: 'normal'}}>library 3</option>
			<option value={lib4} style={{fontStyle: 'normal'}}>library 4</option>
			<option value={lib5} style={{fontStyle: 'normal'}}>library 5</option>
		</select>


}


const ChooseMethod = props => {

	const [method, setMethod] = React.useState('model hit')

	const handleChange = e => {
		const met = e.target.value
		setMethod(met)
		props.updateMethod(met)
	}

	return <Cardtridge style={{padding: 3, display: 'flex', alignItems: 'center'}}>

		<div style={{fontSize: 11, marginRight: 5}}>teeth placement method</div>

		<select style={{fontSize: 11}} value={method} onChange={handleChange}>

			<option value='model hit'>model hit</option>
			<option value='model front'>model front axis</option>
			<option value='photo front'>photo front axis</option>
		
		</select>
		
	</Cardtridge>

}




export default SmileDesigner