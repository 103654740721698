import socket from './socket'


const LandmarksDetection = id => {

  return new Promise(async(resolve, reject)=>{

    socket.emit('LandmarksDetection',id, markers=>{

      
     // let markers = JSON.parse(json)

      //console.log(markers)

      resolve(markers==='error'? false : markers)
     // console.log (markers==='error'? false : markers)

      

    })

  })

}



 export default LandmarksDetection
