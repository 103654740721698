const TutoNavigation = props =>
	
	<div style={{position: 'absolute', bottom: 3, left: 3, fontWeight: 'bold', fontSize: 10, pointerEvents: 'none'}}>
		{props.rotate&&<div>left click : rotate viewport</div>}
		<div>right click : pan viewport</div>
		<div>wheel / middle click : zoom viewport</div>
	</div>


TutoNavigation.defaultProps = {rotate: true}


export default TutoNavigation