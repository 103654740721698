import * as THREE from 'three'
import CameraControls from 'camera-controls'


class OrthographicCamera extends THREE.OrthographicCamera {

	getBoundingBox = mesh => {
		const clone = mesh.clone().clear()
		const bBox = new THREE.Box3().setFromObject(clone, true)
		const meshPosition = new THREE.Vector3()
		bBox.getCenter(meshPosition)

		const meshSize = new THREE.Vector3()
		bBox.getSize(meshSize)


		return [meshPosition, meshSize]
	}

	fitToHeight = (mesh, renderer, control=null) => {
		
		const [ meshPosition, meshSize ] = this.getBoundingBox(mesh)
		const aspect = renderer.domElement.width / renderer.domElement.height

		this.position.x = meshPosition.x
		this.position.y = meshPosition.y
		this.position.z = meshPosition.z + 1000

		this.left = - meshSize.y * aspect / 2
		this.right = meshSize.y * aspect / 2
		this.top = meshSize.y / 2
		this.bottom = - meshSize.y / 2

		this.zoom = 1

		this.updateProjectionMatrix()

		if (control) {
			control.target = meshPosition
			control.update()
		}
	}


	fitToWidth = (mesh, renderer, control=null) => {

		const [ meshPosition, meshSize] = this.getBoundingBox(mesh)
		const aspect = renderer.domElement.width / renderer.domElement.height
		
		this.position.x = meshPosition.x
		this.position.y = meshPosition.y
		this.position.z = meshPosition.z + 1000

		this.left = - meshSize.x / 2
		this.right = meshSize.x / 2
		this.top = meshSize.x / aspect / 2
		this.bottom = - meshSize.x / aspect / 2

		this.zoom = 1

		this.updateProjectionMatrix()

		if (control) {
			control.target = meshPosition
			control.update()
		}
	}


	fit = (mesh, renderer, control=null) => {

		const [ , meshSize] = this.getBoundingBox(mesh)
		const wRatio = renderer.domElement.width / meshSize.x
		const hRatio = renderer.domElement.height / meshSize.y

		if (wRatio <= hRatio)
			this.fitToWidth(mesh, renderer, control)
		else
			this.fitToHeight(mesh, renderer, control)


	}


	responsive = (aspect = window.innerWidth / window.innerHeight) => {

		const frustumSize = Math.abs(this.top - this.bottom)

		this.left = - frustumSize * aspect / 2
		this.right = frustumSize * aspect / 2
		this.top = frustumSize / 2
		this.bottom = - frustumSize / 2

		this.updateProjectionMatrix()
	}


}


class PerspectiveCamera extends THREE.PerspectiveCamera {

	/* getBoundingBox = mesh => {

		const bBox = new THREE.Box3().setFromObject(mesh)
		const meshPosition = new THREE.Vector3()
		bBox.getCenter(meshPosition)

		const meshSize = new THREE.Vector3()
		bBox.getSize(meshSize)


		return [meshPosition, meshSize]
	}


	fitToWidth = (mesh, renderer, control=null) => {

		const [ meshPosition, meshSize] = this.getBoundingBox(mesh)
		const aspect = renderer.domElement.width / renderer.domElement.height
		this.zoom = 1

		this.position.x = meshPosition.x
		this.position.y = meshPosition.y
		this.position.z = meshSize.x / aspect / Math.tan(this.getEffectiveFOV()*Math.PI/180/2) / 2 / this.zoom + mesh.position.z + meshSize.z*2
		
		this.lookAt(mesh.position)
		
		if (control) {
			control.target = meshPosition
			control.update()
		}

	}


	fitToHeight = (mesh, renderer, control=null) => {

		const [ meshPosition, meshSize] = this.getBoundingBox(mesh)
		this.zoom = 1

		this.position.x = meshPosition.x
		this.position.y = meshPosition.y
		this.position.z = meshSize.y / Math.tan(this.getEffectiveFOV()*Math.PI/180/2) / 2 / this.zoom + mesh.position.z + meshSize.z*2
		
		this.lookAt(mesh.position)

		if (control) {
			control.target = meshPosition
			control.update()
		}
	}

	fit = (mesh, renderer, control=null) => {

		const [ , meshSize] = this.getBoundingBox(mesh)
		const wRatio = renderer.domElement.width / meshSize.x
		const hRatio = renderer.domElement.height / meshSize.y

		if (wRatio <= hRatio)
			this.fitToWidth(mesh, renderer, control)
		else
			this.fitToHeight(mesh, renderer, control)
	} */


	responsive = (aspect = window.innerWidth / window.innerHeight) => {
		this.aspect = aspect
		this.updateProjectionMatrix()
	}

}


CameraControls.install( { THREE: THREE } )

class Controls extends CameraControls {
	
	enableRotate = bool => {
		const currentPolar = this.polarAngle
		const currentAzimuth = this.azimuthAngle

		if (bool) {
			this.minPolarAngle = 0
			this.maxPolarAngle = Math.PI
			this.minAzimuthAngle = -Infinity
			this.maxAzimuthAngle = Infinity
		}
		else {
			this.minPolarAngle = currentPolar
			this.maxPolarAngle = currentPolar
			this.minAzimuthAngle = currentAzimuth
			this.maxAzimuthAngle = currentAzimuth
		}

	}
}



export { OrthographicCamera, PerspectiveCamera, Controls }