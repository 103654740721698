import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'

import {drawVoid, loadTexture, loadCubeTexture} from './threeCustomFunction'
import tone from '../toonGradient/fiveTone.jpg'
import mirrorGeometry from './mirrorGeometry'
import envFront from '../map/front.jpg'
import envBack from '../map/back.jpg'
import envSide from '../map/side.jpg'



class Smile {

	constructor(lower=false) {

		this.selected = []
		this.teethMesh = []
		this.tooth = {}
		this.original = {}
		this.reset = {}
		this.anchor = {}
		this.center = {}
		this.grouped = new THREE.Group()
		this.currentMaterial = 'toon'

		this.lower = lower

	}


	updateLib = async lib => {
		//const library = await this.fetchLib(lib, progress)
		const geo = await this.loadLib(lib)

		const teethGeo1 = geo.map(geometry => {
			geometry = geometry.clone()
			geometry.name = this.lower?'4'+ geometry.name:'1'+ geometry.name
			return geometry
		})

		const teethGeo2 = geo.map(geometry => {
			geometry = geometry.clone()
            mirrorGeometry(geometry)
			geometry.name = this.lower?'3'+ geometry.name:'2'+ geometry.name
			return geometry
		})

		const geometries = [...teethGeo1, ...teethGeo2]

		geometries.forEach(geometry => {

		//update tooth geometry
			this.tooth[geometry.name].geometry.copy(geometry)
			this.original[geometry.name].copy(geometry.clone())
			this.tooth[geometry.name].geometry.computeBoundsTree()

		})

		this.centerAnchor()
	}



	loadLib = async lib => {

		return new Promise(resolve => {
			const loader = new OBJLoader()
			loader.load(lib, group => {

				const geometries = group.children.map(mesh => {

                    const geo = mergeVertices(mesh.geometry)
                    const position = geo.getAttribute('position')
					const color = []

					for (let i=0; i<position.count; i++) {
						color.push(1, 1, 1)
					}

					geo.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))
                    geo.name = mesh.name

					return geo
				})

				resolve(geometries)
			})
		})
	}


	/* generateLayers = (mesh, material, ratio) => {

		const layer = new THREE.Mesh(mesh.geometry, material)
		layer.scale.multiplyScalar(ratio)
		const bboxM = new THREE.Box3().setFromObject(mesh)
		const bboxO = new THREE.Box3().setFromObject(layer)
		const m = new THREE.Vector3()
		const o = new THREE.Vector3()
		bboxM.getCenter(m)
		bboxO.getCenter(o)
		layer.position.set((m.x-o.x), (m.y-o.y), (m.z-o.z))

		return layer
	} */


	getFromLib = async (lib) => {

		const defaultVisible = ['15', '14', '13', '12', '11', '21', '22', '23', '24', '25']

	//draw teeth geometries
		const geometries = await this.loadLib(lib)


	//set materials

		const env = await loadCubeTexture([envSide, envSide, envSide, envSide, envFront, envBack])
		const fiveTone = await loadTexture(tone)
		fiveTone.minFilter = THREE.NearestFilter
		fiveTone.magFilter = THREE.NearestFilter


		const teethMesh1 = geometries.map(geometry => {

			const mesh = new THREE.Mesh(geometry.clone())
            mesh.geometry.computeBoundsTree()

			mesh.name = this.lower?'4'+ geometry.name:'1'+ geometry.name
			return mesh
		})

		const teethMesh2 = geometries.map(geometry => {

			const mesh = new THREE.Mesh(geometry.clone())
            mirrorGeometry(mesh.geometry)
            mesh.geometry.computeBoundsTree()

			mesh.name = this.lower?'3'+ geometry.name:'2'+ geometry.name
			return mesh
		})

		this.teethMesh = [...teethMesh1, ...teethMesh2]

	

	//put easy to read teeth references : teeth object {number, mesh}  and  tooth[number]
		this.teeth = this.teethMesh.map(toothMesh => {


////////////////////////////////////////////////////////////////////////////////////////////////////////////
			const material = {
				viewport: new THREE.MeshPhysicalMaterial({ side: THREE.DoubleSide , vertexColors: true, emissive: 0x222222, color: 0x888888, metalness: 0.05, roughness: 0.2, clearcoat: 1, envMap: env, envMapIntensity: 0.9, transparent: true}),
				toon: new THREE.MeshToonMaterial({color: 0xffffff, gradientMap: fiveTone, blending: THREE.MultiplyBlending, transparent: true})
			}	

			toothMesh.material = material.viewport	
			if (defaultVisible.includes(toothMesh.name))
				toothMesh.visible = true
			else
				toothMesh.visible = false



		//anchor
			const bboxM = new THREE.Box3().setFromObject(toothMesh, true)
			const m = new THREE.Vector3()
			bboxM.getCenter(m)
			const center = drawVoid(m.x, m.y, m.z)
			center.name = toothMesh.name
			toothMesh.add(center)


		//keep original geometries
			const originalGeometry = toothMesh.geometry.clone()


		//keep references with easy to call names
			this.tooth[toothMesh.name] = toothMesh
			this.center[toothMesh.name] = center
			this.original[toothMesh.name] = originalGeometry



			return {number: toothMesh.name, mesh: toothMesh, center, originalGeometry, material}
		})

		return this.teethMesh
	}


	groupAll = (children=[]) => {

		const setAnchor = (toothNumber, mirror=false) => {

			//based on original geometry
			const mesh = new THREE.Mesh(this.original[toothNumber])

			const bBox = new THREE.Box3().setFromObject(mesh, true)

			const bBoxCenter = new THREE.Vector3()
			bBox.getCenter(bBoxCenter)
			const bBoxSize = new THREE.Vector3()
			bBox.getSize(bBoxSize)
			const anchor = drawVoid(bBoxCenter.x, (bBoxCenter.y + bBoxSize.y/2), bBoxCenter.z)
			this.anchor[toothNumber] = anchor

			return anchor
		} 

		const setMiddleAnchor = (anchor1, anchor2) => {
			const middle = new THREE.Vector3().lerpVectors(anchor1.position, anchor2.position, 0.5)
			const anchor = drawVoid(middle.x, middle.y, middle.z)
			this.anchor.middle = anchor

			return anchor
		}

	//reset group & mesh transform
		const m = this.grouped.matrix.clone()
		m.invert()

		this.grouped = new THREE.Group()
		this.teethMesh.forEach(mesh => {
			mesh.applyMatrix4(m)
		})

		children.forEach(mesh => {
			mesh.applyMatrix4(m)
		})

		setMiddleAnchor( setAnchor(13), setAnchor(23, true) )
		this.grouped.add(...this.teethMesh, ...children )


		return this.grouped
	}


	ungroup = () => {

		const scene = this.grouped.parent
		const children = this.grouped.children
		const m = this.grouped.matrix.clone()

		children.forEach(mesh => {
			mesh.applyMatrix4(m)
		})

		scene.add(...children)

	}

	select = (...selected) => {

		const numbers = selected.map(sel => sel.number)

		this.teeth.forEach(tooth => {
			if (numbers.includes(tooth.number)) {
				tooth.mesh.material.emissive = new THREE.Color(0x333333)
			}
			else {
				tooth.mesh.material.emissive = new THREE.Color(0x000000)
			}
		})
	}

	unselect = () => {
		this.teeth.forEach(tooth => tooth.mesh.material.emissive = new THREE.Color(0x222222))
	}


	setMaterial = (materialName='viewport') => {
		this.teeth.forEach(tooth => tooth.mesh.material = tooth.material[materialName])
	}

	setOpacity = value => {

		if (value < 0) value = 0
		else if (value > 1) value = 1

		this.teeth.forEach(tooth => tooth.mesh.material.opacity = value)

	}


	/* showContact = (tooth, target) => {
		if (!target) 
			return




		const bvh = target.geometry.boundsTree

		const position = tooth.geometry.getAttribute('position')
		const color = []

		for (let i=0; i<position.count; i++) {
			
			const p = new THREE.Vector3(position.array[ 3*i ], position.array[ 3*i +1 ], position.array[ 3*i +2 ])
			tooth.updateMatrixWorld()
			target.updateMatrixWorld()
			const inverse = target.matrixWorld.clone().invert()
			p.applyMatrix4(tooth.matrixWorld)
			
			const raycaster = new THREE.Raycaster(p, new THREE.Vector3(0, this.lower?-1:1, 0) , 0, 10)
			raycaster.firstHitOnly = true
			const inter = raycaster.intersectObject(target)

			if (inter.length>0)
				color.push(1, 0, 0)

			else {
				p.applyMatrix4(inverse)
				const info = new THREE.Vector3()
				const dist = bvh.closestPointToPoint( target, p, info, 0, 1.5 )
				
				if (dist<1.5) 
					color.push(dist/1.5, dist/1.5, 1)		
			
				else
					color.push(1, 1, 1)	
			}

		}

		tooth.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))
	}


	hideContact = tooth => {

		const colorAttribute = tooth.geometry.getAttribute('color')
		const color = []

		for (let i=0; i<colorAttribute.count; i++) {
			color.push(1, 1, 1)
		}

		tooth.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))

	} */


	/* showContact = bool => {

		if (bool)
			this.teeth.forEach(tooth => tooth.material.viewport.vertexColors = true)
		else
			this.teeth.forEach(tooth => tooth.material.viewport.vertexColors = false)
	} */


	centerAnchor = () => {
		this.teeth.forEach(tooth => {
			const box = new THREE.Box3().setFromObject(tooth.mesh, true)
			const center = new THREE.Vector3()
			box.getCenter(center)
			tooth.center.parent.worldToLocal(center)
			tooth.center.position.set(center.x, center.y, center.z)
		})
	}


}

export default Smile