import React from 'react'

class Wall extends React.Component {
	componentDidMount = () => {

			this.props.callback()
	}

	render = () =>
		<></>
}

export default Wall