import React from 'react'
import * as THREE from 'three'
import { loadTexture, unmountThree, setMarkers, toNDC, calcMarkerRadius, drawVoid, resetTransform, free, drawBall } from '../functions/threeCustomFunction'
import TutoMatching from './TutoMatching'
import { OrthographicCamera } from '../functions/threeCustomClass'
import { Controls } from '../functions/threeCustomClass'
import Cardtridge from './Cardtridge'
import { Button } from './Button'
import Menu from './Menu'
import fit from '../icons/fit.svg'
import fitLips from '../icons/fitSmile.svg'
import reset from '../icons/refresh.svg'
import undo from '../icons/undo.svg'
import redo from '../icons/redo.svg'
import play from '../icons/play.svg'

import Transition from './Transition'
import History from '../functions/History'

import CaseConsumer from '../context/CaseContextConsumer'


const BASECOLOR = 'blue'


const create2DScene = ref => {

	const WIDTH = ref.current.getBoundingClientRect().width
	const HEIGHT = ref.current.getBoundingClientRect().height

	//mouse picking
	const raycaster = new THREE.Raycaster()
	raycaster.firstHitOnly = true

	const scene = new THREE.Scene()
	const camera = new OrthographicCamera( WIDTH/- 2, WIDTH/2, HEIGHT/2, HEIGHT /-2, 1, 10000 )
	camera.position.z = 100

	const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
	renderer.setSize( WIDTH, HEIGHT )

	const controls = new Controls( camera, renderer.domElement )
	controls.mouseButtons.wheel = Controls.ACTION.ZOOM
	controls.mouseButtons.middle = Controls.ACTION.ZOOM
//prevent rotation
	controls.minPolarAngle = Math.PI/2
	controls.maxPolarAngle = Math.PI/2
	controls.minAzimuthAngle = 0
	controls.maxAzimuthAngle = 0



//append to dom
	ref.current.appendChild( renderer.domElement )


//responsive function
	const resize = () => {
		renderer.domElement.remove()
		const WIDTH = ref.current.getBoundingClientRect().width
		const HEIGHT = ref.current.getBoundingClientRect().height		
		renderer.setSize( WIDTH, HEIGHT )		
 		camera.responsive( WIDTH/HEIGHT )
		ref.current.appendChild( renderer.domElement)
	}

//dispose scene
	const dispose = () => {
		unmountThree(scene, renderer)
		controls.dispose()

	}

//render scene
	const render = () => {
		renderer.render(scene, camera)
	}


	return { scene, camera, renderer, controls, raycaster, resize, dispose, render, ref }

}


const create2DPlane = async image => {
	const texture = await loadTexture(image)
	const planeMaterial = new THREE.MeshBasicMaterial( { map: texture, color: 0xffffff } )
	const planeGeometry = new THREE.PlaneGeometry( texture.image.width, texture.image.height, 1, 1 )
	const plane = new THREE.Mesh( planeGeometry, planeMaterial )

	return plane
}



class Matching2D extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			markersFace: [],
			markersBuccal: [],
			position: new THREE.Vector3(),
			rotation: new THREE.Euler(),
			scale: new THREE.Vector3(),
			opacity: 0.5
		}

		this.clock = new THREE.Clock()
		this.mouseFace = new THREE.Vector2(5, 5)
		this.mouseBuccal = new THREE.Vector2(5, 5)
		this.markersFace = []
		this.markersBuccal = []

		this.matchNeedUpdate = false

		this.selected = null

		this.faceRef = React.createRef()
		this.buccalRef = React.createRef()
		this.matchRef = React.createRef()

		this.history = new History({
			update: state=>this.setState(state),
			loadState: this.loadState,
			saveState: this.saveState
		})	
	}


	componentDidMount = async () => {

		//this.props.caseContext.setState({loading: true})

	//face viewport
		this.canvasFace = create2DScene(this.faceRef)
		this.face = await create2DPlane(this.props.facePhoto)
		this.canvasFace.scene.add(this.face)
		this.fit(this.face, this.canvasFace)


	//buccal viewport
		this.canvasBuccal = create2DScene(this.buccalRef)
		this.buccal = await create2DPlane(this.props.buccalPhoto)
		this.canvasBuccal.scene.add(this.buccal)
		this.fit(this.buccal, this.canvasBuccal)


	//match viewport 
		this.canvasMatch = create2DScene(this.matchRef)
		this.faceMatch = this.face.clone()

		this.buccalMatch = this.buccal.clone()
		this.buccalMatch.material = this.buccalMatch.material.clone()
		this.buccalMatch.material.transparent = true
		this.buccalMatch.material.opacity = this.state.opacity

		this.canvasMatch.scene.add(this.faceMatch)
		this.canvasMatch.controls.fitToBox(this.faceMatch)


	//events
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('keydown', this.handleKeyDown)

		this.faceRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouseFace, this.canvasFace.renderer.domElement))
		this.faceRef.current.addEventListener('pointerout', ()=> {
			this.mouseFace.set(5, 5)
			if (this.canvasFace.selected) {
				this.face.add(this.canvasFace.selected)
				this.canvasFace.selected = null
			}
		})

		this.buccalRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouseBuccal, this.canvasBuccal.renderer.domElement))
		this.buccalRef.current.addEventListener('pointerout', ()=> {
			this.mouseBuccal.set(5, 5)
			if (this.canvasBuccal.selected) {
				this.buccal.add(this.canvasBuccal.selected)
				this.canvasBuccal.selected = null
			}
		})

		if (this.props.buccalTransform)
			this.loadState(this.props.buccalTransform.matchPoints)

		this.history.saveHistory()
		this.animate()
	}


	handleResize = () => {

	//make it responsive
		const canvas = [ this.canvasFace, this.canvasBuccal, this.canvasMatch ]

		canvas.forEach(canva => canva.renderer.domElement.remove())
		canvas.forEach(canva => canva.resize())
		canvas.forEach(canva => canva.ref.current.appendChild(canva.renderer.domElement))

	}

	componentWillUnmount = () => {
	//avoid leak memory	
		cancelAnimationFrame(this.loop)
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('keydown', this.handleKeyDown)
		this.canvasFace.dispose()
		this.canvasBuccal.dispose()
		this.canvasMatch.dispose()
	}


	handleKeyDown = e => {

		if (!this.props.caseContext.enableShortcuts)
			return

		if (e.code==='KeyW' && e.ctrlKey) {
			e.preventDefault()
			this.history.undo()		
		}

		if (e.code==='KeyY' && e.ctrlKey) {
			e.preventDefault()
			this.history.redo()
		}

		if (this.state.markersFace.includes(this.lastSelected) || this.state.markersBuccal.includes(this.lastSelected)) {
			if (e.code==='ArrowLeft') {
				e.preventDefault()
				this.lastSelected.position.x -= 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowRight') {
				e.preventDefault()
				this.lastSelected.position.x += 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowUp') {
				e.preventDefault()
				this.lastSelected.position.y += 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowDown') {
				e.preventDefault()
				this.lastSelected.position.y -= 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}
		}	
	}


	fit = (mesh, canvas) => {

		canvas.camera.fitToWidth(mesh, canvas.renderer, canvas.controls)
	}

	fitFace = () => {
		this.canvasFace.controls.fitToBox(this.face, true)
	}
	fitBuccal = () => {
		this.canvasBuccal.controls.fitToBox(this.buccal, true)
	}

	fitFaceMatch = () => {
		this.canvasMatch.controls.fitToBox(this.faceMatch, true)
	}

	fitBuccalMatch = () => {
		this.canvasMatch.controls.fitToBox(this.buccalMatch, true)
	}


	animate = () => {
		this.loop = requestAnimationFrame(this.animate)
		this.refresh()
	}


	drawMarkersFace = () => {

		let size = calcMarkerRadius(this.face, 90)

		setMarkers({
			canvas: this.canvasFace, 
			mouse: this.mouseFace, 
			mesh: this.face,
			otherMesh: this.buccal, 
			markersArray: this.markersFace, 
			otherMarkersArray: this.markersBuccal, 
			markerSize: size,
			enableNavigation: ()=>true, 
			history: this.history,
			callback: lastSelected => {
				this.lastSelected = lastSelected
				this.matchNeedUpdate = true
			},
			realTime: true,
			maxPoints: 2,
			setState: markersFace => this.setState({markersFace}),
			sizeAttenuation: false

		})
	}

	drawMarkersBuccal = () => {

		let size = calcMarkerRadius(this.buccal, 70)

		setMarkers({
			canvas: this.canvasBuccal, 
			mouse: this.mouseBuccal, 
			mesh: this.buccal,
			otherMesh: this.face, 
			markersArray: this.markersBuccal, 
			otherMarkersArray: this.markersFace, 
			markerSize: size,
			enableNavigation: ()=>true, 
			history: this.history,
			callback: lastSelected => {
				this.lastSelected = lastSelected
				this.matchNeedUpdate = true
			},
			realTime: true,
			maxPoints: 2,
			setState: markersBuccal => this.setState({markersBuccal}),
			sizeAttenuation: false

		})
	}



	sortMarkers = () => {

		const comparePosition = (a, b) => a.position.x - b.position.x
		const sortedFace = [...this.markersFace].sort(comparePosition)
		const first = this.markersFace.indexOf(sortedFace[0])
		const second = this.markersFace.indexOf(sortedFace[1])

		this.markersFace = [this.markersFace[first], this.markersFace[second]]
		this.markersBuccal = [this.markersBuccal[first], this.markersBuccal[second]]

	}


	match = () => {


		if (this.markersFace.length < 2 || this.markersBuccal.length < 2)
			return

		resetTransform(this.buccalMatch)
		this.faceMatch.add(this.buccalMatch)
		this.sortMarkers()


		const f0 = drawVoid(this.markersFace[0].position.x, this.markersFace[0].position.y, 0)
		const f1 = drawVoid(this.markersFace[1].position.x, this.markersFace[1].position.y, 0)

		const b0 = drawVoid(this.markersBuccal[0].position.x, this.markersBuccal[0].position.y, 0)
		const b1 = drawVoid(this.markersBuccal[1].position.x, this.markersBuccal[1].position.y, 0)


	//prepare hierarchy
		this.faceMatch.add(f0, f1)
		this.buccalMatch.add(b1)
		this.faceMatch.add(b0)
		b0.attach(this.buccalMatch)

	//match position XY
		b0.position.copy(f0.position)

	//match scale
		const f0WPos = f0.getWorldPosition(new THREE.Vector3())
		const f1WPos = f1.getWorldPosition(new THREE.Vector3())
		const b0WPos = b0.getWorldPosition(new THREE.Vector3())
		const b1WPos = b1.getWorldPosition(new THREE.Vector3())

		const faceDistance = f0WPos.distanceTo(f1WPos)
		const buccalDistance = b0WPos.distanceTo(b1WPos)
		const ratio = faceDistance/buccalDistance
		b0.scale.set(ratio, ratio, 1)

	//match rotation Z
		const a1 = Math.atan2(f0WPos.x - f1WPos.x, f0WPos.y - f1WPos.y)
		const a2 = Math.atan2(b0WPos.x - b1WPos.x, b0WPos.y - b1WPos.y)
		const a = a2 - a1
		b0.rotation.z = a


	//reset hierarchy
		this.canvasMatch.scene.attach(this.buccalMatch)
		free(f0, f1, b0, b1)

		this.faceMatch.add(this.buccalMatch)
		this.setState({position: new THREE.Vector3().copy(this.buccalMatch.position), rotation: new THREE.Euler().copy(this.buccalMatch.rotation), scale: new THREE.Vector3().copy(this.buccalMatch.scale)})
		this.matchNeedUpdate = false
	}


	refresh = () => {

		const delta = this.clock.getDelta()
		this.canvasFace.controls.update(delta)
		this.canvasBuccal.controls.update(delta)
		this.canvasMatch.controls.update(delta)

		this.drawMarkersFace()
		this.markersFace.forEach(mesh => {
			const scale = 1/this.canvasFace.camera.zoom
			mesh.scale.set(scale, scale, scale) 
		})

		this.drawMarkersBuccal()
		this.markersBuccal.forEach(mesh => {
			const scale = 1/this.canvasBuccal.camera.zoom
			mesh.scale.set(scale, scale, scale) 
		})

		if (this.matchNeedUpdate)
			this.match()

	//anim loop
		this.canvasFace.render()
		this.canvasBuccal.render()
		this.canvasMatch.render()

//this.stats.update()

//console.log(this.rendererMatch.info.memory)
	}

	changeOpacity = e => {
		const opacity = e.target.value
		this.setState({opacity})
		this.buccalMatch.material.opacity = opacity
	}


	reset = () =>{

		this.fitFaceMatch()

		this.markersFace.forEach(mark => {
			free(mark)
		})

		this.markersBuccal.forEach(mark => {
			free(mark)
		})

		this.markersFace = []
		this.markersBuccal = []
		this.setState({markersFace: this.markersFace, markersBuccal: this.markersBuccal, opacity: 0.5})

		this.faceMatch.remove(this.buccalMatch)

		this.history.saveHistory()

	}

	fineUpdate = ({px, py, r, s}) => {
		if (px) { this.setState({position: new THREE.Vector3(px, this.state.position.y, this.state.position.z)}); this.buccalMatch.position.x = px }
		if (py) { this.setState({position: new THREE.Vector3(this.state.position.x, py, this.state.position.z)}); this.buccalMatch.position.y = py }
		
		if (r) { this.setState({rotation: new THREE.Euler(this.state.rotation.x, this.state.rotation.y, r)}); this.buccalMatch.rotation.z = r }

		if (s) { this.setState({scale: new THREE.Vector3(s, s, s)}); this.buccalMatch.scale.set(s, s, s) }
	}


	saveState = () => {
		return {
			markersFace: this.markersFace.map(marker => marker.position.toArray()),
			markersBuccal: this.markersBuccal.map(marker => marker.position.toArray())
		}


	}


	loadState = state => {

	if (!state)
		return

	//update markers on face
		this.markersFace.forEach(mark => {
			free(mark)
		})

		const markersFace = state.markersFace.map(mark => {
			const size = calcMarkerRadius(this.face, 90)
			const [x, y, z] = mark
			const ball = drawBall(x, y, z, size, BASECOLOR )
			this.face.add(ball)
			return ball
		})
		this.markersFace = markersFace

	//update markers on buccal
		this.markersBuccal.forEach(mark => {
			free(mark)
		})

		const markersBuccal = state.markersBuccal.map(mark => {
			let size = calcMarkerRadius(this.buccal, 50)
			const [x, y, z] = mark
			const ball = drawBall(x, y, z, size, BASECOLOR )
			this.buccal.add(ball)
			return ball
		})
		this.markersBuccal = markersBuccal

		this.setState({markersFace: this.markersFace, markersBuccal: this.markersBuccal})


	//update model matching
		this.face.remove(this.buccal)
		this.matchNeedUpdate = true
	}


	saveBuccalTransform = async () => {

		if (this.markersFace.length === 2 && this.markersBuccal.length === 2) {


		//save camera coordinate

			const buccalTransform = {
				position: this.buccalMatch.position.toArray(),
				quaternion: this.buccalMatch.quaternion.toArray(),
				scale: this.buccalMatch.scale.toArray(),
				matchPoints: this.saveState()
			}

			this.props.save(buccalTransform)
		}

	}



	render = () => {
		const valid = this.state.markersFace.length===2 && this.state.markersBuccal.length===2

		return <>

			<div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>		

				<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '3fr 2fr', gridGap: 5}}>
					<div ref={this.matchRef} style={{position: 'relative', gridColumn: '1 / span 2', gridRow: 1}} onContextMenu={e=>e.preventDefault()}>
						<div style={{position: 'absolute', left: 5, right: 5, bottom: 5}}><input style={{minWidth: 200, width: '60%'}} type='range' min={0} max={1} step={0.01} value={this.state.opacity} onChange={this.changeOpacity} /></div>
					</div>

					<div ref={this.faceRef} style={{position: 'relative', gridColumn: 1, gridRow: 2}} onContextMenu={e=>e.preventDefault()}>
						<TutoMatching fit={this.fitFace}/>
						{this.state.markersFace.length<2&&<div style={{position: 'absolute', top: 0, left: 0, background: 'rgba(255, 255, 255, 0.6)', padding: 5, fontWeight: 'bold'}}>add {2-this.state.markersFace.length} {this.state.markersFace.length===1?'point':'points'} on photo</div>}
					</div>

					<div ref={this.buccalRef} style={{position: 'relative', gridColumn: 2, gridRow: 2}} onContextMenu={e=>e.preventDefault()}>
						<TutoMatching fit={this.fitBuccal}/>
						{this.state.markersBuccal.length<2&&<div style={{position: 'absolute', top: 0, left: 0, background: 'rgba(255, 255, 255, 0.6)', padding: 5, fontWeight: 'bold'}}>add {2-this.state.markersBuccal.length} {this.state.markersBuccal.length===1?'point':'points'} on photo</div>}
					</div>

				</div>

				<Menu>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<Button icon={reset} label='reset' onClick={this.reset}/>
						<Button icon={fit} label='center viewport' onClick={this.fitFaceMatch}/>
						<Button icon={fitLips} label='center viewport' disabled={!valid} onClick={this.fitBuccalMatch}/>
					</Cardtridge>

					<Transition mounted={valid} transition='leftSlide'>
						<Cardtridge>
							<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
								<div style={{fontSize: 10, fontWeight: 'bold'}}>position</div>
								<input style={{fontSize: 10, width: '30%'}} type='number' value={Math.round(100*this.state.position.x)/100} step={0.1} onChange={e=>this.fineUpdate({px: e.target.value})}/>
								<input style={{fontSize: 10, width: '30%'}} type='number' value={Math.round(100*this.state.position.y)/100} step={0.1} onChange={e=>this.fineUpdate({py: e.target.value})}/>
							</div>
							<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
								<div style={{fontSize: 10, fontWeight: 'bold'}}>rotation</div>
								<input style={{fontSize: 10, width: '60%'}} type='number' value={Math.round(100*this.state.rotation.z*180/Math.PI)/100} step={0.1} onChange={e=>this.fineUpdate({r: e.target.value*Math.PI/180})}/>
							</div>
							<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
								<div style={{fontSize: 10, fontWeight: 'bold'}}>scale</div>
								<input style={{fontSize: 10, width: '60%'}} type='number' value={Math.round(100*this.state.scale.x)/100} step={0.01} onChange={e=>this.fineUpdate({s: e.target.value})}/>
							</div>
						</Cardtridge>
					</Transition>
					
					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<Button icon={undo} label='undo' onClick={this.history.undo} disabled={this.state.disabledUndo}/>
						<Button icon={redo} label='redo' onClick={this.history.redo} disabled={this.state.disabledRedo}/>
					</Cardtridge>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<img src={play} style={{height: 20, cursor: 'pointer', transform: 'rotateZ(180deg)'}} alt='previous step' title='previous step' onClick={this.props.cancel}/>
						<img src={play} style={{height: 20, cursor: valid?'pointer':'not-allowed', opacity: valid?1:0.5, transition: 'opacity 0.5s'}} alt='next step' title='next step' onClick={this.saveBuccalTransform}/>
					</Cardtridge>

				</Menu>
			</div>

		</>	}
}

export default CaseConsumer(Matching2D)