
import { loadStripe } from "@stripe/stripe-js"
import socket from '../functions/socket'
import React from "react"
import { Elements } from "@stripe/react-stripe-js"
//import {ElementsConsumer, PaymentElement, CardElement} from '@stripe/react-stripe-js'
import {PaymentElement, useStripe,useElements} from "@stripe/react-stripe-js";
import init from '../init'
import Cardtridge from './Cardtridge'
import UserConsumer from '../context/UserContextConsumer'

//import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader"

import Loading from '../components/Loading'
import Popup from '../components/Popup'




const stripePromise = loadStripe(init.stripeKey, {locale: 'en'})


let message = {
	new :'inform your new payment method here',
	problem : 'You have problem with your payment method. The cadsmile 3D subscription cost 40 € / month without obligation'
}




let PayCardApplication = props => {


	const [clientSecret, setClientSecret] = React.useState(false)

	React.useEffect(() => {
		socket.emit('fetchClientSecret', props.type, clientSecret=>{
			setClientSecret(clientSecret)
			props.userContext.setState({loading : false})
		})
	}, [])	

		
	return <>

		{clientSecret && <Pay clientSecret = {clientSecret} message={message.new} cancel={props.cancel} success={props.success}/>}

	</>
	
}




const PayNew = props=>{

	const [clientSecret, setClientSecret] = React.useState(false)
	const [loader, setLoader] = React.useState(true)

	React.useEffect(() => {
		socket.emit('fetchClientSecretNewCard', clientSecret=>{
			setClientSecret(clientSecret)
			setLoader(false)
		})
	}, [])

	//const closeWindow = ()=> console.log('return')

	const saveNewPm = response=>{
		console.log(response)
		socket.emit('saveNewPm', response.setupIntent.payment_method, ()=>{
		props.success()
		})
	}


	return <>

		{loader && <Loading/>}

		{clientSecret && <Pay clientSecret = {clientSecret} message={message.new} success = {saveNewPm} fail={props.fail} setup={true} cancel={props.cancel} successMessage = 'Congratulation! New card is added !'/>}

	</>


}


const Pay = props=> {

	const styleDiv = {
		top : 0, left : 0,
		position : 'fixed', width : '100vw', height : '100vh',
		zIndex : 200,
		display : 'flex',  justifyContent : 'center', alignItems: 'center', 
		backgroundColor : 'rgba(255,255,255,0.9)'
	}

	const appearance = {
	    	theme: 'stripe',
	    	variables: {
	    		colorPrimary: '#2b333b',
	    		borderRadius : 1
	    	}
  	}
  
  	const options = {clientSecret : props.clientSecret, appearance}

	return <div style={styleDiv}>

		<div style={{top : 0, left : 0, position : 'fixed', width : '100vw', height : '100vh', backgroundColor : 'rgba(255,255,255,0.7)'}} onClick={props.cancel || (()=>null)}/>
      
      	<Elements options={options} stripe={stripePromise}>
      		<CheckoutForm  message={props.message} success={props.success} fail = {props.fail} clientSecret={props.clientSecret} setup = {props.setup} successMessage={props.successMessage}/>
      	</Elements>
    		
    	</div>

 		
}






let CheckoutForm = props=> {

	const reload = ()=> window.location.reload()
	const stripe = useStripe()
  	const elements = useElements()
	const [loading, setLoading] = React.useState(false)
	const [loader, setLoader] = React.useState(true)
	const [popup, setPopup] = React.useState({popup: false, popupMessage: '', popupError: false, popupCallback: ()=>null})
	const [valid, setValid] = React.useState(false)


	React.useEffect(() => {
		if (props.clientSecret==='error') setPopup({popup : true, popupMessage: 'The link doesn\'t work', popupError: true, popupCallback :  props.fail || hidePopup})
	}, [])	

	const handleChange = e=>{
		setValid(e.complete)
	}

	
	const handleSubmit = async e => {
    		e.preventDefault()
    		setLoading(true)
    	
    		if (!stripe || !elements) return

    	const confirm = props.setup? stripe.confirmSetup : stripe.confirmPayment
    
		const result = await confirm({
	     		elements,
	     		redirect: 'if_required'
	    	})


	    	if (result.error) {
	    		setLoading(false)
	    		setPopup({popup : true, popupMessage: 'We have problem with your pay card', popupError: true, popupCallback : hidePopup})
	    	}
	    

	    	else {
	    		//let successUp = props.success(result)
	    		setPopup({popup : true, popupMessage: props.successMessage ||'Congratulation ! You subscribe cadSmile3D.', popupCallback : props.success? ()=>props.success(result) : reload})
	    	}
	       
  	}

  	//const success = result

  	const hidePopup = ()=>{
  		popup.popupCallback()
  		setPopup({popup: false, popupMessage: '', popupError: false, popupCallback: ()=>null})
  		if (props.fail) props.fail()
  	}
	

	 const formStyle = {
	 	//border : 'red solid',
	 	//position : 'relative',
	 	maxWidth : 300, maxHeight : '80vh',
	  	overflowY : 'auto',
	  	background: 'white',
	  	zIndex : 300

	}

	  	

	/* const override = css`
  		display: block;
  		margin: 0 auto;
  		border-color: red;
	` */

	const options = {
		business : {name :'cadsmile'},
		terms : {card : 'never'},
		paymentMethodOrder : ['card']
	}


	return <div style={formStyle}>

		<Cardtridge >

			{loader && <Loading/>}
			{popup.popup&&<Popup hide={hidePopup} message={popup.popupMessage} error={popup.popupError}/>}

			<form onSubmit={handleSubmit}>

				<div> {props.message}<hr/> </div>
				<PaymentElement options = {options} onReady={()=>setLoader(false)} onChange={handleChange}/>
				<button style={{cursor : valid? 'default' : 'not-allowed'}} disabled={!stripe || !valid}>{loading ? <PulseLoader  loading={true} size={10}/> : 'Subscribe'}</button>
				         
			</form>

		</Cardtridge>   

	</div> 	
	
}




 //PayApplication = UserConsumer(PayApplication)
 PayCardApplication = UserConsumer(PayCardApplication)
 //PayCardListApplication = UserConsumer(PayCardListApplication)


export {PayCardApplication, PayNew, Pay}