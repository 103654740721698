/** @jsxImportSource @emotion/react */

import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'

import MatchModel from './MatchModel'
import hashNav from '../functions/hashNav'
import Wall from './Wall'
import socket from '../functions/socket'
import Check from './Check'
import neural from '../images/neural.png'
//import SocketStream from '../functions/SocketStream'
import ajax from '../functions/ajaxx'

import TutoAccess from './TutoAccess'


const Matching3D = props => {

	const photo = props.caseContext[props.fileName.photo]
	const buccal = props.caseContext[props.fileName.buccal]
	const model = props.caseContext[props.fileName.model]

	const show = props.caseContext.page === 'matching'
	const showButton = props.caseContext.page === 'summary'

	const cancel = () => {
		window.history.back()
	}

	const gotoMatching = () => {
		if (photo.file && model.file)
			hashNav({page: 'matching'})
	}

	const saveMatching = ({camera, thumbnail}) => {

		const saveThumbnail = () => 
			new Promise(resolve => {

				ajax({
					url: '/upload',
					data: {
						priv: true,

						filesArray: [{name: props.fileName.photo+'_matching_thumbnail.jpg', data: thumbnail}],

						caseId: props.caseContext.id,
						folder: '', 
						root: 'casesFiles'
					},					
					onComplete: () => resolve(thumbnail)
				})
			})

		const saveCamera = () => 
			new Promise(resolve => {
				socket.emit('saveData', props.caseContext.id, {object: {camera}, fileName: props.fileName.photo, priv: true}, () => resolve(camera))
			})

		Promise.all([ saveThumbnail(), saveCamera() ]).then(([ thumbnail, camera ]) => {

			props.caseContext.setState({[props.fileName.photo]: {...props.caseContext[props.fileName.photo], camera : {...camera, thumbnail}}}, 
				()=>hashNav({page: 'summary'})
			)
		})	
	}

	const checked = photo.camera.position && photo.camera.quaternion
	const disabled = !photo.file || !model.file
	const creator = props.caseContext.creator.id === props.userContext.id || props.userContext.admin || props.userContext.designer


	return <>

		{showButton&&<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
			
				<div css={{opacity: creator?1:0.6, transition: 'all 0.5s', background: `center / cover no-repeat url(${!checked?neural:photo.camera.thumbnail})`, position: 'absolute', top: 5, bottom: 5, left: 5, right: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: !creator||disabled?'not-allowed':'pointer', '&:hover': !creator||disabled?{}:{top: 0, bottom: 0, left: 0, right: 0}}}
				title='matching 3D'
				onClick={creator?gotoMatching:null}>

					{!checked&&<div style={{transition: 'all 0.5s', fontSize: 30, fontWeight: 'bolder', textShadow: '0px 0px 2px white', opacity: !creator||disabled?0.4:1}}>matching 3D</div>}
				
				</div>

				<Check checked={checked}/>
				{disabled&&creator&&<div style={{
					position: 'absolute', 
					bottom: 0, 
					left: 0, 
					right: 0,
					fontWeight: 'bold', 
					color: 'white', 
					fontSize: 10, 
					pointerEvents: 'none', 
					background: 'rgba(0, 0, 0, 0.4)'
				}}>you need to put photo of face and 3D model in order to use it</div>}

				{!creator&&<TutoAccess/>}

			
		</div>}
			{show&&<MatchModel photo={photo} buccal={buccal} model={model} caseContext={props.caseContext} cancel={cancel} save={saveMatching}/>}
			{(!photo.file || !model.file)&&show&&<Wall callback={cancel}/>}

		
	</>
}



export default UserConsumer(CaseConsumer(Matching3D))