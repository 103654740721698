import { writeSearch, readSearch } from './crypto'

const hashNav = object => {

	const [hash, search] = window.location.hash.split('?')
	const navObj = readSearch(search)

	if (!hash) return


	const newNav = hash + '?' + writeSearch({...navObj, ...object})

	window.location.hash = newNav

}


export default hashNav