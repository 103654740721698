/* import loader from '../images/logo.svg' */
import Logo from './Logo'

const Loading = props => 
	<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 100, background: 'white', opacity: 0.9, cursor: 'wait'}}>

		{/* <img style={{userSelect: 'none'}} src={loader} alt='loading'/> */}

		{props.progress&&
			<div style={{ position: 'absolute', left: 10, right: 10}}>
				<div style={{ position: 'absolute', left: 0, right: `${100-props.progress}%`, background: '#003b6f', opacity: 0.5, textAlign: 'left'}}>
					<div style={{color: 'white', fontWeight: 'bolder'}}>{props.progress} %</div>
				</div>
			</div>}

		<Logo message={props.message} animate/>

	</div>


	Loading.defaultProps = {
		message: ''
	}




	export default Loading
