
const TutoAccess = props =>
	<>
		<div style={{
			display: 'flex', 
			alignItems: 'center', 
			justifyContent: 'center', 
			position: 'absolute', 
			//bottom: '5%', right: '2.5%', left: '2.5%',
			top: '50%', right: '2.5%', left: '2.5%',
			transform: 'translateY(-50%)',
			fontWeight: 'bold', 
			color: 'white', 
			fontSize: 10, 
			pointerEvents: 'none', 
			background: 'rgba(0, 0, 0, 1)',
			border: 'solid white 1px'}}
		>
			<div>you must be the creator of the case to access</div>

		</div>
	</>

export default TutoAccess