import React from 'react'
import Cardtridge from './Cardtridge'
import BurgerMenu from './BurgerMenu'

const Menu = props => {

	const [active, setActive] = React.useState(true)
	const [scrollable, setScrollable] = React.useState(false)
	const toogle = () => setActive(!active)

	const handleScrollable = bool => {
		setScrollable(bool)
	}
	
	return (

		<div style={{pointerEvents: scrollable?'all':'none', position: 'absolute', top: 40, left: 0, bottom: 0, width: active?300:100, transition: 'width 0.5s', overflowY: 'scroll', overflowX: 'hidden', ...props.style}}>

		<Cardtridge title={active?'close menu':'open menu'} onClick={toogle} style={{pointerEvents: 'all', zIndex: 10, padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer'}}> 

			<BurgerMenu active={active}/>

		</Cardtridge>

		{active&& 
			<div 
				style={{pointerEvents: 'all'}} 
				onPointerOver={()=>handleScrollable(true)} 
				onPointerLeave={()=>handleScrollable(false)}
			>
			{ props.children }
		</div>}

		</div>
	)
}


export default Menu