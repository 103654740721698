import { Button } from './Button'
import fit from '../icons/fit.svg'


const TutoMatching = props =>
	<>
		<div style={{position: 'absolute', bottom: 3, right: 3, fontWeight: 'bold', fontSize: 10, pointerEvents: 'none'}}>
			<div>left click : add/move point</div>
			<div>Shift + left click : delete point</div>
		</div>

		<div style={{position: 'absolute', top: 3, right: 3}}>
			<Button icon={fit} label='center viewport' onClick={props.fit}/>
		</div>
	</>

export default TutoMatching