import React from 'react'
import check from '../icons/check.svg'


const Check = props => {

	const size = props.size
	const [checked, setChecked] = React.useState(false)

	React.useEffect(() => {
		setTimeout(()=>setChecked(props.checked), 100)
  	}, [props.checked] )

	return <img src={check} alt='check'
		style={{
			position: 'absolute',
			opacity: checked?0.8:0, 
			top: checked?size/2:(3*size/4), 
			left: checked?size/2:(3*size/4),
			width: checked?size:size/4, 
			height: checked?size:size/4,
			transition: 'all 0.5s'
		}}/>	
} 

Check.defaultProps = {
	size: 40
}




export default Check