import * as THREE from 'three'
import { toNDC, drawLine } from './threeCustomFunction'
import { CONTAINED, INTERSECTED, NOT_INTERSECTED } from 'three-mesh-bvh'


const params = {

	size: 5,
}


class SculptTool {

	constructor({camera, domElement, controls, onChange, callback}) {

		this.camera = camera
		this.domElement = domElement
		this.controls = controls
		this.onChange = onChange
		this.callback = callback

		this.mouse = new THREE.Vector2(5, 5)
		this.targetMesh = null
		this.firstHit = null

		this.sculpting = false

		this.drawCursor()
	}


	drawCursor = () => {

		const cursorMaterial = new THREE.LineBasicMaterial( { color: 0xff6600 } )
		const s = params.size/3
		const cursorArc = new THREE.Path()
		cursorArc.absarc(0, 0, 1)
		const cursorNormal = drawLine([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 1)], cursorMaterial)
		const cursorArcGeo = new THREE.BufferGeometry().setFromPoints( cursorArc.getPoints() )
		const cursor = new THREE.Line( cursorArcGeo, cursorMaterial )

		this.cursor = new THREE.Group()
		this.cursor.add(cursor, cursorNormal)
		this.cursor.position.set(0, 0, 30)
		this.cursor.scale.set(s, s, s)
		this.cursor.visible = false

	} 

	attach = targetMesh => {

		this.targetMesh = targetMesh
		this.domElement.addEventListener('pointerdown', this.handlePointerDown)
		this.domElement.addEventListener('pointermove', this.updateMousePosition)			
		this.domElement.addEventListener('wheel', this.handleWheel)

	}

	detach = () => {
		this.targetMesh = null
		this.domElement.removeEventListener('pointerdown', this.handlePointerDown)	
		this.domElement.removeEventListener('pointermove', this.updateMousePosition)
		this.domElement.removeEventListener('wheel', this.handleWheel)

	}

	updateMousePosition = e => {

		toNDC(e, this.mouse, this.domElement)
	}

	handlePointerDown = e => {

		toNDC(e, this.mouse, this.domElement)
		const raycaster = new THREE.Raycaster()
		raycaster.setFromCamera( this.mouse, this.camera )
		raycaster.firstHitOnly = true
		const hit = raycaster.intersectObject( this.targetMesh )


		if (hit.length > 0) {

			this.firstHit = hit[0].point
			this.initialGeometry = this.targetMesh.geometry.clone()
			this.indices = this.findIntersection(this.firstHit)

			this.domElement.addEventListener('pointermove', this.handlePointerMove)
			this.domElement.addEventListener('pointerup', this.handlePointerUp)
		}

	}

	handlePointerUp = e => {
		this.firstHit = null
		//this.controls.enabled = true
		this.sculpting = false
		this.domElement.removeEventListener('pointermove', this.handlePointerMove)
		this.domElement.removeEventListener('pointerup', this.handlePointerUp)
		this.callback()

	}

	handlePointerMove = e => {

		if (!this.firstHit)
			return

		this.sculpting = true

		const firstProject = this.firstHit.clone().project(this.camera)
		const secondHitNDC = new THREE.Vector3(this.mouse.x, this.mouse.y, firstProject.z)
		this.secondHit = secondHitNDC.unproject(this.camera)
		this.vertexAdjustment(this.firstHit, this.secondHit)
	}

	handleWheel = e => {
		e.preventDefault()

		let delta = e.deltaY

		if ( e.deltaMode === 1 ) 
			delta *= 40

		if ( e.deltaMode === 2 )
			delta *= 40

		params.size -= delta * 0.005
		params.size = Math.max( Math.min( params.size, 30 ), 2 )

	}



	findIntersection = point => {

		const inverseMatrix = new THREE.Matrix4()
		inverseMatrix.copy( this.targetMesh.matrixWorld ).invert()

		const sphere = new THREE.Sphere()
		sphere.center.copy( point ).applyMatrix4( inverseMatrix )
		sphere.radius = params.size

		// Collect the intersected vertices
		const indices = new Set()
		const tempVec = new THREE.Vector3()
		const indexAttr = this.targetMesh.geometry.index
		const bvh = this.targetMesh.geometry.boundsTree
		
		bvh.shapecast(
			{

				intersectsBounds: ( box, isLeaf, score, depth, nodeIndex ) => {

					const intersects = sphere.intersectsBox( box )
					const { min, max } = box
					if ( intersects ) {

						for ( let x = 0; x <= 1; x ++ ) {

							for ( let y = 0; y <= 1; y ++ ) {

								for ( let z = 0; z <= 1; z ++ ) {

									tempVec.set(
										x === 0 ? min.x : max.x,
										y === 0 ? min.y : max.y,
										z === 0 ? min.z : max.z
									)
									if ( ! sphere.containsPoint( tempVec ) ) {

										return INTERSECTED
									}
								}
							}
						}

						return CONTAINED

					}

					return intersects ? INTERSECTED : NOT_INTERSECTED

				},


				intersectsTriangle: ( tri, index, contained ) => {

					const i3 = 3 * index
					const a = i3 + 0
					const b = i3 + 1
					const c = i3 + 2
					const va = indexAttr.getX( a )
					const vb = indexAttr.getX( b )
					const vc = indexAttr.getX( c )
					if ( contained ) {

						indices.add( va )
						indices.add( vb )
						indices.add( vc )

					} else {

						if ( sphere.containsPoint( tri.a ) )
							indices.add( va )

						if ( sphere.containsPoint( tri.b ) ) 
							indices.add( vb )

						if ( sphere.containsPoint( tri.c ) ) 
							indices.add( vc )
					}

					return false

				}
			}
		)

		return indices
	}



	vertexAdjustment = (point1, point2) => {


		this.targetMesh.geometry.copy(this.initialGeometry)

		const inverseMatrix = new THREE.Matrix4()
		inverseMatrix.copy( this.targetMesh.matrixWorld ).invert()
		const localPoint = new THREE.Vector3()
		localPoint.copy( point1 ).applyMatrix4(inverseMatrix)
		const directionPoint = new THREE.Vector3()
		directionPoint.copy(point2).applyMatrix4(inverseMatrix)

		const tempVec = new THREE.Vector3()
		const posAttr = this.targetMesh.geometry.attributes.position

		const direction = new THREE.Vector3().subVectors(directionPoint, localPoint)
		direction.normalize()
		const distance = point1.distanceTo(point2)

		this.indices.forEach( index => {
			tempVec.fromBufferAttribute( posAttr, index )

			const dist = tempVec.distanceTo( localPoint )
			let intensity = 1.0 - ( dist / params.size )


			intensity = Math.pow( intensity, 2 )
			tempVec.addScaledVector( direction,  intensity * distance )

			posAttr.setXYZ( index, tempVec.x, tempVec.y, tempVec.z )
		})

		// If we found vertices
		if ( this.indices.size ) {

			posAttr.needsUpdate = true
			this.targetMesh.geometry.computeVertexNormals()
			this.targetMesh.geometry.computeBoundsTree()

			this.onChange()

		}
	}


	update = () => {

		if (!this.targetMesh)
			return

		const raycaster = new THREE.Raycaster()
		raycaster.setFromCamera( this.mouse, this.camera )
		raycaster.firstHitOnly = true

		const hit = raycaster.intersectObject( this.targetMesh )

		if (hit.length>0 && !this.sculpting) {


			const mx = new THREE.Matrix4().lookAt(hit[0].face.normal,new THREE.Vector3(0,0,0),new THREE.Vector3(0,1,0))
			const orient = new THREE.Quaternion().setFromRotationMatrix(mx)

			this.controls.enabled = false
			this.cursor.visible = true
			const size = params.size/3
			this.cursor.scale.set(size, size, size)
			this.cursor.position.copy(hit[0].point)
			this.cursor.quaternion.copy(orient)
		} 
		else {
			this.cursor.visible = false
			this.controls.enabled = true
		}
	}




}


export default SculptTool