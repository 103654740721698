import React from 'react'
import Cardtridge from './Cardtridge'
import { formatDate } from '../functions/formatFunctions'

class SaveWindow extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			selected: null,
			name: '',
			description: ''
		}
	}

	componentDidMount = () => {
		window.addEventListener('keydown', this.handleKeyDown)
		this.selectFirst()
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	componentDidUpdate = prevProps => {
		if (prevProps.caseContext.savedList !== this.props.caseContext.savedList) {
			this.selectFirst()
		}
	}

	handleKeyDown = e => {

		let index = this.props.caseContext.savedList.indexOf(this.state.selected)

		if (e.keyCode === 40)
			index ++
		else if (e.keyCode === 38)
			index --
		else if (e.keyCode === 13)
			this.EnterKey()
		else if (e.keyCode === 27)
			this.props.cancel()

		if (index < 0)
			index = this.props.caseContext.savedList.length - 1
		else if (index > this.props.caseContext.savedList.length - 1)
			index = 0
		
		this.setState({selected: this.props.caseContext.savedList[index]})
	}

	save = e => {
		e.stopPropagation()
		const {name, description} = this.state
		this.props.save({name, description})
		this.setState({name: '', description: ''})
	}

	areYouSure = callback => {

		const hide = () => this.props.caseContext.setState({popup: false, popupMessage: '', popupButton: null})
		const accept = () => {
			hide()
			callback()
		}

		const button = <>
			<button onClick={accept} style={{margin: 10, fontWeight: 'bold'}}>yes</button>
			<button onClick={hide} style={{margin: 10, fontWeight: 'bold'}}>no</button>
		</>

		this.props.caseContext.setState({popup: true, popupMessage: 'Are you sure ?', popupButton: button})	
	}

	del = e => {
		e.stopPropagation()

		this.areYouSure(() => {
			this.props.del(this.state.selected.id)
		})
	}

	overWrite = e => {
		e.stopPropagation()

		this.areYouSure(() => {
			const {name, description} = this.state.selected

			this.props.del(this.state.selected.id, () => {
				this.props.save({name, description})
			})
			
		})
		
	}

	load = e => {
		e.stopPropagation()
		this.props.load(this.state.selected.id)
	}

	onDoubleClick = id => {
		this.props.load(id)
	}

	EnterKey = () => {
		if (this.state.name) {
			const {name, description} = this.state
			this.props.save({name, description})
		}

		else
			this.props.load(this.state.selected.id)
	}

	select = id => {
		const caseSelected = this.props.caseContext.savedList.filter(list => list.id === id)[0]
		this.setState({selected: caseSelected})
	}

	selectFirst = () => {

		if (this.props.caseContext.savedList.length>0)
			this.setState({selected: this.props.caseContext.savedList[0]})
		else
			this.setState({selected: null})

	}

	render = () => {
		return <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right:0, background: 'rgba(255, 255, 255, 0.8)', zIndex: 10}} onClick={this.props.cancel}>
		
			<Cardtridge style={{position: 'absolute', top: '20%', bottom: '20%', left: '20%', right: '20%', background: 'white', overflow: 'auto'}} onClick={e=>e.stopPropagation()}>

				{this.state.selected&&<>

					<div style={{height: '20%', overflow: 'auto'}}>
						<List savedList={this.props.caseContext.savedList} select={this.select} selected={this.state.selected} onDoubleClick={this.onDoubleClick}/>
					</div>


					<Cardtridge style={{height: '8%', display: 'flex', alignItems: 'center', justifyContent: 'end', fontSize: 28, fontWeight: 'bolder', margin: 0, padding: 0, marginLeft: '15%', paddingRight: 20, textTransform: 'uppercase'}}>
						{this.state.selected.name}
					</Cardtridge>

					<div style={{height: '60%', display: 'flex', justifyContent: 'center', position: 'relative'}}>

						<div style={{flex: 1, padding: 5}}>
							<img style={{border: '2px solid black', marginRight: 10, objectFit: 'cover',  width: '100%', height: '100%'}} src={this.state.selected.thumbnail} alt='thumbnail'/>
						</div>

						<div style={{flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: 5}}>
							<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, fontWeight: 'bold'}}>
								{this.state.selected.description}
							</div>
							<div style={{display: 'flex', justifyContent: 'start'}}>
								<button style={{margin: 5}} onClick={this.load}>load</button>
								<button style={{margin: 5}} onClick={this.overWrite}>overwrite</button>	
								<button style={{margin: 5}} onClick={this.del}>delete</button>
							</div>
						</div>
					</div>
				</>}


				<div style={{height: '10%', display: 'flex', alignItems: 'end', justifyContent: 'space-around'}}>

					<div>name <input value={this.state.name} onChange={e=>this.setState({name: e.target.value})}/></div>
					<div>description <input value={this.state.description} onChange={e=>this.setState({description: e.target.value})}/></div>
					<div><button onClick={this.save} disabled={!this.state.name}>save as</button></div>

				</div>
				
			</Cardtridge>

		</div>
	}
}




const List = props => {
	const ref = React.useRef()

	React.useEffect(() => {
		ref.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
	}, [props.selected])

	const list = props.savedList.map(saved => {
		const selected = saved.id === props.selected.id
			return <div ref={selected?ref:null} style={{background: selected?'#ff6600':'none', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}} key={saved.id} onClick={()=>props.select(saved.id)} onDoubleClick={()=>props.onDoubleClick(saved.id)}>
				<div style={{fontWeight: 'bold', flex: 1}}>{saved.name}</div> 
				<div style={{flex: 1}}>{formatDate(saved.date).englishFormat} {formatDate(saved.date).time}</div>
				<div style={{flex: 1}}>by {saved.creator.name}</div>
			</div>
	})

	return list
}




export default SaveWindow