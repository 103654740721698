import CaseConsumer from '../context/CaseContextConsumer'
import SmileDesigner from './SmileDesigner'
import photoFace from '../demoFiles/photoFace.jpg'
import modelMaxillar from '../demoFiles/modelMaxillar.ply'
import modelMaxillar_basement from '../demoFiles/modelMaxillar_basement.ply'
import modelMandibular from '../demoFiles/modelMandibular.ply'
import React from 'react'






const photo = {
    file: photoFace,
    landmarks: {    
        rightIris: [ -851.560115814209, -22.92609214782715, 1 ],
        leftIris: [ -51.88250541687198, -9.007930755615437, 1 ],
        middle: [ -456.7848735058769, -718.9766885203637, 1 ],
        leftNose: [ -181.83076857239712, -595.0426004430022, 0.9999999999999858 ],
        rightNose: [ -729.6749073799998, -587.857507321533, 0.9999999999999858 ],
        lips: [
            [ -844.8810394715973, -823.4036422315814, 1 ],
            [ -711.3818009938016, -846.5865626104451, 1 ],
            [ -577.4324592979261, -876.6890252551364, 1 ],
            [ -455.9254269215531, -900.1859082063138, 1 ],
            [ -335.050885961635, -875.43558851051, 1 ],
            [ -151.39564970159057, -817.5122576152881, 1 ],
            [ -20.05480207820591, -809.742270258336, 1 ],
            [ -76.90826792456453, -938.5045655818834, 1 ],
            [ -225.64326602829053, -1035.5134845352866, 1 ],
            [ -437.9333928807952, -1072.275643661552, 1 ],
            [ -666.4819246967566, -1044.324686102306, 1 ],
            [ -780.8469964964329, -938.4513552537086, 1 ]
        ],
        crop: {
            topLeft: [ -1671.3978984228506, 1083.437366115226, 1 ],
            topRight: [ 829.712186085357, 1083.437366115226, 1 ],
            bottomRight: [ 829.712186085357, -1956.771058665885, 1 ],
            bottomLeft: [ -1671.3978984228506, -1956.771058665885, 1 ]
        }
    },

    camera: {
        position: [ 26.66010707324562, 201.3539145988638, 559.1665068748988 ],
        quaternion: [ -0.14295244511235825, 0.017061486538043125, -0.025521486633374713, 0.9892533334962955 ],
        focalLength: 90,
    }  
}

const buccal = {
    file: '',
    transform: ''
}


const model = {
	file: modelMaxillar,
    basement: modelMaxillar_basement,
}

const mandibular = {
    file: modelMandibular
}



const Demo = props => {

    React.useEffect(() => {

        props.caseContext.setState({
    //case data
            id: '',
            name: '',
            page: '',
            savedList: [],


            photoFace: {
                file: '',
                exif: '',
                landmarks: '',
                camera: ''
            },

            modelMaxillar: {
                file: '',
                thumbnail: '',
                basement: ''
            }
        })
    }, [])

	const goToSub = () => {
		window.location.hash = 'profil'
		props.caseContext.setState({popup: false, popupError: false, popupMessage: ''})
	}

	const goToMainPage = () => {
		window.location.hash = ''
		props.caseContext.setState({popup: false, popupError: false, popupMessage: ''})
	}

	const doNothing = () => null

	const warning = () => {

		const warnMessage = <div>
			<div style={{marginBottom: 15}}>you are in the demo case of Cadsmile3D. You need to be subscribed to use this function.</div>
			<button onClick={goToSub}>go to subscribe page</button>
		</div>


		props.caseContext.setState({popup: true, popupError: true, popupMessage: warnMessage})
	}

	return <>
		<button style={{position: 'absolute', left: 10, top: 50, zIndex: 100, fontWeight: 'bold', cursor: 'pointer'}} onClick={goToMainPage}>leave demo</button>
		<SmileDesigner photo={photo} buccal={buccal} model={model} mandibular={mandibular} caseContext={props.caseContext} cancel={doNothing} load={doNothing} save={warning} del={warning} exportDesign={warning}/>
	</>


}


export default CaseConsumer(Demo)