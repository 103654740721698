import React from 'react'
import { UsernameInput, MailInput, PasswordInput, ChoosePasswordInput } from './Inputs'
import UserConsumer from '../context/UserContextConsumer'
import pencil from '../icons/pencil.svg'
import socket from '../functions/socket'
import { passwordHash, encrypt } from '../functions/crypto'
import puKey from '../key/puKey.pem'
import Cardtridge from './Cardtridge'


/* const Profil = props => {
	
	const goToMainPage = e => {
		window.history.back()
	}

	const stopPropagation = e => {
		e.stopPropagation()
	}

	const updateUsername = (username, callback=()=>null) => {

		if (username === props.userContext.username)
			return 

		socket.emit('updateUsername', username, response => {

			props.userContext.setState({loading: false})

			if (response.error)
				props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true})
			else
				props.userContext.setState({username: response.username}, () => callback(response.username) )
		})
	}

	const updateMail = (mail, callback=()=>null) => {
		if (mail === props.userContext.mail)
			return 

		socket.emit('updateMail', mail, response => {

			props.userContext.setState({loading: false})

			if (response.error)
				props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true})
			else
				props.userContext.setState({popup: true, popupMessage: response.message}, () => callback(props.userContext.mail) )
		})
	}

	return <div onClick={goToMainPage} style={{position: 'absolute', left: 0, bottom: 0, right: 0, top: 50}}>
			<Cardtridge onClick={stopPropagation} style={{position: 'absolute', top: '10%', bottom: '10%', left: '10%', right: '10%', display: 'flex', alignItems: 'center'}}>
				<div>
					<Update attribute='username' updateData={updateUsername}><UsernameInput/></Update>						
					<Update attribute='mail' updateData={updateMail}><MailInput label='mail address'/></Update>
					<UpdatePW/>
				</div>
			</Cardtridge>
		</div>
} */


class UpdtUsername extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = {
			disabled: true,
			value: props.defaultValue?? props.userContext.username,
			valid: false
		}

		this.ref = React.createRef()
	}

	makeUpdatable = () => {
		this.setState({disabled: false}, () => this.ref.current.focus())
	}

	cancelUpdate = () => {
		this.setState({value: this.props.defaultValue?? this.props.userContext.username, disabled: true })
	}

	updateValue = value => {
		this.setState({value})
	}

	updateValid = valid => {
		this.setState({valid})
	}

	handleSubmit = e => {
		e.preventDefault()

		if (this.state.valid) {
			this.setState({disabled: true})
			this.updateUsername(this.state.value, value => {
				this.updateValue(value)
				this.ref.current.blur()
			})

		}

	}


	updateUsername = (username, callback=()=>null) => {

		if (username === this.props.userContext.username)
			return 

		this.props.userContext.setState({loading: true})
		socket.emit('updateUsername', username, response => {

			this.props.userContext.setState({loading: false})

			if (response.error)
				this.props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true})
			else
				this.props.userContext.setState({username: response.username}, () => callback(response.username) )
		})
	}

	
	render = () => {

		return 	<form style={{position: 'relative'/* display: 'flex', alignItems: 'center', justifyContent: 'space-between' */}} onSubmit={this.handleSubmit}>
			<UsernameInput 
				defaultValue={this.state.value}
				disabled={this.state.disabled}
				onBlur={this.cancelUpdate}
				value={this.updateValue}
				valid={this.updateValid}
				ref={this.ref}
				showDelete={false}
			/>

			{this.state.disabled && <img style={{position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', height: 25, marginLeft: 15, cursor: 'pointer'}} alt='modify' src={pencil} onClick={this.makeUpdatable}/> 
		}

		</form>
	}


}




class UpdtMail extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = {
			disabled: true,
			value: props.defaultValue?? props.userContext.mail,
			valid: false
		}

		this.ref = React.createRef()
	}

	makeUpdatable = () => {
		this.setState({disabled: false}, () => this.ref.current.focus())
	}

	cancelUpdate = () => {
		this.setState({value: this.props.defaultValue?? this.props.userContext.mail, disabled: true })
	}

	updateValue = value => {
		this.setState({value})
	}

	updateValid = valid => {
		this.setState({valid})
	}

	handleSubmit = e => {
		e.preventDefault()

		if (this.state.valid) {
			this.setState({disabled: true})
			this.updateMail(this.state.value, value => {
				this.updateValue(value)
				this.ref.current.blur()
			})

		}

	}


	updateMail = (mail, callback=()=>null) => {

		if (mail === this.props.userContext.mail)
			return 

		this.props.userContext.setState({loading: true})
		socket.emit('updateMail', mail, response => {

			this.props.userContext.setState({loading: false})

			if (response.error)
				this.props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true}, () => callback(this.props.userContext.mail))
			else
				this.props.userContext.setState({popup: true, popupMessage: response.message}, () => callback(this.props.userContext.mail) )
		})
	}

	
	render = () => {

		return 	<form style={{position: 'relative'/* display: 'flex', alignItems: 'center', justifyContent: 'space-between' */}} onSubmit={this.handleSubmit}>
			<MailInput 
				label='mail adress'
				defaultValue={this.state.value}
				disabled={this.state.disabled}
				onBlur={this.cancelUpdate}
				value={this.updateValue}
				valid={this.updateValid}
				ref={this.ref}
				showDelete={false}
			/>

			{this.state.disabled && <img style={{position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', height: 25, marginLeft: 15, cursor: 'pointer'}} alt='modify' src={pencil} onClick={this.makeUpdatable}/> 
		}

		</form>
	}


}







/* class Updt extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			disabled: true,
			value: props.children.props.defaultValue?? props.userContext[props.attribute],
			valid: false
		}

		this.ref = React.createRef()

		this.Input = () => React.cloneElement(props.children, {
			defaultValue: this.state.value,
			disabled: this.state.disabled,
			onBlur: this.cancelUpdate,
			value: this.updateValue,
			valid: this.updateValid,
			ref: this.ref,
			showDelete: false
		})
	}

	makeUpdatable = () => {
		this.setState({disabled: false}, () => this.ref.current.focus())
	}

	cancelUpdate = () => {
		this.setState({value: this.props.children.props.defaultValue?? this.props.userContext[this.props.attribute], disabled: true })
	}

	updateValue = value => {
		this.setState({value})
	}

	updateValid = valid => {
		this.setState({valid})
	}

	handleSubmit = e => {
		e.preventDefault()

		if (this.state.valid) {
			this.setState({disabled: true})
			this.props.updateData(this.state.value, value => {
				this.updateValue(value)
				this.ref.current.blur()
			})

		}

	}


	render = () => {
		return 	<form style={{position: 'relative'}} onSubmit={this.handleSubmit}>
			<this.Input/>
			{this.state.disabled && <img style={{position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', height: 25, marginLeft: 15, cursor: 'pointer'}} alt='modify' src={pencil} onClick={this.makeUpdatable}/> 
		}

		</form>
	}
} */




/* const UpdtUsername = props => {

	const updateUsername = (username, callback=()=>null) => {

		if (username === props.userContext.username)
			return 

		props.userContext.setState({loading: true})
		socket.emit('updateUsername', username, response => {

			props.userContext.setState({loading: false})

			if (response.error)
				props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true})
			else
				props.userContext.setState({username: response.username}, () => callback(response.username) )
		})
	}

	return <Update attribute='username' updateData={updateUsername}><UsernameInput/></Update>
} */


/* const UpdtMail = props => {

	const updateMail = (mail, callback=()=>null) => {

		if (mail === props.userContext.mail)
			return 

		props.userContext.setState({loading: true})
		socket.emit('updateMail', mail, response => {

			props.userContext.setState({loading: false})

			if (response.error)
				props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true}, () => callback(props.userContext.mail))
			else
				props.userContext.setState({popup: true, popupMessage: response.message}, () => callback(props.userContext.mail) )
		})
	}

	return <Update attribute='mail' updateData={updateMail}><MailInput label='mail address'/></Update>
} */




class UpdtPW extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			valueActualPW: '',
			valueNewPW: '',
			valueNewRPW: '',
			validActualPW: false,
			validNewPW: false
		}

		this.refRPW = React.createRef()
	}

	updateActualPW = value => {
		this.setState({valueActualPW: value})
	}

	updateNewPW = value => {
		this.setState({valueNewPW: value})
	}

	validActualPW = valid => {
		this.setState({validActualPW: valid})
	}	

	validNewPW = valid => {
		this.setState({validNewPW: valid})
	}

	updatePW = e => {
		e.preventDefault()
		this.props.userContext.setState({loading: true})

		const actualHash = passwordHash(this.state.valueActualPW)
		const newHash = passwordHash(this.state.valueNewPW)

		socket.emit('updatePW2', actualHash, newHash, async response => {
			this.props.userContext.setState({loading: false})
			
			if (response.error)
				this.props.userContext.setState({popup: true, popupMessage: response.error.message, popupError: true})

			else {
				this.props.userContext.setState({popup: true, popupMessage: response.message})
				this.setState({valueActualPW: '', valueNewPW: ''})
				this.refRPW.current.erase()

				const logData = {
					mail: this.props.userContext.mail,
					hash: response.hashPW
				}

				//encrypt log data
				let publicKey = await fetch(puKey).then(data=>data.text())
				const encrypted = encrypt(JSON.stringify(logData), publicKey)

				//save session
				sessionStorage.setItem('sessId', encrypted)
			}
			
		})
	}

	render = () => {

		const valid = this.state.validActualPW && this.state.validNewPW

		return 	<>


				<form style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}} onSubmit={this.updatePW}>
					<div style={{width: '50%', minWidth: 300}}><PasswordInput defaultValue={this.state.valueActualPW} value={this.updateActualPW} valid={this.validActualPW} label='actual password'/></div>
					<Cardtridge style={{width: '50%', minWidth: 300}}>
						<div style={{marginTop: 10, fontWeight: 'bold'}}>new password</div>
						<ChoosePasswordInput ref={this.refRPW} value={this.updateNewPW} valid={this.validNewPW}/>

						<button disabled={!valid}>update</button>
					</Cardtridge>

				</form>

		</>
	}
}




const ContentAccountInformations = props => {
	return <div style={{width: '50%'}}>
		<UpdateUsername/>
		<UpdateMail/>
	</div>
}


// const ContentAccountInformations = props => {
// 	return <div style={{width: '50%'}}>
// 		test
// 	</div>
// }



//const Update = UserConsumer(Updt)
const UpdateUsername = UserConsumer(UpdtUsername)
const UpdateMail = UserConsumer(UpdtMail)
const UpdatePW = UserConsumer(UpdtPW)
export { UpdateUsername, UpdateMail, UpdatePW, ContentAccountInformations }
