import CaseContext from '../context/CaseContext'
import React from 'react'

const CaseConsumer = WrappedComponent => 

	React.forwardRef((props, ref) => 
		<CaseContext.Consumer>
			{context => <WrappedComponent ref={ref} {...props} caseContext={context}/>}
		</CaseContext.Consumer>

	) 



export default CaseConsumer