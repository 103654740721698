import Cardtridge from './Cardtridge'

const description = props => {

	const importantStyle = {
		textTransform: 'uppercase', 
		fontWeight: 'bolder', 
		color: '#ff6600'
	}
	

	return <Cardtridge style={{position: 'absolute', left: 20, right: 20, bottom: 5}}>
		<div style={{marginBottom: 20}}>
			Welcome to <span style={importantStyle}>cadsmile3D</span>
			, the simpliest and fastest <span style={importantStyle}>Digital Wax up Design</span> software.<br/>

		</div>

		<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/videoseries?list=PLttuzysZJttG3mwbWsbQ7cEG1RLnCfyXU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

	</Cardtridge>

}


export default description