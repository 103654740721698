const init = {

	dev : {
		client: 'http://localhost:3000',
		socket: 'http://localhost:4000',
		ajax: 'http://localhost:5000',
		stripeKey : 'pk_test_51JowW7BLbYImU6PjONaKWhPh02HAlIcOBtqCPu1VtCzH9rXj9gVEt0LQackGdF3CHG9Hiqw5BqNQCCl9kBMAtZ4q000GAIRy2K',
		designerMail : 'aa@aa.aaaa'
	},

	prod : {
		client : 'https://cadsmile3d.com',
		socket : 'https://cadsmile3d.com',
		ajax : 'https://cadsmile3d.com',
		stripeKey : 'pk_live_51JowW7BLbYImU6PjzN6q0svVVjkoO3DNgKkfDshZS3ZcZz4NxkDBjJmhjdGpjMpkI59c6aZNO1ygmaIQhZdlM0tY00ol7pz8dM',
		designerMail : 'teamcadsmile@gmail.com'

	}
}

export default init.prod