import UserContext from '../context/UserContext'
import React from 'react'

const UserConsumer = WrappedComponent => 

	React.forwardRef((props, ref) => 
		<UserContext.Consumer>
			{context => <WrappedComponent ref={ref} {...props} userContext={context}/>}
		</UserContext.Consumer>

	)



export default UserConsumer