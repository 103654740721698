import RotateCoords from '../functions/calcRotateCoord'

import {useState, useEffect} from "react"

import help from '../icons/helpicon.png'
import cancel from '../icons/helpiconClose.png'
import hashNav from '../functions/hashNav'
import CaseConsumer from '../context/CaseContextConsumer'


const Cadscore = props=>{

	const [scores, setScores] = useState(false)
	const [showComments, setShowComments] = useState(false)

	useEffect(() => {
		const cadscore = props.landmarks ? score(props.landmarks) : props.caseContext.photoFace.cadscore
		setScores(cadscore)
		props.caseContext.setState({photoFace: {...props.caseContext.photoFace, cadscore }})
	}, [props.landmarks])

	const scored = score =>{
		if (score >= 70) return {color : 'green', comment : 'Congratulation ! Your picture is of good quality'}
		else if (score > 40) return {color : 'orange', comment : 'You can use your picture but the quality could be better'}
		else return {color : 'red', comment : 'Your picture quality is not good. I don\'t recommend to use if'}
	}
	
	return <>

		<div style={{background: 'rgba(255, 255, 255, 0.6)', maxWidth : 200, position: 'fixed', bottom: 50, right: 50, zIndex : 10, textAlign : 'center', boxShadow: '17px 19px 15px 5px rgba(0,0,0,0.25)'}}>
				<div style={{margin : 5, fontWeight: 'bold'}}>CADSCORE</div>
				<div title={scored(scores.score).comment} style={{backgroundColor : scored(scores.score).color, color : 'white', fontSize : '2em', margin : 5, cursor : 'help'}}>{scores.score}</div>
				
				{scores.score<100&& <div> 
					<div style={{fontWeight: 'bold'}}>How increase the score</div><img src={showComments? cancel : help} alt='info' width={20} style={{cursor : 'pointer'}} onClick={e=>setShowComments(!showComments)}/> 
				</div>}

				<ul style={{fontSize : '0.8em', maxHeight : showComments? 500 : 0, textAlign : 'left', overflowY : 'hidden', transition : 'max-height 0.4s'}}>
					{scores.sizeScore<20 && <li>Increase the resolution of face</li>}
					{scores.rotateScore<30 && scores.glabellaEyeRatio>=1 && <li>Turn the patient's head right</li>}
					{scores.rotateScore<30 && scores.glabellaEyeRatio<1 && <li>Turn the patient's head left</li>}
					{scores.inclineScore<30 && scores.inclineRatio<0 && <li>Incline the patient's head down</li>}
					{scores.inclineScore<30 && scores.inclineRatio>=0 && <li>Incline the patient's head up</li>}
					{scores.smileWidthScore<10 && <li>The patient must smile wider</li>}
					{scores.openMouthScore<10 && <li>The patient must show teeth</li>}

					<div style={{margin : 5}}><button onClick={e=>hashNav({page: 'summary'})}>Use another picture</button></div>
				</ul>

				
		</div>

	</>
}



const score = landmarks=>{

	// test

	let points = {
		leftEye : 473, rightEye : 468,
		glabella : 168,
		top : 10, bottom : 152,
		leftEar : 454, rightEar : 234,
		leftOrbit : 230, rightOrbit : 450,
		leftNose : 327, rightNose : 98,
		leftLipsJonction : 291, rightLipsJonction : 61,
		upperLip : 13, lowerLip : 14
	}

	
	const createNewCoordsObject = points=>{

		let leftEye = {x : landmarks.vertices[points.leftEye][0], y : landmarks.vertices[points.leftEye][1]}
		let rightEye = {x : landmarks.vertices[points.rightEye][0], y : landmarks.vertices[points.rightEye][1]}
		let center = {x : landmarks.vertices[points.glabella][0], y : landmarks.vertices[points.glabella][1]}

		let angle = Math.atan((rightEye.y-leftEye.y)/(rightEye.x-leftEye.x))
		let calcRotate = new RotateCoords(angle, center)
		let newCoords = {}

		for (const [key, value] of Object.entries(points)) {
  			let coords = {x : landmarks.vertices[value][0], y : landmarks.vertices[value][1]}
  			newCoords = {[key] : calcRotate.calcCoords(coords), ...newCoords}
		}

		return newCoords

	}


	let  coords = createNewCoordsObject(points)

	// calc face dimensions
	let heightFace = Math.abs(coords.top.y - coords.bottom.y)
	let widthFace = Math.abs(coords.leftEar.x - coords.rightEar.x)

	let sizeScore
	if (heightFace>=2000) sizeScore = 20
	else if (heightFace<1000) sizeScore = 0
	else sizeScore = Math.round((heightFace-1000)/50)

	//Evaluate head rotation
	let rightGlabellaEyeDistance = Math.abs(coords.rightEye.x - coords.glabella.x)
	let leftGlabellaEyeDistance = Math.abs(coords.leftEye.x - coords.glabella.x)
	let glabellaEyeRatio = rightGlabellaEyeDistance/leftGlabellaEyeDistance
	
	let rotateScore
	if (glabellaEyeRatio>=0.8 && glabellaEyeRatio<=1.2) rotateScore = 30
	else if (glabellaEyeRatio>=0.6 && glabellaEyeRatio<=1.4) rotateScore = 20
	else if (glabellaEyeRatio>=0.4 && glabellaEyeRatio<=1.6) rotateScore = 10
	else rotateScore = 0


	//Evaluate head inclinaison
	let frankfortDistance =  ((coords.leftOrbit.y + coords.rightOrbit.y)/2) - ((coords.leftEar.y + coords.rightEar.y)/2) 
	let inclineRatio = 100*frankfortDistance/heightFace

	let inclineScore
	if (inclineRatio>=-3 && inclineRatio<=3) inclineScore = 30
	else if (inclineRatio>=-5 && inclineRatio<=5) inclineScore = 20
	else if (inclineRatio>=-7 && inclineRatio<=7) inclineScore = 10
	else inclineScore = 0


	//evaluate smile quality
	let openMouth = 100*(coords.lowerLip.y - coords.upperLip.y)/heightFace
	let openMouthScore = openMouth>5? 10 : 0
	let smileWidth = 100*(coords.leftLipsJonction.x-coords.rightLipsJonction.x)/widthFace
	let smileWidthScore = smileWidth>45? 10 : 0

	return {
		sizeScore, heightFace, widthFace,
		rotateScore, glabellaEyeRatio,
		inclineScore, inclineRatio,
		openMouthScore,
		smileWidthScore,
		score : sizeScore+rotateScore+inclineScore+openMouthScore+smileWidthScore
	}



}



export default CaseConsumer(Cadscore) 