import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import * as THREE from 'three'

import Cardtridge from './Cardtridge'
import Menu from './Menu'
import TutoNavigation from './TutoNavigation'
import { Button } from './Button'
import GizmoView from './GizmoView'
import { exportThumbnailToBase64 } from '../functions/thumbnailExporter'
import CreateModel from '../functions/CreateModel'
import Transition from './Transition'


import { init3DScene, createModel, drawText, free, loadTexture, toNDC, toOrthographic, toPerspective, exportPLY, getRealBoundingBox, drawLine } from '../functions/threeCustomFunction'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'
import { Controls } from '../functions/threeCustomClass'

import undo from '../icons/undo.svg'
import redo from '../icons/redo.svg'
import play from '../icons/play.svg'
import cancel from '../icons/cancel.svg'
import changeFile from '../icons/changeFile.svg'
import translate from '../icons/translate.svg'
import rotate from '../icons/rotate.svg'
import cancelTransform from '../icons/null.svg'
import anchor from '../icons/anchor.svg'
import anchorToOrigin from '../icons/anchorToOrigin.svg'
import modelToOrigin from '../icons/modelToOrigin.svg'
import anchorToCenter from '../icons/anchorToCenter.svg'
import toFloor from '../icons/toFloor.svg'
import socle from '../icons/socle.svg'
import socleHeight from '../icons/socleHeight.svg'
import socleWidth from '../icons/socleWidth.svg'
import trashcan from '../icons/trashcan.svg'
import fix from '../icons/fix.svg'
import refresh from '../icons/refresh.svg'
import rubber from '../icons/rubber.svg'
import selectAll from '../icons/selectAll.svg'
import deselect from '../icons/deselect.svg'
import invisibleIcon from '../icons/invisible.svg'
import visibleIcon from '../icons/visible.svg'

import History from '../functions/History'



/* import Stats from 'three/examples/jsm/libs/stats.module.js'
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js' */
//import tstText from '../demoFiles/photoFace.jpg'



class AdjustModel extends React.Component {
	
	constructor(props) {
		super(props)
		this.state = {
			activeCamera: {},
			anchor: false,
			transformMode: '',
			loaded: false,
			vertex: 0,
			lowerVertex: 0,
			minY: 0,
			maxY: 0,
			minZ: 0,
			maxZ: 0,
			valueY: 0,
			valueZ: 0,
			cleaned: false,
			lowerCleaned: false,
			basement: false,
			lowerBasement: false,
			height: 5,
			thickness: 3,
			lowerJaw: null,
			lowerJawFile: '',
			lowerJawVisible: true,
			model: 'maxillar'
		}

		this.containerRef = React.createRef()
		this.gizmoRef = React.createRef()
		this.inputLowerJawRef = React.createRef()
		this.mouse = new THREE.Vector2(5, 5)
		this.selected = null
		this.transforming = false
		this.orienting = false
		this.clock = new THREE.Clock()
		this.lowerMesh = null

		this.history = new History({
			update: state=>this.setState(state),
			loadState: this.loadState,
			saveState: this.saveState
		})
	}

	componentDidMount = () => {

		this.props.caseContext.setState({loading: true})

		const mesh = createModel({
			url: this.props.model.url, 
			format: this.props.model.extension,
		})

		const lowerMesh = this.props.caseContext.modelMandibular.file ?
			createModel({url: this.props.caseContext.modelMandibular.file}) :
			null
		
		const basement = this.props.caseContext.modelMaxillar.basement ?
			createModel({url: this.props.caseContext.modelMaxillar.basement}) :
			null

		const lowerBasement = this.props.caseContext.modelMandibular.basement ?
			createModel({url: this.props.caseContext.modelMandibular.basement}) :
			null
		const anchorTexture = loadTexture(anchor)

		Promise.all([ mesh, lowerMesh, basement, lowerBasement, anchorTexture ]).then(([mesh, lowerMesh, basement, lowerBasement, anchorTexture]) => {
			this.props.caseContext.setState({loading: false})

			if (this.containerRef.current)
				this.sceneConstruct(mesh, lowerMesh, basement, lowerBasement, anchorTexture)
		})

	}

	sceneConstruct = async (mesh, lowerMesh, basement, lowerBasement, anchorTexture ) => {

		const transformMatrix = new THREE.Matrix4().fromArray(this.props.model.transform)
		const transformMatrixInvert = transformMatrix.clone().invert()


		this.canvas3D = init3DScene({
			ref: this.containerRef,
			mesh
		})
		this.setState({vertex: this.updateCount(mesh)})
		this.canvas3D.mesh.geometry.applyMatrix4(transformMatrixInvert)
		this.canvas3D.mesh.geometry.computeBoundsTree()
		this.canvas3D.mesh.applyMatrix4(transformMatrix)
		this.originalGeometry = this.canvas3D.mesh.geometry.clone()

		if (basement) {
			basement.material = this.canvas3D.mesh.material
			this.canvas3D.mesh.add(basement)
			basement.geometry.applyMatrix4(transformMatrixInvert)
			this.canvas3D.mesh.userData.basement = basement
			this.setState({basement: true})
		}

		if (lowerMesh) {
			this.lowerMesh = lowerMesh
			lowerMesh.applyMatrix4(transformMatrixInvert)
			this.canvas3D.mesh.add(lowerMesh)
			this.setState({lowerJaw: lowerMesh})
			this.originalLowerGeometry = lowerMesh.geometry.clone()
			this.setState({lowerVertex: this.updateCount(lowerMesh)})


			if (lowerBasement) {
				lowerBasement.material = this.lowerMesh.material
				this.lowerMesh.add(lowerBasement)
				this.lowerMesh.userData.basement = lowerBasement
				this.setState({lowerBasement: true})
			}
		}

		this.canvas3D.controls.mouseButtons.wheel = Controls.ACTION.ZOOM
		this.canvas3D.controls.mouseButtons.middle = Controls.ACTION.ZOOM



	//select camera
		this.setState({activeCamera: this.canvas3D.camera})


	//anchor sprite from svg
		const anchorMaterial = new THREE.SpriteMaterial( { map: anchorTexture } )
		this.anchor = new THREE.Sprite( anchorMaterial )
		this.anchor.scale.set(3, 3, 3)
		this.anchor.attach(this.canvas3D.mesh)



	//grid with orientation text
		const gridHelper = new THREE.GridHelper( 200, 20, 'black', 'lightgrey' )
		this.canvas3D.scene.add( gridHelper, this.anchor )

		const front = await drawText('FRONT')
		const back = await drawText('BACK')
		const left = await drawText('LEFT')
		const right = await drawText('RIGHT')

		front.position.set(0, 0, 105)
		back.position.set(0, 0, -105)
		left.position.set(110, 0, 0)
		right.position.set(-110, 0, 0)

		front.rotation.x = -Math.PI/2		
		back.rotation.x = -Math.PI/2
		left.rotation.x = -Math.PI/2
		right.rotation.x = -Math.PI/2

		this.canvas3D.scene.add( front, back, left, right )

	//placement helper
		const helperShape = new THREE.Shape()
		helperShape.absarc(0, 0, 30, Math.PI/5, 4*Math.PI/5)
		helperShape.moveTo(-30, 0)
		helperShape.lineTo(-40, -20)
		helperShape.lineTo(-30, -30)
		helperShape.lineTo(-15, -30)
		helperShape.quadraticCurveTo(0, -10, 15, -30)
		helperShape.lineTo(15, -30)
		helperShape.lineTo(30, -30)
		helperShape.lineTo(40, -20)


		const helperMesh = new THREE.Mesh( new THREE.ShapeGeometry( helperShape, 50 ), new THREE.MeshBasicMaterial( { color: 0xff6600, transparent: true, opacity: 0.2, side: THREE.DoubleSide } ) )
		helperMesh.rotation.x = Math.PI/2
		this.canvas3D.scene.add( helperMesh )

	//canines placement helper

		const helperK9 = drawLine(
			[ 
				new THREE.Vector3( -100, 0, 15 ), 
				new THREE.Vector3( 100, 0, 15 )	
			],
			new THREE.LineBasicMaterial({color: 'red', linewidth: 2})
		)

		const textK9 = drawText('K9', 2, 'red')
		textK9.rotation.x = -Math.PI/2
		const textK9b = textK9.clone()
		textK9.position.set(40, 0, 16.5)
		textK9b.position.set(-40, 0, 16.5)

		this.canvas3D.scene.add( helperK9, textK9, textK9b )

	//transform control
		this.transformControl = new TransformControls( this.canvas3D.camera, this.canvas3D.renderer.domElement )
		this.transformControl.addEventListener('mouseDown', () => {
			this.canvas3D.controls.enabled = false
			this.transforming = true
		})

		this.transformControl.addEventListener('mouseUp', () => {
			this.canvas3D.controls.enabled = true
			setTimeout(() => this.transforming = false, 300)
			this.history.saveHistory()

		})
		this.canvas3D.scene.add( this.transformControl )



	//adjust camera position
		this.canvas3D.camera.setFocalLength(90)
		this.canvas3D.controls.fitToBox( this.canvas3D.mesh, false, { paddingTop: 20, paddingLeft: 20, paddingBottom: 20, paddingRight: 20 } )


	//some events
		window.addEventListener('resize', this.resize)
		window.addEventListener('keydown', this.handleKeyDown)
		this.containerRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouse, this.canvas3D.renderer.domElement))
		this.canvas3D.controls.addEventListener('controlstart', this.unorient)



/* const helper = new VertexNormalsHelper( this.canvas3D.mesh, 7, 0x00ff00, 1 )
this.canvas3D.scene.add( helper ) */

/* this.stats = new Stats()
this.containerRef.current.appendChild( this.stats.dom ) */

		this.modelCreator = new CreateModel({
			scene: this.canvas3D.scene,
			model: this.canvas3D.mesh,
			camera: this.state.activeCamera,
			domElement: this.containerRef.current,
			controls: this.canvas3D.controls
		})
		//this.modelCreator.basement = basement


		this.anchorToCenter()
		this.history.deleteHistory()


		this.setState({loaded: true})

		this.animate()

	}





	componentDidUpdate = prevProps => {

		if (prevProps.model.url !== this.props.model.url) {
			this.updateFile(this.props.model)
		}

	}


	updateFile = async model => {

		this.props.caseContext.setState({loading: true})

		const mesh = await createModel({
			url: model.url, 
			format: model.extension,
		})
		this.updateMesh(mesh)
		this.anchorToCenter()

		this.history.deleteHistory()
		this.originalGeometry = mesh.geometry.clone()
		//resetTransform(this.originalMesh)

		this.props.caseContext.setState({loading: false})

	}

	updateMesh = mesh => {

		this.canvas3D.scene.attach(this.canvas3D.mesh)

		mesh.geometry.computeBoundsTree()
		this.setState({vertex: this.updateCount(mesh)})
		this.canvas3D.mesh.updateMatrixWorld()
		const transform = this.canvas3D.mesh.matrixWorld.clone()

		free(this.canvas3D.mesh)
		mesh.applyMatrix4(transform)
		this.canvas3D.scene.add(mesh)
		this.canvas3D.mesh = mesh


		if (!this.state.anchor) 
			this.anchor.attach(this.canvas3D.mesh)

		if (this.lowerMesh)
			this.canvas3D.mesh.add(this.lowerMesh)

		//this.modelCreator.attach(this.canvas3D.mesh)
		this.modelCreator.setSelectionBoxPosition({})
		this.modelCreator.model.userData.basement = null
		this.modelCreator.model.userData.borderIndex = new Set()

		this.setState({cleaned: false, basement: false})

		//this.selectTransform('')



	}

	// update vertex count
	updateCount = mesh => {

		const count = Math.floor( mesh.geometry.attributes.position.count )
		return count
		//this.setState({vertex: count})
	}


	toOrthographic = (camera, controls) => {

		const orthoCamera = toOrthographic(camera, controls)
		this.setState({activeCamera: orthoCamera})
		this.transformControl.camera = orthoCamera
		this.modelCreator.camera = orthoCamera
	}


	toPerspective = (camera, controls) => {

		const perspectiveCamera = toPerspective(camera, controls)
		this.setState({activeCamera: perspectiveCamera})
		this.transformControl.camera = perspectiveCamera
		this.modelCreator.camera = perspectiveCamera

	}


	resize = () => {
		this.canvas3D.resize()
	}


	componentWillUnmount = () => {
		cancelAnimationFrame( this.loop )
		window.removeEventListener('resize', this.resize)
		window.removeEventListener('keydown', this.handleKeyDown)

		if (this.canvas3D)
			this.canvas3D.dispose()
		
		if (this.transformControl) {
			this.transformControl.detach()
			this.transformControl.dispose()
		}

		this.props.caseContext.setState({loading: false})


	}

	animate = () => {
		this.loop = requestAnimationFrame(this.animate)
		const delta = this.clock.getDelta()
		this.canvas3D.controls.update( delta )

		this.refresh()
	}

	handleKeyDown = e => {

		if (this.props.caseContext.loading || !this.props.caseContext.enableShortcuts)
			return

		const cancelCtrl = () => {
			this.canvas3D.controls.enabled = true
			window.removeEventListener('keyup', cancelCtrl)
		}


		if (e.ctrlKey) {
			this.canvas3D.controls.enabled = false
			window.addEventListener('keyup', cancelCtrl)
		}
			




		if (e.code==='KeyW' && e.ctrlKey) {
			e.preventDefault()
			this.history.undo()		
		}

		else if (e.code==='KeyY' && e.ctrlKey) {
			e.preventDefault()
			this.history.redo()
		}

		else if (e.code==='KeyT') {
			e.preventDefault()
			if (this.state.transformMode === 'translate')
				this.selectTransform('')
			else
				this.selectTransform('translate')
		}
		
		else if (e.code==='KeyR') {
			e.preventDefault()
			if (this.state.transformMode === 'rotate')
				this.selectTransform('')
			else
				this.selectTransform('rotate')
		}


		
	}


	orient = side => {

		this.orienting = true
		setTimeout(()=>this.orienting = false, 100)
		this.toOrthographic(this.canvas3D.camera, this.canvas3D.controls)

		const DEG90 = Math.PI /2
		const DEG180 = Math.PI

		switch ( side ) {

			case 'front': this.canvas3D.controls.rotateTo( 0, DEG90, true ); break
			case 'back': this.canvas3D.controls.rotateTo( DEG180, DEG90, true ); break
			case 'up': this.canvas3D.controls.rotateTo( 0, 0, true ); break
			case 'down': this.canvas3D.controls.rotateTo( 0, DEG180, true ); break
			case 'left': this.canvas3D.controls.rotateTo( DEG90, DEG90, true ); break
			case 'right': this.canvas3D.controls.rotateTo( - DEG90, DEG90, true ); break
			default: break

		}

		this.canvas3D.controls.fitToBox( this.canvas3D.mesh, true, { paddingTop: 20, paddingLeft: 20, paddingBottom: 20, paddingRight: 20 } )
		
	}

	unorient = e => {

		if (this.state.activeCamera.type === 'OrthographicCamera') {

			const azimuth = e.target.azimuthAngle
			const polar = e.target.polarAngle

			const isRotate = e => {
				if (azimuth.toFixed(2) !== e.target.azimuthAngle.toFixed(2) || polar.toFixed(2) !== e.target.polarAngle.toFixed(2)) {
					this.toPerspective(this.state.activeCamera, this.canvas3D.controls)
					e.target.removeEventListener('control', isRotate)

				}
			}

			e.target.addEventListener('control', isRotate)
		}


	}

	selectTransform = transformMode => {


		if (this.state.anchor) {
			this.anchorChange({target: {checked: false}})
		}

		this.setState({transformMode}, () => {

			if (!transformMode) {

				this.transformControl.detach()
				this.modelCreator.disable()
				
				//this.anchor.add(this.canvas3D.mesh)
				this.canvas3D.mesh.material.visible = true
				if (this.lowerMesh && this.state.lowerJawVisible) this.lowerMesh.material.visible = true
			}

			else if (transformMode === 'geometry') {
				this.transformControl.detach()
				this.chooseJawGeometry()
			}

			else if (transformMode === 'basement') {
				return
			}

			else {
				this.transformControl.attach(this.anchor)
				this.transformControl.setMode(transformMode)
				this.modelCreator.disable()
			}

		})
	}


	chooseJawGeometry = () => {

		if (this.state.transformMode === 'basement' ) {

			if (this.state.model==='mandibular')
				this.modelCreator.attach(this.lowerMesh)

			if (this.state.model==='maxillar')
				this.modelCreator.attach(this.canvas3D.mesh)

			return
		}
		else if (this.state.transformMode === 'geometry' ) {

			this.canvas3D.mesh.material.visible = true
			//this.canvas3D.mesh.userData.basement.visible = true
			if (this.lowerMesh) {
				this.lowerMesh.material.visible = true
				//this.lowerMesh.userData.basement.visible = true
			}
			this.modelCreator.disable()

			if (this.state.model==='mandibular') {

				if (!this.state.lowerJawVisible)
					this.toogleLowerJaw()

				this.canvas3D.mesh.material.visible = false
				//this.canvas3D.mesh.userData.basement.visible = false
				this.modelCreator.attach(this.lowerMesh)

			}
			else {
				if (this.lowerMesh)  {
					this.lowerMesh.material.visible = false
					//this.lowerMesh.userData.basement.visible = false
				}
				this.modelCreator.attach(this.canvas3D.mesh)
			}


			this.modelCreator.enable()
			const selectionUpdate = this.modelCreator.getSelectionBox()

			this.setState({
				valueY: selectionUpdate.position.y, valueZ: selectionUpdate.position.z,
				minY: this.state.model==='mandibular'?- selectionUpdate.dimensions.y*1.5 : - selectionUpdate.dimensions.y, 
				maxY: this.state.model==='mandibular'? selectionUpdate.dimensions.y : selectionUpdate.dimensions.y*1.5, 
				minZ: - selectionUpdate.dimensions.z, 
				maxZ: selectionUpdate.dimensions.z
			})
		}


	}


	updateSelectionBox = ({x, y, z}) => {

		if (y)
			this.setState({valueY: y})
		
		if (z)
			this.setState({valueZ: z})
	
		this.modelCreator.setSelectionBoxPosition({x, y, z})
	}


	anchorChange = e => {
		const anchor = e.target.checked
		this.setState({anchor})

		if (!anchor) {
			this.anchor.attach(this.canvas3D.mesh)
			this.setState({transformMode: ''})
			this.transformControl.detach()
		}
		
		else {
			this.canvas3D.scene.attach(this.canvas3D.mesh)
			this.selectTransform('translate')
		}

	}



	refresh = () => {

		this.canvas3D.raycaster.setFromCamera( this.mouse, this.state.activeCamera )
		const intersect = this.canvas3D.raycaster.intersectObject(this.canvas3D.mesh)

		const changeTransform = () => {

			if (this.state.anchor) {
				this.anchorChange({target: {checked: false}})
				this.selectTransform('translate')
			}
			else {
				if (this.state.transformMode === 'translate') this.selectTransform('rotate')
				else this.selectTransform('translate')
			}
		}

		if (intersect.length > 0)
			this.containerRef.current.style.cursor = 'pointer'
		else
			this.containerRef.current.style.cursor = 'default'

		this.containerRef.current.onpointerup = () => {

			if (this.canvas3D.controls.controlled || this.state.transformMode === 'geometry' || this.transforming) {
				return
			}

			else if (!this.transforming && !this.orienting && intersect.length > 0) {
				changeTransform()
			}
			else if (!this.transforming && !this.orienting && intersect.length === 0) {
				this.selectTransform('')
			}
		}


		this.canvas3D.renderer.render(this.canvas3D.scene, this.state.activeCamera)
		if (this.gizmoRef.current)
			this.gizmoRef.current.animate()
				
//this.stats.update()
		this.modelCreator.update()
//console.log(this.canvas3D.renderer.info.memory)


	}

	saveState = () => {

			this.canvas3D.mesh.matrixWorldNeedsUpdate = true
			this.anchor.matrixWorldNeedsUpdate = true

		return  {
			mesh: {
				position: this.canvas3D.mesh.getWorldPosition(new THREE.Vector3()),
				quaternion: this.canvas3D.mesh.getWorldQuaternion(new THREE.Quaternion())
			},

			anchor: {
				position: this.anchor.getWorldPosition(new THREE.Vector3()),
				quaternion: this.anchor.getWorldQuaternion(new THREE.Quaternion())
			},


		}
	}

	loadState = ({mesh, anchor, newMesh}) => {


		if (!this.state.anchor) this.canvas3D.scene.attach(this.canvas3D.mesh)

			this.canvas3D.mesh.position.copy(mesh.position)
			this.canvas3D.mesh.quaternion.copy(mesh.quaternion)			
			this.anchor.position.copy(anchor.position)
			this.anchor.quaternion.copy(anchor.quaternion)


		if (!this.state.anchor) this.anchor.attach(this.canvas3D.mesh)

		if (this.state.transformMode === 'geometry')
			this.modelCreator.setSelectionBoxPosition({})

	}

	/* saveModel = async () => {
		this.canvas3D.mesh.updateMatrixWorld()
		console.log(this.canvas3D.mesh.matrixWorld.toArray())
	} */


	saveModel = async () => {


	//prevent several click
		if (this.inactive)
			return

		this.inactive = true

	//fit for thumbnail
		const fit = () =>
			new Promise(resolve=> {

				const controlsSleep = () => {
					this.canvas3D.controls.removeEventListener('sleep', controlsSleep)
					resolve()
				}
					
					const DEG90 = Math.PI /2
					this.canvas3D.controls.addEventListener('sleep', controlsSleep)
					this.canvas3D.controls.rotateTo( 0, DEG90, true )
					this.canvas3D.controls.fitToBox( this.canvas3D.mesh, false )
				})
		
		this.selectTransform('')


		if (this.lowerMesh)
			this.lowerMesh.material.visible = true

		await fit()


	//export thumbnail
		const thumbnail = exportThumbnailToBase64({
				scene: this.canvas3D.scene,
				camera: this.state.activeCamera,
				renderer: this.canvas3D.renderer
			})

	//save transform
		this.canvas3D.mesh.updateMatrixWorld()
		const transform = this.canvas3D.mesh.matrixWorld.toArray()

		const promises = [
			exportPLY(this.canvas3D.mesh.clone().clear()), 
			this.state.lowerJaw?exportPLY(this.lowerMesh.clone().clear()):null, 
			this.state.basement?exportPLY(this.canvas3D.mesh.userData.basement.clone()):null, 
			(this.state.lowerJaw&&this.state.lowerBasement)?exportPLY(this.lowerMesh.userData.basement.clone()):null, 
			thumbnail, 
			transform
		]

		Promise.all(promises)
		.then( ([model, lowerMesh, basement, lowerBasement, thumbnail, transform])=> {
			this.inactive = false
			this.props.save({model, lowerMesh, basement, lowerBasement, thumbnail, transform})
		})


	}


	anchorToOrigin = () => {

		if (!this.state.anchor) this.canvas3D.scene.attach(this.canvas3D.mesh)
		this.anchor.position.set(0, 0, 0)
		this.anchor.setRotationFromQuaternion(new THREE.Quaternion())
		if (!this.state.anchor) this.anchor.attach(this.canvas3D.mesh)

		this.history.saveHistory()
	}

	anchorToCenter = () => {
		const bBox = new THREE.Box3().setFromObject(this.canvas3D.mesh, true)
		const center = bBox.getCenter(new THREE.Vector3())

		if (!this.state.anchor) this.canvas3D.scene.attach(this.canvas3D.mesh)
		this.anchor.position.set(center.x, center.y, center.z)
		if (!this.state.anchor) this.anchor.attach(this.canvas3D.mesh)

		this.history.saveHistory()
	}

	modelToOrigin = () => {
		if (this.state.anchor) this.anchor.attach(this.canvas3D.mesh)
		this.anchor.position.set(0, 0, 0)
		if (this.state.anchor) this.canvas3D.scene.attach(this.canvas3D.mesh)

		if (this.state.transformMode === 'geometry')
			this.modelCreator.setSelectionBoxPosition({})

		this.history.saveHistory()

	}

	toFloor = () => {

		if (!this.state.anchor) 
			this.canvas3D.scene.attach(this.canvas3D.mesh)

		if (this.lowerMesh)
			this.canvas3D.mesh.remove(this.lowerMesh)

		const bBox = getRealBoundingBox(this.canvas3D.mesh)

		this.canvas3D.mesh.position.y -= bBox.min.y
		this.anchor.position.y -= bBox.min.y

		if (!this.state.anchor) this.anchor.attach(this.canvas3D.mesh)

		if (this.state.transformMode === 'geometry')
			this.modelCreator.setSelectionBoxPosition({})

		if (this.lowerMesh)
			this.canvas3D.mesh.add(this.lowerMesh)

		this.history.saveHistory()

	}



	handleChangeModel = e => {
		this.setState({model: e.target.value}, () => {
			this.chooseJawGeometry()
		})
	}


	selectAll = () => {
		this.modelCreator.selectAll()
	}


	deselect = () => {
		this.modelCreator.deselect()
	}


	cleanModel = () => {

		this.props.caseContext.setState({loading: true})

		setTimeout(()=> {

			this.modelCreator.clean()
			.then(response=> {

				this.modelCreator.model.geometry.copy(response.mesh.geometry)
				this.modelCreator.model.geometry.computeBoundsTree()
				this.modelCreator.lassoIndicesAdd.clear()
				this.modelCreator.lassoIndicesRemove.clear()
				this.props.caseContext.setState({loading: false})
				this.modelCreator.disable()
				this.selectTransform('')

				if (this.state.model === 'mandibular')
					this.setState({lowerVertex: this.updateCount(this.lowerMesh)})
				else
					this.setState({vertex: this.updateCount(this.canvas3D.mesh)})




			})
			.catch(response => {
				if (response.error) 
					this.props.caseContext.setState({popup: true, popupMessage: response.error.message, popupError: true, loading: false})
			})

		}, 100)
	}


	fixModel = () => {

		this.props.caseContext.setState({loading: true})


		setTimeout(()=> {

			this.modelCreator.fix(this.state.model==='mandibular'?-1:1)
			.then(response => {

				if (this.state.model === 'mandibular')
					this.setState({lowerCleaned: true})
				if (this.state.model === 'maxillar')
					this.setState({cleaned: true})

				this.props.caseContext.setState({loading: false})

			})
			.catch(response => {
				if (response.error) 
					this.props.caseContext.setState({popup: true, popupMessage: response.error.message, popupError: true, loading: false})
			})
			

		}, 100)
	}


	createBasement = () => {

		this.props.caseContext.setState({loading: true})


		/* if ((this.state.model === 'maxillar' && !this.state.cleaned) || (this.state.model === 'mandibular' && !this.state.lowerCleaned))
			this.fixModel() */		

		setTimeout(()=> {

			this.modelCreator.createBasement(this.state.height, this.state.thickness, this.state.model)
			.then(response => {
				this.modelCreator.model.add(response.basement)
				this.modelCreator.model.userData.basement = response.basement
				this.props.caseContext.setState({loading: false})
				
				if (this.state.model === 'mandibular')
					this.setState({lowerBasement: true})

				if (this.state.model === 'maxillar')
					this.setState({basement: true})


			})

		}, 100)
	}


	changeHeight = e => {
		let value = Number(e.target.value)

		if (value < 1)
			value = 1

		if(value > 50)
			value = 50

		this.setState({height: value})
	}

	changeThickness = e => {
		let value = Number(e.target.value)

		if (value < 1)
			value = 1

		if(value > 10)
			value = 10

		this.setState({thickness: value})
	}


	resetMesh = () => {
		
		if (this.state.model === 'mandibular') {
			this.lowerMesh.geometry.copy(this.originalLowerGeometry)
			this.lowerMesh.geometry.computeBoundsTree()
			this.lowerMesh.userData.borderCurve = null
			this.setState({lowerCleaned: false, lowerVertex: this.updateCount(this.lowerMesh)})
		}
		else {
			this.canvas3D.mesh.geometry.copy(this.originalGeometry)
			this.canvas3D.mesh.geometry.computeBoundsTree()
			this.canvas3D.mesh.userData.borderCurve = null
			this.setState({cleaned: false, vertex: this.updateCount(this.canvas3D.mesh)})

		}

	}


	deleteBasement = () => {
		//this.modelCreator.basement = null
		if (this.state.model === 'mandibular') {
			free(this.lowerMesh.userData.basement)
			this.setState({lowerBasement: false})
		} 
			
		if (this.state.model === 'maxillar') {
			free(this.canvas3D.mesh.userData.basement)
			this.setState({basement: false})
		}


	}


	readLowerJaw = e => {
		const file = e.target.files[0]
		if (!file) return

		const fileArray = file.name.split('.')
		const extension = fileArray[fileArray.length-1]
		const testExt = /ply|stl|obj/i.test(extension)

		if (!testExt) 
			this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'model must be a .PLY, .STL or .OBJ file.'})
		else
			this.readModel(file, extension)


	}


	dropManager = e => {
		e.preventDefault()

		const file = e.dataTransfer.items[0].getAsFile()
		if (!file) return

		const fileArray = file.name.split('.')
		const extension = fileArray[fileArray.length-1]
		const testImage = /jpg|jpeg|png|bmp/i.test(extension)
		const testModel = /ply|stl|obj/i.test(extension)
		
		const mouse = new THREE.Vector2()
		toNDC(e, mouse, this.canvas3D.renderer.domElement)
		this.canvas3D.raycaster.setFromCamera( mouse, this.state.activeCamera )

		const toDetect = [this.canvas3D.mesh]

		if (this.lowerMesh)
			toDetect.push(this.lowerMesh)
		const intersect = this.canvas3D.raycaster.intersectObjects(toDetect, false)

		
		if (testModel) 
			this.readModel(file, extension)

		else if (testImage) {

			if (intersect.length > 0) { 
				const hitGeometry = intersect[0].object.geometry
				this.updateColor(file, hitGeometry)
			}
			else
				this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'model must be a .PLY, .STL or .OBJ file.'})
		}

		else {
			if (intersect.length > 0)				
				this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'color must be a .JPG, .JPEG, .PNG or .BMP file.'})

			else
				this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'model must be a .PLY, .STL or .OBJ file.'})
		}


	}


	updateColor = (file, geometry) => {


		if (!geometry.hasAttribute('uv')) {
			this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'this geometry can\'t receive texture'})
			return
		}

		this.props.caseContext.setState({loading: true})


		const reader  = new FileReader()
		const img = new Image()
		const canvas = document.createElement('canvas')
		const context = canvas.getContext('2d')
		const color = []
		const uvs = geometry.getAttribute('uv')


		reader.onload = e => {
			img.src = reader.result
		}


		img.onload = () => {
			canvas.width = img.width
			canvas.height = img.height
			context.drawImage(img, 0, 0, img.width, img.height)
			

			for (let i=0; i<uvs.count*2; i+=2) {

				const uv = new THREE.Vector2(uvs.array[i], uvs.array[i+1])
				const x = uv.x * img.width
				const y = (1 - uv.y) * img.height

				const pixel = context.getImageData(x, y, 1, 1).data


				color.push(pixel[0]/255, pixel[1]/255, pixel[2]/255)

			}

			geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(color), 3))
			this.props.caseContext.setState({loading: false})

		}


		reader.readAsDataURL(file)

	}




	readModel = (file, extension) => {

		const reader  = new FileReader()
		reader.onload = e => {
			const src = reader.result
			const blob = new Blob([src], { type: 'application/octet-stream'})
			this.setState({lowerJaw : {url: URL.createObjectURL(blob) , extension}, lowerJawFile: '' }, () => {
				this.addLowerJaw()
			})
		}
		
		if (file) 
			reader.readAsArrayBuffer(file)
	}


	addLowerJaw = async () => {

		free(this.lowerMesh)
		this.lowerMesh = await createModel({
			url: this.state.lowerJaw.url, 
			format: this.state.lowerJaw.extension
		})

		this.canvas3D.mesh.add(this.lowerMesh)
		this.setState({lowerCleaned: false, lowerVertex: this.updateCount(this.lowerMesh)})
	}

	delLowerJaw = () => {
		free(this.lowerMesh)
		this.lowerMesh = null
		this.setState({lowerJaw : null, lowerJawVisible: true, model: 'maxillar', lowerCleaned: false})
	}

	toogleLowerJaw = () => {
		const visible = this.state.lowerJawVisible
		this.setState({lowerJawVisible: !visible})
		this.lowerMesh.material.visible = !visible
	}



	render = () => {
		return <>


			<div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>		
				
				<div 	
					ref={this.containerRef} 
					style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0}}
					onDrop={this.dropManager} 
					onDragOver={e=>e.preventDefault()}
				>
					<TutoNavigation/>
					{this.state.loaded&&<GizmoView ref={this.gizmoRef} camera={this.state.activeCamera} controls={this.canvas3D.controls} orient={this.orient}/>}
				</div>

				

				<Menu>
					<Cardtridge style={{fontWeight: 'bold', textAlign: 'center', padding: 5}}>Transform {this.state.anchor?'anchor point':'model'}
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 10}}>
							<button style={{cursor: 'pointer', opacity: this.state.transformMode==='translate' && !this.state.anchor?0.5:1}} disabled={this.state.transformMode==='translate' && !this.state.anchor} onClick={()=>this.selectTransform('translate')} title='translate (shortcut: T)'><img src={translate} style={{width: 25, height: 25}} alt='translate'/></button>
							<button style={{cursor: 'pointer', opacity: this.state.transformMode==='rotate'?0.5:1}} disabled={this.state.transformMode==='rotate'} onClick={()=>this.selectTransform('rotate')} title='rotate (shortcut: R)'><img src={rotate} style={{width: 25, height: 25}} alt='rotate'/></button>
							<button style={{cursor: 'pointer', opacity: this.state.transformMode===''?0.5:1}} disabled={this.state.transformMode===''} onClick={()=>this.selectTransform('')}><img src={cancelTransform} style={{width: 25, height: 25}} alt='cancel'/></button>
							<div title='modify anchor point' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><input type='checkbox' checked={this.state.anchor} onChange={this.anchorChange}/> <img src={anchor} style={{height: 15}} alt='anchor'/></div>
						</div>
					</Cardtridge>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<button style={{cursor: 'pointer'}} onClick={this.anchorToCenter} title='set anchor to center'><img src={anchorToCenter} style={{height: 25, width: 25}} alt='anchorToCenter'/></button>
						<button style={{cursor: 'pointer'}} onClick={this.anchorToOrigin} title='set anchor to origin'><img src={anchorToOrigin} style={{height: 25, width: 25}} alt='anchorToOrigin'/></button>
						<button style={{cursor: 'pointer'}} onClick={this.modelToOrigin} title='set model to origin'><img src={modelToOrigin} style={{height: 25, width: 25}} alt='modelToOrigin'/></button>
						<button style={{cursor: 'pointer'}} onClick={this.toFloor} title='set model to floor'><img src={toFloor} style={{height: 25, width: 25}} alt='toFloor'/></button>

					</Cardtridge>


					<Cardtridge>

						<div style={{fontWeight: 'bold', textAlign: 'center'}}>Clean model</div>
						{this.state.lowerJaw&&<div  style={{fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: 10}}>
							<div><input type='radio' value='maxillar' checked={this.state.model==='maxillar'} onChange={this.handleChangeModel}/>maxillar</div>
							<div><input type='radio' value='mandibular' checked={this.state.model==='mandibular'} onChange={this.handleChangeModel}/>mandibular</div>
						</div>}

						{this.state.transformMode==='geometry' || this.state.transformMode==='basement' ?

						<div style={{position: 'relative', height: 25}}>
							<button style={{cursor: 'pointer', position: 'absolute', right: 0}} onClick={()=>this.selectTransform('')} ><img src={cancel} style={{height: 10, width: 10}} alt='cancel'/></button>
						</div> :

						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
							<button style={{cursor: 'pointer'}} onClick={()=>this.selectTransform('geometry')} title='erase part'><img src={rubber} style={{height: 25, width: 25}} alt='erase'/></button>
							<button style={{cursor: 'pointer'}} onClick={()=>this.selectTransform('basement')} title='create basement'><img src={socle} style={{height: 25, width: 25}} alt='basement'/></button>
							
							<button style={{cursor: 'pointer'}} onClick={this.fixModel} title='fix geometry'><img src={fix} style={{height: 25, width: 25}} alt='clean'/></button>
							<button style={{cursor: 'pointer'}} title='reset geometry' onClick={this.resetMesh} ><img src={refresh} style={{height: 25, width: 25}} alt='delete'/></button>
						</div>
						}
						
						<Transition mounted={this.state.transformMode==='geometry'} transition='leftSlide'>
							
								
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 5}}>
								<img src={socleHeight} style={{height: 20}} alt='Y'/>
								<input type='range' min={this.state.minY} max={this.state.maxY} value={this.state.valueY} onChange={e=>this.updateSelectionBox({y: e.target.value})}/>								
							</div>							

							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 5}}>
								<img src={socleWidth} style={{height: 20}} alt='Z'/>
								<input type='range' min={this.state.minZ} max={this.state.maxZ} value={this.state.valueZ} onChange={e=>this.updateSelectionBox({z: e.target.value})}/>								
							</div>

							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: 10}}>
								<button style={{cursor: 'pointer'}} title='select all' onClick={this.selectAll} ><img src={selectAll} style={{height: 25, width: 25}} alt='select all'/></button>
								<button style={{cursor: 'pointer'}} title='deselect' onClick={this.deselect} ><img src={deselect} style={{height: 25, width: 25}} alt='deselect'/></button>
								<button style={{cursor: 'pointer'}} title='clean model' onClick={this.cleanModel} ><img src={rubber} style={{height: 25, width: 25}} alt='clean'/></button>
							</div>

							<div style={{fontSize: 10, marginTop: 10, fontWeight: 'bold'}}>
								<div>Ctrl + left click : lasso select</div>
								<div>Ctrl + right click : lasso unselect</div>
							</div>

						</Transition>	

						<Transition mounted={this.state.transformMode==='basement'} transition='leftSlide'>
							

							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: 10}}>
								
								<div>
									<div style={{fontWeight: 'bold'}}>height</div>
									<input type='number' min={1} max={50} value={this.state.height} onChange={this.changeHeight}/>
								</div> 

								{/* <div>
									<div style={{fontWeight: 'bold'}}>thickness</div>								 
									<input type='number' min={1} max={10} value={this.state.thickness} onChange={this.changeThickness}/>
								</div> */}

							</div>

							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
								<button style={{cursor: 'pointer', margin: 10}} title='create basement' onClick={this.createBasement} ><img src={socle} style={{height: 25, width: 25}} alt='basement'/></button>
								
								{( (this.state.basement&&this.state.model==='maxillar') || (this.state.lowerBasement&&this.state.model==='mandibular') )&&
								<button style={{cursor: 'pointer', margin: 10}} title='delete basement' onClick={this.deleteBasement} ><img src={trashcan} style={{height: 25, width: 25}} alt='delete'/></button>}
							</div>

						</Transition>

					</Cardtridge>

					<Cardtridge>

						<input ref={this.inputLowerJawRef} style={{display: 'none'}} type='file' accept='.obj, .ply, .stl' value={this.state.lowerJawFile} onChange={this.readLowerJaw}/>
						
						<div 
							onClick={e => this.inputLowerJawRef.current.click()} style={{border: 'dashed 1px black', margin: 5, cursor: 'pointer', fontWeight: 'bold'}}
							onDrop={this.dropManager} 
							onDragOver={e=>e.preventDefault()}
						>add lower jaw</div>

						<Transition mounted={this.state.lowerJaw} transition='leftSlide' style={{display: 'flex', alignItems: 'cente', justifyContent: 'space-around'}}>
							<div><button onClick={this.delLowerJaw} title='delete'><img src={trashcan} style={{height: 25, width: 25}} alt='delete'/></button></div>
							<div><button onClick={this.toogleLowerJaw} title={this.state.lowerJawVisible?'hide lower jaw':'show lower jaw'}><img src={this.state.lowerJawVisible?visibleIcon:invisibleIcon} style={{height: 25, width: 25}} alt='delete'/></button></div>
						</Transition>

					</Cardtridge>

					<Cardtridge style={{fontWeight: 'bolder', fontSize: 12}}>
						<div>{this.state.vertex} vertices</div>
						{this.state.lowerJaw&&<div>{this.state.lowerVertex} vertices</div>}
					</Cardtridge>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<Button icon={undo} label='undo (ctrl+Z)' onClick={this.history.undo} disabled={this.state.disabledUndo}/>
						<Button icon={redo} label='redo (ctrl+Y)' onClick={this.history.redo} disabled={this.state.disabledRedo}/>
					</Cardtridge>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<img src={play} style={{height: 20, cursor: 'pointer', transform: 'rotateZ(180deg)'}} alt='previous step' title='previous step' onClick={this.props.cancel}/>
						<img src={changeFile} style={{height: 20, cursor: 'pointer'}} alt='change file' title='change file' onClick={this.props.changeFile}/>
						<img src={play} style={{height: 20, cursor: 'pointer'}} alt='next step' title='next step' onClick={this.saveModel}/>
					</Cardtridge>

				</Menu>

			</div>


		</>
	}

}


export default CaseConsumer(AdjustModel)