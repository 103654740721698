import './App.css'

import React from 'react'
import UserContextProvider from './context/UserContextProvider'

import Activation from './components/Activation'
import ForgotPW from './components/ForgotPW'
import isMobile from './functions/isMobile'
import Desktop from './components/Desktop'
import Smart from './components/Smart'

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


class App extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			updated: false
		}
	}

	componentDidMount = () => {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://cra.link/PWA
		serviceWorkerRegistration.register({
			onUpdate: () => this.setState({updated: true})
		})
	}

	hideUpdate = () => {
		this.setState({updated: false})
	}


	render = () => 

		

		    <div className="App">
		    	{window.location.pathname==='/activation' ?
		    		<Activation socketEvent='activateAccount'/> :

		    	window.location.pathname==='/updatemail' ?
		    		<Activation socketEvent='activateMail'/> :

		    	window.location.pathname==='/forgotPW' ?
		    		<ForgotPW/> :

		    	<UserContextProvider>	
		    		<>
		    			{this.state.updated&&<Updated hide={this.hideUpdate}/>}
				   		{isMobile() ? 
				   		<Smart/> :
				   		<Desktop/>}
					</>
				</UserContextProvider>
		    	}

		    </div>
		

}


const Updated = props => 
	<div style={{background: '#ff6600', position: 'absolute', left: 0, right: 0, zIndex: 1000, color: 'white', fontWeight: 'bold', padding: 5, bottom: 0, textAlign: 'left'}}>
		cadsmile3D become better. Restart your navigator to update it.
		<div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 5, color: 'white', cursor: 'pointer'}} onClick={props.hide}>&#10006;</div>
	</div>


export default App
