import EXIF from 'exif-js'


const getExif = image => 

	new Promise(resolve => {

		EXIF.getData(image, () => {
			const allMetaData = EXIF.getAllTags(image)
			/*console.log(allMetaData.FocalLengthIn35mmFilm) */
			//get in 35 mm if doesnt exist : check regex canon : 1.6
			//(Resolution in pixels/Focal plane resolution in dpi) X 25.4(mm/in)=size in mm
			resolve(allMetaData)
		})
	})



export default getExif