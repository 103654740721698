/** @jsxImportSource @emotion/react */

import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'
import AdjustImage from './AdjustImage'
import Matching2D from './Matching2D'
//import Transition from './Transition'
import Wall from './Wall'
import Check from './Check'
import hashNav from '../functions/hashNav'
/* import {writeSearch} from '../functions/crypto' */
/* import Stream from 'socket.io-stream' */
import socket from '../functions/socket'
//import SocketStream from '../functions/SocketStream'
import ajax from '../functions/ajaxx'

import drop from '../icons/drop2D.svg'
import dropOver from '../icons/dropover.svg'

import TutoAccess from './TutoAccess'


class AddImageFile extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			src: props.caseContext[props.fileName].file,
			additionalSrc: props.caseContext[props.additionalFileName].file,
			lastAdditionalSrc: props.caseContext[props.additionalFileName].file,
			additionalTransform: props.caseContext[props.additionalFileName].transform,
			drag: false
		}

		this.inputRef = React.createRef()
		this.inputAdditionalRef = React.createRef()
	}


	show = () => {
		hashNav({page: 'adjustImage'})
	}

	hide = () => {
		hashNav({page: 'summary'})

	}

	handleChange = e => {
		const file = e.target.files[0]
		this.readFile(file)
	}

	handleChangeAdditional = e => {
		const file = e.target.files[0]
		this.readAdditionalFile(file)
	}

	handleDrop = e => {
		e.preventDefault()
		this.readFile(e.dataTransfer.items[0].getAsFile())
	}

	addFile = e => {
		e.preventDefault()
		this.inputRef.current.click()
	}

	addAdditionalFile = e => {
		e.preventDefault()
		this.inputAdditionalRef.current.click()
	}

	checkExtension = file => {

		if (!file) return null

		const fileArray = file.name.split('.')
		const extension = fileArray[fileArray.length-1]
		const testExt = /jpg|jpeg|png|bmp/i.test(extension)


		if (!testExt) {
			this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'you must use .JPG, .JPEG, .PNG or .BMP file.'})
			return null
		}
		else return file

	}

	readFile = file => {
		
		this.setState({drag: false})

		file = this.checkExtension(file)

		const reader  = new FileReader()

		reader.onload = e => {
			const src = reader.result
			this.setState({src}, () => this.show())
		}

		if (file) 
			reader.readAsDataURL(file)
	}

	readAdditionalFile = file => {

		file = this.checkExtension(file)

		const reader  = new FileReader()

		reader.onload = e => {
			const src = reader.result
			this.setState({additionalSrc: src}, () => {

				hashNav({page: 'adjustAdditionalImage'})


			})
		}

		if (file) 
			reader.readAsDataURL(file)
	}

	changeFile = e => {
		e.preventDefault()
		this.setState({src: this.props.caseContext[this.props.fileName].file})
		this.show()
	}


	cancel = () => {
		window.history.back()
		this.inputRef.current.value = ''
	}

	cancelAdditional = () => {
		
		this.inputAdditionalRef.current.value = ''
		this.setState({additionalSrc: ''})		
	}

	goToMatch2D = () => {
		hashNav({page: 'matching2D'})
	}

	goToAdjust = () => {
		hashNav({page: 'adjustImage'})
	}


	validAdditional = ({image}) => {
		this.inputAdditionalRef.current.value = ''
		this.setState({additionalSrc: image, lastAdditionalSrc: image, additionalTransform: null})
		this.goToMatch2D()
	}

	saveAdditionalTransform = t => {
		this.setState({additionalTransform: t})
		this.goToAdjust()
	}

	save = async  state => {
		this.props.caseContext.setState({loading: true, loadingMessage: 'upload image'})


		// clean additional file
		const delFile = (caseId, file, priv) => {

			return new Promise(resolve => {
				socket.emit('deleteFile', caseId, file, priv, response => {

					resolve(response)
				})
			})
		}
		const deleteAdditionnal = delFile(this.props.caseContext.id, this.props.additionalFileName+'.jpg', true)

		const filesArray = [
			{name: this.props.fileName+'.jpg', data: state.image}, 
			{name: this.props.fileName+'_thumbnail.jpg', data: state.thumbnail}
		]

		if (this.state.additionalSrc)
			filesArray.push({
				name: this.props.additionalFileName+'.jpg', data: this.state.additionalSrc
			})



	//save image & thumbnail file on server

		ajax({
			url: '/upload',
			data: {
				priv: true,

				filesArray,

				caseId: this.props.caseContext.id,
				folder: '', 
				root: 'casesFiles'
			},
			onProgress: percent => this.props.caseContext.setState({progress: percent}),
			
			onComplete: () => {

				const saveExif = () =>
				//save exif on server 
					new Promise(resolve => {
						const exif = Object.keys(state.exif).length === 0?this.props.caseContext[this.props.fileName].exif:state.exif
						socket.emit('saveData', this.props.caseContext.id, {fileName: this.props.fileName, object: {exif}}, () => resolve(exif))
					})

				const saveAdditional = () =>
				//save additional transform
					new Promise(resolve => {
						const transform = this.state.additionalTransform
						socket.emit('saveData', this.props.caseContext.id, {fileName: this.props.additionalFileName, object: {transform}}, () => resolve(transform))
					})
				

				Promise.all([saveExif(), saveAdditional(), deleteAdditionnal]).then(([exif, transform]) => {

				//save in global state
					this.props.caseContext.setState({
						loading: false, 
						loadingMessage: '',
						progress: false,
						[this.props.fileName]: {
							...this.props.caseContext[this.props.fileName], 
							file: state.image,
							exif,
							thumbnail: state.thumbnail,
							landmarks: '',
							score: ''
						},
						[this.props.additionalFileName]: {
							file: this.state.additionalSrc,
							transform
						}
					}, () => hashNav({page: 'landmarks'} ))
				})
			}
		})
	}


	render = () => {

		const image = this.props.caseContext[this.props.fileName].file
		const drag = this.state.drag
		const creator = this.props.caseContext.creator.id === this.props.userContext.id || this.props.userContext.admin || this.props.userContext.designer

		return <>
			{this.props.caseContext.page==='summary'&&
			
				<div style={{background: 'rgba(255, 255, 255, 0.5)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: creator?'pointer':'not-allowed'}}
					title='photo of face'>
				
					<img src={drag?dropOver:image?image:drop} alt={this.props.fileName}
						css={{opacity: creator?1:0.6, height: '95%', width: '95%', objectFit: 'contain', '&:hover': image&&creator?{height: '100%', width: '100%' }:{}, transition: 'all 0.5s'}} 
						onContextMenu={this.addFile}
						onClick={!creator?null:image?this.changeFile:this.addFile}
						onDrop={creator?this.handleDrop:null} 
						onDragOver={e=>e.preventDefault()}
						onDragEnter={!creator?null:()=>this.setState({drag: true})} 
						onDragLeave={!creator?null:()=>this.setState({drag: false})} 
						draggable={false}
					/> 
					{image&&creator&&<TutoFile/>}
					{!creator&&<TutoAccess/>}

					<Check checked={image}/>

				</div>}

			<input ref={this.inputRef} style={{display: 'none'}} type='file' accept='image/png, image/jpeg, image/bmp' onChange={this.handleChange}/>
			<input ref={this.inputAdditionalRef} style={{display: 'none'}} type='file' accept='image/png, image/jpeg, image/bmp' onChange={this.handleChangeAdditional}/>

			{this.state.src&&this.props.caseContext.page==='adjustImage'&&
				<AdjustImage 
					im={this.state.src} 
					additionalIm={this.state.additionalSrc} 
					additionalTransform={this.state.additionalTransform} 
					additionalImage={true} 
					changeFile={this.addFile} 
					changeAdditionalFile={this.addAdditionalFile} 
					readAdditionalFile={this.readAdditionalFile} 
					goToMatch2D={this.goToMatch2D}
					cancelAdditional={this.cancelAdditional} 
					cancel={this.cancel} 
					save={this.save}
				/> 
			}

			{this.state.src&&this.state.additionalSrc&&this.props.caseContext.page==='adjustAdditionalImage'&&
				<AdjustImage 
					im={this.state.additionalSrc} 
					changeFile={this.addAdditionalFile} 
					cancel={this.cancelAdditional} 
					save={this.validAdditional}
				/>
			}
			
			{this.state.src&&this.state.additionalSrc&&this.props.caseContext.page==='matching2D'&&
				<Matching2D 
					facePhoto={this.state.src} 
					buccalPhoto={this.state.additionalSrc} 
					buccalTransform={this.state.additionalTransform} 
					cancel={this.goToAdjust} 
					save={this.saveAdditionalTransform}
				/>
			}

			{(!this.state.src&&this.props.caseContext.page==='adjustImage')&&<Wall callback={this.cancel}/>}
			{(!this.state.additionalSrc&&this.props.caseContext.page==='adjustAdditionalImage')&&<Wall callback={this.cancel}/>}
			{((!this.state.src || !this.state.additionalSrc)&&this.props.caseContext.page==='matching2D')&&<Wall callback={this.cancel}/>}
		</>
	}

} 




const TutoFile = props =>
	<>
		<div style={{
			display: 'flex', 
			alignItems: 'center', 
			justifyContent: 'space-between', 
			position: 'absolute', 
			bottom: '5%', right: '2.5%', left: '2.5%', 
			fontWeight: 'bold', 
			color: 'white', 
			fontSize: 10, 
			pointerEvents: 'none', 
			background: 'rgba(0, 0, 0, 0.4)'}}
		>
			<div>left click : view & modify image</div>
			<div>right click : change file</div>
		</div>
	</>




export default UserConsumer(CaseConsumer(AddImageFile))
