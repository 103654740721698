const TutoDesign = props =>
	
	<div style={{position: 'absolute', bottom: 3, left: 3, fontWeight: 'bold', fontSize: 10, textAlign: 'left', pointerEvents: 'none'}}>
		<div>left click : select tooth</div>
		<div>right click : select jaw</div>
		<div>double right click : select all</div>
		<div>click out : unselect</div>
	</div>


export default TutoDesign