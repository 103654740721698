import React from 'react'

const d = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
const m = ['junary', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
const mNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']


const formatDate = date =>
{
	const addZero = int =>
	{
		if (int < 10)
			return '0'+int
		else 
			return int
	}

	const dateObject = new Date(date)

	const dayw0 = dateObject.getDate()
	const day = addZero(dateObject.getDate())
	const dayName = d[dateObject.getDay()]
	const month = m[dateObject.getMonth()]
	const monthNumber = mNumber[dateObject.getMonth()]
	const year = dateObject.getFullYear()
	const hour = addZero(dateObject.getHours())
	const minute = addZero(dateObject.getMinutes())
	const second = addZero(dateObject.getSeconds())
	const unix = Math.round(dateObject.getTime()/1000)

	return {
		object: dateObject,
		unix: unix,
		frenchFormat: `${day}/${monthNumber}/${year}`,
		englishFormat: `${year}-${monthNumber}-${day}`,
		text: `${dayName}, ${month} ${dayw0}${dayw0===1||dayw0===21||dayw0===31?'st':dayw0===2||dayw0===22?'nd':dayw0===3||dayw0===23?'rd':'th'}, ${year}`,
		time: `${hour}:${minute}:${second}`
	}
}

let accentInsensitive = regex =>
	regex.replace(/[eéèêë]/ig, '[eéèêë]').replace(/[aàâä]/ig, '[aàâä]').replace(/[iîï]/ig, '[iîï]').replace(/[oôö]/ig, '[oôö]').replace(/[uûüù]/ig, '[uûüù]').replace(/[cç]/ig, '[cç]')

let showRegex = (string, reg, style)=>
{
	let regex = accentInsensitive(reg)
	let match = string.match(new RegExp(regex, 'ig'))
	let split = string.split(new RegExp(regex, 'ig'))

	let html = 
		<span>
		{
			split.map((array, index)=>
				<span key={index}>
					{array}
					{index!==split.length-1 && <span style={style}>{match[index]}</span>}
				</span>

				)
		}
		</span>

		return html
}


let colorToHex = color=>
{
	let hex = Number(color).toString(16)
   	if (hex.length < 2)
     	hex = `0${hex}`

	return hex
}

let hexToRgb = hex=>
{
	let r = parseInt(hex[1]+hex[2], 16)
	let g = parseInt(hex[3]+hex[4], 16)
	let b = parseInt(hex[5]+hex[6], 16)

	return [r, g, b]
}

let rgbToHex = rgb=> `#${colorToHex(rgb[0])}${colorToHex(rgb[1])}${colorToHex(rgb[2])}`




export { formatDate, showRegex, colorToHex, accentInsensitive, hexToRgb, rgbToHex }