
const mirrorGeometry = geometry => {
	geometry.scale(-1, 1, 1)
	const index = geometry.getIndex()
	index.array.reverse()
	index.needsUpdate = true
	geometry.computeVertexNormals()
    geometry.normalizeNormals()
    geometry.computeBoundingBox()

    return geometry
}

export default mirrorGeometry