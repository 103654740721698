/** @jsxImportSource @emotion/react */

import {Matrix4} from 'three'
import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'

import AdjustModel from './AdjustModel'
import hashNav from '../functions/hashNav'
/* import {writeSearch} from '../functions/crypto' */

import Wall from './Wall'
import Check from './Check'


import socket from '../functions/socket'
//import SocketStream from '../functions/SocketStream'
import ajax from '../functions/ajaxx'

import drop from '../icons/drop3D.svg'
import dropOver from '../icons/dropover.svg'
import TutoAccess from './TutoAccess'


class AddModelFile extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			//show: false,
			src: this.props.caseContext[this.props.fileName].file,
			drag: false,
			transform: this.props.caseContext.transform,
			extension: 'ply'
		}

		this.inputRef = React.createRef()

	}

	show = () => {
		hashNav({page: 'adjustModel'})
	}

	hide = () => {
		hashNav({page: 'summary'})
	}

	handleChange = e => {
		const file = e.target.files[0]
		this.readFile(file)
	}

	handleDrop = e => {
		e.preventDefault()
		this.readFile(e.dataTransfer.items[0].getAsFile())
		this.setState({drag: false})
	}

	addFile = e => {
		e.preventDefault()
		this.inputRef.current.click()
	}

	readFile = file => {
		this.setState({drag: false})

		if (!file) return
	
		const fileArray = file.name.split('.')
		const extension = fileArray[fileArray.length-1]
		const testExt = /ply|stl|obj/i.test(extension)

		if (!testExt) {
			this.props.caseContext.setState({popup: true, popupError: true, popupMessage: 'you must use .PLY, .STL or .OBJ file.'})
			return
		}


		const reader  = new FileReader()

		reader.onload = e => {
			const src = reader.result
			const blob = new Blob([src], { type: 'application/octet-stream'})
			//console.log(blob)
			this.setState({src: URL.createObjectURL(blob) , extension, transform: new Matrix4().toArray()})
			this.inputRef.current.value = ''
			this.show()
			//this.props.caseContext.setState({showMenu: false})
		}

		if (file) 
			reader.readAsArrayBuffer(file)
	}


	changeFile = e => {
		e.preventDefault()
		this.setState({src: this.props.caseContext[this.props.fileName].file, extension: 'ply'})
		this.show()
		//this.props.caseContext.setState({showMenu: false})
	}

	cancel = () => {

		/* this.setState({src: null}) */
		this.inputRef.current.value = ''
		this.setState({transform: this.props.caseContext.transform})
		window.history.back()

		//this.props.caseContext.setState({showMenu: true})	
		//this.hide()	

	}

	save = async state => {

		this.props.caseContext.setState({loading: true, loadingMessage: 'upload maxillar model'})

		const filesArray = [
				{name: this.props.fileName+'.ply', data: state.model},
				{name: this.props.fileName+'_thumbnail.jpg', data: state.thumbnail}
			]

		if (state.basement)
			filesArray.push({name: this.props.fileName+'_basement.ply', data: state.basement})
		else
			this.props.caseContext.setState({modelMaxillar: {...this.props.caseContext.modelMaxillar,  basement: ''}})

		if (state.lowerMesh)
			filesArray.push({name: 'modelMandibular.ply', data: state.lowerMesh})
		else
			this.props.caseContext.setState({modelMandibular: {file: ''}})

		if (state.lowerBasement)
			filesArray.push({name: 'modelMandibular_basement.ply', data: state.lowerBasement})
		else
			this.props.caseContext.setState({modelMandibular: {...this.props.caseContext.modelMandibular,  basement: ''}})



		// clean basement file if update model file
		const delFile = (caseId, file, priv) => {

			return new Promise(resolve => {
				socket.emit('deleteFile', caseId, file, priv, response => {

					resolve(response)
				})
			})
		}
		await delFile(this.props.caseContext.id, this.props.fileName+'_basement.ply', true)
		await delFile(this.props.caseContext.id, 'modelMandibular.ply', true)
		await delFile(this.props.caseContext.id, 'modelMandibular_basement.ply', true)


		const saveTransform = () =>
	//save transform in database 
			new Promise(resolve => {
				const transform = state.transform
				socket.emit('saveData', this.props.caseContext.id, {object: {transform}}, () => resolve(transform))
			})	

	//save model files on server
		const saveModels = () => 
			new Promise(resolve => {
				ajax({
					url: '/upload',
					data: {
						priv: true,

						filesArray,

						caseId: this.props.caseContext.id,
						folder: '', 
						root: 'casesFiles'
					},
					onProgress: percent => this.props.caseContext.setState({progress: percent}),
					
					onComplete: () => {
						resolve(filesArray)
					}
				})
			})


			Promise.all([saveTransform(), saveModels()]).then(([transform, filesArray]) => {
			//save in global state
				this.props.caseContext.setState({
					loading: false, 
					loadingMessage: '',
					progress: false,
					transform,
					[this.props.fileName]: {
						...this.props.caseContext[this.props.fileName], 
						file: state.model,
						basement: state.basement??'',
						thumbnail: state.thumbnail
					},
					modelMandibular: {
						file: state.lowerMesh??'',
						basement: state.lowerBasement??''
					}
				}, () =>  {
					this.setState({transform: this.props.caseContext.transform})
					hashNav({page: 'summary'})
				})

			})
		}
	


	render = () => {
		const image = this.props.caseContext[this.props.fileName].thumbnail
		const model = this.props.caseContext[this.props.fileName].file
		const drag = this.state.drag
		const creator = this.props.caseContext.creator.id === this.props.userContext.id || this.props.userContext.admin || this.props.userContext.designer


		return <>
			{this.props.caseContext.page==='summary' &&

				<div style={{background: 'rgba(255, 255, 255, 0.5)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: creator?'pointer':'not-allowed'}}
					title='3D dental impression'>
					
					<img src={drag?dropOver:image?image:drop} alt={this.props.fileName}
						css={{opacity: creator?1:0.6, height: '95%', width: '95%', objectFit: drag?'contain':image?'cover':'contain', '&:hover': image&&creator?{height: '100%', width: '100%' }:{}, transition: 'all 0.5s'}} 
						onContextMenu={this.addFile}
						onClick={!creator?null:image?this.changeFile:this.addFile}							
						onDrop={creator?this.handleDrop:null} 
						onDragOver={e=>e.preventDefault()}
						onDragEnter={!creator?null:()=>this.setState({drag: true})} 
						onDragLeave={!creator?null:()=>this.setState({drag: false})} 
						draggable={false}
						/> 
						{image&&creator&&<TutoFile/>}
						{!creator&&<TutoAccess/>}

						<Check checked={model}/>

				</div>}

			<input ref={this.inputRef} style={{display: 'none'}} type='file' accept='.obj, .ply, .stl' onChange={this.handleChange}/>
			{this.state.src&&this.props.caseContext.page==='adjustModel'&&<AdjustModel model={{url:this.state.src, extension: this.state.extension, transform: this.state.transform}} changeFile={this.addFile} cancel={this.cancel} save={this.save}/>}

			{(!this.state.src&&this.props.caseContext.page==='adjustModel')&&<Wall callback={this.cancel}/>}

		</>
	}
}




const TutoFile = props =>
	<>
		<div style={{
			display: 'flex', 
			alignItems: 'center', 
			justifyContent: 'space-between', 
			position: 'absolute', 
			bottom: '5%', right: '2.5%', left: '2.5%', 
			fontWeight: 'bold', 
			color: 'white', 
			fontSize: 10, 
			pointerEvents: 'none', 
			background: 'rgba(0, 0, 0, 0.4)'}}
		>
			<div>left click : view & modify model</div>
			<div>right click : change file</div>

		</div>
	</>




export default UserConsumer(CaseConsumer(AddModelFile)) 
