import * as THREE from 'three'
import { OrthographicCamera } from './threeCustomClass'

import { loadTexture, drawMarker, unmountThree } from './threeCustomFunction'


const bgCanvas = async (photo, buccal=null) => {

//create bg scene
	const landmarks = photo.landmarks??{}
	const texturePlane = await loadTexture(photo.file)


	let topLeft
	let bottomRight

	if (landmarks.crop) {
		topLeft = landmarks.crop.topLeft
		bottomRight = landmarks.crop.bottomRight
	}
	else {
		topLeft = [-texturePlane.image.width/2, texturePlane.image.height/2, 1]
		bottomRight = [texturePlane.image.width/2, -texturePlane.image.height/2, 1]
	}
	


	const scene = new THREE.Scene()

	const camera = new OrthographicCamera( topLeft[0], bottomRight[0], topLeft[1], bottomRight[1], 1, 1000 )
	camera.position.z = 100
	scene.add( camera )


	const createPlane = texture => {
		if (!texture) return null

		const material = new THREE.MeshBasicMaterial( { map: texture, color: 0xffffff} )
		const planeGeo = new THREE.PlaneGeometry( texture.image.width, texture.image.height, 1, 1 )
		const plane = new THREE.Mesh( planeGeo, material )
		return plane
	}

	const plane = createPlane(texturePlane)
	scene.add( plane )

	let planeBuccal
	if (buccal.file && buccal.transform) {
		planeBuccal = createPlane( await loadTexture(buccal.file))
		planeBuccal.material.transparent = true
		planeBuccal.material.opacity = 0
		planeBuccal.position.copy(new THREE.Vector3().fromArray(buccal.transform.position) )
		planeBuccal.quaternion.copy(new THREE.Quaternion().fromArray(buccal.transform.quaternion) )
		planeBuccal.scale.copy(new THREE.Vector3().fromArray(buccal.transform.scale) )
		plane.add(planeBuccal)
	}

//set landmarks
	const landmarksObject = {}

	if (landmarks.leftIris) 
		landmarksObject.leftIris = drawMarker(landmarks.leftIris[0], landmarks.leftIris[1], landmarks.leftIris[2])
		
	if (landmarks.rightIris) 
		landmarksObject.rightIris = drawMarker(landmarks.rightIris[0], landmarks.rightIris[1], landmarks.rightIris[2])	
		
	if (landmarks.leftNose) 
		landmarksObject.leftNose = drawMarker(landmarks.leftNose[0], landmarks.leftNose[1], landmarks.leftNose[2])
		
	if (landmarks.rightNose) 
		landmarksObject.rightNose = drawMarker(landmarks.rightNose[0], landmarks.rightNose[1], landmarks.rightNose[2])
		
	if (landmarks.middle) 
		landmarksObject.middle = drawMarker(landmarks.middle[0], landmarks.middle[1], landmarks.middle[2])
		
	if (landmarks.lips) 
		landmarksObject.lips = landmarks.lips.map(mark=>drawMarker(mark[0], mark[1], mark[2]))
	

//horizontalize
	if (landmarks.leftIris && landmarks.rightIris) {
		const x = landmarks.leftIris[0] - landmarks.rightIris[0]
		const y = landmarks.leftIris[1] - landmarks.rightIris[1]
		const angle = Math.atan2(y, x)
		plane.rotation.z = -angle
	}


//calc landmark coordinates for cropped and horizontalized image
	const applyOnLandmarks = (object,fn) => {

		Object.entries(object).forEach(([key, mesh])=> {
			if (key === 'lips')
				object.lips.forEach(mark=>fn(mark)) 
			else
				fn(mesh)
		})
	}


	applyOnLandmarks(landmarksObject, mark=> {
		plane.add(mark)
		scene.attach(mark)
		mark.position.x -= (topLeft[0] + bottomRight[0])/2
		mark.position.y -= (topLeft[1] + bottomRight[1])/2
		scene.remove(mark)
	})


	//set cropped canvas
		const renderer = new THREE.WebGLRenderer()
		renderer.setSize( (bottomRight[0]-topLeft[0]), (topLeft[1]-bottomRight[1]) )
		renderer.render(scene, camera)


	//change opacity of intraoral photo
		const setOpacity = opacity => {
			if (!planeBuccal)
				return 
			planeBuccal.material.opacity = opacity
			renderer.render(scene, camera)
		}

		const dispose = () => {
			unmountThree(scene, renderer)
		}


	//return canvas and landmarks
		return new Promise(resolve=>resolve(
			{
				canvas: renderer.domElement,
				renderer,
				landmarks: landmarksObject,
				setOpacity,
				dispose
			}
		))
	}


export default bgCanvas