const BurgerMenu = props => 
    <div>

        <div style={{
        position: 'relative', 
        width: 20,
        height: 20, 
        margin: 3, 
        transform: 'rotate3D(0 , 1 ,0 ,-20deg)',
        cursor: 'pointer',
        transition: 'color 0.5s, background-color 0.5s, transform 1s',
        }}>

            <div style={{
                transition: 'transform 0.5s, top 0.5s', 
                position: 'absolute', 
                top: props.active?'50%':0, 
                right: 0, 
                left: 0, 
                height: '15%', 
                backgroundColor: 'black', 
                'transform': `translateY(${props.active?'-50%':'0px'}) rotate(${props.active?'45deg':'0deg'})`
                }}></div>

            <div style={{
                transition: 'transform 0.5s, opacity 0.5s', 
                opacity: props.active?0:1, 
                top: '50%', 
                position: 'absolute', 
                right: 0, 
                left: 0, 
                height: '15%', 
                backgroundColor: 'black', 
                transform: `translateY(-50%) `
                }}></div>

            <div style={{
                transition: 'transform 0.5s, bottom 0.5s', 
                position: 'absolute', 
                bottom: props.active?'50%':0, 
                right: 0, 
                left: 0, 
                height: '15%', 
                backgroundColor: 'black', 
                'transform': `translateY(${props.active?'50%':'0px'}) rotate(${props.active?'-45deg':'0deg'})`
                }}></div>
                
        </div>
    </div>


BurgerMenu.defaultProps = {
    active: false
}


export default BurgerMenu