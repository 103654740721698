import React from 'react'
import Cardtridge from './Cardtridge'
import { formatDate } from '../functions/formatFunctions'


class ExportWindow extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			retracted: true,
			smile: true,
			anonymous: false,
			original: true,
			waxSTL: true,
			waxPLY: true,
			modelSTL: false,
			modelPLY: false,
			teethSTL: false,
			teethPLY: false,
			/* basement: true, */
			text: true,
			textBasement: `${props.caseContext.name} _ ${formatDate(new Date()).englishFormat}`
		}

	}

	componentDidMount = () => {
		if (localStorage.getItem('exportChoice')) {
			const defaultChoice = JSON.parse(localStorage.getItem('exportChoice'))
			this.setState(defaultChoice)
		}

		window.addEventListener('keydown', this.handleKeyDown)

	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown = e => {

		if (e.keyCode === 27)
			this.props.cancel()
	}

	handleChange = (e, state) => {

		const value = e.target.hasOwnProperty('checked')?e.target.checked:e.target.value

		this.setState({[state]: value}, () => {
			const selected = {...this.getObjectSelected()}
			delete selected.textBasement
			localStorage.setItem('exportChoice', JSON.stringify(selected))

		})

	}

	getObjectSelected = () => {
		return {
			retracted: this.state.retracted, 
			smile: this.state.smile, 
			anonymous: this.state.anonymous,
			original: this.state.original,
			waxSTL: this.state.waxSTL, 
			waxPLY: this.state.waxPLY, 
			modelSTL: this.state.modelSTL, 
			modelPLY: this.state.modelPLY, 
			teethSTL: this.state.teethSTL, 
			teethPLY: this.state.teethPLY,
			/* basement: this.state.basement, */
			text: this.state.text,
			textBasement: this.state.textBasement

		}
	}

	export = () => {
		this.props.export(this.getObjectSelected())
	}


	render = () => {
		return <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right:0, background: 'rgba(255, 255, 255, 0.8)', zIndex: 10}} onClick={this.props.cancel}>
			
			<Cardtridge style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', position: 'absolute', top: '20%', bottom: '20%', left: '20%', right: '20%', background: 'white', overflow: 'auto'}} onClick={e=>e.stopPropagation()}>

				<Cardtridge style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
					<div style={{flex: 3, fontWeight: 'bold', fontSize: 22}}>export photos</div>
					<div style={{flex: 1}}>original <input type='checkbox' checked={this.state.original}  onChange={e=>this.handleChange(e, 'original')}/></div>
					<div style={{flex: 1}}>retracted<input type='checkbox' checked={this.state.retracted} onChange={e=>this.handleChange(e, 'retracted')}/></div>
					<div style={{flex: 1}}>smile <input type='checkbox' checked={this.state.smile}  onChange={e=>this.handleChange(e, 'smile')}/></div>
				</Cardtridge>



				<Cardtridge style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
					<div style={{flex: 2, fontWeight: 'bold', fontSize: 22}}>export waxup</div>
					<div style={{flex: 1}}>STL <input type='checkbox' checked={this.state.waxSTL} onChange={e=>this.handleChange(e, 'waxSTL')}/></div>
					<div style={{flex: 1}}>PLY <input type='checkbox' checked={this.state.waxPLY} onChange={e=>this.handleChange(e, 'waxPLY')}/></div>
				</Cardtridge>

				<Cardtridge style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
					<div style={{flex: 2, fontWeight: 'bold', fontSize: 22}}>export model</div>
					<div style={{flex: 1}}>STL <input type='checkbox' checked={this.state.modelSTL} onChange={e=>this.handleChange(e, 'modelSTL')}/></div>
					<div style={{flex: 1}}>PLY <input type='checkbox' checked={this.state.modelPLY} onChange={e=>this.handleChange(e, 'modelPLY')}/></div>
				</Cardtridge>

				{this.props.caseContext.modelMaxillar.basement&&<Cardtridge style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
					{/* <div style={{flex: 2, fontWeight: 'bold', fontSize: 22}}>
						export basement<input type='checkbox' checked={this.state.basement} onChange={e=>this.handleChange(e, 'basement')}/>
					</div> */}
					<div style={{flex: 1}}>add text <input type='checkbox' checked={this.state.text} onChange={e=>this.handleChange(e, 'text')}/></div>
					<div style={{flex: 1}}><input value={this.state.textBasement} disabled={!this.state.text} onChange={e=>this.handleChange(e, 'textBasement')}/></div>
				</Cardtridge>}					


				<Cardtridge style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
					<div style={{flex: 2, fontWeight: 'bold', fontSize: 22}}>export teeth files</div>
					<div style={{flex: 1}}>STL <input type='checkbox' checked={this.state.teethSTL} onChange={e=>this.handleChange(e, 'teethSTL')}/></div>
					<div style={{flex: 1}}>PLY <input type='checkbox' checked={this.state.teethPLY} onChange={e=>this.handleChange(e, 'teethPLY')}/></div>
				</Cardtridge>



				<div>
					<button onClick={this.export}>export</button>
				</div>


				<div style={{position: 'absolute', top: 0, right: 0}}>anonymous <input type='checkbox' checked={this.state.anonymous}  onChange={e=>this.handleChange(e, 'anonymous')}/></div>
			</Cardtridge>

		</div>
	}

}


export default ExportWindow