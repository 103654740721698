import React from 'react'
import UserConsumer from '../context/UserContextConsumer'
import logo from '../images/cadsmile_logo.png'
import off from '../icons/off.svg'
import gears from '../icons/gears.svg'
import fullScreenOn from '../icons/full_screen_on.svg'
import fullScreenOff from '../icons/full_screen_off.svg'
import ChatNotification from './chatNotification'
import {readSearch} from '../functions/crypto'


const Header = props => {

	const [fullScreen, setFullScreen] = React.useState(0)
	const [chatNotification, setChatNotification] = React.useState(false)

	React.useEffect(() => {
		window.addEventListener('resize', changeFullScreen)
		window.addEventListener('hashchange',changePage)
	 	changePage()

		return ()=>	{
			window.removeEventListener('resize', changeFullScreen)
			window.removeEventListener('hashchange',changePage)
		}

	}, [])	

	const changeFullScreen = () => {
		setFullScreen(document.fullscreen)
	}	

	const enableFullScreen = () => {
		document.documentElement.requestFullscreen()
	}

	const exitFullScreen = () => {
		document.exitFullscreen()
	}

	const changePage = ()=>{
		let page = readSearch(window.location.hash.split('?')[1])
		setChatNotification(page.id && page.page!=='summary')
	}


	const goToProfil = () => {

		if (window.location.hash === '#profil')
			window.history.back()
		else
			window.location.hash = 'profil'
	}


	return <div style={{zIndex: 201, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'rgba(255, 255, 255, 0.8)', marginBottom: 10, height: 40}}>



		<div style={{display: 'flex', alignItems: 'center'}}>
			<img style={{width: 30}} src={logo} alt='logo'/> 

			{props.userContext.logged&&<>
				<div style={{fontWeight: 'bolder', fontSize: 16, marginLeft: 10}}>{props.userContext.username}</div>
				<img alt='user profil' title='user profil' style={{width: 20, marginLeft: 10, cursor: 'pointer'}} src={gears} onClick={goToProfil}/> 
				<img alt='log out' title='log out' style={{width: 20, marginLeft: 10, cursor: 'pointer'}} src={off} onClick={props.userContext.logOut}/> 
				{chatNotification && <ChatNotification style={{width: 20, marginLeft: 10, cursor: 'pointer'}}/>}
			</>}
		</div>


		<img 
			title={!fullScreen?'fullscreen':'exit fullscreen'} 
			alt='fullscreen'
			style={{width: 20, marginRight: 10, cursor: 'pointer'}} 
			src={!fullScreen?fullScreenOff:fullScreenOn} 
			onClick={!fullScreen?enableFullScreen:exitFullScreen}/> 


	</div>	   		

}


export default UserConsumer(Header)