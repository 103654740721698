import React from 'react'
import socket from '../functions/socket'
import CaseSummary from './CaseSummary'
//import SocketStream from '../functions/SocketStream'
import ajax from '../functions/ajaxx'

import UserConsumer from '../context/UserContextConsumer'
import CaseConsumer from '../context/CaseContextConsumer'


//import ex from '../context/caseForExample'


class Case extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			loaded: false,
		}
	}

	componentDidMount = () => {

this.props.caseContext.setState({id: this.props.id})

//example case
//this.props.caseContext.setState(ex, this.setState({loaded: true}))

this.updateCase()
		this.updatePage(this.props.page)

	}

	componentDidUpdate = prevProps => {

		if (this.props.id !== prevProps.id)
			this.updateCase()

		if (this.props.page !== prevProps.page)
			this.updatePage(this.props.page)
	}

	updatePage = page => {
		this.props.caseContext.setState({page: this.props.page})
	}

	resetCase = () => {
		this.props.caseContext.setState({
	//case data
			id: '',
			name: '',
			page: '',


			photoFace: {
				file: '',
				exif: '',
				landmarks: '',
				camera: '',
				cadscore: ''
			},

			photoBuccal: {
				file: '',
				transform: ''
			},

			modelMaxillar: {
				file: '',
				thumbnail: '',
				basement: ''
			},

			modelMandibular: {
				file: '',
				basement: ''
			},

			transform : [ 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1 ]
		})
	}

	updateCase = () => {
	//prevent reload case if it s in cache

		if (this.props.caseContext.id !== this.props.id) {


		//flush case data
			this.resetCase()

		//create url if blob exist
			//const url = blob => blob?URL.createObjectURL(blob):''
			
			this.props.caseContext.setState({loading: true, loadingMessage: 'download case data'})


			const fetchCase = () =>
				new Promise((resolve, reject) => {
					socket.emit('fetchcase', this.props.id, result => {
						//console.log(result)

					if (result.error) 
						this.props.userContext.setState({popup: true, popupMessage: result.error.message, popupError: true, popupCallback: ()=>window.location.hash=''}, reject())
					else
						this.props.caseContext.setState({

							id: result.id, 
							name: result.name, 
							creator: result.creator,
							photoFace: {
								...this.props.caseContext.photoFace, 
								exif: result.photoFace.exif??{}, 
								landmarks: result.photoFace.landmarks??'',
								camera: {
									...this.props.caseContext.photoFace.camera,
									...result.photoFace.camera
								},
								cadscore: result.photoFace.cadscore??''
							},
							photoBuccal: {
								...this.props.caseContext.photoBuccal,
								transform: result.photoBuccal.transform
							},

							transform: result.transform, 

							savedList: result.savedList.map(({id, date, name, description, thumbnail, creator, globalQuaternion, VDO}) => {

								const thumbURL = thumbnail?URL.createObjectURL(new Blob([thumbnail])):null
								return {
									id, date, name, description, thumbnail: thumbURL, creator
								}
							}),
							userAccess: result.userAccess

						}, resolve())
					})	
				})



			const download = () => 

				new Promise(resolve => {

					ajax({
						url: '/download',
						data: {
							files: ['photoFace.jpg', 'photoBuccal.jpg', 'modelMaxillar.ply', 'modelMandibular.ply', 'modelMaxillar_basement.ply', 'modelMandibular_basement.ply', 'modelMaxillar_thumbnail.jpg', 'photoFace_matching_thumbnail.jpg'],
							caseId: this.props.id,
							folder: '', 
							root: 'casesFiles'
						},
						onProgress: percent => this.props.caseContext.setState({progress: percent}),
						onComplete: blobs => {

														this.props.caseContext.setState({
										photoFace: {
											...this.props.caseContext.photoFace,
											file: blobs.photoFace,

											camera: {
												...this.props.caseContext.photoFace.camera,
												thumbnail: blobs.photoFace_matching_thumbnail
											}
										},

										photoBuccal: {
											...this.props.caseContext.photoBuccal,
											file: blobs.photoBuccal
										},

										modelMaxillar: {
											...this.props.caseContext.modelMaxillar,
											file: blobs.modelMaxillar,
											thumbnail: blobs.modelMaxillar_thumbnail,
											basement: blobs.modelMaxillar_basement
										},

										modelMandibular: {
											...this.props.caseContext.modelMandibular,
											file: blobs.modelMandibular,
											basement: blobs.modelMandibular_basement
										},

										loading: false, loadingMessage: '', progress: false
									}, resolve())
								
						}

					})

				})




				/* new Promise(resolve => {
					SocketStream.download({
						files: ['photoFace.jpg', 'modelMaxillar.ply', 'modelMaxillar_basement.ply', 'modelMaxillar_thumbnail.jpg', 'photoFace_matching_thumbnail.jpg'],
						caseId: this.props.id,
						onProgress: percent => {
							this.props.caseContext.setState({progress: percent})
						},
						onDone: blobs => {
							this.props.caseContext.setState({
								photoFace : {
									...this.props.caseContext.photoFace,
									file: url(blobs.photoFace),

									camera: {
										...this.props.caseContext.photoFace.camera,
										thumbnail: url(blobs.photoFace_matching_thumbnail)
									}
								},

								modelMaxillar: {
									...this.props.caseContext.modelMaxillar,
									file: url(blobs.modelMaxillar),
									thumbnail: url(blobs.modelMaxillar_thumbnail),
									basement: url(blobs.modelMaxillar_basement)
								},

								loading: false, loadingMessage: '', progress: false
							}, resolve())
						}
					})
				}) */


			Promise.all([ fetchCase(), download() ]).then(() => this.setState({loaded: true}))
		}

		else {
			this.setState({loaded: true})
		}

	}

	render = () => <div style={{position: 'absolute', top: 50, bottom: 0, left: 0, right: 0}}> 
		{/* {this.props.caseContext.name+this.props.id} */} 

		{this.state.loaded&&<CaseSummary/>}

	</div>
}

export default UserConsumer( CaseConsumer(Case) ) 


