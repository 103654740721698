import * as THREE from 'three'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js'
import { PLYExporter } from 'three/examples/jsm/exporters/PLYExporter.js';

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { STLExporter  } from 'three/examples/jsm/exporters/STLExporter.js';

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { OrthographicCamera, PerspectiveCamera } from './threeCustomClass'
/* import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls' */
import { Controls } from '../functions/threeCustomClass'
import { mergeBufferGeometries  } from 'three/examples/jsm/utils/BufferGeometryUtils'

import helvetica from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'
import toIndexed from './toIndexed'


// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
THREE.Mesh.prototype.raycast = acceleratedRaycast


const loadTexture = image => 
	new Promise(resolve => new THREE.TextureLoader().load(image , texture => resolve(texture)))


const loadCubeTexture = urls =>
	new Promise(resolve => new THREE.CubeTextureLoader().load(urls, cubeTexture => resolve(cubeTexture)))

	
	
const exportPLY = (mesh, b64=true, color=true) => 

	
	new Promise(resolve => {
		const options = { binary: true }
		if (!color)
			options.excludeAttributes = 'color'
		
		const exporter = new PLYExporter()
		exporter.parse( mesh, buffer => {
			
			if (b64) {
				const base64 = 'data:application/octet-stream;base64,' + Buffer.from(buffer).toString('base64')
				resolve(base64)
			}
			else {
				const blob = new Blob( [ buffer ], { type: 'application/octet-stream' } )
				resolve(blob)
			}

			
		}, 
		options )
	})


const exportSTL = mesh => 
	new Promise(resolve => {
		const exporter = new STLExporter()
		const buffer = exporter.parse( mesh, { binary: true } )
		const blob = new Blob( [ buffer ], { type: 'application/octet-stream' } )
		resolve(blob)
	})

	


const exportJSON = obj => {
	const json = JSON.stringify(obj.toJSON())
	const blob = new Blob( [json], { type: 'application/json'})
	return blob
}



const drawMarker = (x, y, z, diameter=50, color='white')=> {

	const material =  new THREE.MeshBasicMaterial( { color: color, transparent: true, opacity: 0.8 } )
	const geometry = new THREE.CircleGeometry( diameter, 32 )
	const mark = new THREE.Mesh( geometry, material )

	mark.position.x = x
	mark.position.y = y
	mark.position.z = z

	return mark
}

const drawVoid = (x=0, y=0, z=0) => {
	const voidObject = new THREE.Object3D()
	voidObject.position.set(x, y, z)
	return voidObject
}


const drawBall = (x=0, y=0, z=0, diameter=50, color='white')=> {
	const material =  new THREE.MeshBasicMaterial( { color: color, transparent: true, opacity: 0.6/* , depthTest : false, depthWrite: false */ } )
	const geometry = new THREE.SphereGeometry( diameter, 32, 32 )
	const ball = new THREE.Mesh( geometry, material )

	ball.position.x = x
	ball.position.y = y
	ball.position.z = z

	return ball
}

const drawCube = (x=0, y=0, z=0, diameter=50, color='white')=> {
	const material =  new THREE.MeshBasicMaterial( { color: color, transparent: true, opacity: 0.6, depthTest : false, depthWrite: false } )
	const geometry = new THREE.BoxGeometry( diameter, diameter, diameter )
	const ball = new THREE.Mesh( geometry, material )

	ball.position.x = x
	ball.position.y = y
	ball.position.z = z

	return ball
}

const drawPoint = (x, y, z)=> {
	const point = new THREE.Points()

	point.position.x = x
	point.position.y = y
	point.position.z = z

	return point
}

const drawRect = (x, y, z, width, height, color='white') => {

	const material =  new THREE.MeshBasicMaterial( { color: color, transparent: true, opacity: 0.5, side: THREE.DoubleSide } )
	const geometry = new THREE.PlaneGeometry( width, height, 1, 1 )
	const rect = new THREE.Mesh( geometry, material )

	rect.position.x = x
	rect.position.y = y
	rect.position.z = z

	return rect
}


const drawLine = (points, material) => {
			const geometry = new THREE.BufferGeometry().setFromPoints( points )
			const line = new THREE.Line( geometry, material )

			if ( material.type === 'LineDashedMaterial' )
				line.computeLineDistances()

			return line
		}


const drawText = (text, size=5, color='black') => {

	const loader = new FontLoader()
	const font = loader.parse(helvetica)

	const shapes = font.generateShapes( text, size )
	const geometry = new THREE.ShapeGeometry( shapes )

	const material = new THREE.LineBasicMaterial( {
		color: color,
	} )

	geometry.center()
	const textMeshFront = new THREE.Mesh( geometry, material )
	const textMeshBack = textMeshFront.clone()
	textMeshBack.rotation.x = Math.PI

	const textMesh = new THREE.Group()
	textMesh.add(textMeshFront, textMeshBack)

	return textMesh

}


const calcMarkerRadius = (mesh, ratio=150) => {
	const bBox = new THREE.Box3().setFromObject(mesh, true)
	const sphere = new THREE.Sphere()
	bBox.getBoundingSphere(sphere)

	return sphere.radius/ratio
}


const loadModel = ({url, format='ply', onProgress=()=>null, onLoad=()=>null, onError=()=>null}) => {

	return new Promise(async (resolve, reject) => {

		if (/ply/i.test(format)) {
			const loader = new PLYLoader()
			loader.load(url, geometry => {
				resolve(toIndexed(geometry))
				onLoad()
			}, onProgress, onError )
		}

		else if (/stl/i.test(format)) {
			const loader = new STLLoader()
			loader.load(url, geometry => {
				resolve(toIndexed(geometry))
				onLoad()
			}, onProgress, onError)
		}

		else if (/obj/i.test(format)) {
			const loader = new OBJLoader()
			loader.load(url, group => {
				const geometries = group.children.map(mesh => mesh.geometry)
				const geometry = mergeBufferGeometries(geometries)
				resolve(toIndexed(geometry))
				onLoad()
			}, onProgress, onError)
		}

		else 
			reject('file must be .stl, .ply or .obj')
	})

}


const toNDC = (e, mouse, ref) => {

	const bounding = ref.getBoundingClientRect()

	mouse.x = (e.clientX - bounding.left) / bounding.width * 2 -1 
	mouse.y = -(e.clientY - bounding.top) / bounding.height * 2 +1 
	mouse.buttons = e.buttons

	
}


const resetTransform = mesh => {
	mesh.position.set(0, 0, 0)
	mesh.setRotationFromQuaternion(new THREE.Quaternion())
	mesh.scale.set(1, 1, 1)
}


const createModel = async (object) => {
	const modelGeometry = await loadModel({
		...object
	})

	if (!modelGeometry.hasAttribute('color')) {


		const length = modelGeometry.attributes.position.count
		const colorArray = []
		for (let i = 0; i < length; i++) {

			colorArray.push(169/255, 145/255, 94/255)
		}

		modelGeometry.setAttribute( 'color', new THREE.BufferAttribute( new Float32Array(colorArray) , 3 ) )

	}


	const modelMaterial = new THREE.MeshPhysicalMaterial( { 
		emissive: 0x888888,
		color: 0xffffff, 
		vertexColors:true,
		side: THREE.DoubleSide,
		clearcoat: 1, 
		clearcoatRoughness: 0.3
		//reflectivity: 1
	} )


	modelGeometry.computeBoundsTree()
	const mesh = new THREE.Mesh( modelGeometry, modelMaterial )

	return mesh
}





const init3DScene = ({ref, mesh}) => {

	const WIDTH = ref.current.getBoundingClientRect().width
	const HEIGHT = ref.current.getBoundingClientRect().height

//mouse picking
	const raycaster = new THREE.Raycaster()
	raycaster.firstHitOnly = true
	mesh.geometry.computeBoundsTree()

//3D viewport
	const scene = new THREE.Scene()
	const camera = new PerspectiveCamera( 75, WIDTH / HEIGHT, 1, 10000 )
	camera.position.z = 200

	const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
	renderer.setSize( WIDTH, HEIGHT )

//navigation
	const controls = new Controls( camera, renderer.domElement )
	controls.controlled = false
	controls.addEventListener('controlstart', () => {
		setTimeout(()=>controls.controlled = true, 300)
	})
	controls.addEventListener('controlend', () =>  {
		setTimeout(()=>controls.controlled = false, 300)
	})

	/* controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM
	controls.mouseButtons.middle = CameraControls.ACTION.ZOOM */

//light
	const light = new THREE.PointLight(  0xffffff , 0.5 )
	const rearLight = new THREE.PointLight(  0xffffff , 0.3 )
	light.position.copy(camera.position)
	rearLight.position.z = -100
	rearLight.position.y = -100

	const light2 = new THREE.HemisphereLightProbe(  0xffffff , 0x999999, 0.4 )
	const light3 = new THREE.AmbientLightProbe(0.4)


//add to scene
	scene.add(mesh, light, light2, light3, rearLight)
	scene.add(camera)

//append to dom
	ref.current.appendChild( renderer.domElement )


//responsive function
	const resize = () => {
		renderer.domElement.remove()
		const WIDTH = ref.current.getBoundingClientRect().width
		const HEIGHT = ref.current.getBoundingClientRect().height		
		renderer.setSize( WIDTH, HEIGHT )		
 		camera.aspect = WIDTH/HEIGHT
		camera.updateProjectionMatrix()
		ref.current.appendChild( renderer.domElement)
	}

//dispose scene
	const dispose = () => {
		unmountThree(scene, renderer)
		controls.dispose()

	}



	return { scene, camera, renderer, controls, mesh, light, raycaster, resize, dispose, selected: null}
}





const init2DScene = ({ref, photo}) => {

	const WIDTH = ref.current.getBoundingClientRect().width
	const HEIGHT = ref.current.getBoundingClientRect().height	

//mouse picking
	const raycaster = new THREE.Raycaster()
	raycaster.firstHitOnly = true

//2D viewport
	const bg = new THREE.CanvasTexture(photo.renderer.domElement)

	const scene = new THREE.Scene()
	const camera = new OrthographicCamera( WIDTH/- 2, WIDTH/2, HEIGHT/2, HEIGHT /-2, 1, 10000 )
	camera.position.z = 100

	const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
	renderer.setSize( WIDTH, HEIGHT )

	const controls = new Controls( camera, renderer.domElement )
	controls.mouseButtons.wheel = Controls.ACTION.ZOOM
	controls.mouseButtons.middle = Controls.ACTION.ZOOM
//prevent rotation
	controls.minPolarAngle = Math.PI/2
	controls.maxPolarAngle = Math.PI/2
	controls.minAzimuthAngle = 0
	controls.maxAzimuthAngle = 0

	controls.controlled = false
	
	controls.addEventListener('controlstart', () => {
		setTimeout(()=>controls.controlled = true, 100)
	})
	controls.addEventListener('controlend', () =>  {
		setTimeout(()=>controls.controlled = false, 100)
	})

//2D plane
	const planeMaterial = new THREE.MeshBasicMaterial( { map: bg, color: 0xffffff } )
	const planeGeometry = new THREE.PlaneGeometry( bg.image.width, bg.image.height, 1, 1 )
	const bgPlane = new THREE.Mesh( planeGeometry, planeMaterial )

//fit on lips. if lips not exist, fit on all image
	const landmarks = photo.landmarks

	let lips = new THREE.Group()

	if (landmarks.lips)
		landmarks.lips.forEach(mesh=>lips.add(mesh))
	else
		lips = bgPlane
		
//add to scene
	scene.add( bgPlane )


//append to dom
	ref.current.appendChild( renderer.domElement )


//responsive function
	const resize = () => {
		renderer.domElement.remove()
		const WIDTH = ref.current.getBoundingClientRect().width
		const HEIGHT = ref.current.getBoundingClientRect().height		
		renderer.setSize( WIDTH, HEIGHT )		
 		camera.responsive( WIDTH/HEIGHT )
		ref.current.appendChild( renderer.domElement)
	}

	//dispose scene
	const dispose = () => {
		unmountThree(scene, renderer)
		controls.dispose()
		photo.dispose()
	}


	return { photo, scene, camera, renderer, controls, bgPlane, bg, lips, landmarks, raycaster, resize, dispose, selected: null}
}





const setMarkers = ({sizeAttenuation=true, setState, canvas, mouse, mesh, otherMesh, markersArray, otherMarkersArray, markerSize, enableNavigation, history, callback, realTime, maxPoints=4}) => {
	
	const BASECOLOR = 'blue'
	const ACTIVECOLOR = 'green'
	const DELETECOLOR = 'red'
	let lastSelected = null


	canvas.raycaster.setFromCamera( mouse, canvas.camera )
	const intersectMesh = canvas.raycaster.intersectObject(mesh, false)


//show point on model 
	if (intersectMesh.length > 0) {

			


		 otherMarkersArray.forEach((ball, i)=> {
			if (i === markersArray.length)
				ball.material.color.set(ACTIVECOLOR)
			else
				ball.material.color.set(BASECOLOR)
			})

			const size = markerSize
			free(canvas.showBall)
			

			enableNavigation(false)
			canvas.showBall = drawBall(intersectMesh[0].point.x, intersectMesh[0].point.y, intersectMesh[0].point.z, size, BASECOLOR )
			if (!sizeAttenuation) {
				const scale = 1/canvas.camera.zoom
				canvas.showBall.scale.set(scale, scale, scale) 
			}

			
			if ( markersArray.length < maxPoints || canvas.selected)
				mesh.add(canvas.showBall) 

		}
		else {

			enableNavigation(true)
			free(canvas.showBall)
			otherMarkersArray.forEach(ball=> ball.material.color.set(BASECOLOR))
		}


	//show concordance of 2 points
		const clickedModel = canvas.raycaster.intersectObjects(markersArray)

		if ( clickedModel.length > 0 ) {
			canvas.renderer.domElement.style.cursor = 'pointer'
			const index = markersArray.indexOf(clickedModel[0].object)
			otherMarkersArray.forEach((ball, i)=> {
					if (i === index)
						ball.material.color.set(DELETECOLOR)
					else
						ball.material.color.set(BASECOLOR)
				})
		}
		else
			canvas.renderer.domElement.style.cursor = 'default'

	//move point on model
		canvas.renderer.domElement.onpointerdown = ()=> {
			if ( clickedModel.length > 0 ) {
				canvas.selected = clickedModel[0].object
				free(canvas.selected)

			}
		}


		canvas.renderer.domElement.onpointerup = ()=> {

			if (canvas.selected) {
				canvas.selected.position.set(canvas.showBall.position.x, canvas.showBall.position.y, canvas.showBall.position.z)
				mesh.add(canvas.selected)
				lastSelected = canvas.selected
			}
	
		}

		canvas.renderer.domElement.onpointermove = ()=> {
			if (realTime && canvas.selected) {
				canvas.selected.position.set(canvas.showBall.position.x, canvas.showBall.position.y, canvas.showBall.position.z)
			
				callback(lastSelected)
			}
		}


		canvas.renderer.domElement.onclick = e=> {
		//del point on model
			if ( clickedModel.length > 0 && e.shiftKey) {

				const index = markersArray.indexOf(clickedModel[0].object)
				free(clickedModel[0].object)
				free(otherMarkersArray[index])
				markersArray.splice(index, 1)
				otherMarkersArray.splice(index, 1)
				setState(markersArray)

			}
		//add point on model
			else if (!canvas.selected && intersectMesh.length > 0 && markersArray.length < maxPoints && !e.shiftKey) {
				const marker = canvas.showBall.clone()
				mesh.add(marker)
				lastSelected = marker
				markersArray.push(marker)
				setState(markersArray)
			}

			canvas.selected = null

			if (history && intersectMesh.length > 0)
				history.saveHistory()	


			callback(lastSelected) 		

		}

	}




	const free = (...objects) => {

		objects.forEach(object =>  {

			if (!object)
				return 

			if ( object.parent) object.parent.remove(object)
			if (object.material) object.material.dispose()
			if (object.geometry) object.geometry.dispose()

		})
	}

	const unmountThree = (scene, renderer) => {
		if (!scene || !renderer)
			return 
		
		scene.children.forEach(obj=>free(obj))
		renderer.dispose()	
	}



	const extendCurve = (curv, distance) => {

		const extend =  (t, d) => {
			const tangent = curv.getTangent(t)
			const point = curv.getPoint(t)
			const extend = point.add(tangent.multiplyScalar(d))

			return extend
		}
			
		const lastExtends = extend(1, distance)
		const firstExtends = extend(0, -distance)
		curv.points.push(lastExtends)
		curv.points.unshift(firstExtends)
	}

	const extrudeCurve = (curve, dist=50, define=200) => {

		const p1 = curve.getSpacedPoints( define )
		p1.forEach(vector=>vector.y += dist/2)
		const p2 = curve.getSpacedPoints( define )
		p2.forEach(vector=>vector.y -= dist/2)

		const pArray = [...p1, ...p2]

		const geo = new THREE.BufferGeometry()
		const vertices = []
		for (let i = 0; i < define; i++) {
			vertices.push(
				pArray[i+1].x, pArray[i+1].y, pArray[i+1].z,
				pArray[i].x, pArray[i].y, pArray[i].z,
				pArray[i+define+1].x, pArray[i+define+1].y, pArray[i+define+1].z,

				pArray[i+1].x, pArray[i+1].y, pArray[i+1].z,
				pArray[i+define+1].x, pArray[i+define+1].y, pArray[i+define+1].z,
				pArray[i+define+2].x, pArray[i+define+2].y, pArray[i+define+2].z,

			)
		}

		const v = new Float32Array(vertices)

		geo.setAttribute( 'position', new THREE.BufferAttribute( v, 3 ) )
		geo.computeVertexNormals()

		return geo
	}



	const toOrthographic = (camera, controls) => {
	//check type of camera

		if (camera.type === 'OrthographicCamera')
			return camera

		const target =  controls.getTarget()
		const position = camera.position

		const line_of_sight = new THREE.Vector3()
		camera.getWorldDirection( line_of_sight )
		const distance = target.clone().sub( position )
		const depth = distance.dot( line_of_sight )

		const aspect = camera.aspect

		const height = depth * 2 * Math.atan( camera.fov*(Math.PI/180) / 2 )
		const width = height * aspect


		const orthoCamera = new THREE.OrthographicCamera(
    		width / -2, width /  2,
    		height /  2, height / -2,
    		camera.near, camera.far )

		controls.camera = orthoCamera

		orthoCamera.position.copy( camera.position ) 
		orthoCamera.quaternion.copy( camera.quaternion )

		return orthoCamera 
	}

	const toPerspective = (camera, controls) => {
	//check type of camera

		if (camera.type === 'PerspectiveCamera')
			return camera

		const target =  controls.getTarget()
		const position = camera.position

		const line_of_sight = new THREE.Vector3()
		camera.getWorldDirection( line_of_sight )
		const distance = target.clone().sub( position )
		const depth = distance.dot( line_of_sight )


		const width = Math.abs(camera.right) *2 
		const height = Math.abs(camera.top) *2 
		const aspect = width / height

		const fov = (360/Math.PI) * Math.tan(height / depth / 2 )

		const perspectiveCamera = new THREE.PerspectiveCamera(
			fov, aspect, camera.near, camera.far )

		controls.camera = perspectiveCamera

		perspectiveCamera.position.copy( camera.position ) 
		perspectiveCamera.quaternion.copy( camera.quaternion )

		return perspectiveCamera

	}


const getRealBoundingBox = mesh => {

	const quaternion = mesh.quaternion.clone()

	mesh.geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromQuaternion ( quaternion ))
	mesh.quaternion.copy(new THREE.Quaternion())

	const bBox = new THREE.Box3().setFromObject(mesh)

	mesh.geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromQuaternion ( quaternion ).invert())
	mesh.quaternion.copy(quaternion)

	return bBox
}



					



export { loadTexture, loadCubeTexture, exportPLY, exportSTL, exportJSON, drawVoid, drawMarker, loadModel, createModel, drawBall, drawCube, drawPoint, drawText, toNDC, resetTransform, init3DScene, init2DScene,  setMarkers, calcMarkerRadius, drawLine, drawRect, free, unmountThree, extendCurve, extrudeCurve, toOrthographic, toPerspective, getRealBoundingBox }