import init from '../init'
//import socket from '../functions/socket'


const ajax = ({url, data={}, onProgress, onComplete}) => {

	const doNothing = () => null

	onProgress = onProgress ?? doNothing
	onComplete = onComplete ?? doNothing



	const sessId = sessionStorage.getItem('sessId')
//init
	const xhr = new XMLHttpRequest()
	xhr.open('POST', init.ajax + url)
	xhr.setRequestHeader('Content-Type', 'text/plain')



//functions
	const handleComplete = e => {
		onComplete(JSON.parse(e))
	}

	const handleProgress = e => {
		const percent = 100*e.loaded/e.total
		onProgress(Math.round(percent))

	}


//events

	xhr.addEventListener('readystatechange', e => {

		if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200)
			handleComplete(xhr.responseText)	
	})


	xhr.addEventListener('progress', e => handleProgress(e))
	xhr.upload.addEventListener('progress', e => handleProgress(e))
	

//send data
	const dataSecure = {sessId, ...data}
	xhr.send(JSON.stringify(dataSecure))


	return {data, onProgress, onComplete}

	
}



export default ajax
