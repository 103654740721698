/** @jsxImportSource @emotion/react */
import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import SmileDesigner from './SmileDesigner'
import hashNav from '../functions/hashNav'

//import SocketStream from '../functions/SocketStream'
import ajax from '../functions/ajaxx'

import socket from '../functions/socket'
import Wall from './Wall'
import { loadModel } from '../functions/threeCustomFunction'
import mirrorGeometry from '../functions/mirrorGeometry'
import { formatDate } from '../functions/formatFunctions'
import newDesign from '../icons/newDesign.svg'
import Cardtridge from './Cardtridge'


class DWD extends React.Component {

	constructor(props) {
		super(props)
		this.refDesigner = React.createRef()
	}

	cancel = () => {
		window.history.back()
	}

	gotoDesigner = saved => {
		const photo = this.props.caseContext[this.props.fileName.photo]
		const model = this.props.caseContext[this.props.fileName.model]

		if (photo.file && model.file && photo.landmarks && photo.camera && photo.camera.position && photo.camera.quaternion)
			hashNav({page: 'design', saved: saved?saved.id:''})	
	}

	saveDesign = data => {
	
		this.props.caseContext.setState({loading: true, loadingMessage: 'save'})

		const {dataTeeth, globalQuaternion, VDO, grouped, controlPoints, thumbnail, name, description} = data


		const teeth = dataTeeth.map(({number, position, quaternion, scale, visible}) => {
			return {
				number,
				position,
				quaternion,
				scale,
				visible
			}
		})

		const caseData = {
			name,
			description,
			controlPoints,
			globalQuaternion,
			VDO,
			grouped,
			teeth,
		}
		
		socket.emit('saveCase', this.props.caseContext.id, caseData, newSave => {

			if (newSave.error) {
				this.props.caseContext.setState({loading: false, loadingMessage: '', popup: true, popupMessage: newSave.error.message, popupError: true})
				return
			}

			const teethArray = dataTeeth.filter(dataTooth => dataTooth.visible === true).map(dataTooth => {
				return {
					name: dataTooth.number+'.ply',
					data: dataTooth.blob
				}
			})

			const originalArray = []
			const originalToSave = [17, 16, 15, 14, 13, 12, 11, 41, 42, 43, 44, 45, 46, 47]

			dataTeeth.forEach(dataTooth => {
				if ( originalToSave.includes(Number(dataTooth.number))) {
					originalArray.push( {
						name: dataTooth.number+'_original.ply',
						data: dataTooth.original				
					} )
				}

			})

			ajax({
				url: '/upload',
				data: {

					filesArray: [
						{name: 'thumbnail.jpg', data: thumbnail},
						...teethArray, ...originalArray
					],
					caseId: this.props.caseContext.id,
					folder: newSave.id, 
					root: 'casesFiles'
				},
				onProgress: percent => this.props.caseContext.setState({progress: percent}),
				
				onComplete: () => {

					const thumbURL = thumbnail??null

					const newSaved = {
						...newSave,
						thumbnail: thumbURL
					}


					this.props.caseContext.setState({
						loading: false, 
						loadingMessage: '',
						progress: false,
						savedList: [newSaved, ...this.props.caseContext.savedList]
					}, () => hashNav({page: 'design', saved: newSave.id})	
					)
				}
			})

		}) 
		
	}

	
	delDesign = (id, callback=()=>null) => {

		socket.emit('delSaveCase', this.props.caseContext.id, id, id => {

			if (id.error) {
				this.props.caseContext.setState({loading: false, loadingMessage: '', popup: true, popupMessage: id.error.message, popupError: true})
				return
			}
			
			const saved = this.props.caseContext.savedList
			const index = saved.findIndex(a => a.id === id)
			saved.splice(index, 1)
			this.props.caseContext.setState({savedList: [...saved]}, () => hashNav({page: 'design', saved: ''}))
			callback()

		})
	}


	loadDesign = id => {

		this.props.caseContext.setState({loading: true, loadingMessage: 'load saved design'})


		const getTeethData = new Promise(resolve => {
			socket.emit('loadSaveCase', this.props.caseContext.id, id, caseData => {
				resolve(caseData)
			})
		})


		const getGeometry = new Promise(resolve => {


			ajax({
				url: '/download',
				data: {
					files: [
						'11.ply', '12.ply', '13.ply', '14.ply', '15.ply', '16.ply', '17.ply', 
						'21.ply', '22.ply', '23.ply', '24.ply', '25.ply', '26.ply', '27.ply', 
						'11_original.ply', '12_original.ply', '13_original.ply', '14_original.ply', '15_original.ply', '16_original.ply', '17_original.ply',  
						'31.ply', '32.ply', '33.ply', '34.ply', '35.ply', '36.ply', '37.ply', 
						'41.ply', '42.ply', '43.ply', '44.ply', '45.ply', '46.ply', '47.ply', 
						'41_original.ply', '42_original.ply', '43_original.ply', '44_original.ply', '45_original.ply', '46_original.ply', '47_original.ply'],
					caseId: this.props.caseContext.id,
					folder: id, 
					root: 'casesFiles'
				},
				onProgressDownload: percent => this.props.caseContext.setState({progress: percent}),
				onComplete: blobs => {
					resolve(blobs)
				}

			}) 
		})


		Promise.all([getTeethData, getGeometry])
			.then(async ([teethData, blobs]) => {


				const geometries = {}
				const originals = {}
				const regex = new RegExp('_original$')

				const p = Object.entries(blobs).map(async ([number, blob]) => {

					const getGeometry = (number, blob) => 
						new Promise(resolve => {

							loadModel({url: blob})
								.then(geometry => {
									geometry.name = number
									resolve(geometry)
								})
						})

					if (regex.test(number)) {

						const num = Number(number.replace(regex, ''))
						const geo = await getGeometry(number, blob)
						originals[num] = geo
						originals[num].name = num

						const otherSide = num<30 ? 10 : -10
						originals[num+otherSide] = mirrorGeometry(geo.clone())
						originals[num+otherSide].name = num+otherSide

					}
					else 
						geometries[number] = await getGeometry(number, blob)

					
				})


				Promise.all(p).then(()=> {

					teethData.teeth.forEach(tooth => {
						
						const geometry = geometries[tooth.number] ?? originals[tooth.number]
						const original = originals[tooth.number]
						tooth.geometry = geometry
						tooth.original = original
					}) 

					this.props.caseContext.setState({
						loading: false, 
						loadingMessage: '',
						progress: false}, () => hashNav({page: 'design', saved: id}))

					if (this.refDesigner.current)
						this.refDesigner.current.load(teethData)
				})

			})	
	}


	exportDesign = exportObject => {

		socket.emit('checkJWT', () => {
			this.refDesigner.current.export(exportObject)
		})

	}


	render = () => {
		const photo = this.props.caseContext[this.props.fileName.photo]
		const buccal = this.props.caseContext[this.props.fileName.buccal]
		const model = this.props.caseContext[this.props.fileName.model]
		const mandibular = this.props.caseContext[this.props.fileName.mandibular]

		const show = this.props.caseContext.page === 'design'
		const showButton = this.props.caseContext.page === 'summary'
		const disabled = !photo.file || !photo.landmarks  || !photo.camera || !model.file || !photo.camera.position || !photo.camera.quaternion
	
		const thumbs = this.props.caseContext.savedList.map(saved => 
			<div key={saved.id} css={{boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)', position: 'relative', height: '100%', margin: 3, opacity: 0.7, '&:hover': {opacity: 1}, transition: 'all 0.5s', cursor: 'pointer'}} onClick={()=>this.gotoDesigner(saved)}>
				<img style={{objectFit: 'cover', height: '100%', aspectRatio: '1/1'}} src={saved.thumbnail} alt={saved.name}/>
				<div style={{position: 'absolute', top: 0, left: 0, fontSize: 10}}>
					<div style={{fontWeight: 'bold', textAlign: 'left', fontSize: 12}}>{saved.name}</div>
					<div>{formatDate(saved.date).englishFormat} {formatDate(saved.date).time}</div>
				</div>
				<div style={{position: 'absolute', bottom: 5, right: 0, fontSize: 12}}>by {saved.creator.name}</div>
			</div>
		)

		return <>
			{showButton&&<Cardtridge style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>

				<div style={{textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, bottom: '60%', left: 0, right: 0, transition: 'all 0.5s', fontSize: 30, fontWeight: 'bolder', textShadow: '0px 0px 2px white', opacity: disabled?0.4:1}}>smile designer</div>
					
				<div style={{overflowY: 'auto', position: 'absolute', bottom: 5, top: '40%', left: 0, right: 0, display: 'flex', alignItems: 'end', flexWrap: 'wrap'}}>
						
					<div css={{boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)', position: 'relative', height: '100%', margin: 3, opacity: 0.7, '&:hover': {opacity: disabled?0.7:1}, transition: 'all 0.5s', cursor: disabled?'not-allowed':'pointer'}} onClick={()=>this.gotoDesigner(null)}>
						<img style={{objectFit: 'fill', height: '100%', aspectRatio: '1/1'}} src={newDesign} alt='newDesign'/>
					</div>

					{thumbs}

				</div>

			</Cardtridge>}

			{show&&<SmileDesigner ref={this.refDesigner} photo={photo} buccal={buccal} model={model} mandibular={mandibular} caseContext={this.props.caseContext} cancel={this.cancel} load={this.loadDesign} save={this.saveDesign} del={this.delDesign} exportDesign={this.exportDesign}/>}
			{(!photo.file || !model.file)&&show&&<Wall callback={this.cancel}/>}



		</>
	}


}





export default CaseConsumer(DWD)

