import React from 'react'
import * as THREE from 'three'

import { OrthographicCamera } from '../functions/threeCustomClass'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Controls } from '../functions/threeCustomClass'

import { createModel, drawBall, drawVoid, toNDC, resetTransform, init3DScene, init2DScene, setMarkers, calcMarkerRadius, free, unmountThree } from '../functions/threeCustomFunction'
import History from '../functions/History'
import bgCanvas from '../functions/bgCanvas'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'

import Cardtridge from './Cardtridge'
import { Button } from './Button'
import TutoMatching from './TutoMatching'
import Menu from './Menu'
import Pills from './Pills'
import Transition from './Transition'
import { exportThumbnailToBase64 } from '../functions/thumbnailExporter'



import fit from '../icons/fit.svg'
import fitLips from '../icons/fitSmile.svg'
import reset from '../icons/refresh.svg'
import undo from '../icons/undo.svg'
import redo from '../icons/redo.svg'
import play from '../icons/play.svg'
import withTexture from '../icons/withTexture.svg'
import withoutTexture from '../icons/withoutTexture.svg'

import CaseConsumer from '../context/CaseContextConsumer'
//import Stats from 'three/examples/jsm/libs/stats.module.js'

const BASECOLOR = 'blue'


class MatchModel extends React.Component {

	constructor(props) {
		super(props)

		this.state= {
	focal: 90,
	exactFocal: true,
	withTexture: false,
	markersModel: [],
	markersPhoto: [],
	position: new THREE.Vector3(),
	rotation: new THREE.Euler(),
	opacity: 1
	}
		this.clock = new THREE.Clock()
		this.initialFocal = this.state.focal

		this.matchRef = React.createRef()
		this.twoDRef = React.createRef()
		this.threeDRef = React.createRef()

		this.mouse3D = new THREE.Vector2(5, 5)
		this.mouse2D = new THREE.Vector2(5, 5)

		this.markersModel = []
		this.markersPhoto = []

		this.matchNeedUpdate = false
		this.matchCanvasNeedsUpdate = false

		this.selected = null

		this.history = new History({
			update: state=>this.setState(state),
			loadState: this.loadState,
			saveState: this.saveState
		})	
	}


	componentDidMount = () => {

		this.props.caseContext.setState({loading: true})

	//get focal length
		if (this.props.photo.exif.hasOwnProperty('FocalLengthIn35mmFilm'))
			this.setState({focal: this.props.photo.exif.FocalLengthIn35mmFilm, exactFocal: true})

		else if (this.props.photo.exif.hasOwnProperty('FocalLength')) {
			const focalLength = this.props.photo.exif.FocalLength
			let focal = focalLength.numerator / focalLength.denominator

			if (/canon/i.test(this.props.photo.exif.Make)) focal /= 1.6
			else focal *= 1.5

			this.setState({focal, exactFocal: false})
		}

		else this.setState({focal: 90, exactFocal: false})


	//call async
		const mesh = createModel({
			url: this.props.model.file, 
		})

		const bgObject = bgCanvas(this.props.photo, this.props.buccal)

		Promise.all([ mesh, bgObject ]).then( ([ mesh, bgObject ]) => {

			this.props.caseContext.setState({loading: false})

			if (this.matchRef.current && this.twoDRef.current && this.threeDRef.current)
				this.sceneConstruct(mesh, bgObject)
		})

	}


	sceneConstruct = (mesh, bgObject)=> {


	//3D viewport
		this.canvas3D = init3DScene({
			ref: this.threeDRef,
			mesh
		})
		this.canvas3D.camera.setFocalLength(this.state.focal)
		this.canvas3D.controls.fitToBox(this.canvas3D.mesh, false)


	//2D viewport
		this.canvas2D = init2DScene({
			ref: this.twoDRef,
			photo: bgObject
		})
		this.canvas2D.controls.fitToBox(this.canvas2D.lips, false)


	//match viewport
		const WIDTHPANANDZOOM = this.matchRef.current.getBoundingClientRect().width
		const HEIGHTPANANDZOOM = this.matchRef.current.getBoundingClientRect().height
		const SCALE = new THREE.Sphere()
		new THREE.Box3().setFromObject(this.canvas2D.lips, true).getBoundingSphere(SCALE)
		const SCALE_FACTOR = SCALE.radius

		const bg = this.canvas2D.bgPlane.material.map

		this.sceneMatch = new THREE.Scene()
		this.cameraMatch = new THREE.PerspectiveCamera( 75, bg.image.width / bg.image.height, 1, 10000 )
		this.cameraMatch.position.z = 200
		this.cameraMatch.setFocalLength(this.state.focal)
		this.sceneMatch.background = bg
		this.modelMeshMatch = this.canvas3D.mesh.clone()

		//const modelMatchMaterial = this.canvas3D.mesh.material.clone()	
		this.transparentMaterial = 	new THREE.MeshBasicMaterial({transparent: true, opacity: 0})	
 		this.modelMeshMatch.material = this.transparentMaterial
 		//modelMatchMaterial.transparent = true
 		//modelMatchMaterial.opacity = 0.5
 		const light = new THREE.HemisphereLight(  0xffffff , 0x999999, 1 )
 		this.sceneMatch.add(light)


		this.rendererMatch = new THREE.WebGLRenderer({antialias: true})
		this.rendererMatch.setSize( bg.image.width, bg.image.height )
		this.composerMatch = new EffectComposer( this.rendererMatch )
		const renderPass = new RenderPass( this.sceneMatch, this.cameraMatch )
		this.composerMatch.addPass( renderPass )
		this.outlinePass = new OutlinePass( new THREE.Vector2( WIDTHPANANDZOOM, HEIGHTPANANDZOOM ), this.sceneMatch, this.cameraMatch )
		this.outlinePass.selectedObjects = [this.modelMeshMatch]
		this.outlinePass.edgeStrength = SCALE_FACTOR /50
		this.outlinePass.edgeThickness = SCALE_FACTOR / 200
		this.composerMatch.addPass( this.outlinePass )


	//pan & zoom viewport
		this.scenePanAndZoom = new THREE.Scene()
		this.cameraPanAndZoom = new OrthographicCamera( WIDTHPANANDZOOM/- 2, WIDTHPANANDZOOM/2, HEIGHTPANANDZOOM/2, HEIGHTPANANDZOOM /-2, 1, 1000 )
		this.cameraPanAndZoom.position.z = 100

		this.rendererPanAndZoom = new THREE.WebGLRenderer({antialias: true, alpha: true})
		this.rendererPanAndZoom.setSize( WIDTHPANANDZOOM, HEIGHTPANANDZOOM )

	//controls pan & zoom
		this.controlsPanAndZoom = new Controls( this.cameraPanAndZoom, this.rendererPanAndZoom.domElement )
		this.controlsPanAndZoom.mouseButtons.wheel = Controls.ACTION.ZOOM
		this.controlsPanAndZoom.mouseButtons.middle = Controls.ACTION.ZOOM
	//prevent rotation
		this.controlsPanAndZoom.minPolarAngle = Math.PI/2
		this.controlsPanAndZoom.maxPolarAngle = Math.PI/2
		this.controlsPanAndZoom.minAzimuthAngle = 0
		this.controlsPanAndZoom.maxAzimuthAngle = 0	

	//2D plane
		this.matchedPlaneCanvas = new THREE.CanvasTexture(this.rendererMatch.domElement)
		const matchedMaterial = new THREE.MeshBasicMaterial( { map: this.matchedPlaneCanvas, color: 0xffffff } )
		const matchedGeometry = new THREE.PlaneGeometry( this.matchedPlaneCanvas.image.width, this.matchedPlaneCanvas.image.height, 1, 1 )
		this.matchedPlane = new THREE.Mesh( matchedGeometry, matchedMaterial )

		this.controlsPanAndZoom.fitToBox(this.matchedPlane, false)

	//add to scene
		this.scenePanAndZoom.add( this.matchedPlane )

	//append context
		this.matchRef.current.appendChild( this.rendererPanAndZoom.domElement)

	//render
		this.composerMatch.render()


	//events
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('keydown', this.handleKeyDown)

		this.threeDRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouse3D, this.canvas3D.renderer.domElement))

		this.threeDRef.current.addEventListener('pointerout', ()=> {
			this.mouse3D.set(5, 5)
			if (this.canvas3D.selected) {
				this.canvas3D.mesh.add(this.canvas3D.selected)
				this.canvas3D.selected = null
			}
		})

		this.twoDRef.current.addEventListener('pointermove', e=> toNDC(e, this.mouse2D, this.canvas2D.renderer.domElement))
		
		this.twoDRef.current.addEventListener('pointerout', ()=> {
			this.mouse2D.set(5, 5)
			if (this.selected2D) {
				this.canvas2D.bgPlane.add(this.selected2D)
				this.selected2D = null
			}
		})	

		this.setOpacity(this.state.opacity)
		this.loadState(this.props.caseContext.photoFace.camera.matchPoints)


		/* this.stats = new Stats()
		this.matchRef.current.appendChild( this.stats.dom ) */

		this.history.saveHistory()
		this.animate()


	}


	componentWillUnmount = () => {
	//avoid leak memory	
		cancelAnimationFrame(this.loop)
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('keydown', this.handleKeyDown)
		if (this.canvas3D) this.canvas3D.dispose()
		if (this.canvas2D) this.canvas2D.dispose()
		unmountThree(this.sceneMatch, this.rendererMatch)

		if (this.rendererPanAndZoom)
			this.rendererPanAndZoom.dispose()

	}


	handleKeyDown = e => {

		if (!this.props.caseContext.enableShortcuts)
			return

		if (e.code==='KeyW' && e.ctrlKey) {
			e.preventDefault()
			this.history.undo()		
		}

		if (e.code==='KeyY' && e.ctrlKey) {
			e.preventDefault()
			this.history.redo()
		}

		if (this.state.markersPhoto.includes(this.lastSelected)) {
			if (e.code==='ArrowLeft') {
				e.preventDefault()
				this.lastSelected.position.x -= 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowRight') {
				e.preventDefault()
				this.lastSelected.position.x += 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowUp') {
				e.preventDefault()
				this.lastSelected.position.y += 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

			else if (e.code==='ArrowDown') {
				e.preventDefault()
				this.lastSelected.position.y -= 0.5
				this.matchNeedUpdate = true
				this.history.saveHistory()
			}

		}

		
	}


	handleResize = () => {

	//make it responsive
		const contexts = [
			{renderer: this.rendererPanAndZoom, ref: this.matchRef, camera: this.cameraPanAndZoom}, 
			{renderer: this.canvas3D.renderer, ref: this.threeDRef, camera: this.canvas3D.camera}, 
			{renderer: this.canvas2D.renderer, ref: this.twoDRef, camera: this.canvas2D.camera}
		]

		contexts.forEach(context => context.renderer.domElement.remove())

		contexts.forEach(context => {
			const WIDTH = context.ref.current.getBoundingClientRect().width
			const HEIGHT = context.ref.current.getBoundingClientRect().height
			context.renderer.setSize(WIDTH, HEIGHT)
			context.camera.responsive(WIDTH/HEIGHT)
		})

		contexts.forEach(context => context.ref.current.appendChild(context.renderer.domElement))


	}

	fit2D = (enableTransition=true) => {
		this.canvas2D.controls.fitToBox(this.canvas2D.lips, enableTransition)
	}

	fit3D = (enableTransition=true) => {
		this.canvas3D.controls.fitToBox(this.canvas3D.mesh, enableTransition)
	}

	fitPanAndZoom = (enableTransition=true) => {
		this.controlsPanAndZoom.fitToBox(this.matchedPlane, enableTransition)
	}

	fitPanAndZoomLips = (enableTransition=true) => {
		let size = calcMarkerRadius(this.matchedPlane, 10)
		this.controlsPanAndZoom.fitToBox(this.canvas2D.lips, enableTransition, { paddingTop: size, paddingLeft: size, paddingBottom: size, paddingRight: size } )
	}


	animate = () => {
		this.loop = requestAnimationFrame(this.animate)
		this.refresh()
	}


	drawMarkers3D = () => {

		let size = calcMarkerRadius(this.canvas3D.mesh, 50)

		setMarkers({
			canvas: this.canvas3D, 
			mouse: this.mouse3D, 
			mesh: this.canvas3D.mesh,
			otherMesh: this.canvas2D.bgPlane, 
			markersArray: this.markersModel, 
			otherMarkersArray: this.markersPhoto, 
			markerSize: size,
			enableNavigation: bool => this.canvas3D.controls.enabled = bool, 
			history: this.history,
			callback: lastSelected => {
				this.lastSelected = lastSelected
				this.matchNeedUpdate = true
			},
			setState: markersModel => this.setState({markersModel})

		})
	}

	drawMarkers2D = () => {

		let size = calcMarkerRadius(this.canvas2D.lips, 50)

		setMarkers({
			canvas: this.canvas2D, 
			mouse: this.mouse2D, 
			mesh: this.canvas2D.bgPlane,
			otherMesh: this.canvas3D.mesh, 
			markersArray: this.markersPhoto, 
			otherMarkersArray: this.markersModel, 
			markerSize: size,
			enableNavigation: ()=>true, 
			history: this.history,
			callback: lastSelected => {
				this.lastSelected = lastSelected
				this.matchNeedUpdate = true
			},
			realTime: true,
			setState: markersPhoto => this.setState({markersPhoto})
		})
	}


	refresh = () => {

		const delta = this.clock.getDelta()
		this.controlsPanAndZoom.update( delta )
		this.canvas3D.controls.update(delta)
		this.canvas2D.controls.update(delta)



		if (!this.canvas3D.controls.controlled || this.canvas3D.selected)
			this.drawMarkers3D()

		this.drawMarkers2D()

		if (this.matchNeedUpdate)
			this.match()



	//anim loop
		this.canvas3D.renderer.render(this.canvas3D.scene, this.canvas3D.camera)
		this.canvas2D.renderer.render(this.canvas2D.scene, this.canvas2D.camera)
		this.rendererPanAndZoom.render(this.scenePanAndZoom, this.cameraPanAndZoom)
		
		if (this.matchCanvasNeedsUpdate) {
			this.canvas2D.bg.needsUpdate = true
			this.matchedPlaneCanvas.needsUpdate = true
			this.composerMatch.render()
			this.matchCanvasNeedsUpdate = false
		} 


//this.stats.update()

//console.log(this.rendererMatch.info.memory)
	}


	sortMarkers = () => {

		const comparePosition = (a, b) => a.position.x - b.position.x
		const sortedPhoto = [...this.markersPhoto].sort(comparePosition)
		const first = this.markersPhoto.indexOf(sortedPhoto[0])
		const second = this.markersPhoto.indexOf(sortedPhoto[1])
		const third = this.markersPhoto.indexOf(sortedPhoto[2])
		const fourth = this.markersPhoto.indexOf(sortedPhoto[3])

		this.markersPhoto = [this.markersPhoto[first], this.markersPhoto[second], this.markersPhoto[third], this.markersPhoto[fourth]]
		this.markersModel = [this.markersModel[first], this.markersModel[second], this.markersModel[third], this.markersModel[fourth]]

	}


	match = () => {

		if (this.markersPhoto.length < 4 || this.markersModel.length < 4)
			return

	if (!this.state.exactFocal)
		this.cameraMatch.setFocalLength(90)		


	//reset rotate marker parent
		const resetRotate = () => {
			this.sceneMatch.attach(this.modelMeshMatch)
			m0.quaternion.copy(new THREE.Quaternion())
			m0.attach(this.modelMeshMatch)
		}


		const matchRotation = ({axis, rotate}) => {

			const findRotation = []

			const STEP = 10
			for (let i = 0; i <360*STEP; i++) {

				let rad = 1/STEP*Math.PI/180
				m0.rotateOnWorldAxis(axis, rad)
				this.modelMeshMatch.updateMatrixWorld()

				const m0OnScreen = m0.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
				const m1OnScreen = m1.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
				const m2OnScreen = m2.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
				const m3OnScreen = m3.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)

				const mMiddleOnScreen = new THREE.Vector3().lerpVectors(m1OnScreen, m2OnScreen, 0.5)

				let condition
				let offset

				if (rotate === 'X') {
					condition = m0.getWorldPosition(new THREE.Vector3()).z < m1.getWorldPosition(new THREE.Vector3()).z
					offset = Math.abs(mMiddleOnScreen.y - pMiddleOnScreen.y)
				}
				else if (rotate === 'Y'){
					condition = m0OnScreen.x < m3OnScreen.x
					const pProportion = (p3OnScreen.x - p0OnScreen.x) / (pMiddleOnScreen.x - p0OnScreen.x)
					const mProportion = (m3OnScreen.x - m0OnScreen.x) / (mMiddleOnScreen.x - m0OnScreen.x)
					offset = Math.abs(mProportion - pProportion)
				}
				else if (rotate === 'Z') {
					condition = m0OnScreen.x < m1OnScreen.x
					offset = Math.abs(m3OnScreen.y - p3OnScreen.y)
				}
				else
					return


				if ( condition )
					findRotation.push({quaternion: m0.quaternion.clone(), offset})
		
			} 

			const minOffset = Math.min(...findRotation.map(({offset})=>offset))
			const rotation = findRotation.filter(({offset})=> offset === minOffset)
			if (rotation[0])
				m0.setRotationFromQuaternion(rotation[0].quaternion)
			else
				return
		}


	//reset model position
		resetTransform(this.modelMeshMatch)
		this.sceneMatch.remove(this.modelMeshMatch)
		this.sortMarkers()

	//calc project vector from plane's markers position
		const bg = this.canvas2D.bgPlane.material.map.image
		const halfCam = this.cameraMatch.position.z/2

		const coord0 = new THREE.Vector3(this.markersPhoto[0].position.x/(bg.width/2)*halfCam, this.markersPhoto[0].position.y/(bg.height/2)*halfCam, 0).unproject(this.cameraMatch)
		const coord1 = new THREE.Vector3(this.markersPhoto[1].position.x/(bg.width/2)*halfCam, this.markersPhoto[1].position.y/(bg.height/2)*halfCam, 0).unproject(this.cameraMatch)
		const coord2 = new THREE.Vector3(this.markersPhoto[2].position.x/(bg.width/2)*halfCam, this.markersPhoto[2].position.y/(bg.height/2)*halfCam, 0).unproject(this.cameraMatch)
		const coord3 = new THREE.Vector3(this.markersPhoto[3].position.x/(bg.width/2)*halfCam, this.markersPhoto[3].position.y/(bg.height/2)*halfCam, 0).unproject(this.cameraMatch)

		const p0 = drawVoid(coord0.x, coord0.y, 0)
		const p1 = drawVoid(coord1.x, coord1.y, 0)
		const p2 = drawVoid(coord2.x, coord2.y, 0)
		const p3 = drawVoid(coord3.x, coord3.y, 0)
		this.sceneMatch.add(p0, p1, p2, p3, this.modelMeshMatch)


	//add model's markers
		const m0 = drawVoid(this.markersModel[0].position.x, this.markersModel[0].position.y, this.markersModel[0].position.z)
		const m1 = drawVoid(this.markersModel[1].position.x, this.markersModel[1].position.y, this.markersModel[1].position.z)
		const m2 = drawVoid(this.markersModel[2].position.x, this.markersModel[2].position.y, this.markersModel[2].position.z)
		const m3 = drawVoid(this.markersModel[3].position.x, this.markersModel[3].position.y, this.markersModel[3].position.z)
		this.modelMeshMatch.add(m0, m1, m2, m3)


	//prepare hierarchy
		this.sceneMatch.attach(m0)
		m0.attach(this.modelMeshMatch)
		const p0OnScreen  = p0.position.clone().project(this.cameraMatch)
		const p1OnScreen  = p1.position.clone().project(this.cameraMatch)
		const p2OnScreen  = p2.position.clone().project(this.cameraMatch)
		const p3OnScreen  = p3.position.clone().project(this.cameraMatch)

		const pMiddleOnScreen = new THREE.Vector3().lerpVectors(p1OnScreen, p2OnScreen, 0.5)



//match position XY

		m0.position.project(this.cameraMatch)
		m0.position.x = p0OnScreen.x
		m0.position.y = p0OnScreen.y 
		m0.position.unproject(this.cameraMatch)


//match rotation Y

		matchRotation({
			axis: new THREE.Vector3(0, 1, 0),
			rotate: 'Y'
		})


//match position Z

		resetRotate()

		const axisZ = m0.getWorldPosition(new THREE.Vector3()).sub(p0.getWorldPosition(new THREE.Vector3())).normalize()

		for (var i = 0; i <1000; i++) {
			this.modelMeshMatch.updateMatrixWorld()

			const m0OnScreen = m0.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
			const m3OnScreen = m3.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
			const mDist = Math.sqrt(Math.pow(m0OnScreen.x - m3OnScreen.x, 2) + Math.pow(m0OnScreen.y - m3OnScreen.y, 2))
			const pDist = Math.sqrt(Math.pow(p0OnScreen.x - p3OnScreen.x, 2) + Math.pow(p0OnScreen.y - p3OnScreen.y, 2))

			const step = mDist - pDist>0?-1:1
			m0.translateOnAxis(axisZ, step)

		}


//match rotation Z
		resetRotate()

		matchRotation({
			axis: axisZ,
			rotate: 'Z'
		})



//match rotation X
		resetRotate()

		matchRotation({
			axis: m0.getWorldPosition(new THREE.Vector3()).sub(m3.getWorldPosition(new THREE.Vector3())).normalize(),
			rotate: 'X'
		})


//match focal length if not in exif

		if (!this.state.exactFocal) {

			const findFocal = []
			for (let i = 1; i < 200; i+=0.5) {
				this.cameraMatch.setFocalLength(i)
				const m1OnScreen = m1.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
				const m2OnScreen = m2.getWorldPosition(new THREE.Vector3()).project(this.cameraMatch)
				const offset1 = new THREE.Vector2(p1OnScreen.x, p1OnScreen.y).distanceTo(new THREE.Vector2(m1OnScreen.x, m1OnScreen.y))
				const offset2 = new THREE.Vector2(p2OnScreen.x, p2OnScreen.y).distanceTo(new THREE.Vector2(m2OnScreen.x, m2OnScreen.y))
				findFocal.push({focal: i, offset1, offset2})
			}

			const minOffset1 = Math.min(...findFocal.map(({offset1})=>offset1))
			const minOffset2 = Math.min(...findFocal.map(({offset2})=>offset2))
			const focal1 = findFocal.filter(({offset1})=> offset1 === minOffset1)
			const focal2 = findFocal.filter(({offset2})=> offset2 === minOffset2)

			if (focal1[0] && focal2[0]) {
				const f1 = focal1[0].focal
				const f2 = focal2[0].focal
				const f = ( f1 + f2 )/2

				this.cameraMatch.setFocalLength(f)		
				this.setState({focal: f})
			}
		}
		

	//restore hierarchy
		this.sceneMatch.attach(this.modelMeshMatch)
		this.modelMeshMatch.attach(m0)
		free(p0, p1, p2, p3)
		this.modelMeshMatch.clear()

	//render
		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()
		this.matchNeedUpdate = false
		this.setState({position: new THREE.Vector3().copy(this.modelMeshMatch.position), rotation: new THREE.Euler().copy(this.modelMeshMatch.rotation)})


	}

	changeFocal = e => {

		let value = e.target.value
		if (value < 5) value = 5
		if (value > 200) value = 200

		this.setState({focal: value})
	
		this.cameraMatch.setFocalLength(value)		
		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()

	}

	toogleMaterial = bool => {

		if (bool === true) {
			this.modelMeshMatch.material = this.canvas3D.mesh.material
			//this.composerMatch.removePass( this.outlinePass )
			this.outlinePass.enabled = false
		}
		else {
			this.modelMeshMatch.material = this.transparentMaterial
			//this.composerMatch.addPass( this.outlinePass )
			this.outlinePass.enabled = true

		}
		
		this.setState({withTexture: bool})
		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()
		//this.matchNeedUpdate = true
	}

	saveState = () => {
		return {
			markersModel: this.markersModel.map(marker => marker.position.toArray()),
			markersPhoto: this.markersPhoto.map(marker => marker.position.toArray())
		}


	}


	loadState = state => {

	if (!state)
		return

	//update markers on model
		this.markersModel.forEach(mark => {
			free(mark)
		})

		const markersModel = state.markersModel.map(mark => {
			const size = calcMarkerRadius(this.canvas3D.mesh, 50)
			const [x, y, z] = mark
			const ball = drawBall(x, y, z, size, BASECOLOR )
			this.canvas3D.mesh.add(ball)
			return ball
		})
		this.markersModel = markersModel

	//update markers on plane
		this.markersPhoto.forEach(mark => {
			free(mark)
		})

		const markersPhoto = state.markersPhoto.map(mark => {
			let size = calcMarkerRadius(this.canvas2D.lips, 50)
			const [x, y, z] = mark
			const ball = drawBall(x, y, z, size, BASECOLOR )
			this.canvas2D.bgPlane.add(ball)
			return ball
		})
		this.markersPhoto = markersPhoto

		this.setState({markersModel: this.markersModel, markersPhoto: this.markersPhoto})


	//update model matching
		this.sceneMatch.remove(this.modelMeshMatch)
		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()

		this.matchNeedUpdate = true
	}

	reset = () =>{

		this.setState({focal: this.initialFocal})
		this.cameraMatch.setFocalLength(this.initialFocal)		
		this.canvas3D.camera.setFocalLength(this.initialFocal)

		this.fit2D()
		this.fit3D()
		this.fitPanAndZoom()

		this.markersModel.forEach(mark => {
			free(mark)
		})

		this.markersPhoto.forEach(mark => {
			free(mark)
		})

		this.markersModel = []
		this.markersPhoto = []
		this.setState({markersModel: this.markersModel, markersPhoto: this.markersPhoto})

		this.sceneMatch.remove(this.modelMeshMatch)
		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()

		this.history.saveHistory()

	}

	fineUpdate = ({px, py, pz, rx, ry, rz}) => {
		if (px) { this.setState({position: new THREE.Vector3(px, this.state.position.y, this.state.position.z)}); this.modelMeshMatch.position.x = px }
		if (py) { this.setState({position: new THREE.Vector3(this.state.position.x, py, this.state.position.z)}); this.modelMeshMatch.position.y = py }
		if (pz) { this.setState({position: new THREE.Vector3(this.state.position.x, this.state.position.y, pz)}); this.modelMeshMatch.position.z = pz }
		
		if (rx) { this.setState({rotation: new THREE.Euler(rx, this.state.rotation.y, this.state.rotation.z)}); this.modelMeshMatch.rotation.x = rx }
		if (ry) { this.setState({rotation: new THREE.Euler(this.state.rotation.x, ry, this.state.rotation.z)}); this.modelMeshMatch.rotation.y = ry }
		if (rz) { this.setState({rotation: new THREE.Euler(this.state.rotation.x, this.state.rotation.y, rz)}); this.modelMeshMatch.rotation.z = rz }

		this.matchedPlaneCanvas.needsUpdate = true
		this.composerMatch.render()
	}


	saveCameraTransform = async () => {

		if (this.markersPhoto.length === 4 && this.markersModel.length === 4) {
		//focus on lips & texture model

			const fit = () =>
				new Promise(resolve=> {

					const controlsSleep = () => {
						this.controlsPanAndZoom.removeEventListener('sleep', controlsSleep)
						resolve()
					}

					this.controlsPanAndZoom.addEventListener('sleep', controlsSleep)
					this.fitPanAndZoomLips(false)

				})
			


			this.toogleMaterial(true)
			await fit()


		//save camera coordinate
			const modelMeshMatch = this.modelMeshMatch.clone()
			const cameraMatch = this.cameraMatch.clone()

			modelMeshMatch.attach(cameraMatch)
			resetTransform(modelMeshMatch)

			const cameraTransform = {
				position: cameraMatch.position.toArray(),
				quaternion: cameraMatch.quaternion.toArray(),
				focalLength: this.state.focal,
				matchPoints: this.saveState()
			}

		//save thumbnail
			const thumbnail = exportThumbnailToBase64({
				scene: this.scenePanAndZoom,
				camera: this.cameraPanAndZoom,
				renderer: this.rendererPanAndZoom
			})

			Promise.all([ cameraTransform, thumbnail ]).then(([ camera, thumbnail ]) => {
				this.props.save({camera, thumbnail})
			})

		}
	}

	setOpacity = opacity => {
		this.setState({opacity})
		this.canvas2D.photo.setOpacity(opacity)
		this.canvas2D.bg.needsUpdate = true
		this.matchCanvasNeedsUpdate = true
	}



	render = () => { 

		const valid = this.state.markersModel.length===4 && this.state.markersPhoto.length===4

		return <>

			<div style={{position: 'fixed', zIndex: 10, top: 0, bottom: 0, left: 0, right: 0}}>		

				<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '3fr 2fr', gridGap: 5}}>
					<div ref={this.matchRef} style={{position: 'relative', gridColumn: '1 / span 2', gridRow: 1}} onContextMenu={e=>e.preventDefault()}></div>

					<div ref={this.twoDRef} style={{position: 'relative', gridColumn: 1, gridRow: 2}} onContextMenu={e=>e.preventDefault()}>
						<TutoMatching fit={this.fit2D}/>
						{this.state.markersPhoto.length<4&&<div style={{position: 'absolute', top: 0, left: 0, background: 'rgba(255, 255, 255, 0.6)', padding: 5, fontWeight: 'bold'}}>add {4-this.state.markersPhoto.length} {this.state.markersPhoto.length===3?'point':'points'} on photo</div>}
					</div>

					<div ref={this.threeDRef} style={{position: 'relative', gridColumn: 2, gridRow: 2}} onContextMenu={e=>e.preventDefault()}>
						<TutoMatching fit={this.fit3D}/>
						{this.state.markersModel.length<4&&<div style={{position: 'absolute', top: 0, left: 0, background: 'rgba(255, 255, 255, 0.6)', padding: 5, fontWeight: 'bold'}}>add {4-this.state.markersModel.length} {this.state.markersModel.length===3?'point':'points'} on model</div>}

					</div>

				</div>

				<Menu>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<Button icon={reset} label='reset' onClick={this.reset}/>
						<Button icon={fit} label='center viewport' onClick={this.fitPanAndZoom}/>
						<Button icon={fitLips} label='center viewport' onClick={this.fitPanAndZoomLips}/>
					</Cardtridge>

					{this.props.buccal.file&&
						<Cardtridge>
							<input style={{minWidth: 200, width: '60%'}} type='range' min={0} max={1} step={0.01} value={this.state.opacity} onChange={e=>this.setOpacity(e.target.value)} />
						</Cardtridge>
					}

					<Transition mounted={valid} transition='leftSlide'>
					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<img src={withoutTexture} style={{height: 20, cursor: 'pointer'}} alt='withoutTexture' title='untexture model' onClick={()=>this.toogleMaterial(false)}/>
						<Pills onChange={this.toogleMaterial} title='toogle material' value={this.state.withTexture}/>
						<img src={withTexture} style={{height: 20, cursor: 'pointer'}} alt='withTexture' title='texture model' onClick={()=>this.toogleMaterial(true)}/>
					</Cardtridge>
					<Cardtridge>
						<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
							<div style={{fontSize: 10, fontWeight: 'bold'}}>position</div>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.position.x)/100} step={0.1} onChange={e=>this.fineUpdate({px: e.target.value})}/>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.position.y)/100} step={0.1} onChange={e=>this.fineUpdate({py: e.target.value})}/>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.position.z)/100} step={0.1} onChange={e=>this.fineUpdate({pz: e.target.value})}/>
						</div>
						<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
							<div style={{fontSize: 10, fontWeight: 'bold'}}>rotation</div>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.rotation.y*180/Math.PI)/100} step={0.1} onChange={e=>this.fineUpdate({ry: e.target.value*Math.PI/180})}/>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.rotation.x*180/Math.PI)/100} step={0.1} onChange={e=>this.fineUpdate({rx: e.target.value*Math.PI/180})}/>
							<input style={{fontSize: 10, width: '20%'}} type='number' value={Math.round(100*this.state.rotation.z*180/Math.PI)/100} step={0.1} onChange={e=>this.fineUpdate({rz: e.target.value*Math.PI/180})}/>
						</div>

						<div style={{display: 'flex', alignitems: 'center', justifyContent: 'space-between'}}>
							<div style={{fontSize: 10, fontWeight: 'bold'}}>focal length</div>
							<input style={{fontSize: 10, width: '50%'}} type='number' min={5} max={200} step={0.5} value={this.state.focal} onChange={this.changeFocal}/>
						</div>

					</Cardtridge>
					</Transition>
					
					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<Button icon={undo} label='undo' onClick={this.history.undo} disabled={this.state.disabledUndo}/>
						<Button icon={redo} label='redo' onClick={this.history.redo} disabled={this.state.disabledRedo}/>
					</Cardtridge>

					<Cardtridge style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
						<img src={play} style={{height: 20, cursor: 'pointer', transform: 'rotateZ(180deg)'}} alt='previous step' title='previous step' onClick={this.props.cancel}/>
						<img src={play} style={{height: 20, cursor: valid?'pointer':'not-allowed', opacity: valid?1:0.5, transition: 'opacity 0.5s'}} alt='next step' title='next step' onClick={this.saveCameraTransform}/>
					</Cardtridge>

				</Menu>
			</div>

		</>
	}
	 
}





export default CaseConsumer(MatchModel)