import React from 'react'
import invisible from '../icons/invisible.svg'
import visible from '../icons/visible.svg'


class RawInput extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			focus: false,
			value: '',
			valid: false,
		}

		this.inputRef = React.createRef()
	}

	componentDidMount = () =>
	{//use default value localstorage > sessionstorage > defaultvalue

		if (this.props.localStorage && localStorage.getItem(this.props.localStorage))
			this.updateValue(localStorage.getItem(this.props.localStorage))
		
		else if (this.props.sessionStorage && sessionStorage.getItem(this.props.sessionStorage))
			this.updateValue(sessionStorage.getItem(this.props.sessionStorage))

		else
			this.updateValue(this.props.defaultValue)

	}

	componentDidUpdate = (prevProps, prevState) => {
		
		if (prevProps.defaultValue !== this.props.defaultValue)
			this.updateValue(this.props.defaultValue)
	}


	handleFocus = () => {
		this.setState({focus: true})
		this.props.onFocus()
	}
		
	handleBlur = e => {
		this.setState({focus: false})
		this.props.onBlur()

	}

	handleChange = e => {
		this.updateValue(e.target.value)
	}

	erase = () => {
		this.updateValue('')
		this.focus()
	}

	focus = () => {
		this.inputRef.current.focus()
	}

	blur = () => {
		this.inputRef.current.blur()
	}

	validation = value => {

		const regTest = this.props.regex.test(value)
		const validTest = this.props.validFunction(value)

		if (regTest && validTest)
			{
				this.setState({valid: true})
				this.props.valid(true)
			}
		else
			{
				this.setState({valid: false})
				this.props.valid(false)
			}
	}
	

	updateValue = value => {
		value = this.props.format(value)

		this.setState({value})
		this.props.value(value)

		this.validation(value)


		if (this.props.localStorage)
			localStorage.setItem(this.props.localStorage, value)

		else if (this.props.sessionStorage)
			sessionStorage.setItem(this.props.sessionStorage, value)
	}


	render = () => {

		const labelUp = this.state.focus || this.state.value
		const showDelete = this.state.value && !this.props.disabled && this.props.showDelete

		return <>
			<div style={{position: 'relative', padding: 10, marginTop: 15}}>
				<input 
					type={this.props.type}
					style={{
						width: '95%', 
						padding: 10, 
						margin: 3,
						fontWeight: 'bolder', 
						textAlign: 'center', 
						border: this.state.focus?'solid 1px grey':'solid 1px black', 
						boxShadow: this.state.focus?'0px 0px 0px 2px grey':'none', 
						outline: 'none',
						cursor: this.props.disabled?'not-allowed':'auto',
						transition: 'border 0.5s, box-shadow 0.5s'}} 
					onFocus={this.handleFocus} 
					onBlur={this.handleBlur} 
					value={this.state.value} 
					onChange={this.handleChange}
					disabled={this.props.disabled}
					ref={this.inputRef}
					autoComplete='on'/>

				<div style={{
						color: labelUp?'grey':'black', 
						position: 'absolute', 
						top: labelUp?0:'50%', 
						left:  labelUp?0:'50%', 
						transform: labelUp?'translate(8px, -50%)':'translate(-50%, -50%)', 
						pointerEvents: 'none', 
						transition: 'left 0.5s, top 0.5s, transform 0.5s, color 0.5s'}}>

					{this.props.label} 
					<span style={{color: 'green', opacity: this.state.valid?1:0, transition: 'opacity 0.5s'}}>&nbsp; &#x2714;</span>
				</div>

				{showDelete&&<div 
					style={{
						position: 'absolute',
						right: 20,
						top: '50%',
						transform: 'translateY(-50%)',
						cursor: 'pointer'
						}}
					onClick={this.erase}
					disabled={true}>

					&#x274C;
				</div>}

			</div>
		</>
	}
}

RawInput.defaultProps = {
	type: 'text',
	defaultValue: '',
	value: value=>value,
	valid: valid=>valid,
	format: value=>value,
	regex: /.+/,
	validFunction: ()=>true,
	onFocus: ()=>null,
	onBlur: ()=>null,
	showDelete: true

}



const MailInput = React.forwardRef( (props, ref) => {

	return <RawInput 
			ref={ref}
			format={val=>val.toLowerCase().trim()} 
			regex={/^.+@.+\..{2,4}$/}
			{...props}/>
})

MailInput.defaultProps = {
	label: 'put your mail adress, please' 
}


const UsernameInput = React.forwardRef((props, ref) => 
	<RawInput
		ref={ref}
		label='username'
		{...props}/>
)


const CasenameInput = props => 
	<RawInput
		label=''
		{...props}/>


const PasswordInput = React.forwardRef((props, ref) => {

	const iconStyle = {position: 'absolute', left: 20, top: '50%', transform: 'translateY(-50%)', width: 20, height: 20, cursor: 'pointer'}
	const [showPW, setShowPW] = React.useState(false)

	const tooglePW = () => {
		setShowPW(!showPW)
	}

	return <div style={{position: 'relative'}}>
		<RawInput
			ref={ref}
			label={props.label}
			type={showPW?'text':'password'}
			{...props}/>

		<img style={iconStyle} title={showPW?'hide':'show'} alt={showPW?'hide':'show'} src={showPW?visible:invisible} onClick={tooglePW} />
	</div>
})

PasswordInput.defaultProps = {
	label: 'password'
}


class ChoosePasswordInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showPW: false,
			showRPW: false,
			valuePW: '',
			valueRPW: '',
			validPW: '',
			validRPW: '',
			isUppercase: false,
			isNumber: false,
			is6: false
		}

		this.refPW = React.createRef()
		this.refRPW = React.createRef()
	}
	componentDidMount = () => {
		this.checkRPWValidation()
	}

	tooglePW = () => {
		this.setState({showPW: !this.state.showPW})
	}

	toogleRPW = () => {
		this.setState({showRPW: !this.state.showRPW})
	}

	changeValuePW = value => {
		this.setState({valuePW: value}, ()=> {

			this.props.value(value)
			this.validPWRules(value)

			this.checkRPWValidation()

		})

	}

	checkRPWValidation = () => {
		const inputRPW = this.refRPW.current

		if (inputRPW) 
			inputRPW.validation(inputRPW.state.value)
	}	

	changeValueRPW = value => {
		this.setState({valueRPW: value})
	}


	validPWRules = value => {
		this.setState({
			isUppercase: /[A-Z]+/.test(value),
			isNumber: /[0-9]+/.test(value),
			is6: /.{6,}/.test(value)
		})
	}

	validRPWFunction = value => {
		return this.state.valuePW === value
	}

	validPW = valid => {
		this.setState({validPW: valid}, ()=>this.valid())
	}

	validRPW = valid => {
		this.setState({validRPW: valid}, ()=>this.valid())
	}


	valid = () => {
		this.props.valid(this.state.validPW && this.state.validRPW)
	}

	erase = () => {
		this.refPW.current.erase()
		this.refRPW.current.erase()
		this.refRPW.current.blur()
	}

	render = () => {

		const iconStyle = {position: 'absolute', left: 20, top: '50%', transform: 'translateY(-50%)', width: 20, height: 20, cursor: 'pointer'}


		return <>

			<div style={{position: 'relative', marginTop: 20}}>
				<RawInput
					ref={this.refPW}
					label='choose a password'
					type={this.state.showPW?'text':'password'}
					regex={/(?=.*[A-Z])(?=.*[0-9]).{6,}/}
					value={this.changeValuePW}
					valid={this.validPW}
					defaultValue = {this.props.defaultValue}
					/>

				<img style={iconStyle} title={this.state.showPW?'hide':'show'}  alt={this.showPW?'hide':'show'} src={this.state.showPW?visible:invisible} onClick={this.tooglePW} />

				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', fontSize: 12, marginTop: -10}}>
					<div style={{fontWeight: 'bold'}}>required : &nbsp;&nbsp;</div>
					<div> <span style={{color: 'green', opacity: this.state.is6?1:0, transition: 'opacity 0.5s'}}>&#x2714; &nbsp;</span><span>6 characters </span></div>
					<div> <span style={{color: 'green', opacity: this.state.isUppercase?1:0, transition: 'opacity 0.5s'}}>&#x2714; &nbsp;</span><span>1 uppercase </span></div>
					<div> <span style={{color: 'green', opacity: this.state.isNumber?1:0, transition: 'opacity 0.5s'}}>&#x2714; &nbsp;</span><span>1 number </span></div>

				</div>
			</div>


			<div style={{position: 'relative'}}>
				<RawInput
					ref={this.refRPW}
					label='repeat your password'
					type={this.state.showRPW?'text':'password'}
					value={this.changeValueRPW}
					validFunction={this.validRPWFunction}
					valid={this.validRPW}
					defaultValue = {this.props.defaultValue}
					/>

				<img style={iconStyle} title={this.state.showRPW?'hide':'show'}  alt={this.showPW?'hide':'show'} src={this.state.showRPW?visible:invisible} onClick={this.toogleRPW} />
			</div>

		</>
	}
}

ChoosePasswordInput.defaultProps = {
	value: value=>value,
	valid: valid=>valid
}





export default RawInput
export { MailInput, UsernameInput, CasenameInput, PasswordInput, ChoosePasswordInput }