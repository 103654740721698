/** @jsxImportSource @emotion/react */


import React, {useState} from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import UserConsumer from '../context/UserContextConsumer'
import socket from '../functions/socket'
import {formatDate} from '../functions/formatFunctions'
import { css, keyframes } from '@emotion/react'




const Chat = props=> {

	const divStyle = { 
		position : 'relative', 
		height : '100%',
		display : 'flex', flexDirection : 'column-reverse'
	}

	const [answer, setAnswer] = useState('')

	return (

		<div style={divStyle}>
			<ChatInput answer = {answer} eraseAnswer={()=>setAnswer('')}/>
			<ChatBody answer = {object=>setAnswer(object)} answered={answer}/>
		</div>

	)
}






class ChatInput extends React.Component {

	constructor(props) {
		super(props)
		this.inputRef = React.createRef()
		this.state = {msg : ''}
	}
	
	componentDidUpdate = prevProps => {
  	if (this.props.answer !== prevProps.answer) this.inputRef.current.focus()
	}

	componentWillUnmount = ()=>clearTimeout(this.timerChange)

	sendMsg = e=>{
		e.preventDefault()
		const disabled = this.state.msg.trim() === ''

		if (!disabled) {
			socket.emit('saveChatMsg', this.state.msg, this.props.caseContext.id, this.props.answer._id)
			this.setState({msg : ''}, this.props.eraseAnswer)
		}
		
	}

	handleChange = e=>{
		
		this.setState({msg : e.target.value})
		clearTimeout(this.timerChange)
		
		if (!this.writing) this.emit(true)

			this.timerChange = setTimeout(()=>{
			
				this.writing = false

				let data = {
					typing : false,
					id : this.props.userContext.id,
					username : this.props.userContext.username
				}

				socket.emit('typeOnChat', this.props.caseContext.id, data)
		
		},1000)

	}

	emit = writing=>{

		this.writing = writing

		let data = {
			typing : writing,
			id : this.props.userContext.id,
			username : this.props.userContext.username
		}

		socket.emit('typeOnChat', this.props.caseContext.id, data)

	}

	focus = bool => {
		this.props.caseContext.setState({enableShortcuts: !bool})
	}


	render = ()=>{

		let author = this.props.answer.author ? this.props.answer.author._id===this.props.userContext.id : false
		const disabled = this.state.msg.trim() === ''

		const divStyle = {
			position : 'relative',
			display : 'flex',  justifyContent : 'space-between', alignItems: 'center',
			padding : 5
		}

		const styleIcon = {
			transform : 'rotate(-30deg)',
			cursor : !disabled ? 'pointer' : 'not-allowed',
			opacity : !disabled ? 1 : 0.5
		}

		const styleInput = {
			maxWidth : '80%'
		}

		const answerStyle = {
			width : '100%', borderRadius : 5,
			backgroundColor : author? 'lightgreen' : 'lightgrey',
			padding : 4,
			marginTop : 10, marginBottom : 10,
			textAlign :  'left',
			cursor : 'pointer',
			whiteSpace : 'pre-wrap'
		}

	
		return (

			<form onSubmit={this.sendMsg}>


				{this.props.answer && <div style= {answerStyle} onDoubleClick={this.props.eraseAnswer} title='Cancel answer'> 	
					<div style={{ fontWeight : 'bold'}}>{this.props.answer.author && (author? 'You' : this.props.answer.author.username)}</div>
					{this.props.answer.msg}
				</div> }

				<div style={divStyle}>

					<MultilineInput 
						ref = {this.inputRef}
						style = {styleInput}
						value = {this.state.msg} 
						onChange = {this.handleChange}
						onSubmit = {this.sendMsg}
						onFocus={()=>this.focus(true)}
						onBlur={()=>this.focus(false)}
					/>
					
					<i className="material-icons" style={styleIcon} onClick={this.sendMsg}>send</i>

				</div>	

				<Typing/>

			</form>

		)

	}

}



class Typing extends React.Component {

	constructor(props) {
		super(props)
		this.state = {typer : []}
	}

	componentDidMount = ()=>socket.on('typing',this.type)

	componentWillUnmount = ()=>socket.off('typing',this.type)

	type = data=>data.typing ? this.addTyper(data) : this.removeTyper(data)

	addTyper = typer=>{
		if ( this.state.typer.findIndex(element=>element.id===typer.id) === -1 ) this.setState({typer : [...this.state.typer, typer]})
	}

	removeTyper = typer=>{

		let index = this.state.typer.findIndex(element=>element.id===typer.id)
		this.state.typer.splice(index,1)
		this.forceUpdate()

	}

	render = ()=>{

		const divStyle = {
			height : 25,
			fontSize : '0.8em',
			fontStyle: 'italic'
				
		}

		return (

			<div style={divStyle}>

				{this.state.typer.length === 1 && <span>{`${this.state.typer[0].username} is typing...`}</span>}

				{this.state.typer.length > 1 && <span>{`${this.state.typer.length} users are typing...`}</span>}

			</div>
		)
	}
}



class ChatBody extends React.Component {

	constructor(props) {

		super(props)

		this.bodyRef = React.createRef()
		this.initialLimit = 40

		this.state = {
			msgList : [], totalMsg : 0,
			newRef : '',
			arrow : false,
			limit : this.initialLimit,
		
		}
	}


	componentDidMount = () => {

		socket.emit('joinChatRoom', this.props.caseContext.id)

		let options = {
  			root: this.bodyRef.current,
  			rootMargin: '0px',
  			threshold: [0, 0.5, 1]
		}

		this.observer = new IntersectionObserver(this.lastVisible, options)

		this.loadList().then(()=>this.goToNew(true))

		socket.on('chatSubscribe',this.addMsg)
			
	}

	componentWillUnmount = ()=>{
		socket.emit('leaveChatRoom', this.props.caseContext.id)
		socket.off('chatSubscribe',this.addMsg)
		this.observer.disconnect()
	}

	
 	findNewRef = (list)=>{
		let testRead = list.map(msg=>msg.readers.includes(this.props.userContext.id))
		let index = testRead.indexOf(false)
		return index
		
	}


	fetchChatMsg = (skip)=> {

		return new Promise ((resolve, result)=>{

			socket.emit('fetchChatMsg', this.props.caseContext.id, this.state.limit, skip, messagers=>{
			//	console.log(messagers) 
				messagers.list.reverse()
				resolve(messagers)
			})

		})

	}


	loadList =  ()=>{



		return new Promise (async (resolve, reject)=>{

			let loop = true
			const unloop = () => {
				loop = false
				resolve()
			}
			// search last readed message



			while (loop) {

				let messages = await this.fetchChatMsg()
				let indexLastRead = this.findNewRef(messages.list)

				if (indexLastRead === 0 && messages.list.length!==messages.totalMsgs) this.setState({limit : this.state.limit + this.initialLimit})
				else this.setState({msgList : messages.list, totalMsg : messages.totalMsgs, new : indexLastRead===-1 ? false : messages.list[indexLastRead]._id}, unloop)

			}
	
		})	

	}



	addMsg = (id)=>{

		socket.emit('fetchChatOneMsg', id, message=>{
			
			let author = message.msg.author._id === this.props.userContext.id

			this.setState({msgList : [...this.state.msgList, message.msg], totalMsg : message.totalMsgs,  limit : this.state.limit+1},()=>{
				author || !this.state.arrow ? this.goToNew() : this.setState({signalNew : true})
			})
		})
	}


	lastVisible = (entries, observer)=>{
		
		entries.forEach(entry =>{
			let lastMsgVisible = entry.intersectionRatio !== 0
			this.setState({arrow : lastMsgVisible ? false : true, signalNew : lastMsgVisible ? false : this.state.signalNew})
		})

	}

	goToNew = start=>this.bodyRef.current.scrollTo({top: this.bodyRef.current.scrollHeight,behavior: start? 'instant' : 'smooth'})

	downloadOlder = ()=>{
		let skip = this.state.limit
		let lastBodyHeight = this.bodyRef.current.scrollHeight
		this.setState({limit : this.state.limit + this.initialLimit}, async ()=>{
			
			let messages = await this.fetchChatMsg(skip)

			this.setState({msgList : [...messages.list, ...this.state.msgList], totalMsg : messages.totalMsgs},()=>{
				let updateMove = this.bodyRef.current.scrollHeight - lastBodyHeight
  	 		this.bodyRef.current.scrollTop = updateMove
			})

		})
	}

	scroll = e=>{
		if (this.bodyRef.current.scrollTop===0 && this.state.msgList.length<this.state.totalMsg) this.downloadOlder()
	}


	render = ()=> {

		const divStyle = {
			display : 'flex', flexDirection : 'column',
			overflowY : 'auto', overflowX : 'hidden',
			width : '100%', 
		}


		return (

			<div ref={this.bodyRef} style = {divStyle} onScroll={this.scroll}>

					{this.state.msgList.map((data, index, array)=>
						<ChatBubble 
							key={index} 
							data={data} 
							index={index} 
							list={array} 
							answer={this.props.answer}
							observer = {this.observer}
							new = {this.state.new===data._id}
							initialLimit = {this.initialLimit}
						/>
					)}

					{this.state.arrow && <GoDownArrow onClick={e=>this.goToNew()} new = {this.state.signalNew}/>}

			</div>
			
		)
	}
}



class ChatBubble extends React.Component {

	constructor(props) {
      super(props)
      this.bubbleRef = React.createRef()
      this.newRef = React.createRef()

      this.state = {readers : this.props.data.readers}
    }

  componentDidMount(){

    if (this.props.new && this.props.list.length===this.props.initialLimit) this.timer = setTimeout(this.goTo,0)
			
		if (this.props.index === this.props.list.length-1) this.props.observer.observe(this.bubbleRef.current)
			
		this.checkReading()
			
		socket.on(`bubbleChatSubscribe${this.props.data._id.toString()}`,this.updateBubble)

		this.saveReader()

  }

  componentWillUnmount = ()=>{
  	socket.off(`bubbleChatSubscribe${this.props.data._id.toString()}`,this.updateBubble)
  	clearTimeout(this.timer)
  }
    

  componentDidUpdate = (prevProps, prevState)=>{

  	if (this.props.list.length !== prevProps.list.length) this.props.index === this.props.list.length-1 ? this.props.observer.observe(this.bubbleRef.current) : this.props.observer.unobserve(this.bubbleRef.current)

  }


  saveReader = ()=>socket.emit('saveReaderBubble', this.props.data._id)

  updateBubble = ()=>{
  	socket.emit('fetchChatOneMsg',this.props.data._id,data=>this.setState({readers : data.msg.readers}, this.checkReading))
  }

  checkReading = ()=>{
  	let allReaders = [this.props.data.case.creator, ...this.props.data.case.userAccess]
  	let viewed = allReaders.map(reader=>this.state.readers.includes(reader))
  	let testViewed = viewed.findIndex(elem=>elem===false)
  	return testViewed===-1 ? true : false
  }

	dateConversion = date=>{
		let time = formatDate(new Date(date))
		return `${time.englishFormat} ${time.time}`
	}

	goTo = ()=>this.newRef.current.scrollIntoView(true)

	render = ()=>{
		const author = this.props.data.author._id===this.props.userContext.id
		
		const newLabelStyle = {
			alignSelf : 'stretch',
			marginTop : 10, marginBottom : 10, padding : 5,
			borderRadius : 5,
			backgroundColor : '#ff6600',
		}


		const bubbleStyle = {
			position : 'relative',
			borderRadius : 5,
			backgroundColor : author? 'lightgreen' : 'lightgrey',
			margin : 5, marginRight : 15, marginLeft : 15, padding : 4,
			width : '70%',
			textAlign : 'left', 
			cursor :'pointer',
			alignSelf: author ? 'flex-end' : 'flex-start',
			whiteSpace : 'pre-wrap'
		}

		const dateStyle = {
			textAlign : 'right',
			fontSize : '0.8em',
			color : 'grey', fontStyle : 'italic'
		}

		const answerStyle = {
			width : '80%', borderRadius : 5,
			backgroundColor : 'rgba(255,255,255,0.5)', 
			padding : 4,
		}

		const triangle1Style = {
			position : 'absolute',
			right : -45, top : 0
		}

		const triangle2Style = {
			position : 'absolute',
			left : -15, top : 0
		}

		const checkStyle = {
			position : 'absolute',
			top : 0, right : 0
		}

		const AnswerComponent = <div style= {answerStyle}> 
				
					<div style={{ fontWeight : 'bold' }}>{this.props.data.answered && ((this.props.data.answered.author._id===this.props.userContext.id)? 'You' : this.props.data.answered.author.username)}</div>
				
					{this.props.data.answered && this.props.data.answered.msg}
				
				</div>

		
	

		return (

			<>

				{this.props.new && <div ref={this.newRef} style = {newLabelStyle}>New messages</div> }

				
				<div  ref={this.bubbleRef} style={bubbleStyle} onDoubleClick={()=>this.props.answer(this.props.data)}>

					{!author && <div style={{ fontWeight : 'bold' }}>{this.props.data.author.username}</div>}

					{this.props.data.answered && AnswerComponent}
					
					{this.props.data.msg}
				
					<div style={dateStyle}>{this.dateConversion(this.props.data.date)}</div>

					{author && (!this.props.list[this.props.index-1] || this.props.data.author._id !== this.props.list[this.props.index-1].author._id) && <svg height="50" width="50" style={triangle1Style}> <polygon points="0,0 20,0 0,20" fill='lightgreen' /> </svg>}

					{!author && (!this.props.list[this.props.index-1] || this.props.data.author._id !== this.props.list[this.props.index-1].author._id) && <svg height="50" width="50" style={triangle2Style}> <polygon points="0,0 20,0 20,20" fill='lightgrey' /> </svg>}

					{this.checkReading() && author && <div style={checkStyle}>🗸</div>}

				</div>

			</>

		)
	}
}





const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

const cssMessage = css`
	display : flex ; justify-content : center ; align-items : center ; align-self : flex-end ;
	border-radius : 10px ; height : 20 ; width : 20 ;
	position : absolute ;
	right : 10px ; 
	background-color: lightgray ;
	cursor : pointer ;
`

const cssMessageAnimate = css`
	display : flex ; justify-content : center ; align-items : center ; align-self : flex-end ;
	border-radius : 10px ; height : 20 ; width : 20 ;
	position : absolute ;
	right : 10px ; 
	background-color: lightgray ;
	animation: ${bounce} 1s ease infinite ;
	cursor : pointer ;
`



const GoDownArrow = props=> {
	
		let arrowStyle = props.new? cssMessageAnimate : cssMessage

		return (

			<div css={arrowStyle} onClick={props.onClick}>

			<i className="material-icons" >keyboard_double_arrow_down</i>

			</div>

		)
	}



class MultilineInput extends React.Component {

   constructor(props) {
     super(props)
     this.inputRef = React.createRef()
   }

   componentDidMount = ()=>this.inputRef.current.addEventListener('keypress',this.keyPress)

   componentWillUnmount = ()=>this.inputRef.current.removeEventListener('keypress',this.keyPress)

   componentDidUpdate = prevProps => {
  		if (this.props.value !== prevProps.value && this.props.value==='') this.adaptSize() 
	}

   focus = ()=>this.inputRef.current.focus()

   keyPress = e=>{
    	if (e.keyCode===13 && !e.shiftKey) this.props.onSubmit(e)
   }

	handleChange = e=>{
		this.props.onChange(e)
		this.adaptSize()
	}

	adaptSize = () => {

		let element = this.inputRef.current
 
    	element.style.height = 'auto'
    	element.style.padding = '0px'
    	element.style.height = element.scrollHeight + 'px'
   		element.style.padding = ''
	}

  render() {

    const areaStyle = {
    	...this.props.style,
    	lineHeight : `14px`,
    	resize: 'none',
    }

    return (

      <textarea 
	      {...this.props} 
	      style = {areaStyle}
	      ref = {this.inputRef}
	      rows = {1}
	      onChange = {this.handleChange}	
      />

    )
  }
}







ChatInput = CaseConsumer(ChatInput)
ChatInput = UserConsumer(ChatInput)

Typing = CaseConsumer(Typing)

ChatBody = CaseConsumer(ChatBody)
ChatBody = UserConsumer(ChatBody)

ChatBubble = UserConsumer(ChatBubble)

export default CaseConsumer(Chat)