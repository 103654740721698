

class RotateCoords {

    constructor(angle, rotateCenter) {
        this.rotateCenter = rotateCenter
        this.angle = angle 
        //* Math.PI / 180 
    }

    calcCoords = (point)=>{
        let xM = point.x - this.rotateCenter.x
        let yM = point.y - this.rotateCenter.y

        let x = xM * Math.cos (this.angle) + yM * Math.sin (this.angle) + this.rotateCenter.x
        let y = - xM * Math.sin (this.angle) + yM * Math.cos (this.angle) + this.rotateCenter.y
    
        return ({x:Math.round (x), y:Math.round (y)})

    }
}


export default RotateCoords