import React from 'react'
import Cardtridge from './Cardtridge'
import UserConsumer from '../context/UserContextConsumer'
import { UpdatePW, ContentAccountInformations} from './ProfilComponents'
import {CardStatus, SubscribeStatus} from './subscribeProfil'
import Invoices from './invoices'
import { formatDate } from '../functions/formatFunctions'
import Contact from './Contact'

import gcuEV from '../GCU/CGVEV.pdf'
import gcuVF from '../GCU/CGVVF.pdf'

import frFlag from '../icons/frFlag.png'
import engFlag from '../icons/engFlag.png'


const Profil = props => {

	const refMenu = React.useRef(null)
	const refFirst = React.useRef(null)

	const [cursorPosition, setCursorPosition] = React.useState(0)
	const [title, setTitle] = React.useState('account informations')

	React.useEffect(() => {
		refFirst.current.select()
		props.userContext.setState({loading : false})
	}, [])

	const select = ({title, cursorPosition}) => {

		const top = refMenu.current.getBoundingClientRect().top
		setCursorPosition(cursorPosition - top)
		setTitle(title)
	}

	const goToMainPage = e => {
		window.history.back()
	}

	const stopPropagation = e => {
		e.stopPropagation()
	}


	const menuList = ['Account informations', 'Password', 'Payment methods', 'Subscription', 'Invoices', 'Contact', 'General conditions of use']
	const first = props.userContext.subscription && (props.userContext.subscription.status==='active' || props.userContext.subscription.status==='trial') && new Date().getTime() < (props.userContext.subscription.expiration*1000)+(3*3600*1000) ? 0 : 3

	const menu = menuList.map((element, index)=><Title key={index} select={select} title={element} ref={index===first ? refFirst : null}/>)


	return <div onClick={goToMainPage} style={{position: 'absolute', left: 0, bottom: 0, right: 0, top: 50}}>
			<Cardtridge onClick={stopPropagation} style={{position: 'absolute', top: '10%', bottom: '10%', left: '10%', right: '10%', display: 'flex', alignItems: 'center'}}>
				
				<Cardtridge ref={refMenu} style={{height: '90%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start'}}>

					<div style={{position: 'absolute', top: '10%', bottom: '10%', left: 44, width: 2, background: '#ff6600'}}></div>
					<div style={{height: 30, width: 30, borderRadius: 30, background: '#ff6600', position: 'absolute', left: 30, top: cursorPosition, transition: 'top 0.4s'}}></div>

					{menu}

				</Cardtridge>

				<div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

					{title === 'Account informations' ? 
						<ContentAccountInformations/> :

					title === 'Password' ? 
						<UpdatePW/> :

					title === 'Payment methods' ?
						<CardStatus/> :

					title === 'Subscription' ?
						<SubscribeStatus/> :

					title === 'Invoices' ?
						<Invoices/> :

					title === 'Contact' ?
						<Contact/> :

					title === 'General conditions of use' ?
						<GCU date={props.userContext.date}/> :

						null
					}

				</div>
			</Cardtridge>
		</div>
}







class Title extends React.Component {

	constructor(props) {
		super(props)
		this.ref = React.createRef()
	}

	select = () => {
		const rect = this.ref.current.getBoundingClientRect()
		this.props.select({title: this.props.title, cursorPosition: rect.bottom})
	}

	render = () => {
		return <div onClick={this.select} ref={this.ref} style={{fontWeight: 'bold', marginLeft: 50, cursor: 'pointer'}}>
			{this.props.title}
		</div>
	}
}


const GCU = props=>{

	const divIconStyle = {
		padding : 30
	}


	return <div>

		<div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
			<div style={divIconStyle} title='download english'><a href={gcuEV} download='GCU_Cadsmile3D.pdf'><img alt='en' src={engFlag} width = {50}/></a></div>
			<div style={divIconStyle} title='download french'><a href={gcuVF} download='CGU_Cadsmile3D.pdf'><img alt='fr' src={frFlag} width = {50}/></a></div>
		</div>

		<div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
			<div style={{fontWeight: 'bold'}}>accepted : &nbsp;</div>
			<div>{formatDate(props.date).text}</div>
		</div>

	</div>

}

	

	


export default UserConsumer(Profil)