
import React from 'react'
import CaseConsumer from '../context/CaseContextConsumer'
import socket from '../functions/socket'
import Cardtridge from './Cardtridge'
import Chat from './chat'
import {readSearch} from '../functions/crypto'



class ChatNotification extends React.Component {

	constructor(props) {
		super(props)
		this.state = {count : 0, visible : false}
	}

	componentDidMount = ()=>{

	 	this.page = readSearch(window.location.hash.split('?')[1])
	 	
	 	this.fetchNonRead()
	 	socket.emit('joinChatRoom', this.page.id)
	 	socket.on('chatSubscribe',this.fetchNonRead)
	 }

	componentWillUnmount = ()=>{
		socket.emit('leaveChatRoom', this.page.id)
		socket.off('chatSubscribe',this.fetchNonRead)
	}

	toggleChat = e=> this.setState({visible : !this.state.visible})

	fetchNonRead = (caseId=this.page.id)=>{
		socket.emit('fetchNonRead', caseId, count=>this.setState({count : count}))
	}


	render = ()=>{

		const styleIcon = {
			...this.props.style,
			//border : 'solid',
			marginTop : 5

		}

		const numberStyle = {
			display : 'flex',  justifyContent : 'center', alignItems: 'center', 
			position : 'absolute',
			top : 0, right : -20,
			width : 20, height : 20, 
			backgroundColor : 'red', borderRadius : 10, fontSize : '0.8em', color : 'white',
			cursor : 'pointer'

		}

		return (

			<>

				<div style = {{position : 'relative'}} onClick={this.toggleChat}>

					<i className="material-icons" style={styleIcon}>message</i>

					{this.state.count>0 && <div style = {numberStyle}>{this.state.count}</div>}

				</div>

				{this.state.visible && this.props.caseContext.id && <Cardtridge style={{position : 'fixed', top : 30, bottom : 0, left : 0, width : 250, gridColumn: 1, gridRow: '1 / span 3' }}><Chat/></Cardtridge>}

			</>
		)
	}


}



export default CaseConsumer(ChatNotification)