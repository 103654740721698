import { ContactMethods } from './Contact'


const Smart = props => {
	return <div>
		mobile version in development, please go to https://cadsmile3d.com with you personal computer.

		<div style={{marginTop: 40, fontWeight: 'bold'}}>Follow us on facebook, youtube and instagram</div>

		<ContactMethods/>

		<iframe width={'"'+window.innerWidth+'"'} height="315" src="https://www.youtube-nocookie.com/embed/videoseries?list=PLttuzysZJttG3mwbWsbQ7cEG1RLnCfyXU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>




	</div>
}

export default Smart